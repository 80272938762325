import React, { useState } from "react";
import companiesTypes from "../../../companiesTypes";
import { useDispatch, useSelector } from "react-redux";
import { hideAlert, showAlert } from "../../../features/alertSlice";
import { createDistributor } from "../../../features/userSlice";
import { useNavigate } from "react-router-dom";
const initialValues = {
  fullName: "",
  email: "",
  password: "",
  confirmPassword: "",
  phoneCode: "+43",
  phone: "",
  commissionRate: "",
  companyLegalForm: companiesTypes[0].title,
  companyName: "",
};

function AdminCreateDistributor() {
  const [values, setValues] = useState(initialValues);
  const [userActive, setUserActive] = useState(false);
  const { isVisible } = useSelector((store) => store.alert);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onValueUpdate = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "phone") {
      if(value.length > 10){
        return
      }
    }
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    let error = 0;
    if (values.password !== values.confirmPassword) {
      dispatch(
        showAlert({
          alertContent: "Passwort muss gleich sein",
          alertType: "danger",
        })
      );
      setTimeout(() => {
        dispatch(hideAlert());
      }, 2000);
      return;
    }
    if (values.commissionRate >= 100) {
      dispatch(
        showAlert({
          alertContent: "Geben Sie alle gültigen Werte ein",
          alertType: "danger",
        })
      );

      setTimeout(() => {
        dispatch(hideAlert());
      }, 3000);
      return;
    } else if (values.phone.length > 10) {
      dispatch(
        showAlert({
          alertContent: "Bitte geben Sie eine gültige Telefonnummer ein",
          alertType: "danger",
        })
      );

      setTimeout(() => {
        dispatch(hideAlert());
      }, 3000);

      return ;
    } else {
      if (values.phone.length <= 10) {
        await dispatch(
          createDistributor({
            ...values,
            isActive: userActive,
            phone: values.phoneCode + values.phone,
          })
        );
      }
      setValues(initialValues);
      navigate("/dashboard/admins/distributors");
    }
  };

  return (
    <>
      <div className="container">
        <div className="page-header row mb-4">
          <div className="col-12">
            <div className="d-flex">
              <div className="me-auto page-title-holder">
                <h2 className="section-title text-dark ">
                  Verteiler erstellen
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="distform">
          <div className="card">
            <form action="" onSubmit={onFormSubmit}>
              <div className="mb-3">
                <label
                  htmlFor="fullNameField"
                  className="form-label small-text"
                >
                  Vollständiger Name
                </label>
                <input
                  type={"text"}
                  name={"fullName"}
                  id={"fullNameField"}
                  className="form-control form-control-sm border-1 border-info shadow-none bg-white"
                  placeholder={"Vollständiger Name"}
                  value={values.fullName}
                  onChange={onValueUpdate}
                  required
                />
              </div>

              <div className="mb-3">
                <label htmlFor="emailField" className="form-label small-text">
                  Email
                </label>
                <input
                  type={"email"}
                  name={"email"}
                  id={"emailField"}
                  className="form-control form-control-sm border-1 border-info shadow-none bg-white"
                  placeholder={"Email"}
                  value={values.email}
                  onChange={onValueUpdate}
                  required
                />
              </div>

              <div className="row row-cols-md-2">
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="passwordField"
                      className="form-label small-text"
                    >
                      Kennwort
                    </label>
                    <input
                      type={"password"}
                      name={"password"}
                      id={"passwordField"}
                      className="form-control form-control-sm border-1 border-info shadow-none bg-white"
                      placeholder={"Kennwort"}
                      value={values.password}
                      onChange={onValueUpdate}
                      required
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="confirmPasswordField"
                      className="form-label small-text"
                    >
                      Kennwort bestätigen
                    </label>
                    <input
                      type={"password"}
                      name={"confirmPassword"}
                      id={"confirmPasswordField"}
                      className="form-control form-control-sm border-1 border-info shadow-none bg-white"
                      placeholder={"Kennwort bestätigen"}
                      value={values.confirmPassword}
                      onChange={onValueUpdate}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="row row-cols-md-2">
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="row mb-3">
                    <div className="col-3">
                      <label
                        htmlFor="phoneField"
                        className="small-text form-label small-text"
                      >
                        Landesvorwahl
                      </label>
                      <select
                        name="phoneCode"
                        id="phoneCodeField"
                        className={`form-select form-select-sm bg-light shadow-none w-100 mr-1`}
                        defaultValue={values.phoneCode}
                        onChange={onValueUpdate}
                        required
                      >
                        {["+49", "+43"].map((code, index) => {
                          return (
                            <option
                              key={index}
                              value={code}
                              selected={code === values?.phone?.slice(0, 3)}
                            >
                              {code}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-9">
                      <div>
                        <label
                          htmlFor="phoneField"
                          className="form-label small-text"
                        >
                          Telefon
                        </label>
                        <input
                          type={"text"}
                          name={"phone"}
                          id={"phoneField"}
                          className="form-control form-control-sm border-1 border-info shadow-none bg-white"
                          placeholder={"Telefon"}
                          value={values.phone}
                          onChange={onValueUpdate}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="commissionRateField"
                      className="form-label small-text"
                    >
                      Provisionssatz (%)
                    </label>
                    <input
                      type={"number"}
                      name={"commissionRate"}
                      id={"commissionRateField"}
                      className="form-control form-control-sm border-1 border-info shadow-none bg-white"
                      placeholder={"Provisionssatz"}
                      value={values.commissionRate}
                      onChange={onValueUpdate}
                      min={0}
                      max={100}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="row row-cols-md-2">
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="companyNameField"
                      className="form-label small-text"
                    >
                      Name der Firma
                    </label>
                    <input
                      type={"text"}
                      name={"companyName"}
                      id={"companyNameField"}
                      className="form-control form-control-sm border-1 border-info shadow-none bg-white"
                      placeholder={"Name der Firma"}
                      value={values.companyName}
                      onChange={onValueUpdate}
                      min={0}
                      required
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  <label
                    htmlFor="companyLegalFormField"
                    className="form-label small-text mb-2"
                  >
                    Gesellschaft Rechtsform
                  </label>
                  <select
                    name="companyLegalForm"
                    id="companyLegalFormField"
                    className="form-select form-select-sm border-1 border-info shadow-none"
                    value={values.companyLegalForm}
                    onChange={onValueUpdate}
                    required
                  >
                    {companiesTypes &&
                      companiesTypes.map((com, index) => {
                        const { title, label } = com;
                        return (
                          <option defaultValue key={index} value={title}>
                            {label}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-12">
                  <div className="form-check">
                    <input
                      className="form-check-input shadow-none"
                      type="checkbox"
                      name="isActive"
                      value={userActive}
                      onChange={(e) => {
                        setUserActive(!userActive);
                      }}
                      id="isActiveField"
                    />
                    <label className="form-check-label" htmlFor="isActiveField">
                      Ist aktiv
                    </label>
                  </div>
                </div>
              </div> */}
              <div className="col-6 mx-auto d-flex justify-content-center mt-4">
                <input
                  type="submit"
                  value="Verteiler erstellen"
                  className="btn btn-dark "
                />

                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="btn btn-outline-dark ms-2"
                >
                  zurück
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminCreateDistributor;
