import { useSelector } from 'react-redux'

function Alert() {
  const { isVisible, alertType, alertContent } = useSelector(
    (store) => store.alert,
  )
  return (
    <>
      {isVisible && (
        <div
          className={`alert alert-${alertType} alert-dismissible fade show`}
          role="alert"
          style={{ zIndex: 10000,}}
        >
          {alertContent}
        </div>
      )}
    </>
  )
}

export default Alert
