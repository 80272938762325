import { useState } from 'react'

function useMultiStepForm(steps) {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentForm, setCurrentForm] = useState(steps[0])
  const [isOpen, setIsOpen] = useState(false)
  const nextStep = () => {
    if (currentIndex <= steps.length - 1) {
      const newIndex = currentIndex + 1
      setCurrentIndex(newIndex)
      setCurrentForm(steps[newIndex])
      console.log({ currentIndex })
    }
  }

  const previousStep = () => {
    if (currentIndex >= 0) {
      const newIndex = currentIndex - 1
      setCurrentIndex(newIndex)
      setCurrentForm(steps[newIndex])
      console.log({ currentIndex })
    }
  }
  const openModal = () => {
    setIsOpen(true)
    setCurrentIndex(2)
    setCurrentForm(steps[1])
  }
  const loadStep = (stepIndex) => {
    setCurrentIndex(stepIndex)
    setCurrentForm(steps[stepIndex])
  }
  return {
    currentIndex,
    currentForm,
    isOpen,
    setIsOpen,
    nextStep,
    previousStep,
    openModal,
    loadStep,
  }
}

export default useMultiStepForm
