import React, { useEffect, useState } from "react";
import axiosInstance, { BASE_URL } from "../utils/axios";
import RemoveContractModal from "./RemoveContractModal";
import ContractItem from "./ContractItem";
import { getTokenFromLocalStorage } from "../utils/localStorage";
import { useDispatch } from "react-redux";
import { hideAlert, showAlert } from "../features/alertSlice";
function StateModal({ openModal, setOpenModal, updateState, loading }) {
  return (
    <>
      <div
        className={`modal fade ${openModal === true ? "show" : ""}`}
        id="stateModal"
        tabIndex="-1"
        aria-labelledby="stateModalLabel"
        aria-hidden="true"
        style={{
          display: openModal === true ? "flex" : "",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="modal-dialog">
          <div className="modal-content bg-light p-4 border border-2 border-dark">
            {/* <div className="modal-header bg-light"></div> */}
            <div className="modal-body bg-light">
              Möchten Sie den Antrag an den Versicherer senden?
            </div>
            <div className="modal-footer border-0 d-flex justify-content-center">
              {/* <button type="button" className="btn btn-dark btn-sm" data-bs-dismiss="modal">Individueller Auftrag</button> */}
              <button
                to={"/dashboard/support"}
                className="btn btn-dark btn-sm w-100 d-flex justify-content-center align-items-center"
                onClick={updateState}
              >
                {loading ? (
                  <>
                    <div class="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </>
                ) : (
                  <>Jetzt Antrag senden</>
                )}
              </button>
              <button
                to={"/dashboard/support"}
                className="btn btn-outline-dark btn-sm w-100"
                onClick={(e) => {
                  setOpenModal(false);
                  window.location.reload();
                }}
                disabled={loading}
              >
                Abbrechen
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function ContractList({ records }) {
  const [openModal, setOpenModal] = useState(false);
  const [modalValue, setModalValue] = useState(null);
  const [modalContractId, setModalContractId] = useState(null);
  const [removeContractId, setRemoveContractId] = useState(null);
  const dispatch = useDispatch();
  const onSendRVMail = async (contractId) => {
    const token = getTokenFromLocalStorage();
    if (token) {
      const response = await axiosInstance.post(
        `/api/mail/rv`,
        { contractId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }
  };

  const [isCompleted, setIsCompleted] = useState(false);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const updateContractState = async (option, contractId) => {
    if (option.value === "ContractAccepted") {
    }

    setUpdatingStatus(true);
    const { value } = option;

    if (option.value === "ContractClosed") {
      const contractResponse = await axiosInstance.get(
        `/api/contracts/${contractId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const contract = await contractResponse.data;
      const contractResult = contract?.result;
      const envelopeId = contractResult?.envelopeId;
      
      if (envelopeId) {
        const statusResponse = await axiosInstance.get(
          `/api/docusign/envelopes/${envelopeId}/is-completed`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const statusData = await statusResponse.data;
        const state = statusData.result;

        if (state === true) {
          await axiosInstance.put(
            `/api/distributor/contracts/${contractId}`,
            { state: value, closedDate: Date.now() },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          await axiosInstance.get(`/api/docusign/documents/${contractId}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
          await onSendRVMail(contractId);

          setOpenModal(false);
          window.location.reload();
        } else {
          dispatch(
            showAlert({
              alertType: "danger",
              alertContent: "Der Vertrag ist noch nicht unterzeichnet worden.",
            })
          );
          setOpenModal(false);
          setTimeout(() => {
            dispatch(hideAlert());
            window.location.reload();
          }, 3000);
        }
      } else {
        dispatch(
          showAlert({
            alertType: "danger",
            alertContent: "Umschlag-ID konnte nicht gefunden werden",
          })
        );
        setTimeout(() => {
          dispatch(hideAlert());
          window.location.reload();
        }, 3000);
      }
    } else {
      await axiosInstance.put(
        `/api/distributor/contracts/${contractId}`,
        { state: value },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setUpdatingStatus(false);
      setOpenModal(false);
      window.location.reload();
    }

    setUpdatingStatus(false);
  };

  return (
    <>
      {openModal && (
        <StateModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          loading={updatingStatus}
          updateState={() => {
            updateContractState(modalValue, modalContractId, isCompleted);
          }}
        ></StateModal>
      )}
      <ul className="list-group admin-contracts-list">
        <div className="contracts-list">
          <li className="list-group-item d-grid list-header">
            <div className="row">
              <div className="col-2 text-left">Kunde</div>
              <div className="col-2 text-left">Versicherungsobjekt</div>
              <div className="col-3 text-left">Auftragsnummer</div>
              <div className="col-5 text-end">Status des Antrags</div>
            </div>
          </li>
          <RemoveContractModal
            removeId={removeContractId}
          ></RemoveContractModal>
          {records.map((contract, index) => {
            return (
              <ContractItem
                key={index}
                contract={contract}
                modalContractId={modalContractId}
                setModalContractId={setModalContractId}
                setModalValue={setModalValue}
                setOpenModal={setOpenModal}
                setRemoveContractId={setRemoveContractId}
                isCompleted={isCompleted}
                setIsCompleted={setIsCompleted}
                updateContractState={updateContractState}
              />
            );
          })}
        </div>
      </ul>
    </>
  );
}

export default ContractList;
