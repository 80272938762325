import React, { useState, useEffect } from "react";
import axiosInstance from "../../../utils/axios";
import formatNumber from "../../../utils/formatNumber";
import download from "downloadjs";
import Loading from "../../../components/Loading";

function AdminPaymentPage() {
  let [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  let get = async () => {
    setLoading(true);
    let response = await axiosInstance.get(
      "/api/admin/payments-transactions-v2",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    const result = await response.data.result;
    setRecords(result);
    console.log(result);
    setLoading(false);
    console.log(result);
  };

  const onExportReports = async (records) => {
    let response = await axiosInstance.post(
      "/api/admin/excel",
      {
        records: records,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          responseType: "blob",
        },
        responseType: "arraybuffer",
      }
    );

    const transData = await response.data;
    const blob = new Blob([transData]);
    download(
      blob,
      "report.xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
  };

  const onExportSEPAReports = async (records) => {
    
    let response = await axiosInstance.post(
      "/api/admin/sepa",
      {
        records: records
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          responseType: "blob",
        },
        responseType: "arraybuffer",
      }
    );

    const transData = await response.data;
    const blob = new Blob([transData]);
    download(blob, "output.xml", "attachment; filename=output.xml");
  };

  useEffect(() => {
    get();
  }, []);
  const [downloadingAllReport, setDownloadingAllReport] = useState(false);
  const exportAllTransactionsReport = async () => {
    setDownloadingAllReport(true);
    console.log("downloading excel ...");
    try {
      let response = await axiosInstance.get(
        "/api/admin/full-payments-transactions",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            responseType: "blob",
          },
          responseType: "arraybuffer",
        }
      );

      const transData = await response.data;
      const blob = new Blob([transData]);
      console.log(blob);
      download(
        blob,
        "report.xlsx",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    } catch (error) {
      console.log(error.message);
    }
    setDownloadingAllReport(false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className="container ">
        <div className="col-12">
          <div className="page-header row mb-4">
            <div className="col-12">
              <div className="d-flex">
                <div className="me-auto page-title-holder">
                  <h2 className="section-title text-dark ">
                    SEPA-Zahlungsverkehr
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-end">
            <div className="col">
              <div className="d-flex align-items-center">
                <button
                  onClick={exportAllTransactionsReport}
                  disabled={downloadingAllReport}
                  className="btn btn-outline-dark d-flex align-items-center gap-1 my-auto btn-sm ms-auto"
                >
                  Bericht herunterladen
                </button>
              </div>
            </div>
          </div>

          <div className="allpayment">
            <div className="list">
              {records.map((record, index) => {
                return (
                  <div className="wrap">
                    <div className="date">{record.date}</div>
                    <div className="card">
                      <div className="head">
                        <h3>Aufträge</h3>
                        <div className="actions">
                          <button
                            className="btn btn-sm"
                            onClick={(e) => {
                              onExportReports(record.transactions);
                            }}
                          >
                            <svg
                              id="Group_253"
                              data-name="Group 253"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="21.494"
                              height="22.455"
                              viewBox="0 0 21.494 22.455"
                            >
                              <g
                                id="Group_252"
                                data-name="Group 252"
                                clipPath="url(#clipPath)"
                              >
                                <path
                                  id="Path_350"
                                  data-name="Path 350"
                                  d="M1.492,267.578c-.124-.04-.251-.075-.373-.121a1.7,1.7,0,0,1-1.113-1.563C0,264.286,0,262.679,0,261.071a.7.7,0,1,1,1.4,0c0,1.535,0,3.069,0,4.6,0,.4.093.5.484.5H19.6c.391,0,.484-.1.484-.5q0-2.291,0-4.582a.684.684,0,0,1,.423-.68.606.606,0,0,1,.729.12.865.865,0,0,1,.24.513q.026,2.433,0,4.867a1.715,1.715,0,0,1-1.318,1.612c-.056.014-.11.033-.166.05Z"
                                  transform="translate(0 -245.122)"
                                  fill="#344167"
                                  stroke="#344167"
                                  strokeWidth="0.5"
                                />
                                <path
                                  id="Path_351"
                                  data-name="Path 351"
                                  d="M101.832,0a.862.862,0,0,1,.532.905q-.014,6.994-.006,13.989v.3c.089-.082.147-.132.2-.186q1.473-1.472,2.945-2.946a.714.714,0,0,1,.752-.229.7.7,0,0,1,.354,1.1,1.89,1.89,0,0,1-.164.176L102.26,17.29a.728.728,0,0,1-1.206,0Q98.93,15.166,96.8,13.043a.722.722,0,0,1-.221-.737.657.657,0,0,1,.514-.488.74.74,0,0,1,.727.255q1.454,1.461,2.913,2.916c.057.057.1.124.154.186l.063-.021v-.241q0-7.005-.006-14.011a.856.856,0,0,1,.532-.9Z"
                                  transform="translate(-90.91)"
                                  fill="#344167"
                                  stroke="#344167"
                                  strokeWidth="0.5"
                                />
                              </g>
                            </svg>
                          </button>
                          <button
                            className="btn btn-dark btn-sm"
                            onClick={(e) => {
                              onExportSEPAReports(record.transactions);
                            }}
                          >
                            SEPA XML erstellen
                          </button>
                        </div>
                      </div>

                      <div className="table">
                        <ul className="list-group payment-list ">
                          <li className="d-flex justify-content-between align-items-center w-100">
                            <div className="col-3 small-text text-center fw-bold">
                              Name Kontoinhaber*in
                            </div>
                            <div className="col-3 small-text text-center fw-bold">
                              IBAN
                            </div>
                            <div className="col-2 small-text text-center fw-bold">
                              BIC
                            </div>
                            <div className="col-2 small-text text-center fw-bold">
                              Kreditinstitut
                            </div>
                            <div className="col-2 small-text text-center fw-bold">
                              Kommission in EUR
                            </div>
                          </li>
                          {record.transactions?.map((transaction, index) => {
                            return (
                              <li
                                key={index}
                                className="item d-flex justify-content-between align-items-center"
                              >
                                <div className="col-3 small-text text-center">
                                  <span>{transaction?.fullName || "-"}</span>
                                </div>
                                <div className="col-3 small-text text-center">
                                  <span>{transaction?.ibanNumber || "-"}</span>
                                </div>
                                <div className="col-2 small-text text-center">
                                  <span>{transaction?.swiftCode || "-"}</span>
                                </div>
                                <div className="col-2 small-text text-center">
                                  <span>
                                    {transaction?.creditInstitution || "-"}
                                  </span>
                                </div>
                                <div className="col-2 small-text text-center">
                                  <span>
                                    {formatNumber(transaction.total) || "-"}
                                  </span>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                );
              })}
              {/* {Object.entries(records).map((item) => {
                const [key, arr] = item;
                
                return (
                  
                  </div>
                );
              })} */}
            </div>

            {/* <div className="list">
              {records &&
                records?.reverse()?.map((record, index) => {
                  if (record.transactions.length > 0) {
                    return (
                     
                    );
                  }
                })}
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminPaymentPage;
