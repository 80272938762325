import React, { useState } from "react";
import { useEffect } from "react";
import axiosInstance from "../../../utils/axios";
import Loading from "../../../components/Loading";
import ContractList from "../../../components/ContractList";

function AdminContractsPage() {
  let [loading, setLoading] = useState(true);
  let [data, setData] = useState([]);
  const [completedContractsCount, setCompletedContractsCount] = useState(0);
  const [offersCount, setOffersCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  let get = async () => {
    const response = await axiosInstance.get("/api/admin/contracts", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    setData(response.data.result);
    const offerCountsValue = response.data.result.filter(
      (c) => c.state === "OfferCreated"
    ).length;
    const completedCountsValue = response.data.result.filter(
      (c) => c.state === "ContractClosed"
    ).length;
    setCompletedContractsCount(completedCountsValue);
    setOffersCount(offerCountsValue);
    setTotalCount(response.data.result.length);
    setLoading(false);
    return response.data.result;
  };

  
  let [targetState, setTargetState] = useState("*");
  const filterData = async (target) => {
    setTargetState(target);
    setLoading(true);
    let baseRecords = await get();
    let newRecords;
    if (target === "OfferCreated") {
      newRecords = baseRecords.filter(
        (record) => record.state === "OfferCreated"
      );
      setData(newRecords);
    } else if (target === "ContractClosed") {
      newRecords = baseRecords.filter(
        (record) => record.state === "ContractClosed"
      );
      setData(newRecords);
    } else {
      setData(baseRecords);
    }
    console.log({ newRecords, target });
    setLoading(false);
  };

  useEffect(() => {
    get();
  }, []);

  let searchFunc = (e) => {
    if (e.target.value.length > 2) {
      let newData = data.filter(
        (x) =>
          x?.customer?.company?.name
            .toUpperCase()
            .includes(e.target.value.toUpperCase()) ||
          x?.customer?.fullName?.toUpperCase()
            .includes(e.target.value.toUpperCase()) ||
          x?.category?.toUpperCase().includes(e.target.value.toUpperCase()) ||
          x?.machine?.toUpperCase().includes(e.target.value.toUpperCase())
      );
      setData(newData);
      setLoading(false);
    } else {
      get();
    }
  };

  if (loading) {
    return <Loading></Loading>;
  }

  return (
    <>
      <div className="container">
        <div className="col-12">
          <div className="d-flex">
            <div className="me-auto page-title-holder">
              <h2 className="section-title text-dark ">Deine Anträge</h2>
              <div className="d-flex align-items-center head-btns">
                <button
                       className={`btn btn-sm btn-light shadow-none rounded-0 ${targetState=="*" && 'active'}`}
                  onClick={() => filterData("*")}
                >
                  Alle <span>({totalCount})</span>{" "}
                </button>
                <button
                  className={`btn btn-sm btn-light shadow-none rounded-0 ${targetState=="OfferCreated" && 'active'}`}
                  onClick={() => filterData("OfferCreated")}
                >
                  Offene Anträge <span>({offersCount})</span>{" "}
                </button>
                <button
                     className={`btn btn-sm btn-light shadow-none rounded-0 ${targetState=="ContractClosed" && 'active'}`}
                  onClick={() => filterData("ContractClosed")}
                >
                  Abgeschlossen <span>({completedContractsCount})</span>{" "}
                </button>
              </div>
            </div>
            <div className="search">
              <div className="input-group">
                <span className="input-group-text">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.378"
                    height="25.506"
                    viewBox="0 0 25.378 25.506"
                  >
                    <g
                      id="Ellipse_14"
                      data-name="Ellipse 14"
                      transform="translate(0 11.314) rotate(-45)"
                      fill="none"
                      stroke="#344167"
                      strokeWidth="1"
                    >
                      <circle cx="8" cy="8" r="8" stroke="none" />
                      <circle cx="8" cy="8" r="7.5" fill="none" />
                    </g>
                    <g
                      id="Ellipse_15"
                      data-name="Ellipse 15"
                      transform="translate(2.552 11.441) rotate(-45)"
                      fill="none"
                      stroke="#344167"
                      strokeWidth="1"
                    >
                      <circle cx="6.195" cy="6.195" r="6.195" stroke="none" />
                      <circle cx="6.195" cy="6.195" r="5.695" fill="none" />
                    </g>
                    <g
                      id="Rectangle_82"
                      data-name="Rectangle 82"
                      transform="translate(14.771 17.728) rotate(-45)"
                      fill="none"
                      stroke="#344167"
                      strokeWidth="1"
                    >
                      <rect width="4" height="11" stroke="none" />
                      <rect x="0.5" y="0.5" width="3" height="10" fill="none" />
                    </g>
                  </svg>
                </span>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Suche"
                  aria-label="Search"
                  aria-describedby="basic-search"
                  onChange={(e) => {
                    setTimeout(() => {
                      searchFunc(e);
                    }, 500);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <ContractList records={data} />
        </div>
      </div>
    </>
  );
}

export default AdminContractsPage;
