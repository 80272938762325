import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loading from "./Loading";
import formatNumber from "../utils/formatNumber";
import DownloadButton from "./DownloadButton";
import moment from "moment";

function CustomerCards({ data, searchFunc, loading }) {
  let [records, setRecords] = useState(data);

  useEffect(() => {
    setRecords(data);
  }, [data]);

  return (
    <>
      <div className="container">
        <div className="col-12">
          <div className="page-header row row-cols-md-1 row-cols-lg-2 row-cols-xl-2 mb-4">
            <div className="col-12 col-md-12 col-lg-6 col-xl-6">
              <div className="me-auto page-title-holder">
                <h2 className="section-title text-dark ">Deine Kunden</h2>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-6 col-xl-6">
              {" "}
              <div className="search">
                <div className="input-group">
                  <span className="input-group-text">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25.378"
                      height="25.506"
                      viewBox="0 0 25.378 25.506"
                    >
                      <g
                        id="Ellipse_14"
                        data-name="Ellipse 14"
                        transform="translate(0 11.314) rotate(-45)"
                        fill="none"
                        stroke="#344167"
                        strokeWidth="1"
                      >
                        <circle cx="8" cy="8" r="8" stroke="none" />
                        <circle cx="8" cy="8" r="7.5" fill="none" />
                      </g>
                      <g
                        id="Ellipse_15"
                        data-name="Ellipse 15"
                        transform="translate(2.552 11.441) rotate(-45)"
                        fill="none"
                        stroke="#344167"
                        strokeWidth="1"
                      >
                        <circle cx="6.195" cy="6.195" r="6.195" stroke="none" />
                        <circle cx="6.195" cy="6.195" r="5.695" fill="none" />
                      </g>
                      <g
                        id="Rectangle_82"
                        data-name="Rectangle 82"
                        transform="translate(14.771 17.728) rotate(-45)"
                        fill="none"
                        stroke="#344167"
                        strokeWidth="1"
                      >
                        <rect width="4" height="11" stroke="none" />
                        <rect
                          x="0.5"
                          y="0.5"
                          width="3"
                          height="10"
                          fill="none"
                        />
                      </g>
                    </svg>
                  </span>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Suche"
                    aria-label="Search"
                    aria-describedby="basic-search"
                    onChange={(e) => {
                      setTimeout(() => {
                        searchFunc(e);
                      }, 500);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row row-cols-md-1 row-cols-lg-2 row-cols-xl-3">
            {loading ? (
              <Loading />
            ) : (
              records.map((record, index) => {
                return (
                  <div
                    key={index}
                    className="col-12 col-md-12 col-lg-6 col-xl-4"
                  >
                    <div className="card distributor-card">
                      <div className="card-header">
                        <div className="distributor-info">
                          <h5>{record?.customerInfo?.fullName}</h5>
                          <span>{record?.customerInfo?.company?.name}</span>
                        </div>
                        <div className="distributor-state d-none">
                          <div className="state-circle my-auto bg-success mb-2"></div>
                          <p className="text-muted small-text  text-center">
                            Antrag unterschrieben
                          </p>
                        </div>
                      </div>
                      <div className="card-body">
                        <h3 className="income-value">
                          {formatNumber(
                            record?.machines
                              ?.map((m) => Number(m.price))
                              .reduce((a, b) => a + b, 0)
                          )}
                        </h3>
                        <div className="label-text">
                        {record?.customerInfo?.email && record.customerInfo?.phone === '' && (
                            <>
                              <label>E-mail</label>
                              <p>{record?.customerInfo?.email}</p>
                            </>
                          )}

                          {record?.customerInfo?.email &&record?.customerInfo?.phone && (
                            <>
                              <label>Tel</label>
                              <p>{record?.customerInfo?.phone}</p>
                            </>
                          )}
                          <p>Antrag#{record?._id.slice(0, 10)}</p>
                          <p>
                            {" "}
                            {moment(record?.lastContract?.updatedAt).format(
                              "MMM-YYYY"
                            )}{" "}
                          </p>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="d-flex links">
                          <Link to={`/dashboard/contracts/${record._id}`}>
                            Verwandte Anträge
                          </Link>
                          <Link
                            to={`/dashboard/customer/${record?.customer}/contracts/`}
                          >
                            Alle ansehen
                          </Link>
                        </div>
                        <div className="btn-group  w-100 mt-2" role="group">
                          <DownloadButton
                            recordId={record?._id}
                            target={"offer"}
                          />
                          {record.state === "ContractClosed" && (
                            <DownloadButton
                              recordId={record?._id}
                              target={"contract"}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomerCards;
