const companiesTypes = [
  {
    id: 1,
    title: 'registeredAssociation',
    label: 'eingetragener Verein',
    shortcut: 'e.V.',
  },
  {
    id: 2,
    title: 'partnershipLimitedByShares',
    label: 'Kommanditgesellschaft auf Aktien',
    shortcut: 'KGaA',
  },
  {
    id: 3,
    title: 'companyWithLimitedLiability',
    label: 'Gesellschaft mit beschränkter Haftung',
    shortcut: 'GmbH',
  },
  {
    id: 4,
    title: 'publicCompany',
    label: 'Aktiengesellschaft',
    shortcut: 'AG',
  },
]

export default companiesTypes
