import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import defaultDiscountsList from "../discounts";
import { Tooltip } from "react-tooltip";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { Calendar } from "primereact/calendar";
import {
    initMachine,
    updateItem,
    setExtraPriceItem,
    clearTargetExtraPrice,
    calculateOneItem,
    calculateTotal,
    updateProtectType,
} from "../features/contract/calculatorSlice";
import {
    getMachines,
    onCategoryChange,
    onValueChange,
    getMachine,
} from "../features/contract/machineSlice";
import formatNumber from "../utils/formatNumber";
import ExtraPriceModal from "./ExtraPriceModal";
import moment from "moment";
import { hideAlert, showAlert } from "../features/alertSlice";
import SupportFormAccordion from "./SupportFormAccordion";

const yearsRange = () => {
    const counter = Number(new Date().getFullYear() - new Date(10));
    let range = [];

    for (let index = 0; index <= 10; index++) {
        range.push(counter + index);
    }
    return [...range.reverse(), "Baujahr vor 2013"];
};

const getMonths = () => {
    const today = new Date();
    let range = [];
    for (let index = 0; index < 12; index++) {
        const date = moment(new Date(today.getFullYear(), index)).format(
            "DD.MM"
        );
        range.push(date);
    }
    return range;
};

function Form1() {
    getMonths();
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const {
        isLoading,
        category,
        machine,
        brand,
        productionYear,
        serialNumber,
        insuranceStartDate,
        purchasePrice,
        categoriesList,
        machinesList,
        discountsList,
        machineErrors,
        machineValid,
        machineType,
        duration,
        mainDue,
    } = useSelector((store) => store.machine);

    const { initialCartItem, machines, country } = useSelector(
        (store) => store.calculator
    );

    //const {country} = useSelector(store=>store.customer);
    const [priceIsValid, setPriceIsValid] = useState(false);
    const [emailFormShow, setEmailFormShow] = useState(false);
    const [priceValidError, setPriceValidError] = useState("");

    const ref = useRef();
    if (isLoading && !categoriesList) {
        return <>Loading</>;
    }
    const handleScrolling = (direction) => {
        const container = document.querySelector("#categories");
        container.scrollLeft = container.scrollLeft + direction;
    };

    const focusOnCalculator = () => {
        setTimeout(() => {
            const el = document.querySelector("body");
            el.setAttribute("class", "");
            el.setAttribute("style", "");
            el.focus();
            el.click();
        }, 3);
    };

    return (
        <>
            <SupportFormAccordion
                emailFormShow={emailFormShow}
                setEmailFormShow={setEmailFormShow}
            />
            <div className="card-body inner-form-container">
                <ExtraPriceModal
                    focusOnCalculator={focusOnCalculator}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    category={category}
                    totalCost={
                        Number(initialCartItem.purchasePrice) +
                        initialCartItem.extraPrices
                            .map((i) => Number(i.price))
                            .reduce((a, b) => a + b, 0)
                    }
                ></ExtraPriceModal>
                <form action="" noValidate>
                    <div className="d-flex justify-content-end">
                        <p className="my-2 budge ms-3">* Pflichtfeld</p>
                    </div>
                    {/* CATEGORIES */}
                    <div className="categories-holder">
                        <button
                            type="button"
                            className="scroll-btn btn btn-sm btn-light p-2 h-100"
                            id="left"
                            onClick={(e) => {
                                handleScrolling(-300);
                            }}
                        >
                            <AiOutlineArrowLeft />
                        </button>
                        <button
                            type="button"
                            className="scroll-btn btn btn-sm btn-light p-2 h-100"
                            id="right"
                            onClick={(e) => {
                                handleScrolling(300);
                            }}
                        >
                            <AiOutlineArrowRight />
                        </button>
                        <div className="d-flex categories-list" id="categories">
                            {categoriesList &&
                                categoriesList.map((cat, index) => {
                                    const defaultCardStyle = {
                                        background: `url(${require(`../assets/categoryImages/${cat.photo}`)})`,
                                    };

                                    const selectedCardStyle = {
                                        background: `url(${require(`../assets/categoryImages/${cat.photo}`)})`,
                                    };

                                    return (
                                        <div
                                            key={index}
                                            className={`category-card mx-2 border-0 card position-relative`}
                                            style={
                                                cat._id !==
                                                initialCartItem.category
                                                    ? defaultCardStyle
                                                    : selectedCardStyle
                                            }
                                            onClick={async () => {
                                                dispatch(
                                                    onCategoryChange({
                                                        categoryId: cat?._id,
                                                    })
                                                );
                                                dispatch(getMachines(cat?._id));

                                                if (
                                                    [...machines].length === 0
                                                ) {
                                                    await dispatch(
                                                        initMachine({
                                                            id: 0,
                                                            machine: "",
                                                            brand: "",
                                                            protectType:
                                                                "fullProduction",
                                                            productionYear: "",
                                                            serialNumber: "",
                                                            insuranceStartDate:
                                                                "",
                                                            insuranceEndDate:
                                                                "",
                                                            description: "",
                                                            purchasePrice: "",
                                                            machineObj: "",
                                                            deductible: "",
                                                            fullProduction: "",
                                                            hull: "",
                                                            mtv: "",
                                                            discountsOptions:
                                                                [],
                                                            extraPrices: [],
                                                            protectFactor: "",
                                                            price: "",
                                                            basePrice: "",
                                                            paymentPeriod:
                                                                "per-month",
                                                            discountsList: [],
                                                            discountFactor:
                                                                null,
                                                            includedPolicyholder:
                                                                "",
                                                            duration: "",
                                                            mainDue: "",
                                                            category: cat?._id,
                                                            categoryObj:
                                                                categoriesList.find(
                                                                    (
                                                                        category
                                                                    ) =>
                                                                        category._id ===
                                                                        cat?._id
                                                                ),
                                                        })
                                                    );
                                                    await dispatch(
                                                        updateProtectType({
                                                            value: "fullProduction",
                                                            itemId: initialCartItem.id,
                                                        })
                                                    );
                                                    await dispatch(
                                                        updateItem({
                                                            itemId: initialCartItem?.id,
                                                            name: "category",
                                                            value: cat?._id,
                                                        })
                                                    );

                                                    await dispatch(
                                                        updateItem({
                                                            itemId: initialCartItem?.id,
                                                            name: "categoryObj",
                                                            value: categoriesList.find(
                                                                (category) =>
                                                                    category._id ===
                                                                    cat?._id
                                                            ),
                                                        })
                                                    );
                                                } else {
                                                    dispatch(
                                                        updateItem({
                                                            itemId: initialCartItem?.id,
                                                            name: "category",
                                                            value: cat?._id,
                                                        })
                                                    );
                                                    dispatch(
                                                        updateItem({
                                                            itemId: initialCartItem?.id,
                                                            name: "categoryObj",
                                                            value: categoriesList.find(
                                                                (category) =>
                                                                    category._id ===
                                                                    cat?._id
                                                            ),
                                                        })
                                                    );
                                                }
                                                for (let machine of machines) {
                                                    dispatch(
                                                        calculateOneItem({
                                                            itemId: machine.id,
                                                            country: country
                                                                ? country
                                                                : "austria",
                                                        })
                                                    );
                                                }
                                                //dispatch(resetValues())
                                            }}
                                        >
                                            {cat._id ===
                                                initialCartItem.category && (
                                                <div className="selected-sign">
                                                    <i className="bi bi-check-lg"></i>
                                                </div>
                                            )}

                                            <div
                                                className={`position-absolute bottom-0 left-0 w-100 rounded-1 bg-${
                                                    cat._id ===
                                                    initialCartItem.category
                                                        ? "primary"
                                                        : "dark"
                                                }`}
                                            >
                                                <p className="category-title my-auto py-3 text-center text-white">
                                                    {cat?.title}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                        {category === "64648b521fe141b073630e08" && (
                            <p
                                className="text-center py-4"
                                style={{ background: "#FFF2CD" }}
                            >
                                Hinweis: Auflieger nur in Verbindung mit
                                Sattelzugmaschinen / Lkw versichert.
                            </p>
                        )}
                        {category === "64648b521fe141b073630e05" && (
                            <p
                                className="text-center  py-4"
                                style={{ background: "#FFF2CD" }}
                            >
                                Hinweis: Innere Betriebsschäden nur bis max. 36
                                Monate ab EZ mitversichert.
                            </p>
                        )}
                        <div className="grey-shadow-holder"></div>
                    </div>

                    {/* FORM INPUTS */}
                    <div className="row row-cols-md-2 row-cols-lg-2 row-cols-xl-4 my-4 ">
                        <div className="col-12 col-md-6 col-lg-6 col-xl-3 ">
                            <select
                                name="machine"
                                id="machineField"
                                className={`form-select form-select-sm bg-light shadow-none`}
                                value={machine}
                                onChange={async (e) => {
                                    const selectedMachinesList = machines.map(
                                        (m) => m.machine
                                    );
                                    dispatch(
                                        onValueChange({
                                            name: e.target.name,
                                            value: e.target.value,
                                        })
                                    );
                                    const machineItem = await dispatch(
                                        getMachine(e.target.value)
                                    );
                                    dispatch(
                                        updateItem({
                                            itemId: initialCartItem.id,
                                            name: "machine",
                                            value: {
                                                machine: e.target.value,
                                                discountsList:
                                                    machineItem.payload.result
                                                        .discounts,
                                            },
                                        })
                                    );

                                    dispatch(
                                        updateItem({
                                            itemId: initialCartItem.id,
                                            name: "discountsList",
                                            value: discountsList,
                                        })
                                    );

                                    await dispatch(
                                        updateItem({
                                            itemId: initialCartItem.id,
                                            name: "machineObj",
                                            value: machineItem,
                                        })
                                    );
                                }}
                                required
                            >
                                <option value="*">Maschine auswählen *</option>
                                {machinesList &&
                                    machinesList.map((machine, index) => {
                                        return (
                                            <option
                                                key={index}
                                                value={machine?._id}
                                            >
                                                {machine.title}
                                            </option>
                                        );
                                    })}
                            </select>
                            <p
                                className="text-sm text-danger"
                                style={{ fontSize: 12 }}
                            >
                                {machineValid === false &&
                                    machineErrors.find(
                                        (error) => error.field === "machine"
                                    )?.text}
                            </p>
                        </div>

                        <div className="col-12 col-md-6 col-lg-6 col-xl-3 ">
                            <input
                                type="text"
                                name="brand"
                                id="brandName"
                                className={`form-control form-control-sm bg-light shadow-none ${
                                    machineErrors &&
                                    machineErrors.find(
                                        (error) => error.field === "brand"
                                    ) &&
                                    "border-danger border border-3"
                                }`}
                                placeholder="Maschinenmarke *"
                                required
                                value={brand}
                                onChange={(e) => {
                                    dispatch(
                                        onValueChange({
                                            name: e.target.name,
                                            value: e.target.value,
                                        })
                                    );
                                    dispatch(
                                        updateItem({
                                            itemId: initialCartItem.id,
                                            name: "brand",
                                            value: e.target.value,
                                        })
                                    );
                                }}
                            />

                            <p
                                className="text-sm text-danger"
                                style={{ fontSize: 12 }}
                            >
                                {machineValid === false &&
                                    machineErrors.find(
                                        (error) => error.field === "brand"
                                    )?.text}
                            </p>
                        </div>

                        <div className="col-12 col-md-6 col-lg-6 col-xl-3 ">
                            <input
                                type="text"
                                name="machineType"
                                id="machineType"
                                className={`form-control form-control-sm bg-light shadow-none ${
                                    machineErrors &&
                                    machineErrors.find(
                                        (error) => error.field === "machineType"
                                    ) &&
                                    "border-danger border border-3"
                                }`}
                                placeholder="Maschinentype *"
                                required
                                value={machineType}
                                onChange={(e) => {
                                    dispatch(
                                        onValueChange({
                                            name: e.target.name,
                                            value: e.target.value,
                                        })
                                    );
                                    dispatch(
                                        updateItem({
                                            itemId: initialCartItem.id,
                                            name: "machineType",
                                            value: e.target.value,
                                        })
                                    );
                                }}
                            />

                            <p
                                className="text-sm text-danger"
                                style={{ fontSize: 12 }}
                            >
                                {machineValid === false &&
                                    machineErrors.find(
                                        (error) => error.field === "machineType"
                                    )?.text}
                            </p>
                        </div>

                        <div className="col-12 col-md-6 col-lg-6 col-xl-3 ">
                            <select
                                name="productionYear"
                                id="productionYearField"
                                className="form-select form-select-sm bg-light shadow-none"
                                required
                                value={productionYear}
                                onChange={(e) => {
                                    if (e.target.value === "Baujahr vor 2013") {
                                        setEmailFormShow(true);
                                        e.target.value = "";
                                    } else {
                                        setEmailFormShow(false);
                                    }

                                    dispatch(
                                        onValueChange({
                                            name: e.target.name,
                                            value: e.target.value,
                                        })
                                    );
                                    dispatch(
                                        updateItem({
                                            itemId: initialCartItem.id,
                                            name: "productionYear",
                                            value: e.target.value,
                                        })
                                    );

                                    dispatch(
                                        calculateOneItem({
                                            itemId: initialCartItem.id,
                                            country: country
                                                ? country
                                                : "austria",
                                        })
                                    );
                                    dispatch(calculateTotal());
                                }}
                            >
                                <option value="*">Baujahr auswählen *</option>
                                {yearsRange() &&
                                    yearsRange().map((year, index) => {
                                        if (year === "Baujahr vor 2013") {
                                            return (
                                                <option
                                                    // data-bs-toggle="modal"
                                                    // data-bs-target="#yearModal"
                                                    key={index}
                                                    value={year}
                                                >{`${year}`}</option>
                                            );
                                        } else {
                                            return (
                                                <option
                                                    key={index}
                                                    value={year}
                                                >{`Baujahr ${year}`}</option>
                                            );
                                        }
                                    })}
                            </select>
                            <p
                                className="text-sm text-danger"
                                style={{ fontSize: 12 }}
                            >
                                {machineValid === false &&
                                    machineErrors.find(
                                        (error) =>
                                            error.field === "productionYear"
                                    )?.text}
                            </p>
                        </div>
                    </div>

                    <div className="row row-cols-md-2 row-cols-lg-2 row-cols-xl-4 my-4 custom-size">
                        <div className="col-12 col-md-12 col-lg-12 col-xl-3">
                            <input
                                type="text"
                                name="serialNumber"
                                id="serialNumberName"
                                className="form-control form-control-sm bg-light shadow-none"
                                placeholder="Seriennummer/Fabriksnummer"
                                required
                                value={serialNumber}
                                onChange={(e) => {
                                    dispatch(
                                        onValueChange({
                                            name: e.target.name,
                                            value: e.target.value,
                                        })
                                    );
                                    dispatch(
                                        updateItem({
                                            itemId: initialCartItem.id,
                                            name: "serialNumber",
                                            value: e.target.value,
                                        })
                                    );
                                }}
                            />
                        </div>

                        <div className="col-12 col-md-12 col-lg-12 col-xl-7 my-4 my-xl-0">
                            <div className="d-flex  align-items-center  justify-content-around">
                                <p className="small-text">
                                    Versicherungszeitraum:
                                </p>
                                <p className="mx-2 small-text">von</p>
                                <div className="d-flex flex-column">
                                    <Calendar
                                        name="insuranceStartDate"
                                        size="small"
                                        placeholder="dd/mm/yyyy"
                                        dateFormat={"dd/mm/yy"}
                                        showButtonBar
                                        id="insuranceStartDateName"
                                        value={
                                            insuranceStartDate
                                                ? new Date(insuranceStartDate)
                                                : Date.now()
                                        }
                                        required
                                        onChange={(e) => {
                                            if (
                                                moment(e.target.value) >
                                                moment(new Date()).add(
                                                    "-1",
                                                    "days"
                                                )
                                            ) {
                                                dispatch(
                                                    onValueChange({
                                                        name: e.target.name,
                                                        value: new Date(
                                                            e.target.value
                                                        ).toISOString(),
                                                    })
                                                );
                                                dispatch(
                                                    updateItem({
                                                        itemId: initialCartItem.id,
                                                        name: "insuranceStartDate",
                                                        value: new Date(
                                                            e.target.value
                                                        ).toISOString(),
                                                    })
                                                );
                                            } else {
                                                dispatch(
                                                    showAlert({
                                                        alertType: "danger",
                                                        alertContent:
                                                            "Versicherungsbeginn konnte nicht vor heute sein",
                                                    })
                                                );
                                                dispatch(
                                                    onValueChange({
                                                        name: e.target.name,
                                                        value: "",
                                                    })
                                                );
                                                setTimeout(() => {
                                                    dispatch(hideAlert());
                                                }, 3000);
                                            }
                                        }}
                                    />
                                    <p
                                        className="text-sm text-danger"
                                        style={{ fontSize: 12 }}
                                    >
                                        {machineValid === false &&
                                            machineErrors.find(
                                                (error) =>
                                                    error.field ===
                                                    "insuranceStartDate"
                                            )?.text}
                                    </p>
                                </div>
                                <p className="mx-2 small-text">Dauer</p>
                                <div className="d-flex flex-column">
                                    <select
                                        name="duration"
                                        id="durationField"
                                        className={`form-select form-select-sm bg-light shadow-none`}
                                        style={{ width: "10rem" }}
                                        value={duration}
                                        onChange={async (e) => {
                                            dispatch(
                                                onValueChange({
                                                    name: e.target.name,
                                                    value: e.target.value,
                                                })
                                            );

                                            dispatch(
                                                updateItem({
                                                    itemId: initialCartItem.id,
                                                    name: "duration",
                                                    value: e.target.value,
                                                })
                                            );

                                            dispatch(
                                                calculateOneItem({
                                                    itemId: initialCartItem.id,
                                                    country: country
                                                        ? country
                                                        : "austria",
                                                })
                                            );
                                            dispatch(calculateTotal());
                                        }}
                                        required
                                    >
                                        <option value="*">
                                            Dauer wählen *
                                        </option>
                                        {category !== "64648b531fe141b073630e2c"
                                            ? [1, 3, 5, 7, 10].map(
                                                  (option, index) => {
                                                      return (
                                                          <option
                                                              key={index}
                                                              value={option}
                                                          >
                                                              {`${option} Jahr`}
                                                          </option>
                                                      );
                                                  }
                                              )
                                            : [1, 3, 5].map((option, index) => {
                                                  return (
                                                      <option
                                                          key={index}
                                                          value={option}
                                                      >
                                                          {`${option} Jahr`}
                                                      </option>
                                                  );
                                              })}
                                    </select>
                                    
                                    <p
                                        className="text-sm text-danger"
                                        style={{ fontSize: 12 }}
                                    >
                                        {machineValid === false &&
                                            machineErrors.find(
                                                (error) =>
                                                    error.field === "duration"
                                            )?.text}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-12 col-lg-12 col-xl-2">
                            <select
                                name="mainDue"
                                id="mainDueField"
                                className="form-select form-select-sm bg-light shadow-none"
                                required
                                defaultValue={mainDue}
                                onChange={(e) => {
                                    dispatch(
                                        onValueChange({
                                            name: e.target.name,
                                            value: e.target.value,
                                        })
                                    );
                                    dispatch(
                                        updateItem({
                                            itemId: initialCartItem.id,
                                            name: "mainDue",
                                            value: e.target.value,
                                        })
                                    );
                                }}
                            >
                                <option value="*">Hauptfälligkeit *</option>
                                {getMonths()?.map((option, index) => {
                                    return (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                    );
                                })}
                            </select>
                            <p
                                className="text-sm text-danger"
                                style={{ fontSize: 12 }}
                            >
                                {machineValid === false &&
                                    machineErrors.find(
                                        (error) => error.field === "mainDue"
                                    )?.text}
                            </p>
                        </div>
                    </div>

                    <hr className="border border-primary my-4" />
                    <p className="lead my-2 fw-2">
                        <span>Kaufpreis *</span>

                        <span className="ms-2 fs-6">
                            <i
                                className="bi bi-info-circle"
                                id="purchasePriceToolTip"
                                data-tooltip-content="Neuwert ist der Kauf oder Lieferpreis der versicherten Sache im Neuzustand zuzüglich der Bezugskosten. Bezugskosten sind Fracht, Zölle, Gebühren, Steuern und Kosten für Montage, Lagerung und Verpackung. In der Rechnung ausgewiesene Rabatte und Preiszugeständnnisse bleiben für den Versicherungswert unberücksichtigt. Der Neuwert ist entsprechend der Preisnetwicklung der vergleichbare Sachen zu vermindern oder zu erhöhen. Ist die Versicherungssumme entsprechend gebildet worden, verzichtet der Versicherer auf den Einwand der Unterversicherung. Der Versichungswert stellt die maximale Entschädigungsleistung dar."
                            ></i>
                        </span>
                    </p>
                    <Tooltip
                        className="toolTip"
                        anchorId="purchasePriceToolTip"
                    />
                    <div className="row row-cols-md-1 row-cols-lg-1 row-cols-xl-3 my-4">
                        <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="d-flex align-items-center justify-content-between">
                                <p className="lead  my-auto">
                                    Ist der Versicherungsnehmer
                                    vorsteuerabzugsberechtigt? *
                                </p>
                                <div className="d-flex w-25">
                                    {[
                                        { label: "Ja", value: "yes" },
                                        { label: "Nein", value: "no" },
                                    ].map((option, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="input-group"
                                            >
                                                <input
                                                    type="radio"
                                                    className="btn-check btn-sm"
                                                    name="includedPolicyholder"
                                                    id={`includedPolicyholder-${index}`}
                                                    autoComplete="off"
                                                    value={option.value}
                                                    checked={
                                                        option.value ==
                                                        initialCartItem.includedPolicyholder
                                                    }
                                                    onChange={async (e) => {
                                                        console.log(
                                                            "e.target.name",
                                                            e.target.name
                                                        );
                                                        console.log(
                                                            "initialCartItem.id",
                                                            initialCartItem.id
                                                        );
                                                        dispatch(
                                                            onValueChange({
                                                                name: e.target
                                                                    .name,
                                                                value: e.target
                                                                    .value,
                                                            })
                                                        );

                                                        dispatch(
                                                            updateItem({
                                                                itemId: initialCartItem.id,
                                                                name: e.target
                                                                    .name,
                                                                value: {
                                                                    includedPolicyholder:
                                                                        e.target
                                                                            .value,
                                                                },
                                                            })
                                                        );
                                                    }}
                                                />
                                                <label
                                                    className="btn btn-outline-dark  rounded-1 btn-sm w-75 mx-auto"
                                                    htmlFor={`includedPolicyholder-${index}`}
                                                >
                                                    {option.label}
                                                </label>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <p
                                className="text-sm text-danger"
                                style={{ fontSize: 12 }}
                            >
                                {machineValid === false &&
                                    machineErrors.find(
                                        (error) =>
                                            error.field ===
                                            "includedPolicyholder"
                                    )?.text}
                            </p>
                        </div>
                    </div>

                    <div className="row row-cols-md-2 row-cols-lg-2 my-4">
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="d-flex justify-content-center align-items-center">
                                <input
                                    type="number"
                                    name="purchasePrice"
                                    id="price"
                                    placeholder="Kaufpreis in €"
                                    pattern="[0-9]*"
                                    className="form-control form-control-sm  bg-light shadow-none"
                                    required
                                    min={0}
                                    onWheel={(e) => e.target.blur()}
                                    value={
                                        initialCartItem.purchasePrice === 0
                                            ? ""
                                            : initialCartItem.purchasePrice
                                    }
                                    onChange={async (e) => {
                                        const speseficCats = [
                                            "64648b531fe141b073630e20",
                                            "64648b531fe141b073630e23",
                                            "64648b531fe141b073630e11",
                                        ];
                                        // if (
                                        //   category === "63cf81e0c5fb8ebcdb752013" ||
                                        //   category === "63cf81e0c5fb8ebcdb752016" ||
                                        //   category === "63cf81e0c5fb8ebcdb752004"
                                        // ) {
                                        //   if (
                                        //     Number(e.target.value) +
                                        //       initialCartItem?.extraPrices
                                        //         .map((i) => i.price)
                                        //         .reduce((a, b) => a + b, 0) >
                                        //     850000
                                        //   ) {
                                        //     setPriceIsValid(false);
                                        //     setPriceValidError(
                                        //       "Der Höchstpreis für ausgewählte Kategorien beträgt 850,000 €"
                                        //     );
                                        //   }
                                        // } else {

                                        //   if (speseficCats.includes(category)) {
                                        //   } else {
                                        //     if (
                                        //       Number(e.target.value) +
                                        //         initialCartItem?.extraPrices
                                        //           .map((i) => Number(i.price))
                                        //           .reduce((a, b) => a + b, 0) >
                                        //       750000
                                        //     ) {
                                        //       setPriceIsValid(false);
                                        //       setPriceValidError(
                                        //         "Der Höchstpreis für ausgewählte Kategorien beträgt 750,000 €"
                                        //       );
                                        //     }
                                        //   }
                                        // }

                                        dispatch(
                                            onValueChange({
                                                name: e.target.name,
                                                value: e.target.value,
                                            })
                                        );
                                        dispatch(
                                            updateItem({
                                                itemId: initialCartItem.id,
                                                name: e.target.name,
                                                value: Number(e.target.value),
                                            })
                                        );
                                        dispatch(
                                            calculateOneItem({
                                                itemId: initialCartItem.id,
                                                country: country
                                                    ? country
                                                    : "austria",
                                            })
                                        );
                                        dispatch(calculateTotal());

                                        setPriceIsValid(true);
                                        setPriceValidError("");
                                        if (e.target.value.includes(".")) {
                                            setPriceIsValid(false);
                                            setPriceValidError(
                                                "Dezimalzahl ist nicht erlaubt"
                                            );
                                            return;
                                        }
                                        if (!machine || !category) {
                                            setPriceIsValid(false);
                                            setPriceValidError(
                                                "Bitte wählen Sie zuerst Kategorie und Maschine aus"
                                            );
                                        }
                                        const cost =
                                            Number(e.target.value) +
                                            initialCartItem.extraPrices
                                                .map((i) => Number(i.price))
                                                .reduce((a, b) => a + b, 0);

                                        if (!speseficCats.includes(category)) {
                                            if (cost > 750000) {
                                                setPriceIsValid(false);
                                                setPriceValidError(
                                                    "Der Höchstpreis für ausgewählte Kategorien beträgt 750.000 €"
                                                );
                                            }
                                        } else {
                                            if (cost > 850000) {
                                                setPriceIsValid(false);
                                                setPriceValidError(
                                                    "Der Höchstpreis für ausgewählte Kategorien beträgt 850.000 €"
                                                );
                                            }
                                        }
                                    }}
                                />
                                <div
                                    className="bg-light ms-2 p-1 d-flex  my-auto  border border rounded-2 border-info "
                                    id="label"
                                >
                                    <span>
                                        {category &&
                                        machine &&
                                        purchasePrice &&
                                        initialCartItem.purchasePrice
                                            ? new Intl.NumberFormat("de-DE", {
                                                  currency: "EUR",
                                              }).format(
                                                  initialCartItem.purchasePrice
                                              )
                                            : new Intl.NumberFormat("de-DE", {
                                                  currency: "EUR",
                                              }).format(0)}
                                    </span>
                                    <span className="">€</span>
                                </div>
                            </div>

                            <p
                                className="text-sm text-danger"
                                style={{ fontSize: 12 }}
                            >
                                {priceValidError}
                            </p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <button
                                type="button"
                                className="btn btn-dark btn-sm w-100"
                                data-bs-toggle={
                                    initialCartItem.purchasePrice > 0
                                        ? "modal"
                                        : ""
                                }
                                data-bs-target={
                                    machines.length > 0 ? "#pricesModal" : ""
                                }
                                onClick={() => {
                                    if (
                                        Number(initialCartItem.purchasePrice) >
                                        0
                                    ) {
                                        dispatch(clearTargetExtraPrice());
                                        setIsOpen(true);
                                    } else {
                                        dispatch(
                                            showAlert({
                                                alertType: "warning",
                                                alertContent:
                                                    "Bitte geben Sie zuerst den Kaufpreis ein",
                                            })
                                        );
                                        setTimeout(() => {
                                            dispatch(hideAlert());
                                        }, 2000);
                                    }
                                }}
                            >
                                <i className="bi bi-plus-lg me-2"></i>
                                <span>Zubehör / Anbaugeräte</span>
                            </button>
                        </div>
                    </div>

                    {initialCartItem?.extraPrices &&
                        initialCartItem?.extraPrices?.length > 0 && (
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="text-center" scope="col">
                                            #
                                        </th>
                                        <th className="text-center" scope="col">
                                            Zubehör / Anbaugeräte
                                        </th>
                                        <th className="text-center" scope="col">
                                            Kaufpreis
                                        </th>
                                        <th className="text-center" scope="col">
                                            Aktionen
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {initialCartItem?.extraPrices &&
                                        initialCartItem?.extraPrices.map(
                                            (item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <th
                                                            className="text-center"
                                                            scope="row"
                                                        >
                                                            {index + 1}
                                                        </th>
                                                        <td className="text-center">
                                                            {item.title}
                                                        </td>
                                                        <td className="text-center">
                                                            {formatNumber(
                                                                Number(
                                                                    item.price
                                                                )
                                                            )}
                                                        </td>
                                                        <td className="text-center">
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-danger"
                                                                onClick={async (
                                                                    e
                                                                ) => {
                                                                    dispatch(
                                                                        updateItem(
                                                                            {
                                                                                itemId: initialCartItem.id,
                                                                                name: "extraPrices",
                                                                                value: {
                                                                                    id: item.id,
                                                                                },
                                                                            }
                                                                        )
                                                                    );
                                                                    dispatch(
                                                                        calculateOneItem(
                                                                            {
                                                                                itemId: initialCartItem.id,
                                                                                country:
                                                                                    country
                                                                                        ? country
                                                                                        : "austria",
                                                                            }
                                                                        )
                                                                    );
                                                                    dispatch(
                                                                        calculateTotal()
                                                                    );
                                                                    // dispatch(calculateTotal())
                                                                }}
                                                            >
                                                                Löschen
                                                            </button>

                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-outline-dark ms-2"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#pricesModal"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    dispatch(
                                                                        setExtraPriceItem(
                                                                            {
                                                                                itemId: initialCartItem.id,
                                                                                name: "extraPrices",
                                                                                value: {
                                                                                    id: item.id,
                                                                                },
                                                                            }
                                                                        )
                                                                    );
                                                                }}
                                                            >
                                                                Bearbeiten
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                </tbody>
                            </table>
                        )}

                    {machine && (
                        <>
                            <hr className="border border-primary my-4" />
                            <p className="lead">Produkt *</p>
                            <div
                                className="row row-cols-md-1 row-cols-lg-1 row-cols-xl-3"
                                id="focusElement"
                            >
                                <div
                                    className="col-12 col-md-12 col-lg-12 col-xl-4 px-2 my-2"
                                    ref={ref}
                                >
                                    <div className="card protectType-card border-dark">
                                        <div className="card-header">
                                            <p className="lead my-auto text-center">
                                                Maschinen-Teilversicherung (MTV)
                                            </p>
                                        </div>
                                        <div className="card-body">
                                            <div className="d-flex justify-contact-start align-items-start mb-2">
                                                <span className="fs-4 text-primary ">
                                                    <i className="bi bi-check-lg"></i>
                                                </span>
                                                <p className="ms-2">
                                                    Diebstahl, Einbruchdiebstahl
                                                    oder Raub.
                                                </p>
                                            </div>

                                            <div className="d-flex justify-contact-start align-items-start mb-2">
                                                <span className="fs-4 text-primary ">
                                                    <i className="bi bi-check-lg"></i>
                                                </span>
                                                <p className="ms-2">
                                                    Unbefugter Gebrauch durch
                                                    Betriebsfremde
                                                </p>
                                            </div>

                                            <div className="d-flex justify-contact-start align-items-start mb-2">
                                                <span className="fs-4 text-primary ">
                                                    <i className="bi bi-check-lg"></i>
                                                </span>
                                                <p className="ms-2">
                                                    Glasbruchschäden
                                                </p>
                                            </div>

                                            <div className="d-flex justify-contact-start align-items-start mb-2">
                                                <span className="fs-4 text-primary ">
                                                    <i className="bi bi-check-lg"></i>
                                                </span>
                                                <p className="ms-2">
                                                    Versaufen, Verschlammen
                                                </p>
                                            </div>
                                        </div>

                                        <div className="card-footer">
                                            <div className="input-group">
                                                <input
                                                    type="radio"
                                                    className="btn-check"
                                                    name="protectType"
                                                    id={`mtv`}
                                                    autoComplete="off"
                                                    value={"mtv"}
                                                    onChange={(e) => {
                                                        dispatch(
                                                            onValueChange({
                                                                name: e.target
                                                                    .name,
                                                                value: "mtv",
                                                            })
                                                        );
                                                        dispatch(
                                                            updateProtectType({
                                                                value: "mtv",
                                                                itemId: initialCartItem.id,
                                                            })
                                                        );
                                                        dispatch(
                                                            calculateOneItem({
                                                                itemId: initialCartItem.id,
                                                                country: country
                                                                    ? country
                                                                    : "austria",
                                                            })
                                                        );
                                                        dispatch(
                                                            calculateTotal()
                                                        );
                                                    }}
                                                    checked={
                                                        initialCartItem.protectType ===
                                                        "mtv"
                                                    }
                                                />
                                                <label
                                                    className={`btn btn-outline-dark btn-sm w-75 rounded-2 mx-auto ${
                                                        initialCartItem.protectType ===
                                                            "mtv" && "checked"
                                                    }`}
                                                    htmlFor={`mtv`}
                                                >
                                                    Tarif wählen
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12 col-xl-4 px-2 my-2">
                                    <div className="card protectType-card border-dark">
                                        <div className="card-header">
                                            <p className="lead my-auto text-center">
                                                Kasko
                                            </p>
                                        </div>
                                        <div className="card-body">
                                            <div className="d-flex justify-contact-start align-items-start mb-2">
                                                <span className="fs-4 text-primary ">
                                                    <i className="bi bi-check-lg"></i>
                                                </span>
                                                <p className="ms-2">
                                                    einschließlich Deckung MTV
                                                </p>
                                            </div>

                                            <div className="d-flex justify-contact-start align-items-start mb-2">
                                                <span className="fs-4 text-primary ">
                                                    <i className="bi bi-check-lg"></i>
                                                </span>
                                                <p className="ms-2">
                                                    Transporte (ausgenommen
                                                    Seetransporte)
                                                </p>
                                            </div>

                                            <div className="d-flex justify-contact-start align-items-start mb-2">
                                                <span className="fs-4 text-primary ">
                                                    <i className="bi bi-check-lg"></i>
                                                </span>
                                                <p className="ms-2">
                                                    Kaskoschäden
                                                    (Verkehrs-/Unfallereignisse)
                                                </p>
                                            </div>

                                            <div className="d-flex justify-contact-start align-items-start mb-2">
                                                <span className="fs-4 text-primary ">
                                                    <i className="bi bi-check-lg"></i>
                                                </span>
                                                <p className="ms-2">
                                                    Erdrutsch
                                                </p>
                                            </div>

                                            <div className="d-flex justify-contact-start align-items-start mb-2">
                                                <span className="fs-4 text-primary ">
                                                    <i className="bi bi-check-lg"></i>
                                                </span>
                                                <p className="ms-2">
                                                    Glasbruchschäden
                                                </p>
                                            </div>
                                        </div>

                                        <div className="card-footer">
                                            <div className="input-group">
                                                <input
                                                    type="radio"
                                                    className="btn-check"
                                                    name="protectType"
                                                    id={`hull`}
                                                    autoComplete="off"
                                                    value={"hull"}
                                                    onChange={(e) => {
                                                        dispatch(
                                                            onValueChange({
                                                                name: e.target
                                                                    .name,
                                                                value: "hull",
                                                            })
                                                        );
                                                        dispatch(
                                                            updateProtectType({
                                                                value: "hull",
                                                                itemId: initialCartItem.id,
                                                            })
                                                        );
                                                        dispatch(
                                                            calculateOneItem({
                                                                itemId: initialCartItem.id,
                                                                country: country
                                                                    ? country
                                                                    : "austria",
                                                            })
                                                        );
                                                        dispatch(
                                                            calculateTotal()
                                                        );
                                                    }}
                                                    checked={
                                                        initialCartItem.protectType ===
                                                        "hull"
                                                    }
                                                />
                                                <label
                                                    className={`btn btn-outline-dark btn-sm w-75 rounded-2 mx-auto ${
                                                        initialCartItem.protectType ===
                                                            "hull" && "checked"
                                                    }`}
                                                    htmlFor={`hull`}
                                                >
                                                    Tarif wählen
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-12 col-lg-12 col-xl-4 px-2 my-2">
                                    <div className="card protectType-card border-dark">
                                        <div className="card-header">
                                            <p className="lead my-auto text-center">
                                                Vollschutz
                                            </p>
                                        </div>
                                        <div className="card-body">
                                            <div className="d-flex justify-contact-start align-items-start mb-2">
                                                <span className="fs-4 text-primary ">
                                                    <i className="bi bi-check-lg"></i>
                                                </span>
                                                <p className="ms-2">
                                                    einschließlich Deckung Kasko
                                                </p>
                                            </div>

                                            <div className="d-flex justify-contact-start align-items-start mb-2">
                                                <span className="fs-4 text-primary ">
                                                    <i className="bi bi-check-lg"></i>
                                                </span>
                                                <p className="ms-2">
                                                    Innere Betriebsschäden -
                                                    elektronische Bauteile -
                                                    Erst- Risiko-Summe 2.500 EUR
                                                </p>
                                            </div>

                                            <div className="d-flex justify-contact-start align-items-start mb-2">
                                                <span className="fs-4 text-primary ">
                                                    <i className="bi bi-check-lg"></i>
                                                </span>
                                                <p className="ms-2">
                                                    Kurzschluss, Überstrom und
                                                    Überspannung
                                                </p>
                                            </div>

                                            <div className="d-flex justify-contact-start align-items-start mb-2">
                                                <span className="fs-4 text-primary ">
                                                    <i className="bi bi-check-lg"></i>
                                                </span>
                                                <p className="ms-2">
                                                    Bedienungsfehler aller Art
                                                </p>
                                            </div>

                                            <div className="d-flex justify-contact-start align-items-start mb-2">
                                                <span className="fs-4 text-primary ">
                                                    <i className="bi bi-check-lg"></i>
                                                </span>
                                                <p className="ms-2">
                                                    Ungeschicklichkeit und
                                                    Fahrlässigkeit
                                                </p>
                                            </div>
                                        </div>

                                        <div className="card-footer">
                                            <div className="input-group">
                                                <input
                                                    type="radio"
                                                    className="btn-check"
                                                    name="protectType"
                                                    id={`fullProduction`}
                                                    autoComplete="off"
                                                    value={"fullProduction"}
                                                    checked={
                                                        initialCartItem.protectType ===
                                                        "fullProduction"
                                                    }
                                                    onChange={(e) => {
                                                        dispatch(
                                                            onValueChange({
                                                                name: e.target
                                                                    .name,
                                                                value: "fullProduction",
                                                            })
                                                        );
                                                        dispatch(
                                                            updateProtectType({
                                                                value: "fullProduction",
                                                                itemId: initialCartItem.id,
                                                            })
                                                        );
                                                        dispatch(
                                                            calculateOneItem({
                                                                itemId: initialCartItem.id,
                                                                country: country
                                                                    ? country
                                                                    : "austria",
                                                            })
                                                        );
                                                        dispatch(
                                                            calculateTotal()
                                                        );
                                                    }}
                                                />
                                                <label
                                                    className={`btn btn-outline-dark btn-sm w-75 rounded-2 mx-auto ${
                                                        initialCartItem.protectType ===
                                                            "fullProduction" &&
                                                        "checked"
                                                    }`}
                                                    htmlFor={`fullProduction`}
                                                >
                                                    Tarif wählen
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="border border-primary my-4" />

                            <p className="lead my-2 fw-2">
                                <span>Rabatte & Zuschläge</span>
                            </p>
                            <div className="row row-cols-md-2 row-cols-lg-2 my-4">
                                <div className="col-12 col-md-12 col-lg-12  ">
                                    <div className="d-flex justify-content-between flex-wrap">
                                        {defaultDiscountsList.map(
                                            (discount, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className="input-group w-50"
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            className="btn-check"
                                                            id={`btncheck-${discount.id}`}
                                                            autoComplete="off"
                                                            value={
                                                                discount.value
                                                            }
                                                            checked={initialCartItem.discountsOptions
                                                                .map(
                                                                    (item) =>
                                                                        item.value
                                                                )
                                                                .includes(
                                                                    discount.value
                                                                )}
                                                            onChange={(e) => {
                                                                dispatch(
                                                                    updateItem({
                                                                        itemId: initialCartItem.id,
                                                                        name: "discountsOptions",
                                                                        value: {
                                                                            ...discount,
                                                                        },
                                                                    })
                                                                );
                                                                dispatch(
                                                                    calculateOneItem(
                                                                        {
                                                                            itemId: initialCartItem.id,
                                                                            country:
                                                                                country
                                                                                    ? country
                                                                                    : "austria",
                                                                        }
                                                                    )
                                                                );
                                                                dispatch(
                                                                    calculateTotal()
                                                                );
                                                            }}
                                                        />

                                                        <label
                                                            className="btn btn-sm btn-outline-dark my-2 w-100 py-2 mx-1 rounded-1 d-flex align-items-center text-left justify-content-start "
                                                            htmlFor={`btncheck-${discount.id}`}
                                                        >
                                                            <span className="text-small">
                                                                {discount.label}
                                                            </span>
                                                            <span className="fs-5 ms-auto">
                                                                <i className="bi bi-check-circle"></i>
                                                            </span>
                                                        </label>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </form>
            </div>
        </>
    );
}

export default Form1;
