function PageHeader({ title, targetCollection, withSearch }) {

    const onSearchFormSubmit = (e) => {
        console.log(e);
    }

    return (<>
        <div className="page-header row mb-4">
            <div className="col-12">
                <div className="d-flex">
                    <div className="me-auto page-title-holder">
                        <h2 className="section-title text-dark ">{title}</h2>
                    </div>
                    {
                        withSearch &&
                        <>
                            <div className="ms-auto">
                                <form action="" onSubmit={onSearchFormSubmit}>
                                    <div className="input-group input-group-sm border border-1 border-info rounded-2">
                                        <span className="input-group-text bg-light border-0" id="basic-search">
                                            <i className="bi bi-search"></i>
                                        </span>
                                        <input type="search" className="form-control form-control-sm border-0 shadow-none  bg-light" placeholder="Suche" aria-label="Search" aria-describedby="basic-search" />
                                    </div>
                                    <input type="submit" value="Suche" hidden />
                                </form>
                            </div>
                        </>
                    }

                </div>
            </div>

        </div>
    </>);
}

export default PageHeader;