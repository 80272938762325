import React, { useState } from "react";
import EditSide from "./EditSide";
import DistributorCard from "./DistributorCard";
function DistributorCards({ records }) {
  const [editMode, setEditMode] = useState(false);
  const [distributor, setDistributor] = useState(null);
  // const [loading, setLoading] = useState(false);
  const onEdit = (target) => {
    setEditMode(true);
    setDistributor(target);
  };
  const onCloseEdit = (e) => {
    setEditMode(!editMode);
  };

  return (
    <>
      {editMode && (
        <EditSide
          onCloseEdit={onCloseEdit}
          distributor={distributor}
        ></EditSide>
      )}

      {records?.map((record, index) => {
        console.log(record)
        return (
            
          <>
            {record && (
              <DistributorCard key={index} record={record} onEdit={onEdit} />
            )}
          </>
        );
      })}
    </>
  );
}

export default DistributorCards;
