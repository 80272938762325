import { useEffect, useState } from "react";
import Loading from "../../../components/Loading";
import axiosInstance from "../../../utils/axios";
import DistributorCards from "../../../components/DistributorsCards";
function AdminDistributorsPage() {
  let [loading, setLoading] = useState(true);
  let [records, setRecords] = useState(null);
  const [targetGroup, setTargetGroup] = useState("all");
  const [counts, setCounts] = useState({ active: 0, inActive: 0, all: 0 });

  let get = async () => {
    let response = await axiosInstance.get("/api/admin/distributors", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await response.data.result;
    setRecords(data);
    setCounts({
      active: data.filter((r) => r?.distributor?.isActive === true).length,
      inActive: data.filter((r) => r?.distributor?.isActive === false).length,
      all: data.length,
    });
    setLoading(false);
    return data;
  };

  const filterData = async (target) => {
    setTargetGroup(target);
    let baseRecords = await get();
    let newRecords;
    if (target === "active") {
      newRecords = baseRecords.filter(
        (record) => record.distributor.isActive === true
      );
      setRecords(newRecords);
    } else if (target === "in-active") {
      newRecords = baseRecords.filter(
        (record) => record.distributor.isActive !== true
      );
      setRecords(newRecords);
    } else {
      setRecords(baseRecords);
    }
  };

  useEffect(() => {
    get();
  }, []);

  let searchFunc = (e) => {
    if (e.target.value.toUpperCase().length > 3) {
      let newData = records.filter(
        (x) =>
          x?.distributor?.fullName
            .toUpperCase()
            .includes(e.target.value.toUpperCase()) ||
          x?.distributor?.company?.name
            .toUpperCase()
            .includes(e.target.value.toUpperCase()) ||
          x?.distributor?.email
            .toUpperCase()
            .includes(e.target.value.toUpperCase())
      );

      setRecords(newData);
    } else {
      get();
    }
  };

  if (loading) {
    return <Loading></Loading>;
  }


  

  

  if (!loading && records) {
    return (
      <>
        <div className="container distributors-page ">
          <div className="col-12">
            <div className="page-header row row-cols-md-2 row-cols-lg-2 row-cols-xl-2 mb-4">
              <div className="col-12 col-md-12 col-lg-6 col-xl-6">
                <div className="me-auto page-title-holder">
                  <h2 className="section-title text-dark ">Vertriebspartner</h2>
                  <div className="head-btns">
                    
                <button className={`btn btn-sm btn-light shadow-none rounded-0 ${targetGroup=="active" && 'active'}`}
                  onClick={() => filterData("active")}
                >
                  Aktive <span>({counts.active})</span>{" "}
                </button>
                <button
                  className={`btn btn-sm btn-light shadow-none rounded-0 ${targetGroup=="in-active" && 'active'}`}
                  onClick={() => filterData("in-active")}
                >
                  Inaktive <span>({counts.inActive})</span>{" "}
                </button>
                <button
                     className={`btn btn-sm btn-light shadow-none rounded-0 ${targetGroup=="all" && 'active'}`}
                  onClick={() => filterData("all")}
                >
                  Alle <span>({counts.all})</span>{" "}
                </button>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-6 col-xl-6 pt-4">
                <div className="search">
                  <div className="input-group">
                    <span className="input-group-text">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25.378"
                        height="25.506"
                        viewBox="0 0 25.378 25.506"
                      >
                        <g
                          id="Ellipse_14"
                          data-name="Ellipse 14"
                          transform="translate(0 11.314) rotate(-45)"
                          fill="none"
                          stroke="#344167"
                          strokeWidth="1"
                        >
                          <circle cx="8" cy="8" r="8" stroke="none" />
                          <circle cx="8" cy="8" r="7.5" fill="none" />
                        </g>
                        <g
                          id="Ellipse_15"
                          data-name="Ellipse 15"
                          transform="translate(2.552 11.441) rotate(-45)"
                          fill="none"
                          stroke="#344167"
                          strokeWidth="1"
                        >
                          <circle
                            cx="6.195"
                            cy="6.195"
                            r="6.195"
                            stroke="none"
                          />
                          <circle cx="6.195" cy="6.195" r="5.695" fill="none" />
                        </g>
                        <g
                          id="Rectangle_82"
                          data-name="Rectangle 82"
                          transform="translate(14.771 17.728) rotate(-45)"
                          fill="none"
                          stroke="#344167"
                          strokeWidth="1"
                        >
                          <rect width="4" height="11" stroke="none" />
                          <rect
                            x="0.5"
                            y="0.5"
                            width="3"
                            height="10"
                            fill="none"
                          />
                        </g>
                      </svg>
                    </span>
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Suche"
                      aria-label="Search"
                      aria-describedby="basic-search"
                      onChange={(e) => {
                        setTimeout(() => {
                          searchFunc(e);
                        }, 500);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row row-cols-md-1 row-cols-lg-2 row-cols-xl-3">
              <DistributorCards records={records} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AdminDistributorsPage;
