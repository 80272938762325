import React from "react";
import logo from "../assets/images/login-logo.svg";
import {Link} from "react-router-dom";
function Footer({}) {
  return (
    <>
      <div className="col-12 footer-container">
        <div className="footerwrap mt-5">
          <div className="copyright-wrap">
            <div className="footer-logo">
              <img src={logo} alt="logo" className="img-fluid" />
            </div>
            <span>COPYRIGHT © 2023</span>
          </div>
          <ul className="footer-menu">
            <li>
              <a href="https://versitech.at/impressum" target="_blank">IMPRESSUM</a>
            </li>
            <li>
              <a href="https://versitech.at/datenschutz" target="_blank">DATENSCHUTZ</a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Footer;
