import { useDispatch } from 'react-redux'
import { Link, useNavigation } from 'react-router-dom'
import logo from '../assets/images/logo.svg'
import { clearCalculator } from '../features/contract/calculatorSlice'
function NavigationHeader({ forms, currentStepIndex }) {
  const dispatch = useDispatch()
  return (
    <>
      <div className="logo-holder">
        <Link to="/dashboard/distributor" role="button" replace>
          <img src={logo} alt="" className="logo" />
        </Link>
      </div>
      <div className="inner-nav">
        {forms &&
          forms.map((form, index) => {
            if (
              form.title === 'Versicherung abschließen' &&
              currentStepIndex === 2
            ) {
              return (
                <div
                  href=""
                  className={`nav-btn border border-dark text-center rounded-1 bg-dark text-white`}
                >
                  <span>{form?.number}</span>
                  {form?.title}
                </div>
              )
            } else {
              return (
                <div
                  key={index}
                  href=""
                  className={`nav-btn border border-dark text-center p-1 bg-${
                    currentStepIndex === index ? 'dark' : 'light'
                  } text-${currentStepIndex === index ? 'white' : 'dark'}`}
                >
                  <span>{form?.number}</span>
                  {form?.title}
                </div>
              )
            }
          })}
      </div>
    </>
  )
}

export default NavigationHeader
