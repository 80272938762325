import React, { useEffect, useState } from "react";
import axiosInstance from "../../utils/axios";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import Loading from "../../components/Loading";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import {
  getCategories,
} from "../../features/contract/machineSlice";

function MachinesTable({ machines, setEditing }) {
  return (
    <>
      {/* MachinesList */}
      <table className="table table-striped machines-table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col" className="col-4">
              Gruppe
            </th>
            <th scope="col" className="col-2">
              Maschine auswählen
            </th>
            <th scope="col" className="col-1">
              Maschinenmarke
            </th>
            <th scope="col" className="col-1">
              Maschinentype
            </th>
            <th scope="col" className="col-2">
              Seriennummer/Fabriksnummer
            </th>
            <th scope="col" className="col-2">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {machines &&
            machines.map((machine, index) => {
              return (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td className="col-4">
                    <div className="d-flex align-items-start">
                      <img
                        src={`${require(`../../assets/categoryImages/${machine.category.photo}`)}`}
                        alt=""
                        className="category-img me-2"
                      />
                      {machine.category.title}
                    </div>
                  </td>
                  <td className="col-2">{machine.machine.title}</td>
                  <td className="col-1">{machine.brand}</td>
                  <td className="col-1">{machine.machineType}</td>
                  <td className="col-2">{machine.serialNumber}</td>
                  <td className="col-2">
                    <button
                      className="btn btn-sm btn-outline-dark mx-2"
                      onClick={() => setEditing(machine.machine._id)}
                    >
                      update
                    </button>
                    <button className="btn btn-sm btn-outline-dark mx-2">
                      remove
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
}

function MachineForm({ targetMachine, categoriesList, setEditingMachine }) {
  
  const [selectedCategory, setSelectedCategory] = useState(
    targetMachine.category._id
  );

  const { machinesList } = useSelector((store) => store.machine);
  const onCategoryChange = () => {};

  const handleScrolling = (direction) => {
    // const container = ref.current;
    const container = document.querySelector("#categories");
    container.scrollLeft = container.scrollLeft + direction;
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between py-3">
        <p className="my-auto">Versicherungsobjekt bearbeiten </p>
        <button
          className="btn btn-outline-dark btn-sm p-2"
          onClick={() => setEditingMachine(false)}
        >
          x
        </button>
      </div>

      <form action="" id="machine-form">
        <div className="row">
          <div className="col-12 px-5 position-relative">
            {/* CATEGORIES */}
            <div className="w-100  categories-holder ">
              <button
                type="button"
                className="scroll-btn btn btn-sm btn-light p-2 h-100"
                id="left"
                onClick={(e) => {
                  handleScrolling(-300);
                }}
              >
                <AiOutlineArrowLeft />
              </button>
              <button
                type="button"
                className="scroll-btn btn btn-sm btn-light p-2 h-100"
                id="right"
                onClick={(e) => {
                  handleScrolling(300);
                }}
              >
                <AiOutlineArrowRight />
              </button>
              <div className="categories-list">
                {categoriesList &&
                  categoriesList.map((category, index) => {
                    return (
                      <div
                        className="col-4 "
                        onClick={() => {
                          setSelectedCategory(category._id);
                          onCategoryChange(category._id);
                        }}
                      >
                        <div className="card category-card mx-2 border-0 rounded-0">
                          <div className="card-body m-0 p-0 border-0">
                            <img
                              src={require(`../../assets/categoryImages/${category.photo}`)}
                              alt=""
                              className="w-100"
                            />
                          </div>
                          <div
                            className={`card-footer py-4 border-0 text-white rounded-0 text-center ${
                              category._id === selectedCategory
                                ? "bg-primary"
                                : "bg-dark"
                            }`}
                          >
                            {category.title}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="grey-shadow-holder"></div>
            </div>
          </div>
        </div>

        <div className="row row-cols-md-3 row-cols-lg-3 my-4 ">
          <div className="col-12 col-md-3 col-lg-3">
            <select
              name="machine"
              id="machineField"
              className={`form-select form-select-sm bg-light shadow-none`}
              value={targetMachine.machine._id}
              onChange={async (e) => {}}
              required
            >
              <option value="*">Maschine auswählen *</option>
              {machinesList &&
                machinesList.map((machine, index) => {
                  return (
                    <option key={index} value={machine?._id}>
                      {machine.title}
                    </option>
                  );
                })}
            </select>
          </div>

          <div className="col-12 col-md-3 col-lg-3">
            <input
              type="text"
              name="brand"
              id="brandName"
              className={`form-control form-control-sm bg-light shadow-none`}
              placeholder="Maschinenmarke *"
              required
              value={targetMachine.brand}
              onChange={(e) => {
                // dispatch(
                //   onValueChange({ name: e.target.name, value: e.target.value })
                // );
                // dispatch(
                //   updateItem({
                //     itemId: initialCartItem.id,
                //     name: "brand",
                //     value: e.target.value,
                //   })
                // );
              }}
            />

            {/* <p className="text-sm text-danger" style={{ fontSize: 12 }}>
              {machineValid === false &&
                machineErrors.find((error) => error.field === "brand")?.text}
            </p> */}
          </div>

          <div className="col-12 col-md-3 col-lg-3">
            <input
              type="text"
              name="machineType"
              id="machineType"
              className={`form-control form-control-sm bg-light shadow-none`}
              placeholder="Maschinentype *"
              required
              value={targetMachine.machineType}
              onChange={(e) => {
                // dispatch(
                //   onValueChange({ name: e.target.name, value: e.target.value })
                // );
                // dispatch(
                //   updateItem({
                //     itemId: initialCartItem.id,
                //     name: "machineType",
                //     value: e.target.value,
                //   })
                // );
              }}
            />

            {/* <p className="text-sm text-danger" style={{ fontSize: 12 }}>
              {machineValid === false &&
                machineErrors.find((error) => error.field === "machineType")
                  ?.text}
            </p> */}
          </div>

          <div className="col-12 col-md-3 col-lg-3">
            <select
              name="productionYear"
              id="productionYearField"
              className="form-select form-select-sm bg-light shadow-none"
              required
              value={targetMachine.productionYear}
              onChange={(e) => {
                // dispatch(
                //   onValueChange({ name: e.target.name, value: e.target.value })
                // );
                // dispatch(
                //   updateItem({
                //     itemId: initialCartItem.id,
                //     name: "productionYear",
                //     value: e.target.value,
                //   })
                // );
              }}
            >
              <option value="*">Baujahr auswählen *</option>
              {/* {yearsRange() &&
                yearsRange().map((year, index) => {
                  if (year === "Baujahr vor 2013") {
                    return (
                      <option
                        data-bs-toggle="modal"
                        data-bs-target="#yearModal"
                        key={index}
                        value={year}
                      >{`${year}`}</option>
                    );
                  } else {
                    return (
                      <option
                        key={index}
                        value={year}
                      >{`Baujahr ${year}`}</option>
                    );
                  }
                })} */}
            </select>
            <p className="text-sm text-danger" style={{ fontSize: 12 }}>
              {/* {machineValid === false &&
                machineErrors.find((error) => error.field === "productionYear")
                  ?.text} */}
            </p>
          </div>
        </div>
      </form>
    </>
  );
}

function UpdateContractPage() {
  const [loading, setLoading] = useState(false);
  const [contract, setContract] = useState(null);
  const [editingMachine, setEditingMachine] = useState(false);
  const [targetMachine, setTargetMachine] = useState(null);

  const dispatch = useDispatch();

  const { id } = useParams();
  const { categoriesList } = useSelector((store) => store.machine);
  const setTargetEditingMachine = (machineId) => {
    setEditingMachine(true);
    const machine = contract.machines.find((m) => m.machine._id === machineId);
    setTargetMachine(machine);
  };

  const getRecord = async () => {
    setLoading(true);
    const response = await axiosInstance.get(
      `/api/distributor/contracts/full/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    const result = await response.data.result;
    setContract(result);
    setLoading(false);
  };

  useEffect(() => {
    getRecord();
    dispatch(getCategories());
  }, []);

  if (loading) {
    return <Loading />;
  }
  if (contract && !loading) {
    return (
      <div className="container py-3 edit-full-page">
        <div className="logo-holder py-2">
          <Link to="/dashboard/distributor" role="button" replace>
            <img src={logo} alt="" className="logo" />
          </Link>
        </div>

        <div className="row px-4">
          <div className="col-12">
            <h3 className="font-bold my-4">versitech Maschinen-Versicherung</h3>

            <div className="row bg-light py-4 my-2 rounded-2 shadow-sm mx-auto">
              <div className="col-12">
                <MachinesTable
                  machines={contract.machines}
                  setEditing={setTargetEditingMachine}
                />
              </div>
            </div>

            {editingMachine && (
              <div className="row bg-light py-4 my-4 rounded-2 shadow-sm mx-auto">
                <div className="col-12 px-4">
                  <MachineForm
                    targetMachine={targetMachine}
                    categoriesList={categoriesList}
                    setEditingMachine={setEditingMachine}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default UpdateContractPage;
