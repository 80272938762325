import biglogo from '../assets/images/login-logo.svg'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import axiosInstance from '../utils/axios'
import { useDispatch } from 'react-redux'
import { hideAlert, showAlert } from '../features/alertSlice'
import Alert from '../components/Alert'
function NewPasswordPage() {
  const dispatch = useDispatch()
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { resetToken } = useParams()
  
  const navigate = useNavigate();
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  const onValueChange = (e) => {
    if (e.target.name === 'password') {
      setNewPassword(e.target.value)
    }
    if (e.target.name === 'confirmNewPassword') {
      setConfirmNewPassword(e.target.value)
    }
  }
  const onSetNewPassword = async (e) => {
    e.preventDefault()
    if (newPassword !== confirmNewPassword) {
      dispatch(
        showAlert({
          alertType: 'danger',
          alertContent: 'Passwörter müssen machbar sein',
        }),
      )
      setTimeout(() => {
        dispatch(hideAlert())
      }, 2000)
      setNewPassword('')
      setConfirmNewPassword('')
      return
    }
    setLoading(true)
    if (newPassword) {
      try {
        const response = await axiosInstance.post(
          `/api/auth/reset-password/${resetToken}`,
          {
            newPassword: newPassword,
          },
        )
        const result = await response.data
       
        dispatch(
          showAlert({
            alertType: 'success',
            alertContent: 'Das Passwort wurde erfolgreich aktualisiert',
          }),
        )
        setTimeout(() => {
          dispatch(hideAlert())
        }, 2000)

        navigate("/");
      } catch (error) {
        console.log(`💥 ${error}`)
      }
    }
  }
  return (
    <>
      <div className="login-page">
        <div className="big-logo">
          <img src={biglogo} alt="logo" className="img-fluid" />
        </div>
        <div className="container justify-content-center d-flex">
          <Alert></Alert>
          <form className="login-form mt-5" onSubmit={onSetNewPassword}>
            <h4 className="text-center">
              Formular zum Zurücksetzen des Passworts
            </h4>
            <div className="mt-3">
              <label className="form-label">Neues Passwort</label>
              <input
                onChange={onValueChange}
                type="password"
                name="password"
                id="passwordField"
                value={newPassword}
                className="form-control shadow-none"
                placeholder="Neues Passwort"
              />
              <input
                onChange={onValueChange}
                type="password"
                name="confirmNewPassword"
                id="confirmNewPassword"
                value={confirmNewPassword}
                className="form-control shadow-none mt-3"
                placeholder="bestätige neues Passwort"
              />
              {!isValid && (
                <p className="text-sm text-danger" style={{ fontSize: 12 }}>
                  {error}
                </p>
              )}
            </div>

            <div className="mt-4">
              <input
                type="submit"
                value="Passwort zurücksetzen"
                className="btn btn-sm d-block"
              />
              <Link
                to={'/'}
                replace
                className="btn btn-sm d-block mt-3 border-dark bg-light text-dark"
              >
                Zurück zum hauptsächlichen
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default NewPasswordPage
