const calculateAllStates = (paymentPeriodValue,machine, country, machines, factor, deductible) => {
  if (machine && country && machines && factor && deductible) {
    
    let paymentPeriod =
    paymentPeriodValue === "per-month"
        ? 1/12
        : paymentPeriodValue === "quarterly"
        ?  1/4
        : paymentPeriodValue === "half-yearly"
        ? 1/2
        : paymentPeriodValue === "yearly"
        ? 1
        : null;

    // find total of selected discount
    const discounts = [...machine.discountsOptions].map((item) => {
      return { ...item }.value;
    });

    // define tax rate value
    let taxRate =
      country === "germany" ? 119 : discounts.includes(-12) ? 111 : 111.4;

    // find cost with tax rate
    let newPrice =
      (Number(machine.purchasePrice) +
        Number(machine.purchasePrice) * (taxRate / 100)) *
      paymentPeriod;

    // total of extra accessories
    const totalExtra = [...machine.extraPrices]
      .map((i) => i.price)
      .reduce((acc, current) => Number(acc) + Number(current), 0);

    if (totalExtra) {
      newPrice =
        (Number(machine.purchasePrice) +
          totalExtra +
          (Number(machine.purchasePrice) + totalExtra) * (taxRate / 100)) *
        paymentPeriod;
    }

    if (factor) {
      newPrice =
        ((Number(machine.purchasePrice) + totalExtra) * (factor / 1000) +
          (Number(machine.purchasePrice) + totalExtra) *
            (factor / 1000) *
            (taxRate / 100)) *
        paymentPeriod;
    }
    // calculate cost depends on machines count
    if (machines.length >= 3) {
      [...machine.discountsOptions].push({
        id: 6,
        label: "Mehrjährigkeitsrabatt bei einer Laufzeit von 3 Jahren (-10%)",
        value: -10,
      });
    }

    if (machines.length >= 3 && machines.length <= 5) {
      [...machine.discountsOptions].push({
        id: 7,
        label: "new addition discount item",
        value: 7,
      });
    } else if (machines.length >= 6 && machines.length <= 10) {
      [...machine.discountsOptions].filter((i) => i.id !== 7);
      [...machine.discountsOptions].push({
        id: 7,
        label: "new addition discount item",
        value: 10,
      });
    } else {
      [...machine.discountsOptions].filter((i) => i.id !== 7);
    }
    const totalDiscounts = [...machine.discountsOptions]
      .map((i) => i.value)
      .reduce((acc, current) => acc + current, 0);

    ///////////
    let itemDiscounts = [...machine.discountsOptions].map(i=>Number(i.value));
    let basePrice = (Number(machine.purchasePrice) + totalExtra) *(Number(factor) / 1000);
    for (let index = 0; index < itemDiscounts.length; index++) {
      const currentDiscount = itemDiscounts[index];
      basePrice += basePrice * (currentDiscount/100);
      
      
    }
    basePrice = basePrice * (taxRate /100) * paymentPeriod;
    
    newPrice = basePrice;
  
    return { price: newPrice, deductible };
  }
};

export { calculateAllStates };
