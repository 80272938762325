import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import PageHeader from "../../components/PagesHeader";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import formatNumber from "../../utils/formatNumber";
import axiosInstance from "../../utils/axios";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import Loading from "../../components/Loading";
import DownloadButton from "../../components/DownloadButton";
function ContractDetails() {
  const { contractId } = useParams();
  let [isLoading, setIsLoading] = useState(true);
  let [currentContract, setCurrentContract] = useState(false);
  
  let get = async () => {
    const response = await axiosInstance.get(
      `/api/contracts/${contractId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    const data = await response.data;
    setCurrentContract(data.result);
    setIsLoading(false);
  };

  useEffect(() => {
    get();
  }, []);

  if (isLoading) {
    return <>
    <Loading/>
    </>;
  }
  if (currentContract) {
    return (
      <>
        <div className="container">
          <div className="col-12">
            <PageHeader title={"Deine Kunden!"} withSearch={false}></PageHeader>

            <div className="contract-details-holder row">
              <div className="col-12">
                <Link
                  to={
                    getUserFromLocalStorage()?.role === "admin"
                      ? "/dashboard/admins/distributors"
                      : "/dashboard/customers"
                  }
                  className="btn mb-3"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9.361"
                    height="16.721"
                    viewBox="0 0 9.361 16.721"
                  >
                    <path
                      id="Path_298"
                      data-name="Path 298"
                      d="M979.742-74.842l6.947-6.947,6.947,6.947"
                      transform="translate(82.788 995.049) rotate(-90)"
                      fill="none"
                      stroke="#344167"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </svg>
                  <span className="px-3">Zurück</span>
                </Link>

                <div className="contract-details card">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h4 className="text-dark my-auto">
                      {currentContract?.customer?.fullName} – Antrag_
                      <Moment
                        date={currentContract?.createdAt}
                        format={"YYYY_MM_DD"}
                      ></Moment>{" "}
                    </h4>
                    {
                      currentContract?.state !== "OfferCreated" && 
                      <DownloadButton recordId={currentContract?._id} target={"contract"} width={"w-20"}/>
                    }
                  </div>
                  <div className="card-body">
                    {currentContract?.machines &&
                      currentContract?.machines?.map((record, index) => {
                        return (
                          <div key={index} className="machine-section my-4">
                            <p className="my-auto ms-0 fs-4 w-25  pb-2 text-dark text-with-border">
                              Maschine {index + 1}
                            </p>
                            <p className="lead fs-5 text-dark mt-3">
                              {record?.category?.title} -{" "}
                              {record?.machine?.title}
                            </p>
                            <div className="contact-info d-flex w-100">
                              <div className="img-holder w-25">
                                <img
                                  src={require(`../../assets/categoryImages/${record?.category?.photo}`)}
                                  alt=""
                                  className="img-fluid rounded-1 border border-1 border-dark"
                                />
                              </div>
                              <div className="details-holder ms-4 w-50">
                                <p className="my-auto text-dark lead fs-6">
                                  <span id="brand" className="me-2 fw-bold">
                                    Hersteller :{" "}
                                  </span>
                                  <span id="brand" className="text-dark">
                                    {record?.brand}
                                  </span>
                                </p>
                                <p className="my-auto text-dark lead fs-6">
                                  <span
                                    id="productionYear"
                                    className="me-2 fw-bold"
                                  >
                                    Baujahr :{" "}
                                  </span>
                                  <span
                                    id="productionYear"
                                    className="text-dark"
                                  >
                                    {record?.productionYear}
                                  </span>
                                </p>

                                <p className="my-auto text-dark lead fs-6">
                                  <span
                                    id="serialNumber"
                                    className="me-2 fw-bold"
                                  >
                                    Seriennummer :{" "}
                                  </span>
                                  <span id="serialNumber" className="text-dark">
                                    {record?.serialNumber}
                                  </span>
                                </p>
                              </div>

                              <div className="total-cost-holder d-flex justify-content-end align-items-end  w-25">
                                <p className="price-label">
                                  {formatNumber(record?.purchasePrice)}
                                </p>
                              </div>
                            </div>

                            <hr />

                            <div className="d-flex justify-content-between">
                              <p className="my-auto text-dark lead fs-6">
                                Kaufpreis
                              </p>
                              <p className="my-auto text-dark lead fs-6 value-text">
                                {formatNumber(record?.purchasePrice)}
                              </p>
                            </div>

                            {record?.extraPrices &&
                              record?.extraPrices?.map((item, index) => {
                                return (
                                  <div key={index}>
                                    <div className="d-flex justify-content-between">
                                      <p className="my-auto text-dark lead fs-6">
                                        {item?.title}:
                                      </p>
                                      <p className="my-auto text-dark lead fs-6 value-text">
                                        {formatNumber(item?.price)}
                                      </p>
                                    </div>
                                  </div>
                                );
                              })}

                            <hr />
                            <div className="d-flex justify-content-between">
                              <p className="my-auto text-dark lead fs-6">
                                Gesamtkosten:
                              </p>
                              <p className="my-auto text-dark lead fs-6 value-text">
                                {record?.extraPrices
                                  ? formatNumber(
                                      Number(record?.purchasePrice) +
                                        record?.extraPrices
                                          .map((p) => Number(p.price))
                                          .reduce(
                                            (acc, current) =>
                                              Number(acc) + Number(current),
                                            0
                                          )
                                    )
                                  : formatNumber(Number(record?.purchasePrice))}
                              </p>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-between">
                              <p className="my-auto text-dark lead fs-6">
                                Selbstbehalt:
                              </p>
                              <p className="my-auto text-dark lead fs-6 value-text">
                                {formatNumber(record?.deductible)}
                              </p>
                            </div>

                            <hr />
                            <div className="d-flex justify-content-between">
                              <p className="my-auto text-dark lead fs-6">
                                Produkt:
                              </p>
                              <p className="my-auto text-dark lead fs-6 value-text">
                                {record?.protectType === "fullProduction"
                                  ? "Vollschutz"
                                  : record?.protectType === "hull"
                                  ? "Kaskoschutz"
                                  : record?.protectType === "mtv"
                                  ? "MTV/Teilschutz"
                                  : ""}
                              </p>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-between">
                              <p className="my-auto text-dark lead fs-6">
                                Rabatte & Zuschläge:
                              </p>
                              <p className="my-auto text-dark lead fs-6 value-text">
                                {record?.discountsOptions
                                  .map((i) => Number(i.value))
                                  .reduce((i, c) => i + c, 0)}{" "}
                                %
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    <div className="d-flex justify-content-between mt-3">
                      <p className="my-auto text-dark lead fs-3 fw-bolder">
                        Angebot
                      </p>
                      <p className="my-auto text-dark lead fs-3 fw-bolder fw-bolder">
                        {formatNumber(currentContract?.total)}
                      </p>
                    </div>

                    <hr className="border border-primary border-1" />

                    <div className="row row-cols-md-2 d-flex justify-content-between mt-4">
                      <div className="col-12 col-md-6 col-lg-6 customer-information">
                        <p className="lead text-dark fs-6 text-with-border w-75 text-uppercase fw-bold ">
                          Persönliche Informationen
                        </p>
                        <p className="my-auto lead fs-6 fw-bold mt-4 text-dark">
                          {currentContract?.customer?.fullName}
                        </p>
                        <p className="my-auto lead fs-6 fw-bold text-dark fst-italic">
                          {currentContract?.customer?.company?.name}{" "}
                          {currentContract?.customer?.company?.companyLegalForm}
                        </p>
                        <p className="my-auto lead fs-6 text-dark fst-italic">
                          {currentContract?.customer?.address?.street}{" "}
                          {currentContract?.customer?.address?.houseNumber}
                        </p>
                        <p className="my-auto lead fs-6 text-dark fst-italic">
                          {currentContract?.customer?.address?.postcode}{" "}
                          {currentContract?.customer?.address?.location}
                        </p>
                        <p className="my-auto lead fs-6 text-dark fst-italic">
                          Mail: {currentContract?.customer?.email}
                        </p>
                      </div>
                      <div className="col-12 col-md-6">
                        <p className="lead text-dark fs-6 text-with-border payment-information  w-75 text-uppercase fw-bold">
                          ZahlungsWEISE
                        </p>

                        
                        <p className="my-auto lead fs-6 fw-bold text-dark mt-4 fst-italic">
                          {currentContract?.customer?.company?.name}{" "}
                          {currentContract?.customer?.company?.companyLegalForm}
                        </p>


                        <p className="my-auto lead fs-6  text-dark fst-italic">
                          {currentContract?.paymentDetails?.bankName}
                        </p>

                        <p className="my-auto lead fs-6 text-dark fst-italic">
                          {currentContract?.paymentDetails?.ibanNumber?.slice(
                            0,
                            18
                          )}
                          *** ****
                        </p>
                        <p className="my-auto lead fs-6 text-dark fst-italic">
                          {currentContract?.paymentDetails?.swiftCode}
                        </p>
                        <p className="my-auto lead fs-6 text-dark fst-italic">
                          {currentContract?.customer?.address?.location}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-center border-0 bg-light">
                    {/* <PDFDownloadLink
                      className="btn btn-dark btn-sm w-75 mx-1 text-info"
                      document={
                        <MyDoc
                          machines={currentContract?.machines}
                          total={currentContract?.total}
                          customer={{
                            fullName: currentContract?.customer?.fullName,
                            email: currentContract?.customer?.email,
                            companyName:
                              currentContract?.customer?.company?.name,
                            companyLegalForm:
                              currentContract?.customer?.company?.legalForm,
                            houseNumber:
                              currentContract?.customer?.address?.houseNumber,
                            postcode:
                              currentContract?.customer?.address?.postcode,
                            street: currentContract?.customer?.address?.street,
                            location:
                              currentContract?.customer?.address?.location,
                            bankName: currentContract?.paymentDetails?.bankName,
                            iban: currentContract?.paymentDetails?.ibanNumber,
                            swiftCode:
                              currentContract?.paymentDetails?.swiftCode,
                          }}
                        />
                      }
                      fileName="doc.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          'Loading document...'
                        ) : (
                          <>
                            <i className="bi bi-download m-2"></i>
                            Angebot herunterladen
                          </>
                        )
                      }
                    </PDFDownloadLink> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ContractDetails;
