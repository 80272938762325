import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../utils/axios";
import { showAlert, hideAlert } from "./alertSlice";
import {
  addTokenToLocalStorage,
  clearLocalStorage,
} from "../utils/localStorage";

const initialState = {
  isLoading: false,
  token: null,
  isAuthenticated: false,
  isAllow: false,
  error: "",
};

export const loginUser = createAsyncThunk(
  "user/login",
  async (user, thunkAPI) => {
    try {
      let response = await axiosInstance.post("/api/auth/login", { ...user });
      const { result } = await response.data;
      if (result) {
        addTokenToLocalStorage(result);
        console.log("👉 token added to local storage");
      }
      // await thunkAPI.dispatch(getProfile());
      thunkAPI.dispatch(
        showAlert({ alertContent: "Willkommen", alertType: "success" })
      );
      setTimeout(() => {
        thunkAPI.dispatch(hideAlert());
      }, 3000);
      return { result };
    } catch (error) {
      const { message } = error.response.data;
      // alert("Bitte überprüfen Sie Ihre Angaben")
      // thunkAPI.dispatch(showAlert({ alertContent: "message", alertType: "danger" }))
      // setTimeout(() => {
      //     thunkAPI.dispatch(hideAlert())
      // }, 3000);
      throw new Error(message);
      console.log(`💥 ${message}`);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logoutUser: (state, action) => {
      state.token = null;
      state.isAuthenticated = false;
      clearLocalStorage();
    },
  },

  extraReducers: (builder) => {
    // Login Action
    builder.addCase(loginUser.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      const { result } = action.payload;

      if (result) {
        state.token = result;
        state.isAuthenticated = true;
      }
      state.isLoading = false;
    });

    builder.addCase(loginUser.rejected, (state, action) => {
      state.token = null;
      state.isLoading = false;
      state.isAuthenticated = false;
      // state.error = action.payload.error;
      const { error } = action;
      if (error) {
        state.error = error.message;
        throw new Error(error.message);
      }
    });
  },
});

export const { logoutUser, updateValues } = userSlice.actions;
export default userSlice.reducer;
