import logo from '../assets/images/login-logo.svg'
import distlogo from '../assets/images/dist-logo.svg'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { logoutUser } from '../features/authSlice'
import {
  addUserToLocalStorage,
  getUserFromLocalStorage,
} from '../utils/localStorage'
import { useRef, useState } from 'react'

import axiosInstance from '../utils/axios'
const distributorLinks = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="34"
        height="34"
        viewBox="0 0 34 34"
      >
        <g
          id="Group_43"
          data-name="Group 43"
          transform="translate(0.347 0.347)"
        >
          <g id="Group_42" data-name="Group 42" clipPath="url(#clipPath)">
            <path
              id="Path_23"
              data-name="Path 23"
              d="M279.3,0h11.189a2.5,2.5,0,0,1,1.454.9,9.011,9.011,0,0,1,.563,1.183V17.369a.229.229,0,0,0-.034.054,2.461,2.461,0,0,1-2.678,2.011q-4.943,0-9.886,0a3.456,3.456,0,0,1-.837-.087,2.42,2.42,0,0,1-1.834-2.485q-.005-4.569,0-9.138c0-1.724,0-3.447,0-5.171A2.44,2.44,0,0,1,278.807.161c.16-.062.326-.107.49-.161"
              transform="translate(-259.195)"
            />
            <path
              id="Path_24"
              data-name="Path 24"
              d="M0,230.657V215.305a.3.3,0,0,0,.031-.057,2.466,2.466,0,0,1,2.682-2.009q4.975,0,9.951,0a2.449,2.449,0,0,1,2.606,2.608c0,1.29,0,2.58,0,3.87q0,5.187,0,10.374a2.431,2.431,0,0,1-1.4,2.343,5.558,5.558,0,0,1-.667.239H2.082A2.543,2.543,0,0,1,.3,231.4a7.546,7.546,0,0,1-.3-.741"
              transform="translate(0 -199.367)"
            />
            <path
              id="Path_25"
              data-name="Path 25"
              d="M13.206,0a7.137,7.137,0,0,1,1.2.6,2.247,2.247,0,0,1,.86,1.883c.006,2.048.015,4.1,0,6.145A2.407,2.407,0,0,1,12.794,11.1q-5.154.024-10.307,0A2.414,2.414,0,0,1,.059,9.192,1.255,1.255,0,0,0,0,9.042V2.017c.04-.109.082-.218.119-.328A2.355,2.355,0,0,1,1.389.243c.2-.1.418-.163.627-.243Z"
            />
            <path
              id="Path_26"
              data-name="Path 26"
              d="M279.168,352.319a6.213,6.213,0,0,1-.7-.257,2.323,2.323,0,0,1-1.362-2.163c-.022-2.092-.029-4.184,0-6.276a2.4,2.4,0,0,1,2.5-2.409c2.493-.011,4.986,0,7.479,0,.889,0,1.778,0,2.667,0a2.437,2.437,0,0,1,2.569,1.98.4.4,0,0,0,.046.085v6.961a8.154,8.154,0,0,1-.52,1.129,2.434,2.434,0,0,1-1.562.953Z"
              transform="translate(-259.067 -319.012)"
            />
          </g>
        </g>
      </svg>
    ),
    label: 'Dashboard',
    link: '/dashboard/distributor',
  },
  {
    icon: (
      <svg
        id="Group_51"
        data-name="Group 51"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="33.821"
        height="29.462"
        viewBox="0 0 33.821 29.462"
      >
        <g id="Group_50" data-name="Group 50" clipPath="url(#clipPath)">
          <path
            id="Path_31"
            data-name="Path 31"
            d="M0,159.89c.05-.2.1-.4.152-.6A4.473,4.473,0,0,1,4.406,156a14.416,14.416,0,0,1,2.5.1,4.326,4.326,0,0,1,3.186,2.47c.153-.145.293-.279.435-.411A7.759,7.759,0,0,1,15.9,156a23.461,23.461,0,0,1,3.42.151,7.331,7.331,0,0,1,4.161,2.19,2.2,2.2,0,0,1,.189.256c.239-.352.419-.664.645-.939A4.31,4.31,0,0,1,27.732,156a13,13,0,0,1,2.562.118,4.34,4.34,0,0,1,3.468,3.623.759.759,0,0,0,.059.147v8.059c-.014.017-.034.031-.04.05a2.021,2.021,0,0,1-2.2,1.6H25.842c-.027.106-.051.19-.07.276a2.283,2.283,0,0,1-2.241,1.835q-6.62.011-13.239,0a2.254,2.254,0,0,1-1.508-.575,2.416,2.416,0,0,1-.794-1.537H7.62c-1.882,0-3.764,0-5.646,0a1.945,1.945,0,0,1-1.893-1.374c-.023-.071-.054-.14-.081-.21V159.89m16.905,9.843h6.436c.4,0,.5-.094.5-.47,0-1.815.018-3.631-.006-5.446a5.92,5.92,0,0,0-4.793-5.693,17.932,17.932,0,0,0-3.089-.141,6,6,0,0,0-5.982,5.989c-.009,1.749,0,3.5,0,5.248,0,.428.088.513.527.513h6.4M8,167.6c0-1.282-.04-2.526.015-3.767a20.9,20.9,0,0,1,.332-2.209A8.038,8.038,0,0,0,8.51,160.4a2.388,2.388,0,0,0-2-2.361,12.49,12.49,0,0,0-1.875-.065,2.491,2.491,0,0,0-2.65,2.642q0,3.318,0,6.635v.35Zm23.823,0a1.5,1.5,0,0,0,.018-.153c0-2.354.011-4.709-.005-7.063a2.392,2.392,0,0,0-1.991-2.342c-.613-.1-1.25-.037-1.874-.073a2.586,2.586,0,0,0-2.54,3.532,7.237,7.237,0,0,1,.326,1.575c.064,1.262.056,2.528.076,3.792,0,.239,0,.478,0,.732Z"
            transform="translate(0 -142.253)"
          />
          <path
            id="Path_32"
            data-name="Path 32"
            d="M132.508,0a5.747,5.747,0,1,1-5.754,5.722A5.755,5.755,0,0,1,132.508,0m-3.772,5.738A3.765,3.765,0,1,0,132.5,1.982a3.778,3.778,0,0,0-3.764,3.755"
            transform="translate(-115.59 0)"
          />
          <path
            id="Path_33"
            data-name="Path 33"
            d="M26.026,51.641A3.632,3.632,0,1,1,22.4,48.008a3.643,3.643,0,0,1,3.631,3.633m-1.982-.01a1.65,1.65,0,1,0-1.65,1.659,1.655,1.655,0,0,0,1.65-1.659"
            transform="translate(-17.11 -43.78)"
          />
          <path
            id="Path_34"
            data-name="Path 34"
            d="M286.4,48.009a3.632,3.632,0,1,1-3.642,3.622,3.641,3.641,0,0,1,3.642-3.622m1.64,3.64a1.65,1.65,0,1,0-1.667,1.642,1.654,1.654,0,0,0,1.667-1.642"
            transform="translate(-257.857 -43.78)"
          />
        </g>
      </svg>
    ),
    label: 'Kunden',
    link: '/dashboard/customers',
  },

  {
    icon: (
      <svg
        id="Group_49"
        data-name="Group 49"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="33.822"
        height="29.584"
        viewBox="0 0 33.822 29.584"
      >
        <g id="Group_48" data-name="Group 48" clipPath="url(#clipPath)">
          <path
            id="Path_27"
            data-name="Path 27"
            d="M33.822,97.994a2.128,2.128,0,0,1-1.177,1.539,3.238,3.238,0,0,1-1.18.235q-9.71.021-19.421.01H2.366A2.152,2.152,0,0,1,0,97.4Q0,88.15,0,78.9a2.15,2.15,0,0,1,2.368-2.377H13.5a2.44,2.44,0,0,1,.428.019,1.053,1.053,0,0,1-.069,2.084,3.3,3.3,0,0,1-.4.01H2.141v4.228H13.48a2.7,2.7,0,0,1,.428.016,1.053,1.053,0,0,1-.065,2.088,3.628,3.628,0,0,1-.4.009H2.136V97.646H31.708V97.27q0-3.468,0-6.936a1.056,1.056,0,0,1,2.044-.438.872.872,0,0,0,.068.1Z"
            transform="translate(0 -70.194)"
            fill="#30426a"
          />
          <path
            id="Path_28"
            data-name="Path 28"
            d="M221.186,9.633q-.092.717-.184,1.434a11.336,11.336,0,0,1-4.1,7.408,24.51,24.51,0,0,1-3.549,2.459,1.1,1.1,0,0,1-1.188.025,20.1,20.1,0,0,1-4.9-3.763,11.094,11.094,0,0,1-2.781-5.98,53.3,53.3,0,0,1-.194-6.99,1.108,1.108,0,0,1,.8-1.058q2.867-1.219,5.729-2.451c.465-.2.932-.393,1.393-.6a1.2,1.2,0,0,1,1.019-.01q3.6,1.551,7.213,3.089a1.294,1.294,0,0,1,.748.822Zm-2.114-2.061c0-1,.008-1.714-.009-2.429a.415.415,0,0,0-.2-.308Q215.9,3.547,212.929,2.29a.568.568,0,0,0-.4,0q-2.975,1.258-5.938,2.544a.378.378,0,0,0-.193.278c.033,1.836.012,3.676.145,5.505a8.413,8.413,0,0,0,1.83,4.626,15.119,15.119,0,0,0,4.216,3.5.392.392,0,0,0,.344-.036c.908-.637,1.848-1.239,2.7-1.947a8.987,8.987,0,0,0,3.157-5.235,18.4,18.4,0,0,0,.283-3.955"
            transform="translate(-187.365 0.001)"
            fill="#30426a"
          />
          <path
            id="Path_29"
            data-name="Path 29"
            d="M54.3,229.818c.66,0,1.32,0,1.98,0a1.063,1.063,0,1,1,.018,2.112q-2,.005-3.993,0a1.063,1.063,0,1,1,.015-2.112c.66,0,1.32,0,1.98,0"
            transform="translate(-46.899 -210.804)"
            fill="#30426a"
          />
          <path
            id="Path_30"
            data-name="Path 30"
            d="M258.794,81.093l2.124-2.654c.4-.5.79-1,1.2-1.491a1.054,1.054,0,0,1,1.85.438.982.982,0,0,1-.2.87c-1.4,1.764-2.807,3.529-4.228,5.28a1.028,1.028,0,0,1-1.714-.081c-.711-1.032-1.4-2.077-2.087-3.127a1.053,1.053,0,1,1,1.751-1.168c.372.531.724,1.077,1.085,1.615.066.1.134.194.222.32"
            transform="translate(-234.4 -70.217)"
            fill="#30426a"
          />
        </g>
      </svg>
    ),
    label: 'Zahlungen',
    link: '/dashboard/payments',
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="38"
        height="38"
        viewBox="0 0 38 38"
      >
        <g
          id="Group_53"
          data-name="Group 53"
          transform="translate(0.122 0.122)"
        >
          <g id="Group_52" data-name="Group 52" clipPath="url(#clipPath)">
            <path
              id="Path_35"
              data-name="Path 35"
              d="M216.4,19.468A9.341,9.341,0,0,1,216,20.754a4.533,4.533,0,0,1-4.36,2.775c-4.055.012-8.11,0-12.165.014a1.267,1.267,0,0,0-.7.234c-1.348.987-2.681,1.994-4.014,3a1.163,1.163,0,0,1-1.2.221,1.037,1.037,0,0,1-.677-.889,2.375,2.375,0,0,1-.012-.294q0-10.543,0-21.086A4.622,4.622,0,0,1,196.055.24c.287-.1.585-.161.878-.24h15.412a.784.784,0,0,0,.161.068,4.448,4.448,0,0,1,3.276,2.312,11.2,11.2,0,0,1,.618,1.75Zm-21.32,4.284c.152-.1.251-.169.345-.24.786-.589,1.58-1.169,2.355-1.773a1.942,1.942,0,0,1,1.258-.423q6.211.012,12.422,0a3.592,3.592,0,0,0,.84-.072,2.433,2.433,0,0,0,1.887-2.562c0-1.164,0-2.328,0-3.8,0-3.111,0-6.53,0-9.949a2.481,2.481,0,0,0-2.722-2.721c-4.546,0-9.093.025-13.639-.014a2.6,2.6,0,0,0-2.761,2.742c.036,6.119.015,12.238.015,18.357v.454"
              transform="translate(-178.645)"
            />
            <path
              id="Path_36"
              data-name="Path 36"
              d="M0,257.192c.111-.537.179-1.087.34-1.608a6.872,6.872,0,0,1,2.855-3.767c.07-.046.134-.1.231-.173a5.818,5.818,0,0,1-2.215-5.068,5.66,5.66,0,0,1,2.013-3.967,5.874,5.874,0,0,1,8.011.367,5.9,5.9,0,0,1-.578,8.663c.355.281.727.542,1.06.845a6.7,6.7,0,0,1,2.3,4.7c.05.857.022,1.719.057,2.578a1.286,1.286,0,0,1-.87,1.413H.811L0,260.363v-3.171m11.893,1.758a18.048,18.048,0,0,0-.149-2.164A4.758,4.758,0,0,0,5.986,253c-2.627.6-4.152,2.947-3.721,5.946Zm-4.81-8.286a3.63,3.63,0,1,0-.161-7.258,3.63,3.63,0,0,0,.161,7.258"
              transform="translate(0 -223.418)"
            />
            <path
              id="Path_37"
              data-name="Path 37"
              d="M300.163,80.022c0-.135,0-.27,0-.405a1.109,1.109,0,0,1,1.165-1.269,1.858,1.858,0,0,0,0-3.713,1.853,1.853,0,0,0-1.858,1.477c-.028.144-.042.292-.058.438a1.086,1.086,0,0,1-1.161,1.03,1.1,1.1,0,0,1-1.043-1.122,4.064,4.064,0,0,1,8.125-.158,4.051,4.051,0,0,1-2.572,3.957.5.5,0,0,0-.407.506,1.06,1.06,0,0,1-1.061.977,1.081,1.081,0,0,1-1.089-.909c-.042-.265-.036-.538-.051-.807h.014"
              transform="translate(-275.286 -67.087)"
            />
            <path
              id="Path_38"
              data-name="Path 38"
              d="M339.657,218.1a1.1,1.1,0,0,1-1.109,1.092,1.113,1.113,0,0,1-1.087-1.121,1.1,1.1,0,0,1,1.116-1.086,1.089,1.089,0,0,1,1.081,1.114"
              transform="translate(-312.575 -200.981)"
            />
          </g>
        </g>
      </svg>
    ),
    label: 'Anträge',
    link: '/dashboard/my-contracts',
  },

  {
    icon: (
      <svg
        id="Group_109"
        data-name="Group 109"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="33.821"
        height="33.808"
        viewBox="0 0 33.821 33.808"
      >
        <g id="Group_108" data-name="Group 108" clipPath="url(#clipPath)">
          <path
            id="Path_97"
            data-name="Path 97"
            d="M16.958,0A16.9,16.9,0,1,1,0,16.945,16.944,16.944,0,0,1,16.958,0m-.01,2.6A14.3,14.3,0,1,0,31.219,17.015,14.313,14.313,0,0,0,16.948,2.6"
            transform="translate(0 0)"
          />
          <path
            id="Path_98"
            data-name="Path 98"
            d="M110.763,78.324a4.887,4.887,0,0,1,4.467,2.93,4.1,4.1,0,0,1-.7,4.552,20.253,20.253,0,0,1-1.72,1.724,3.036,3.036,0,0,0-1.151,2.4,1.231,1.231,0,0,1-1.326,1.282,1.309,1.309,0,0,1-1.256-1.368,5.1,5.1,0,0,1,1.746-4c.445-.436.9-.858,1.35-1.293a1.917,1.917,0,0,0,.734-2.125,2.272,2.272,0,0,0-1.991-1.459,2.478,2.478,0,0,0-3.043,1.89,1.3,1.3,0,1,1-2.5-.743c.6-2.329,2.547-3.792,5.386-3.788"
            transform="translate(-93.907 -69.832)"
          />
          <path
            id="Path_99"
            data-name="Path 99"
            d="M141.274,212.079A1.276,1.276,0,0,1,140,210.788a1.254,1.254,0,0,1,1.288-1.274,1.268,1.268,0,0,1,1.3,1.3,1.282,1.282,0,0,1-1.312,1.26"
            transform="translate(-124.818 -186.8)"
          />
        </g>
      </svg>
    ),
    label: 'Hilfe & Service',
    link: '/dashboard/hilfe-service',
  },
]

const adminLinks = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="34"
        height="34"
        viewBox="0 0 34 34"
      >
        <g
          id="Group_43"
          data-name="Group 43"
          transform="translate(0.347 0.347)"
        >
          <g id="Group_42" data-name="Group 42" clipPath="url(#clipPath)">
            <path
              id="Path_23"
              data-name="Path 23"
              d="M279.3,0h11.189a2.5,2.5,0,0,1,1.454.9,9.011,9.011,0,0,1,.563,1.183V17.369a.229.229,0,0,0-.034.054,2.461,2.461,0,0,1-2.678,2.011q-4.943,0-9.886,0a3.456,3.456,0,0,1-.837-.087,2.42,2.42,0,0,1-1.834-2.485q-.005-4.569,0-9.138c0-1.724,0-3.447,0-5.171A2.44,2.44,0,0,1,278.807.161c.16-.062.326-.107.49-.161"
              transform="translate(-259.195)"
            />
            <path
              id="Path_24"
              data-name="Path 24"
              d="M0,230.657V215.305a.3.3,0,0,0,.031-.057,2.466,2.466,0,0,1,2.682-2.009q4.975,0,9.951,0a2.449,2.449,0,0,1,2.606,2.608c0,1.29,0,2.58,0,3.87q0,5.187,0,10.374a2.431,2.431,0,0,1-1.4,2.343,5.558,5.558,0,0,1-.667.239H2.082A2.543,2.543,0,0,1,.3,231.4a7.546,7.546,0,0,1-.3-.741"
              transform="translate(0 -199.367)"
            />
            <path
              id="Path_25"
              data-name="Path 25"
              d="M13.206,0a7.137,7.137,0,0,1,1.2.6,2.247,2.247,0,0,1,.86,1.883c.006,2.048.015,4.1,0,6.145A2.407,2.407,0,0,1,12.794,11.1q-5.154.024-10.307,0A2.414,2.414,0,0,1,.059,9.192,1.255,1.255,0,0,0,0,9.042V2.017c.04-.109.082-.218.119-.328A2.355,2.355,0,0,1,1.389.243c.2-.1.418-.163.627-.243Z"
            />
            <path
              id="Path_26"
              data-name="Path 26"
              d="M279.168,352.319a6.213,6.213,0,0,1-.7-.257,2.323,2.323,0,0,1-1.362-2.163c-.022-2.092-.029-4.184,0-6.276a2.4,2.4,0,0,1,2.5-2.409c2.493-.011,4.986,0,7.479,0,.889,0,1.778,0,2.667,0a2.437,2.437,0,0,1,2.569,1.98.4.4,0,0,0,.046.085v6.961a8.154,8.154,0,0,1-.52,1.129,2.434,2.434,0,0,1-1.562.953Z"
              transform="translate(-259.067 -319.012)"
            />
          </g>
        </g>
      </svg>
    ),
    label: 'Dashboard',
    link: '/dashboard/admin',
  },
  
  {
    icon: (
      <svg
        id="Group_51"
        data-name="Group 51"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="33.821"
        height="29.462"
        viewBox="0 0 33.821 29.462"
      >
        <g id="Group_50" data-name="Group 50" clipPath="url(#clipPath)">
          <path
            id="Path_31"
            data-name="Path 31"
            d="M0,159.89c.05-.2.1-.4.152-.6A4.473,4.473,0,0,1,4.406,156a14.416,14.416,0,0,1,2.5.1,4.326,4.326,0,0,1,3.186,2.47c.153-.145.293-.279.435-.411A7.759,7.759,0,0,1,15.9,156a23.461,23.461,0,0,1,3.42.151,7.331,7.331,0,0,1,4.161,2.19,2.2,2.2,0,0,1,.189.256c.239-.352.419-.664.645-.939A4.31,4.31,0,0,1,27.732,156a13,13,0,0,1,2.562.118,4.34,4.34,0,0,1,3.468,3.623.759.759,0,0,0,.059.147v8.059c-.014.017-.034.031-.04.05a2.021,2.021,0,0,1-2.2,1.6H25.842c-.027.106-.051.19-.07.276a2.283,2.283,0,0,1-2.241,1.835q-6.62.011-13.239,0a2.254,2.254,0,0,1-1.508-.575,2.416,2.416,0,0,1-.794-1.537H7.62c-1.882,0-3.764,0-5.646,0a1.945,1.945,0,0,1-1.893-1.374c-.023-.071-.054-.14-.081-.21V159.89m16.905,9.843h6.436c.4,0,.5-.094.5-.47,0-1.815.018-3.631-.006-5.446a5.92,5.92,0,0,0-4.793-5.693,17.932,17.932,0,0,0-3.089-.141,6,6,0,0,0-5.982,5.989c-.009,1.749,0,3.5,0,5.248,0,.428.088.513.527.513h6.4M8,167.6c0-1.282-.04-2.526.015-3.767a20.9,20.9,0,0,1,.332-2.209A8.038,8.038,0,0,0,8.51,160.4a2.388,2.388,0,0,0-2-2.361,12.49,12.49,0,0,0-1.875-.065,2.491,2.491,0,0,0-2.65,2.642q0,3.318,0,6.635v.35Zm23.823,0a1.5,1.5,0,0,0,.018-.153c0-2.354.011-4.709-.005-7.063a2.392,2.392,0,0,0-1.991-2.342c-.613-.1-1.25-.037-1.874-.073a2.586,2.586,0,0,0-2.54,3.532,7.237,7.237,0,0,1,.326,1.575c.064,1.262.056,2.528.076,3.792,0,.239,0,.478,0,.732Z"
            transform="translate(0 -142.253)"
          />
          <path
            id="Path_32"
            data-name="Path 32"
            d="M132.508,0a5.747,5.747,0,1,1-5.754,5.722A5.755,5.755,0,0,1,132.508,0m-3.772,5.738A3.765,3.765,0,1,0,132.5,1.982a3.778,3.778,0,0,0-3.764,3.755"
            transform="translate(-115.59 0)"
          />
          <path
            id="Path_33"
            data-name="Path 33"
            d="M26.026,51.641A3.632,3.632,0,1,1,22.4,48.008a3.643,3.643,0,0,1,3.631,3.633m-1.982-.01a1.65,1.65,0,1,0-1.65,1.659,1.655,1.655,0,0,0,1.65-1.659"
            transform="translate(-17.11 -43.78)"
          />
          <path
            id="Path_34"
            data-name="Path 34"
            d="M286.4,48.009a3.632,3.632,0,1,1-3.642,3.622,3.641,3.641,0,0,1,3.642-3.622m1.64,3.64a1.65,1.65,0,1,0-1.667,1.642,1.654,1.654,0,0,0,1.667-1.642"
            transform="translate(-257.857 -43.78)"
          />
        </g>
      </svg>
    ),
    label: 'Vertriebspartner',
    link: '/dashboard/admins/distributors',
  },
  {
    icon: (
      <svg
        id="Group_51"
        data-name="Group 51"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="33.821"
        height="29.462"
        viewBox="0 0 33.821 29.462"
      >
        <g id="Group_50" data-name="Group 50" clipPath="url(#clipPath)">
          <path
            id="Path_31"
            data-name="Path 31"
            d="M0,159.89c.05-.2.1-.4.152-.6A4.473,4.473,0,0,1,4.406,156a14.416,14.416,0,0,1,2.5.1,4.326,4.326,0,0,1,3.186,2.47c.153-.145.293-.279.435-.411A7.759,7.759,0,0,1,15.9,156a23.461,23.461,0,0,1,3.42.151,7.331,7.331,0,0,1,4.161,2.19,2.2,2.2,0,0,1,.189.256c.239-.352.419-.664.645-.939A4.31,4.31,0,0,1,27.732,156a13,13,0,0,1,2.562.118,4.34,4.34,0,0,1,3.468,3.623.759.759,0,0,0,.059.147v8.059c-.014.017-.034.031-.04.05a2.021,2.021,0,0,1-2.2,1.6H25.842c-.027.106-.051.19-.07.276a2.283,2.283,0,0,1-2.241,1.835q-6.62.011-13.239,0a2.254,2.254,0,0,1-1.508-.575,2.416,2.416,0,0,1-.794-1.537H7.62c-1.882,0-3.764,0-5.646,0a1.945,1.945,0,0,1-1.893-1.374c-.023-.071-.054-.14-.081-.21V159.89m16.905,9.843h6.436c.4,0,.5-.094.5-.47,0-1.815.018-3.631-.006-5.446a5.92,5.92,0,0,0-4.793-5.693,17.932,17.932,0,0,0-3.089-.141,6,6,0,0,0-5.982,5.989c-.009,1.749,0,3.5,0,5.248,0,.428.088.513.527.513h6.4M8,167.6c0-1.282-.04-2.526.015-3.767a20.9,20.9,0,0,1,.332-2.209A8.038,8.038,0,0,0,8.51,160.4a2.388,2.388,0,0,0-2-2.361,12.49,12.49,0,0,0-1.875-.065,2.491,2.491,0,0,0-2.65,2.642q0,3.318,0,6.635v.35Zm23.823,0a1.5,1.5,0,0,0,.018-.153c0-2.354.011-4.709-.005-7.063a2.392,2.392,0,0,0-1.991-2.342c-.613-.1-1.25-.037-1.874-.073a2.586,2.586,0,0,0-2.54,3.532,7.237,7.237,0,0,1,.326,1.575c.064,1.262.056,2.528.076,3.792,0,.239,0,.478,0,.732Z"
            transform="translate(0 -142.253)"
          />
          <path
            id="Path_32"
            data-name="Path 32"
            d="M132.508,0a5.747,5.747,0,1,1-5.754,5.722A5.755,5.755,0,0,1,132.508,0m-3.772,5.738A3.765,3.765,0,1,0,132.5,1.982a3.778,3.778,0,0,0-3.764,3.755"
            transform="translate(-115.59 0)"
          />
          <path
            id="Path_33"
            data-name="Path 33"
            d="M26.026,51.641A3.632,3.632,0,1,1,22.4,48.008a3.643,3.643,0,0,1,3.631,3.633m-1.982-.01a1.65,1.65,0,1,0-1.65,1.659,1.655,1.655,0,0,0,1.65-1.659"
            transform="translate(-17.11 -43.78)"
          />
          <path
            id="Path_34"
            data-name="Path 34"
            d="M286.4,48.009a3.632,3.632,0,1,1-3.642,3.622,3.641,3.641,0,0,1,3.642-3.622m1.64,3.64a1.65,1.65,0,1,0-1.667,1.642,1.654,1.654,0,0,0,1.667-1.642"
            transform="translate(-257.857 -43.78)"
          />
        </g>
      </svg>
    ),
    label: 'Kunden',
    link: '/dashboard/admins/customers',
  },
  {
    icon: (
      <svg
        id="Group_49"
        data-name="Group 49"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="33.822"
        height="29.584"
        viewBox="0 0 33.822 29.584"
      >
        <g id="Group_48" data-name="Group 48" clipPath="url(#clipPath)">
          <path
            id="Path_27"
            data-name="Path 27"
            d="M33.822,97.994a2.128,2.128,0,0,1-1.177,1.539,3.238,3.238,0,0,1-1.18.235q-9.71.021-19.421.01H2.366A2.152,2.152,0,0,1,0,97.4Q0,88.15,0,78.9a2.15,2.15,0,0,1,2.368-2.377H13.5a2.44,2.44,0,0,1,.428.019,1.053,1.053,0,0,1-.069,2.084,3.3,3.3,0,0,1-.4.01H2.141v4.228H13.48a2.7,2.7,0,0,1,.428.016,1.053,1.053,0,0,1-.065,2.088,3.628,3.628,0,0,1-.4.009H2.136V97.646H31.708V97.27q0-3.468,0-6.936a1.056,1.056,0,0,1,2.044-.438.872.872,0,0,0,.068.1Z"
            transform="translate(0 -70.194)"
            fill="#30426a"
          />
          <path
            id="Path_28"
            data-name="Path 28"
            d="M221.186,9.633q-.092.717-.184,1.434a11.336,11.336,0,0,1-4.1,7.408,24.51,24.51,0,0,1-3.549,2.459,1.1,1.1,0,0,1-1.188.025,20.1,20.1,0,0,1-4.9-3.763,11.094,11.094,0,0,1-2.781-5.98,53.3,53.3,0,0,1-.194-6.99,1.108,1.108,0,0,1,.8-1.058q2.867-1.219,5.729-2.451c.465-.2.932-.393,1.393-.6a1.2,1.2,0,0,1,1.019-.01q3.6,1.551,7.213,3.089a1.294,1.294,0,0,1,.748.822Zm-2.114-2.061c0-1,.008-1.714-.009-2.429a.415.415,0,0,0-.2-.308Q215.9,3.547,212.929,2.29a.568.568,0,0,0-.4,0q-2.975,1.258-5.938,2.544a.378.378,0,0,0-.193.278c.033,1.836.012,3.676.145,5.505a8.413,8.413,0,0,0,1.83,4.626,15.119,15.119,0,0,0,4.216,3.5.392.392,0,0,0,.344-.036c.908-.637,1.848-1.239,2.7-1.947a8.987,8.987,0,0,0,3.157-5.235,18.4,18.4,0,0,0,.283-3.955"
            transform="translate(-187.365 0.001)"
            fill="#30426a"
          />
          <path
            id="Path_29"
            data-name="Path 29"
            d="M54.3,229.818c.66,0,1.32,0,1.98,0a1.063,1.063,0,1,1,.018,2.112q-2,.005-3.993,0a1.063,1.063,0,1,1,.015-2.112c.66,0,1.32,0,1.98,0"
            transform="translate(-46.899 -210.804)"
            fill="#30426a"
          />
          <path
            id="Path_30"
            data-name="Path 30"
            d="M258.794,81.093l2.124-2.654c.4-.5.79-1,1.2-1.491a1.054,1.054,0,0,1,1.85.438.982.982,0,0,1-.2.87c-1.4,1.764-2.807,3.529-4.228,5.28a1.028,1.028,0,0,1-1.714-.081c-.711-1.032-1.4-2.077-2.087-3.127a1.053,1.053,0,1,1,1.751-1.168c.372.531.724,1.077,1.085,1.615.066.1.134.194.222.32"
            transform="translate(-234.4 -70.217)"
            fill="#30426a"
          />
        </g>
      </svg>
    ),
    label: 'Zahlungen',
    link: '/dashboard/allpayments',
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="38"
        height="38"
        viewBox="0 0 38 38"
      >
        <g
          id="Group_53"
          data-name="Group 53"
          transform="translate(0.122 0.122)"
        >
          <g id="Group_52" data-name="Group 52" clipPath="url(#clipPath)">
            <path
              id="Path_35"
              data-name="Path 35"
              d="M216.4,19.468A9.341,9.341,0,0,1,216,20.754a4.533,4.533,0,0,1-4.36,2.775c-4.055.012-8.11,0-12.165.014a1.267,1.267,0,0,0-.7.234c-1.348.987-2.681,1.994-4.014,3a1.163,1.163,0,0,1-1.2.221,1.037,1.037,0,0,1-.677-.889,2.375,2.375,0,0,1-.012-.294q0-10.543,0-21.086A4.622,4.622,0,0,1,196.055.24c.287-.1.585-.161.878-.24h15.412a.784.784,0,0,0,.161.068,4.448,4.448,0,0,1,3.276,2.312,11.2,11.2,0,0,1,.618,1.75Zm-21.32,4.284c.152-.1.251-.169.345-.24.786-.589,1.58-1.169,2.355-1.773a1.942,1.942,0,0,1,1.258-.423q6.211.012,12.422,0a3.592,3.592,0,0,0,.84-.072,2.433,2.433,0,0,0,1.887-2.562c0-1.164,0-2.328,0-3.8,0-3.111,0-6.53,0-9.949a2.481,2.481,0,0,0-2.722-2.721c-4.546,0-9.093.025-13.639-.014a2.6,2.6,0,0,0-2.761,2.742c.036,6.119.015,12.238.015,18.357v.454"
              transform="translate(-178.645)"
            />
            <path
              id="Path_36"
              data-name="Path 36"
              d="M0,257.192c.111-.537.179-1.087.34-1.608a6.872,6.872,0,0,1,2.855-3.767c.07-.046.134-.1.231-.173a5.818,5.818,0,0,1-2.215-5.068,5.66,5.66,0,0,1,2.013-3.967,5.874,5.874,0,0,1,8.011.367,5.9,5.9,0,0,1-.578,8.663c.355.281.727.542,1.06.845a6.7,6.7,0,0,1,2.3,4.7c.05.857.022,1.719.057,2.578a1.286,1.286,0,0,1-.87,1.413H.811L0,260.363v-3.171m11.893,1.758a18.048,18.048,0,0,0-.149-2.164A4.758,4.758,0,0,0,5.986,253c-2.627.6-4.152,2.947-3.721,5.946Zm-4.81-8.286a3.63,3.63,0,1,0-.161-7.258,3.63,3.63,0,0,0,.161,7.258"
              transform="translate(0 -223.418)"
            />
            <path
              id="Path_37"
              data-name="Path 37"
              d="M300.163,80.022c0-.135,0-.27,0-.405a1.109,1.109,0,0,1,1.165-1.269,1.858,1.858,0,0,0,0-3.713,1.853,1.853,0,0,0-1.858,1.477c-.028.144-.042.292-.058.438a1.086,1.086,0,0,1-1.161,1.03,1.1,1.1,0,0,1-1.043-1.122,4.064,4.064,0,0,1,8.125-.158,4.051,4.051,0,0,1-2.572,3.957.5.5,0,0,0-.407.506,1.06,1.06,0,0,1-1.061.977,1.081,1.081,0,0,1-1.089-.909c-.042-.265-.036-.538-.051-.807h.014"
              transform="translate(-275.286 -67.087)"
            />
            <path
              id="Path_38"
              data-name="Path 38"
              d="M339.657,218.1a1.1,1.1,0,0,1-1.109,1.092,1.113,1.113,0,0,1-1.087-1.121,1.1,1.1,0,0,1,1.116-1.086,1.089,1.089,0,0,1,1.081,1.114"
              transform="translate(-312.575 -200.981)"
            />
          </g>
        </g>
      </svg>
    ),
    label: 'Anträge',
    link: '/dashboard/contracts',
  },
  {
    icon: (
      <svg
        id="Group_109"
        data-name="Group 109"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="33.821"
        height="33.808"
        viewBox="0 0 33.821 33.808"
      >
        <g id="Group_108" data-name="Group 108" clipPath="url(#clipPath)">
          <path
            id="Path_97"
            data-name="Path 97"
            d="M16.958,0A16.9,16.9,0,1,1,0,16.945,16.944,16.944,0,0,1,16.958,0m-.01,2.6A14.3,14.3,0,1,0,31.219,17.015,14.313,14.313,0,0,0,16.948,2.6"
            transform="translate(0 0)"
          />
          <path
            id="Path_98"
            data-name="Path 98"
            d="M110.763,78.324a4.887,4.887,0,0,1,4.467,2.93,4.1,4.1,0,0,1-.7,4.552,20.253,20.253,0,0,1-1.72,1.724,3.036,3.036,0,0,0-1.151,2.4,1.231,1.231,0,0,1-1.326,1.282,1.309,1.309,0,0,1-1.256-1.368,5.1,5.1,0,0,1,1.746-4c.445-.436.9-.858,1.35-1.293a1.917,1.917,0,0,0,.734-2.125,2.272,2.272,0,0,0-1.991-1.459,2.478,2.478,0,0,0-3.043,1.89,1.3,1.3,0,1,1-2.5-.743c.6-2.329,2.547-3.792,5.386-3.788"
            transform="translate(-93.907 -69.832)"
          />
          <path
            id="Path_99"
            data-name="Path 99"
            d="M141.274,212.079A1.276,1.276,0,0,1,140,210.788a1.254,1.254,0,0,1,1.288-1.274,1.268,1.268,0,0,1,1.3,1.3,1.282,1.282,0,0,1-1.312,1.26"
            transform="translate(-124.818 -186.8)"
          />
        </g>
      </svg>
    ),
    label: 'Hilfe & Service',
    link: '/dashboard/hilfe-service',
  },
]
function SidebarList() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let user = getUserFromLocalStorage()

  let [avatar, setAvatar] = useState(user?.photo)

  const logout = async (e) => {
    await dispatch(logoutUser())
    navigate('/')
  }

  const fileInput = useRef(null)

  const adminSideLinks = (
    <>
      <ul className="list-group list-group-flush">
        {adminLinks.map((item, index) => {
          return (
            <NavLink key={index} to={item.link} className="nav-link">
              {item.icon}
              <span className="text">{item.label}</span>
            </NavLink>
          )
        })}
      </ul>
    </>
  )

  const distributorSideLinks = (
    <>
      <ul className="list-group list-group-flush">
        {distributorLinks.map((item, index) => {
          return (
            <NavLink key={index} to={item.link} className="nav-link">
              {item.icon}
              <span className="text">{item.label}</span>
            </NavLink>
          )
        })}
      </ul>
    </>
  )

  const handleClick = () => {
    fileInput.current.click()
  }

  const handleFileChange = async (event) => {
    const formData = new FormData()
    formData.append('avatar', event.target.files[0])
    let response = await axiosInstance.post(
      '/api/utils/avatarupload',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
    )
    const result = await response.data.result
    if (result) {
      addUserToLocalStorage(result)
      setAvatar(result.photo)
    }
  }

  return (
    <>
      <div className="side-bar">
        {user && user.role === 'admin' ? (
          <>
            <div className="side-logo p-4">
              <img src={distlogo} alt="logo" className="img-fluid" />
            </div>
          </>
        ) : (
          <>
            <div className="side-logo">
              <img src={logo} alt="logo" className="img-fluid" />
            </div>
            <div className="avatar">
              <div className="upload">
                <input
                  type="file"
                  className="d-none"
                  onChange={(e) => handleFileChange(e)}
                  ref={fileInput}
                />
                <div className="btn" onClick={() => handleClick()}>
                  <svg
                    fill="#000000"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    enableBackground="new 0 0 512 512"
                  >
                    <g>
                      <g>
                        <path d="m434.2,109.1h-89.7l-23.4-57.8c-3.1-7.7-10.6-12.8-18.9-12.8h-92.3c-8.3,0-15.8,5-18.9,12.8l-23.4,57.8h-89.8c-36.9,0-66.8,30-66.8,66.8v230.7c0,36.9 30,66.8 66.8,66.8h356.3c36.9,0 66.8-30 66.8-66.8v-230.7c0.1-36.8-29.9-66.8-66.7-66.8zm-210.6-29.8h64.8l12,29.7h-88.9l12.1-29.7zm236.6,327.3c0,14.3-11.7,26-26,26h-356.4c-14.3,0-26-11.7-26-26v-230.7c0-14.3 11.7-26 26-26h356.3c14.3,0 26,11.7 26,26v230.7z" />
                        <path d="m256,179.7c-61.5,0-111.6,50.1-111.6,111.6 0,61.6 50.1,111.6 111.6,111.6s111.6-50.1 111.6-111.6c0-61.6-50.1-111.6-111.6-111.6zm0,182.4c-39,0-70.8-31.8-70.8-70.8 0-39 31.8-70.8 70.8-70.8 39,0 70.8,31.8 70.8,70.8 0,39-31.8,70.8-70.8,70.8z" />
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
              <img src={avatar} alt="" className="img-fluid" />
            </div>
            <h3 className="fullName text-center">{user?.fullName}</h3>
            <span className="jobRole text-center mb-5">Vertriebspartner</span>
          </>
        )}
        {user && user.role === 'admin' ? adminSideLinks : distributorSideLinks}
        {user && user.role === 'admin' ? (
          <Link
            to="/dashboard/create-distributor"
            className="btn btn-dark btn-md d-block"
          >
            <i className="bi bi-plus me-2"></i>
            <span>Vertriebspartner anlegen</span>
          </Link>
        ) : (
          <Link
            role="button"
            onClick={(e) => {
              navigate('/calculator')
              window.location.reload()
            }}
            className="btn btn-dark btn-md d-block"
          >
            <i className="bi bi-plus me-2"></i>
            <span>Neuen Antrag erstellen</span>
          </Link>
        )}

        <button onClick={logout} className="btn btn-light logout-btn">
          <svg
            id="Group_55"
            data-name="Group 55"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="32"
            height="32"
            viewBox="0 0 32 32"
          >
            <g id="Group_54" data-name="Group 54" clipPath="url(#clipPath)">
              <path
                id="Path_39"
                data-name="Path 39"
                d="M17.563,0a1.669,1.669,0,0,1,1.2,1.191,1.56,1.56,0,0,1-1.4,1.929c-.124.009-.25,0-.375,0H3.141v25.75h.351q6.842,0,13.684,0a1.549,1.549,0,0,1,1.632,1.594,1.512,1.512,0,0,1-.964,1.41c-.093.042-.187.08-.281.12H1.25A1.822,1.822,0,0,1,0,30.75V1.25A1.81,1.81,0,0,1,1.25,0Z"
                fill="#30426a"
              />
              <path
                id="Path_40"
                data-name="Path 40"
                d="M143.384,87.892a2.9,2.9,0,0,1-.813,1.155q-3.737,3.709-7.461,7.431a1.628,1.628,0,0,1-1.363.555,1.484,1.484,0,0,1-1.292-.944,1.468,1.468,0,0,1,.249-1.607c.186-.223.4-.42.61-.626l4.469-4.457c.065-.065.128-.134.245-.257h-.411q-7.218,0-14.436,0a1.564,1.564,0,0,1-.222-3.12c.114-.008.229,0,.344,0h14.716c-.11-.12-.18-.2-.256-.277q-2.477-2.472-4.955-4.944a1.562,1.562,0,1,1,2.233-2.179q1.809,1.793,3.61,3.593c1.306,1.3,2.608,2.607,3.92,3.9a2.878,2.878,0,0,1,.813,1.155Z"
                transform="translate(-111.384 -71.58)"
                fill="#30426a"
              />
            </g>
          </svg>
          <span className="text">Abmelden</span>
        </button>
      </div>
    </>
  )
}

export default SidebarList
