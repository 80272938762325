import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getCategories,
  onCategoryChange,
  setMachineValues,
} from "../../features/contract/machineSlice";
import MachineForm from "../MachineForm";
import { removeLastMachine } from "../../features/contract/calculatorSlice";

function Form1() {
  const dispatch = useDispatch();
  const { machines,editing } = useSelector((store) => store.calculator);
  const updateMachineList = () => {
    let newMachines = [];
    if (machines.length > 1) {
      for (let index = 0; index < machines.length - 1; index++) {
        newMachines.push(machines[index]);
      }
      let lastMachine = newMachines[newMachines.length - 1];
      console.log({lastMachine});
      dispatch(setMachineValues({ machine: lastMachine }));
      dispatch(onCategoryChange({ categoryId: lastMachine.category }));
      dispatch(removeLastMachine({ machines: newMachines }));
    } else {
      dispatch(removeLastMachine({ machines: newMachines }));
    }
  };

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  return (
    <>
      <div className="card-header border-0 bg-light">
        <div className="d-flex align-items-center justify-content-between py-4">
          <p className="my-auto fw-bold text-dark">
            {machines.length === 0 ? 1 : machines.length}. Wähle eine Maschine
            aus.*{" "}
          </p>
        
          <div className="ms-auto">
          {
            machines.length > 1 && !editing && <button className="btn btn-sm btn-danger" onClick={updateMachineList}>
            Löschen
            </button>
          }
          </div>
        </div>
      </div>

      <div className="card-body bg-light">
        <MachineForm></MachineForm>
      </div>
    </>
  );
}

export default Form1;
