import ReactLoading from "react-loading";

function Loading() {
  return (
    <>
        <div className="loading">
            <ReactLoading type="spin" color="#30426a" />
        </div>
    
    </>
  )
}

export default Loading
