import biglogo from "../assets/images/login-logo.svg";
import { useState, useRef } from "react";

import { loginUser } from "../features/authSlice";
import { useDispatch } from "react-redux";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import {
  getTokenFromLocalStorage,
  getUserFromLocalStorage,
} from "../utils/localStorage";
import { Link, useNavigate } from "react-router-dom";
import { getProfile } from "../features/userSlice";

import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

import { classNames } from "primereact/utils";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Bitte geben Sie eine gültige E-Mail-Adresse ein")
    .required("E-Mail ist ein Pflichtfeld"),

  password: Yup.string()
    .min(4, "Die Mindestlänge des Passworts beträgt 4")
    .required("Passwort ist Pflichtfeld"),
});

function LoginPage() {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fieldType, setFieldType] = useState("password");
  const [buttonIcon, setButtonIcon] = useState(<BsFillEyeFill />);
  const showPassword = () => {
    if (fieldType === "password") {
      setFieldType("text");
      setButtonIcon(<BsFillEyeSlashFill />);
    } else {
      setFieldType("password");
      setButtonIcon(<BsFillEyeFill />);
    }
  };

  const formik = useFormik({
    initialValues: { email: "", password: "", role: null },
    validationSchema: validationSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      localStorage.clear();
      try {
        await dispatch(loginUser({ ...values }));
      } catch (error) {
        console.log(error.message);
        let msg = "";
        if (error.message === "This account is distractive") {
          msg = "Dieses Konto ist inaktiv";
        } else {
          msg = "Bitte überprüfen Sie Ihre Angaben";
        }
        toast.current.show({
          severity: "error",
          summary: "Formular eingereicht",
          detail: msg,
        });
      }

      const token = getTokenFromLocalStorage();
      if (token) {
        await dispatch(getProfile(token));
        const user = getUserFromLocalStorage();
        if (user) {
          const { role } = user;
          if (role === "admin") {
            navigate("/dashboard/admin");
          } else if (role === "distributor") {
            navigate("/dashboard/distributor");
          } else {
            navigate("/permission-error");
          }
        }
      }
    },
  });

  return (
    <>
      <Toast ref={toast} />
      <div className="login-page">
        <div className="container justify-content-center d-flex">
          <form
            noValidate
            className="login-form mt-5"
            onSubmit={formik.handleSubmit}
          >
            <div className="big-logo">
              <img src={biglogo} alt="logo" className="img-fluid" />
            </div>
            <h1 className="text-center d-none">Login</h1>
            <div className="d-flex flex-column gap-2 mb-3">
              <label htmlFor="email">Email</label>
              <InputText
                name="email"
                className={classNames("bg-light", {
                  "p-invalid": formik.errors.email,
                })}
                id="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                aria-describedby="email-help"
              />
              {formik.touched.email && formik.errors.email && (
                <small id="password-help" className="p-error">
                  {formik.errors.email}
                </small>
              )}
            </div>

            <div className="d-flex flex-column gap-2 mb-4">
              <label htmlFor="password">Passwort</label>
              <Password
                name="password"
                className={classNames("bg-light", {
                  "p-invalid":
                    formik.touched.password && formik.errors.password,
                })}
                id="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                toggleMask
                aria-describedby="password-help"
                promptLabel="Wählen Sie ein Passwort"
                weakLabel="Zu einfach"
                mediumLabel="Durchschnittliche Komplexität"
                strongLabel="Komplexes Passwort"
              />
              {formik.touched.password && formik.errors.password && (
                <small id="password-help" className="p-error">
                  {formik.errors.password}
                </small>
              )}
            </div>
            <div className="forgot-password-wrap">
              <Link to="/reset-password" replace>
                Passwort vergessen?
              </Link>
            </div>

            <div className="mt-4">
              <Button
                type="submit"
                label="Einloggen"
                className="btn btn-sm d-block btn-primary"
              />
            </div>
          </form>
          <div className="absolute-footer">
            <div className="container">
              <ul className="footer-menu">
                <li>
                  <a href="https://versitech.at/impressum" target="_blank">
                    IMPRESSUM
                  </a>
                </li>
                <li>
                  <a href="https://versitech.at/datenschutz" target="_blank">
                    DATENSCHUTZ
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
