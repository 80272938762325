import { useSelector, useDispatch } from "react-redux";
import { onValueChange } from "../../features/contract/customerSlice";
import { calculate } from "../../features/contract/machineSlice";
import { Tooltip } from "react-tooltip";
import React, { useState } from "react";

import {
  updatePaymentPeriod,
  updateItem,
  updateCountry,
  updateDescription,
  calculateTotal,
  calculateOneItem,
} from "../../features/contract/calculatorSlice";
function isNumber(value) {
  if (typeof value === "string") {
    return !isNaN(value);
  }
}
function Form2() {
  const {
    customerErrors,
    companiesTypes,
    paymentPeriods,
    companyName,
    companyLegalForm,
    street,
    houseNumber,
    postcode,
    location,
    email,
    phone,
    fullName,
    swiftCode,
    iban,
    ibanCode,
    bankName,
    phoneCode,
    notificationBy
  } = useSelector((store) => store.customer);

  const { description } = useSelector((store) => store.machine);

  const { initialCartItem, paymentPeriod, country, machines } = useSelector(
    (store) => store.calculator
  );
  const dispatch = useDispatch();

  // const [notificationBy, setNotificationBy] = useState("email");

  return (
    <>
      {/* FORM 2 */}

      <div className="card-header border-0 bg-light d-flex">
        <p className="my-auto fw-bold me-auto">2. Versicherungsnehmer</p>
        <p className="my-2 budge ms-3">* Pflichtfeld</p>
      </div>

      <div className="card-body bg-light">
        {/* Company Information's */}
        <p className="lead fs-5 mb-3">Versicherungsnehmer *</p>
        <div className="row row-cols-md-2 row-cols-lg-2">
          <div className="col-12 col-md-6 col-lg-6">
            <input
              type="text"
              name="companyName"
              id="companyNameField"
              className="form-control form-control-sm bg-light shadow-none shadow-none"
              placeholder="Kunde"
              value={companyName}
              onChange={(e) => {
                dispatch(
                  onValueChange({ name: e.target.name, value: e.target.value })
                );
              }}
              required
              minLength={3}
            />
            <p className="text-sm text-danger" style={{ fontSize: 12 }}>
              {
                customerErrors.find((error) => error.field === "companyName")
                  ?.text
              }
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-6">
            <select
              name="companyLegalForm"
              id="companyLegalFormField"
              className="form-select form-select-sm bg-light shadow-none"
              value={companyLegalForm}
              required
              onChange={(e) => {
                dispatch(
                  onValueChange({ name: e.target.name, value: e.target.value })
                );
              }}
            >
              <option value="*">Rechtsform des Unternehmens auswählen</option>
              {companiesTypes.map((ctype, index) => {
                return (
                  <option key={index} value={ctype.value}>
                    {ctype.label}
                  </option>
                );
              })}
            </select>
            <p className="text-sm text-danger" style={{ fontSize: 12 }}>
              {
                customerErrors.find(
                  (error) => error.field === "companyLegalForm"
                )?.text
              }
            </p>
          </div>
        </div>

        <hr className="border border-primary my-4" />
        <div className="row my-4">
          <div className="col-12 col-md-12 col-lg-12">
            <div className="d-flex align-items-center justify-content-between">
              <p className="lead  my-auto">Die Firma ist ansässig in? *</p>
              <div className="d-flex w-50">
                {[
                  { label: "Deutschland", value: "germany" },
                  { label: "Österreich", value: "austria" },
                ].map((option, index) => {
                  return (
                    <div key={index} className="input-group">
                      <input
                        type="radio"
                        className="btn-check btn-sm"
                        name="country"
                        id={`country-${index}`}
                        autoComplete="off"
                        value={country}
                        checked={option.value == country}
                        onChange={async (e) => {
                          dispatch(updateCountry({ country: option.value }));
                          dispatch(
                            onValueChange({
                              name: e.target.name,
                              value: option.value,
                            })
                          );

                          for (let machine of machines) {
                            dispatch(
                              calculateOneItem({
                                itemId: machine.id,
                                country: e.target.value,
                              })
                            );
                          }
                        }}
                      />
                      <label
                        className="btn btn-outline-dark  rounded-1 btn-sm w-75 mx-auto"
                        htmlFor={`country-${index}`}
                      >
                        {option.label}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>

            <p className="text-sm text-danger" style={{ fontSize: 12 }}>
              {customerErrors.find((error) => error.field === "country")?.text}
            </p>
          </div>
        </div>
        <hr className="border border-primary my-4" />
        {/* Customer Address Information's */}
        <p className="lead fs-5 mb-3">
          Anschrift *
        </p>
        <Tooltip className="toolTip" anchorId="addressToolTip" />
        <div className="row row-cols-md-4 row-cols-lg-4">
          <div className="col-12 col-md-3 col-lg-3">
            <input
              type="text"
              name="street"
              id="streetField"
              className="form-control form-control-sm bg-light shadow-none"
              placeholder="Straße"
              value={street}
              onChange={(e) => {
                dispatch(
                  onValueChange({ name: e.target.name, value: e.target.value })
                );
              }}
              required
              minLength={3}
            />
            <p className="text-sm text-danger" style={{ fontSize: 12 }}>
              {customerErrors.find((error) => error.field === "street")?.text}
            </p>
          </div>
          <div className="col-12 col-md-3 col-lg-3">
            <input
              type="text"
              name="houseNumber"
              pattern="[0-9]*"
              id="houseNumberField"
              className="form-control form-control-sm bg-light shadow-none"
              placeholder="Hausnummer"
              value={houseNumber}
              onChange={(e) => {
                dispatch(
                  onValueChange({ name: e.target.name, value: e.target.value })
                );
              }}
              required
              minLength={3}
            />
            <p className="text-sm text-danger" style={{ fontSize: 12 }}>
              {
                customerErrors.find((error) => error.field === "houseNumber")
                  ?.text
              }
            </p>
          </div>
          <div className="col-12 col-md-3 col-lg-3">
            <input
              type="number"
              name="postcode"
              id="postcodeField"
              className="form-control form-control-sm bg-light shadow-none"
              placeholder="PLZ"
              value={postcode}
              onChange={(e) => {
                dispatch(
                  onValueChange({ name: e.target.name, value: e.target.value })
                );
              }}
              required
              minLength={3}
            />
            <p className="text-sm text-danger" style={{ fontSize: 12 }}>
              {customerErrors.find((error) => error.field === "postcode")?.text}
            </p>
          </div>
          <div className="col-12 col-md-3 col-lg-3">
            <input
              type="text"
              name="location"
              id="locationField"
              className="form-control form-control-sm bg-light shadow-none"
              placeholder="Ort"
              value={location}
              onChange={(e) => {
                dispatch(
                  onValueChange({ name: e.target.name, value: e.target.value })
                );
              }}
              required
              minLength={3}
            />
            <p className="text-sm text-danger" style={{ fontSize: 12 }}>
              {customerErrors.find((error) => error.field === "location")?.text}
            </p>
          </div>
        </div>
        <hr className="border border-primary my-4" />
        <p className="lead fs-5 mb-3">Besondere Bemerkungen</p>
        <div className="row my-4">
          <div className="col-12 position-relative">
            <textarea
              name="description"
              id="specialMarksField"
              cols="30"
              rows="5"
              className="form-control form-control-sm bg-light shadow-none"
              placeholder="Besondere Bemerkungen…"
              defaultValue={description}
              onChange={(e) => {
                dispatch(updateDescription({ value: e.target.value }));
                dispatch(
                  updateItem({
                    itemId: initialCartItem.id,
                    name: "description",
                    value: e.target.value,
                  })
                );
              }}
            ></textarea>
          </div>
        </div>
        <hr className="border border-primary my-4" />
        <p className="lead fs-5 mb-3">Kontaktinformationen des Kunden</p>
        <p>
          Bitte geben Sie entweder die E-Mail ODER die Telefonnummer des Kunden
          ein, um den Antrag zur Signierung zu versenden
        </p>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-6">
            <select
              name="notificationBy"
              id="notificationByField"
              className={`form-select form-select-sm bg-light shadow-none`}
              value={notificationBy}
              onChange={(e) => {
                dispatch(
                  onValueChange({
                    name: e.target.name,
                    value: e.target.value,
                  })
                );

                
                dispatch(
                  onValueChange({
                    name: "email",
                    value: "",
                  })
                );
                dispatch(
                  onValueChange({
                    name: "phone",
                    value: "",
                  })
                );
              }}
              required
            >
              {[
                { label: "E-Mail", value: "email" },
                { label: "SMS", value: "sms" },
              ].map((option, index) => {
                return (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                );
              })}
            </select>
          </div>
          {notificationBy === "email" && (
            <div className="col-12 col-md-6 col-lg-6">
              <input
                type="email"
                name="email"
                pattern='^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
                id="emailField"
                className="form-control form-control-sm bg-light shadow-none"
                placeholder="E-Mailadresse *"
                value={email}
                onChange={(e) => {
                  dispatch(
                    onValueChange({
                      name: e.target.name,
                      value: e.target.value,
                    })
                  );
                }}
                required
                minLength={3}
              />
              <p className="text-sm text-danger" style={{ fontSize: 12 }}>
                {customerErrors.find((error) => error.field === "email")?.text}
              </p>
            </div>
          )}

          {notificationBy === "sms" && (
            <div className="col-12 col-md-6 col-lg-6">
              <div className="d-flex justify-content-center align-items-center">
                <select
                  name="phoneCode"
                  id="phoneCodeField"
                  className={`form-select form-select-sm bg-light shadow-none w-25 mx-2`}
                  defaultValue={phoneCode}
                  onChange={(e) => {
                    dispatch(
                      onValueChange({
                        name: e.target.name,
                        value: e.target.value,
                      })
                    );
                  }}
                  required
                >
                  {["+49", "+43"].map((code, index) => {
                    return (
                      <option
                        key={index}
                        value={code}
                        selected={code === phoneCode}
                      >
                        {code}
                      </option>
                    );
                  })}
                </select>
                <input
                  type="number"
                  name="phone"
                  pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"
                  id="phoneField"
                  className="form-control form-control-sm bg-light shadow-none"
                  placeholder="Telefon (ex 5358655866)"
                  value={phone}
                  
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    dispatch(
                      onValueChange({
                        name: e.target.name,
                        value: e.target.value,
                      })
                    );
                  }}
                  required
                  minLength={3}
                />
              </div>
                  
              <p className="text-sm text-danger" style={{ fontSize: 12 }}>
                {customerErrors.find((error) => error.field === "phone")?.text}
              </p>
            </div>
          )}
        </div>
        {/* Payment Information's */}
        <hr className="border border-primary my-4" />
        <p className="lead fs-5 mb-3">Zahlungsweise</p>
        <div className="d-flex mb-5">
          {paymentPeriods.map((period, index) => {
            return (
              <div key={index} className="input-group">
                <input
                  type="radio"
                  className="btn-check"
                  name="paymentPeriod"
                  id={`paymentPeriod-${index}`}
                  autoComplete="off"
                  defaultValue={period.value}
                  checked={period.value === paymentPeriod}
                  onChange={(e) => {
                    dispatch(
                      updatePaymentPeriod({ paymentPeriod: e.target.value })
                    );

                    for (let machine of machines) {
                      dispatch(
                        calculateOneItem({
                          itemId: machine.id,
                          country: country ? country : "germany",
                        })
                      );
                    }

                    dispatch(calculateTotal());
                  }}
                />
                <label
                  className={`btn btn-outline-dark rounded-1 btn-sm w-75 mx-auto`}
                  htmlFor={`paymentPeriod-${index}`}
                >
                  {period.label}
                </label>
              </div>
            );
          })}
        </div>
        <p className="text-sm text-danger" style={{ fontSize: 12 }}>
          {
            customerErrors.find((error) => error.field === "paymentPeriod")
              ?.text
          }
        </p>
        <hr className="border border-primary my-4" />
        <p className="lead fs-5 mb-3">Zahlungsart</p>
        <div className="row">
          <div className="col-12">
            <input
              type="text"
              name="fullName"
              id="fullNameField"
              className="form-control form-control-sm bg-light shadow-none"
              placeholder="Name des Kontoinhabers"
              value={fullName}
              onChange={(e) => {
                dispatch(
                  onValueChange({ name: e.target.name, value: e.target.value })
                );
              }}
              required
              minLength={3}
            />
            <p className="text-sm text-danger" style={{ fontSize: 12 }}>
              {customerErrors.find((error) => error.field === "fullName")?.text}
            </p>
          </div>
        </div>
        <div className="row mt-3  row-cols-md-2 row-cols-lg-2">
          <div className="col-12 col-md-6">
            <input
              type="text"
              name="bankName"
              id="bankNameField"
              className="form-control form-control-sm bg-light shadow-none"
              placeholder="Name des Kreditinstituts"
              value={bankName}
              onChange={(e) => {
                dispatch(
                  onValueChange({ name: e.target.name, value: e.target.value })
                );
              }}
              required
              minLength={3}
            />
            <p className="text-sm text-danger" style={{ fontSize: 12 }}>
              {customerErrors.find((error) => error.field === "bankName")?.text}
            </p>
          </div>

          {country === "germany" && (
            <div className="col-12 col-md-6">
              <input
                type="text"
                name="swiftCode"
                id="swiftCodeField"
                className="form-control form-control-sm bg-light shadow-none"
                placeholder="SWIFT-Code"
                value={swiftCode}
                onChange={(e) => {
                  dispatch(
                    onValueChange({
                      name: e.target.name,
                      value: e.target.value,
                    })
                  );
                }}
                required
                minLength={3}
              />
              <p className="text-sm text-danger" style={{ fontSize: 12 }}>
                {
                  customerErrors.find((error) => error.field === "swiftCode")
                    ?.text
                }
              </p>
            </div>
          )}
        </div>
        <div className="row row-cols-md-2 row-cols-lg-2 row-cols-xl-2 mt-3">
          <div className="col-12 col-md-2 col-lg-2 col-xl-1">
            <input
              type="text"
              name="ibanCode"
              id="ibanCodeField"
              className="form-control form-control-sm bg-light shadow-none"
              value={ibanCode}
              required
              minLength={3}
              readOnly
            />
          </div>
          <div className="col-12 col-md-10 col-lg-10 col-xl-11">
            <input
              type="number"
              name="iban"
              id="ibanField"
              className="form-control form-control-sm bg-light shadow-none"
              placeholder="IBAN"
              pattern="/^[0-9]{18,24}$/"
              value={iban}
              onChange={(e) => {
                if(e.target.value.length < 25){
                  dispatch(
                    onValueChange({
                      name: e.target.name,
                      value: e.target.value,
                    })
                  );
                }
                
              }}
              required
            />
            <p className="text-sm text-danger" style={{ fontSize: 12 }}>
              {customerErrors.find((error) => error.field === "iban")?.text}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Form2;
