import React, { useState } from 'react'
import { useEffect } from 'react'
import Alert from '../../../components/Alert'
import { useDispatch } from 'react-redux'
import { hideAlert, showAlert } from '../../../features/alertSlice'
import axiosInstance from '../../../utils/axios'
import { getTokenFromLocalStorage } from '../../../utils/localStorage'
import moment from 'moment'
import formatNumber from '../../../utils/formatNumber'
import Loading from '../../../components/Loading'

function PaymentPage() {
  const [paymentInfo, setPaymentInfo] = useState(null)
  const [paymentState, setPaymentState] = useState(null)
  const [ibanNumber, setIbanNumber] = useState(null);
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [isValid, setIsValid] = useState(true)
  const getPaymentInfo = async () => {
    setLoading(true)
    const token = getTokenFromLocalStorage()
    if (token) {
      const response = await axiosInstance.get(
        '/api/distributor/payments-info',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      const data = await response.data.result
      setPaymentInfo(data)
      console.log(data)
      setLoading(false)
    }
  }

  const getPaymentState = async () => {
    setLoading(true)
    const token = getTokenFromLocalStorage()
    if (token) {
      const response = await axiosInstance.get(
        '/api/distributor/payments-state',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      const data = await response.data.result
      setPaymentState(data)
      setLoading(false)
    }
  }

  const updatePaymentInfo = async () => {
    setLoading(true)
    const token = getTokenFromLocalStorage();
    const pattern = /^[0-9]{18,24}$/
    if(isValid){
      if (token) {
        const response = await axiosInstance.put(
          '/api/distributor/payments-info',
          { ...paymentInfo },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        const data = await response.data.result
        setPaymentInfo(data)
        setLoading(false)
        dispatch(
          showAlert({
            alertType: 'success',
            alertContent: 'Zahlungsinformationen wurden erfolgreich aktualisiert',
          }),
        )
        setTimeout(() => {
          dispatch(hideAlert())
        }, 2000)
      }
    }else{
      setLoading(false);
      dispatch(showAlert({alertType:"danger",alertContent:"Bitte geben Sie einen gültigen Iban-Wert ein"}))
      setTimeout(() => {
        dispatch(hideAlert())
      }, 2000);
    }
    
  
    
  }

  const onValueChange = (e) => {
    if (e.target.name === 'ibanNumber') {
      setIsValid(false)
      const pattern = /^[0-9]{18,24}$/
      if (e.target.value.match(pattern)) {
        setIsValid(true)
      }else if (e.target.value.length >=18 && e.target.value.length <=24) {
        setIsValid(true)
      }else{
        setIsValid(false)
      }
      const newInfo = Object.assign(paymentInfo, { ibanNumber: e.target.value })
      setPaymentInfo({ ...newInfo })
    }
    if (e.target.name !== 'ibanNumber') {
      setPaymentInfo({ ...paymentInfo, [e.target.name]: e.target.value })
    }
    
  }

  useEffect(() => {
    getPaymentInfo()
    getPaymentState()
  }, [])

  if (loading) {
    return <Loading></Loading>
  }

  if (paymentState) {
    return (
      <>
        <div className="container ">
          <Alert></Alert>
          <div className="col-12">
            <div className="page-header row mb-4">
              <div className="col-12">
                <div className="d-flex">
                  <div className="me-auto page-title-holder">
                    <h2 className="section-title text-dark ">Zahlungen</h2>
                  </div>
                </div>
              </div>
            </div>

            <div className="payment">
              <div className="row">
                <div className="col-md-7">
                  <h3>Deine Umsätze</h3>

                  <div className="card">
                    <ul className="timeline">
                      {paymentState &&
                        paymentState?.map((record, index) => {
                          return (
                            <li key={index}>
                              <span className="date">
                                {record.date}
                              </span>
                              <div className="prices">
                                <div className="child">
                                  <label>Prämien</label>
                                  <span className="price">
                                    {formatNumber(record?.pricePerMonth)}
                                  </span>
                                </div>
                                <div className="child">
                                  <label>Provision</label>
                                  <span className="price">
                                    {formatNumber(record?.total)}
                                  </span>
                                </div>
                                <div className="child">
                                  <label>Performance</label>
                                  {record?.state === 'up' ? (
                                    <div className="price-up"></div>
                                  ) : record?.state === 'down' ? (
                                    <div className="price-down"></div>
                                  ) : (
                                    <div className="price-same">
                                      <i className="bi bi-dash-lg text-dark text-center"></i>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </li>
                          )
                        })}
                    </ul>
                  </div>
                </div>
                <div className="col-md-5">
                  <h3>Deine Zahlungsdaten</h3>
                  <div className="card">
                    <h5>Zahlungsdaten bearbeiten</h5>
                    <div className="row">
                      <div className="col-12 mt-3">
                        <label className="form-label">Kontoinhaber</label>
                        <input
                          className="form-control shadow-none"
                          type="text"
                          placeholder=""
                          name="fullName"
                          onChange={onValueChange}
                          value={paymentInfo?.fullName}
                        />
                      </div>
                      <div className="col-6 mt-3">
                        <label className="form-label">Name der Bank</label>
                        <input
                          className="form-control shadow-none"
                          type="text"
                          name="bankName"
                          value={paymentInfo?.bankName}
                          onChange={onValueChange}
                          placeholder=""
                        />
                      </div>
                      <div className="col-6 mt-3">
                        <label className="form-label">BLZ</label>
                        <input
                          className="form-control shadow-none"
                          type="text"
                          name="location"
                          value={paymentInfo?.location}
                          onChange={onValueChange}
                          placeholder=""
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <label className="form-label">IBAN</label>
                        <input
                          className="form-control shadow-none"
                          type="number"
                          name="ibanNumber"
                          pattern="/^[0-9]{18,24}$/"
                          value={paymentInfo?.ibanNumber}
                          onChange={onValueChange}
                          placeholder=""
                        />
                        {!isValid && (
                          <p
                            className="text-sm text-danger"
                            style={{ fontSize: 12 }}
                          >
                            Bitte geben Sie eine gültige IBAN ein
                          </p>
                        )}
                      </div>

                      <div className="col-6 mt-3">
                        <label className="form-label">BIC/Swift</label>
                        <input
                          className="form-control shadow-none"
                          type="text"
                          name="swiftCode"
                          value={paymentInfo?.swiftCode}
                          onChange={onValueChange}
                        />
                      </div>
                      <div className="col-6 mt-3">
                        <label className="form-label">Währung</label>
                        <p className='p-1 border border-info rounded-2'>EURO</p>
                        
                      </div>
                    </div>
                    <div className="form-button mt-4">
                      <button
                        onClick={updatePaymentInfo}
                        type="button"
                        className="btn btn-dark"
                      >
                        Speichern
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default PaymentPage
