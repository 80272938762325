const defaultDiscountsList = [
  {
    id: 1,
    label: 'Vermietete inkl. Unterschlagung (+20%)',
    value: 20,
  },
  {
    id: 2,
    label: 'Tunnelarbeiten und Arbeiten unter Tage (+15%)',
    value: 15,
  },
  {
    id: 3,
    label: 'Ausschluss Feuer (-12%)',
    value: -12,
  },
  {
    id: 4,
    label: 'Neuwertentschädigung innerhalb der ersten 24 Monate (+5%)',
    value: 5,
  },
  {
    id: 5,
    label: 'Schadenverlaufsabhängiger Rabatt (-20%)',
    value: -20,
  },
  {
    id: 6,
    label: 'Mehrjährigkeitsrabatt bei einer Laufzeit von mindestens 3 Jahren (-10%)',
    value: -10,
  },
]
export default defaultDiscountsList

export const sortDiscounts = (discountsList, selectedDiscounts) => {
  let sortedByLabelArray = [];

  // Sort by label
  discountsList.forEach((item) => {
    selectedDiscounts.forEach((selectedItem) => {
      if (item.label === selectedItem.label) {
        sortedByLabelArray.push(item);
      }
    });
  });

  return sortedByLabelArray;
};