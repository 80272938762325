import { BrowserRouter, Routes, Route } from "react-router-dom";

import React, { useEffect } from "react";

import axiosInstance from "./utils/axios";
import "react-tooltip/dist/react-tooltip.css";

// common pages
import LoginPage from "./pages/LoginPage";
import ProtectedRoute from "./utils/protectedRoute";
import CalculatorPage from "./pages/Dashboard/CalculatorPage";
import LayoutPage from "./pages/Dashboard/LayoutPage";
import PermissionRoute from "./utils/permissionRoute";
// pages
import PermissionError from "./pages/PermissionError";

import {
  DistributorProfilePage,
  DistributorCustomersPage,
  DistributorContractsPage,
  HelpPage,
  AdminProfilePage,
  AdminContractsPage,
  PaymentPage,
  AdminPaymentPage,
  AdminDistributorsPage,
  ContractDetails,
  AdminOneDistributorContractPage,
  CustomerContractsPage,
  EditContractPage,
  AdminCreateDistributor,
  AdminCustomersPage,
  UpdateContractPage
} from "./pages/Dashboard";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import NewPasswordPage from "./pages/NewPasswordPage";
import SupportFormPage from "./pages/SupportFormPage";
import Alert from "./components/Alert";
import { AdminUpdateFullContractPage } from "./pages/Dashboard/Admin";
function App() {
  // TEST API CONNECTION
  useEffect(() => {
    /*
    return async () => {
      const response = await axiosInstance.get("/is-running");
      console.log(response.data);
    };
    */
  }, []);

  return (
    <>
      <Alert></Alert>
      <BrowserRouter>
        <Routes>
          <Route index path="/" element={<LoginPage></LoginPage>}></Route>
          <Route
            index
            path="/reset-password/:resetToken"
            element={<NewPasswordPage></NewPasswordPage>}
          ></Route>
          <Route
            path="/reset-password"
            element={<ResetPasswordPage></ResetPasswordPage>}
          ></Route>
          <Route
            path="/permission-error"
            element={<PermissionError></PermissionError>}
          ></Route>
          <Route
            path="/calculator"
            element={
              <ProtectedRoute allowed={["admin"]}>
                <CalculatorPage></CalculatorPage>
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/calculator/update/:id"
            element={
              <ProtectedRoute allowed={["distributor"]}>
                <CalculatorPage></CalculatorPage>
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/calculator/contracts/edit/:id"
            element={
              <PermissionRoute allowed={["admin", "distributor"]}>
                <EditContractPage></EditContractPage>
              </PermissionRoute>
            }
          ></Route>


          <Route
            path="/calculator/contracts/update-full/:id"
            element={
              <PermissionRoute allowed={["admin", "distributor"]}>
                <UpdateContractPage></UpdateContractPage>
              </PermissionRoute>
            }
          ></Route>

          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <LayoutPage></LayoutPage>
              </ProtectedRoute>
            }
          >
            <Route
              path="admin"
              element={
                <PermissionRoute allowed={["admin"]}>
                  <AdminProfilePage></AdminProfilePage>
                </PermissionRoute>
              }
            ></Route>

            <Route
              path="admins/distributors"
              element={
                <PermissionRoute allowed={["admin"]}>
                  <AdminDistributorsPage></AdminDistributorsPage>
                </PermissionRoute>
              }
            ></Route>

            <Route
              path="admins/customers"
              element={
                <PermissionRoute allowed={["admin"]}>
                  <AdminCustomersPage></AdminCustomersPage>
                </PermissionRoute>
              }
            ></Route>

            <Route
              path="distributors/:id/contracts"
              element={
                <PermissionRoute allowed={["admin"]}>
                  <AdminOneDistributorContractPage></AdminOneDistributorContractPage>
                </PermissionRoute>
              }
            ></Route>

            <Route
              path="allpayments"
              element={
                <PermissionRoute allowed={["admin"]}>
                  <AdminPaymentPage></AdminPaymentPage>
                </PermissionRoute>
              }
            ></Route>
            <Route
              path="payments"
              element={
                <PermissionRoute allowed={["admin", "distributor"]}>
                  <PaymentPage></PaymentPage>
                </PermissionRoute>
              }
            ></Route>

            <Route
              path="support"
              element={
                <PermissionRoute allowed={["admin", "distributor"]}>
                  <SupportFormPage></SupportFormPage>
                </PermissionRoute>
              }
            ></Route>

            <Route
              path="contracts"
              element={
                <PermissionRoute allowed={["admin", "distributor"]}>
                  <AdminContractsPage></AdminContractsPage>
                </PermissionRoute>
              }
            ></Route>

            <Route
              path="create-distributor"
              element={
                <PermissionRoute allowed={["admin"]}>
                  <AdminCreateDistributor></AdminCreateDistributor>
                </PermissionRoute>
              }
            ></Route>

            <Route
              path="distributor"
              element={
                <PermissionRoute allowed={["admin", "distributor"]}>
                  <DistributorProfilePage></DistributorProfilePage>
                </PermissionRoute>
              }
            ></Route>

            <Route
              path="customers"
              element={
                <PermissionRoute allowed={["admin", "distributor"]}>
                  <DistributorCustomersPage></DistributorCustomersPage>
                </PermissionRoute>
              }
            ></Route>

            <Route
              path="contracts/:contractId"
              element={
                <PermissionRoute allowed={["admin", "distributor"]}>
                  <ContractDetails></ContractDetails>
                </PermissionRoute>
              }
            ></Route>
            <Route
              path="/dashboard/customer/:id/contracts/"
              element={
                <PermissionRoute allowed={["admin", "distributor"]}>
                  <CustomerContractsPage></CustomerContractsPage>
                </PermissionRoute>
              }
            ></Route>

            <Route
              path="my-contracts"
              element={
                <PermissionRoute allowed={["admin", "distributor"]}>
                  <DistributorContractsPage></DistributorContractsPage>
                </PermissionRoute>
              }
            ></Route>

            <Route
              path="hilfe-service"
              element={
                <PermissionRoute allowed={["admin", "distributor"]}>
                  <HelpPage></HelpPage>
                </PermissionRoute>
              }
            ></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
