import { configureStore } from '@reduxjs/toolkit'
// import calculatorSlice from "./features/calculatorSlice";
import alertSlice from './features/alertSlice'
import authSlice from './features/authSlice'
import categorySlice from './features/categorySlice'
import userSlice from './features/userSlice'
import contractSlice from './features/contractSlice'
import updateOneContractSlice from './features/contract/updateContractSlice'
import {
  machineSlice,
  calculatorSlice,
  customerSlice,
  createOneContractSlice,
} from './features'

export const store = configureStore({
  reducer: {
    machine: machineSlice,
    customer: customerSlice,
    calculator: calculatorSlice,
    createOneContractSlice: createOneContractSlice,
    updateOneContractSlice: updateOneContractSlice,
    alert: alertSlice,
    auth: authSlice,
    category: categorySlice,
    user: userSlice,
    contract: contractSlice,
  },
})

export default store
