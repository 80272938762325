import { useSelector, useDispatch } from "react-redux";
import React, { useEffect } from "react";

import formatNumber from "../utils/formatNumber";
import MachineCartItem from "./MachineCartItem";
import { useParams } from "react-router-dom";
import { fillContractInfo } from "../features/contract/calculatorSlice";
function BonusCard() {
  const dispatch = useDispatch();
  const { machines, total, paymentPeriod } = useSelector(
    (store) => store.calculator
  );

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(fillContractInfo(id));
    }
  }, []);

  return (
    <>
      <div className="card bonus-card bg-light border-0 px-2 py-1">
        <div className="card-header bg-light">
          <p className="my-auto lead">Prämie</p>
        </div>
        <div className="card-body m-0 p-0">
          <ul className="list-group m-0 p-0 bg-light">
            {machines &&
              machines.map((machine, index) => {
                return (
                  <MachineCartItem
                    key={index}
                    id={machine?.id}
                    index={index}
                    title={machine?.machineObj?.title}
                    brand={machine?.brand}
                    productionYear={machine?.productionYear}
                    serialNumber={machine?.serialNumber}
                    description={machine?.description}
                    price={machine?.price}
                    paymentPeriod={paymentPeriod}
                  ></MachineCartItem>
                );
              })}
          </ul>
        </div>
        <div className="card-footer bg-light py-0">
          <div className="d-flex justify-content-between w-100">
            <p className="lead price-label d-flex flex-column">
              <span>Vorläufige Endsumme</span>
              <span>inkl. Steuern</span>
            </p>
            <p className="lead total-price">{formatNumber(Number(machines.map(m=>{return Number(m.price)}).reduce((a,c)=>a +c,0)))}</p>
          </div>
          
        </div>
      </div>
    </>
  );
}

export default BonusCard;
