import { useSelector, useDispatch } from "react-redux";
import {
    calculateOneItem,
    controlDiscounts,
    removeMachine,
    updateOneMachine,
} from "../features/contract/calculatorSlice";
import React, { useState } from "react";

import {
    resetValues,
    calculate,
    setMachineValues,
    getMachines,
} from "../features/contract/machineSlice";
import formatNumber from "../utils/formatNumber";
import { hideAlert, showAlert } from "../features/alertSlice";
import moment from "moment";

function MachinePreview({
    id,
    machine,
    category,
    brand,
    protectType,
    productionYear,
    serialNumber,
    insuranceStartDate,
    purchasePrice,
    machineObj,
    extraPrices,
    discountsOptions,
    index,
    machineType,
    duration,
    mainDue,
    includedPolicyholder,
}) {
    const { total, machines, country } = useSelector(
        (store) => store.calculator
    );
    const { categoriesList } = useSelector((store) => store.machine);
    const [isActive, setIsActive] = useState(false);
    const dispatch = useDispatch();

    return (
        <>
            <div className="row mb-4 ">
                <div className="col-12 py-3 px-4">
                    <div className="d-flex align-items-center align-items-between">
                        <p className="my-auto  fs-6 fw-bold">
                            {index + 1} - {machineObj?.title}
                        </p>
                        <div className="ms-auto">
                            <button
                                className="btn my-auto btn-sm btn-dark mx-2"
                                onClick={async () => {
                                    const lastItem = [...machines].reverse()[0];
                                    const notValid =
                                        lastItem.brand === "" ||
                                        lastItem.machineType === "";

                                    if (notValid) {
                                        dispatch(
                                            showAlert({
                                                alertType: "warning",
                                                alertContent:
                                                    "Bitte füllen Sie alle Vertragsinformationen aus",
                                            })
                                        );
                                        setTimeout(() => {
                                            dispatch(hideAlert());
                                        }, 3000);
                                    } else {
                                        await dispatch(
                                            updateOneMachine({ itemId: id })
                                        );

                                        await dispatch(
                                            setMachineValues({
                                                machine: machines[index],
                                            })
                                        );

                                        await dispatch(getMachines(category));
                                    }
                                }}
                            >
                                Bearbeiten
                            </button>
                            <button
                                className="btn my-auto btn-sm btn-danger ms-auto"
                                onClick={() => {
                                    dispatch(removeMachine({ machineId: id }));

                                    //dispatch(calculate({ total: total }));
                                    dispatch(controlDiscounts());
                                    for (let machine of machines) {
                                        dispatch(
                                            calculateOneItem({
                                                itemId: machine.id,
                                                country: country
                                                    ? country
                                                    : "austria",
                                            })
                                        );
                                    }
                                }}
                            >
                                Löschen
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row machine-preview-inner py-3 px-4">
                <div className="col-12 col-md-12 col-lg-12 mx-0">
                    {/* CATEGORIES */}
                    <div className="categories-holder">
                        <div className="d-flex categories-list">
                            {categoriesList &&
                                categoriesList.map((cat, index) => {
                                    const defaultCardStyle = {
                                        backgroundImage: `url(${require(`../assets/categoryImages/${cat.photo}`)})`,
                                    };

                                    const selectedCardStyle = {
                                        backgroundImage: `url(${require(`../assets/categoryImages/${cat.photo}`)})`,
                                    };

                                    return (
                                        <div
                                            key={index}
                                            className={`category-card mx-2 border-0 card position-relative`}
                                            style={
                                                cat._id !== category
                                                    ? defaultCardStyle
                                                    : selectedCardStyle
                                            }
                                        >
                                            {cat._id === category && (
                                                <div className="selected-sign">
                                                    <i className="bi bi-check-lg"></i>
                                                </div>
                                            )}

                                            <div
                                                className={`position-absolute bottom-0 left-0 w-100 rounded-1 bg-${
                                                    cat._id === category
                                                        ? "primary"
                                                        : "dark"
                                                }`}
                                            >
                                                <p className="category-title my-auto py-3 text-center text-white">
                                                    {cat?.title}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>

                    {/* FORM INPUTS */}
                    <div className="row row-cols-md-3 row-cols-lg-3 my-4 ">
                        <div className="col-12 col-md-3 col-lg-3">
                            <input
                                type="text"
                                name="machine"
                                id="brandName"
                                className={`form-control form-control-sm bg-light shadow-none`}
                                placeholder="Maschinenmarke"
                                required
                                value={machineObj?.title}
                                readOnly
                            />
                        </div>

                        <div className="col-12 col-md-3 col-lg-3">
                            <input
                                type="text"
                                name="machine"
                                id="brandName"
                                className={`form-control form-control-sm bg-light shadow-none`}
                                placeholder="Maschinenmarke"
                                required
                                value={brand}
                                readOnly
                            />
                        </div>

                        <div className="col-12 col-md-3 col-lg-3">
                            <input
                                type="text"
                                name="machine"
                                id="brandName"
                                className={`form-control form-control-sm bg-light shadow-none`}
                                placeholder="Maschinenmarke"
                                required
                                value={machineType}
                                readOnly
                            />
                        </div>

                        <div className="col-12 col-md-3 col-lg-3">
                            <input
                                type="text"
                                name="machine"
                                id="brandName"
                                className={`form-control form-control-sm bg-light shadow-none`}
                                placeholder="Maschinenmarke"
                                required
                                value={productionYear}
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="row row-cols-md-3 row-cols-lg-3 my-4">
                        <div className="col-12 col-md-3 col-lg-3">
                            <input
                                type="text"
                                name="serialNumber"
                                id="serialNumberName"
                                className="form-control form-control-sm bg-light shadow-none"
                                placeholder="Seriennummer/Fabriksnummer"
                                required
                                value={serialNumber}
                            />
                        </div>

                        <div className="col-12 col-md-7 col-lg-7">
                            <div className="d-flex  align-items-center  justify-content-around ">
                                <p className="small-text">
                                    Versicherungszeitraum:
                                </p>
                                <p className="mx-2 small-text">von</p>
                                <div className="d-flex flex-column">
                                    <input
                                        type="text"
                                        name="insuranceStartDate"
                                        id="insuranceStartDateName"
                                        className="form-control form-control-sm bg-light shadow-none mx-1"
                                        required
                                        disabled
                                        value={moment(
                                            insuranceStartDate
                                        ).format("DD-MM-YYYY")}
                                    />
                                </div>
                                <p className="mx-2 small-text">Dauer</p>
                                <div className="d-flex flex-column">
                                    <input
                                        type="text"
                                        name="duration"
                                        id="durationField"
                                        className="form-control form-control-sm bg-light shadow-none mx-1"
                                        required
                                        value={duration}
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-2 col-lg-2">
                            <input
                                type="text"
                                name="mainDue"
                                id="mainDueField"
                                className="form-control form-control-sm bg-light shadow-none mx-1"
                                required
                                value={mainDue}
                                readOnly
                            />
                        </div>
                    </div>

                    {!isActive && (
                        <div
                            className={`d-flex justify-content-center mt-5 ${
                                isActive ? "wide-shadow" : "wide-shadow-active"
                            }`}
                        >
                            <button
                                className="btn btn-sm my-auto"
                                onClick={() => {
                                    setIsActive(!isActive);
                                }}
                            >
                                <i className="bi bi-chevron-down"></i>
                            </button>
                        </div>
                    )}

                    <div className={`hidden-section ${isActive ? "show" : ""}`}>
                        <div className="row my-4">
                            <div className="col-12">
                                {/* <hr className="border border-primary my-4" /> */}

                                {/* <p className="lead my-2 fw-2">Kaufpreis</p> */}
                                <div className="row row-cols-md-2 row-cols-lg-2 my-4">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="mb-3">
                                            <label
                                                htmlFor="purchasePrice"
                                                className="form-label"
                                            >
                                                Kaufpreis
                                            </label>
                                            <input
                                                type="text"
                                                name="purchasePrice"
                                                id="price"
                                                className="form-control form-control-sm bg-light shadow-none"
                                                required
                                                min={0}
                                                defaultValue={purchasePrice}
                                            />
                                        </div>

                                        {extraPrices &&
                                            extraPrices?.length > 0 && (
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                className="text-center"
                                                                scope="col"
                                                            >
                                                                #
                                                            </th>
                                                            <th
                                                                className="text-center"
                                                                scope="col"
                                                            >
                                                                Zubehör /
                                                                Sonderausstattung
                                                            </th>
                                                            <th
                                                                className="text-center"
                                                                scope="col"
                                                            >
                                                                Kaufpreis
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {extraPrices &&
                                                            extraPrices.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <tr
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <th
                                                                                className="text-center"
                                                                                scope="row"
                                                                            >
                                                                                {index +
                                                                                    1}
                                                                            </th>
                                                                            <td className="text-center">
                                                                                {
                                                                                    item.title
                                                                                }
                                                                            </td>
                                                                            <td className="text-center">
                                                                                {formatNumber(
                                                                                    Number(
                                                                                        item.price
                                                                                    )
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }
                                                            )}
                                                    </tbody>
                                                </table>
                                            )}

                                        <div className="mb-3">
                                            <label
                                                htmlFor="protectType"
                                                className="form-label"
                                            >
                                                Typ schützen
                                            </label>
                                            <input
                                                type="text"
                                                name="protectTypeField"
                                                id="protectType"
                                                className="form-control form-control-sm bg-light shadow-none"
                                                required
                                                min={0}
                                                defaultValue={
                                                    protectType ===
                                                    "fullProduction"
                                                        ? "Vollschutz"
                                                        : protectType === "hull"
                                                        ? "Kaskoschutz"
                                                        : protectType === "mtv"
                                                        ? "MTV/Teilschutz"
                                                        : ""
                                                }
                                            />
                                        </div>
                                        {/* <hr className="border-primary" /> */}
                                        {extraPrices &&
                                            extraPrices.map((item, index) => {
                                                return (
                                                    <div key={index}>
                                                        <div className="d-flex px-2 my-2 justify-content-between">
                                                            <p className="lead">
                                                                {item.title} :{" "}
                                                            </p>
                                                            <p className="lead">
                                                                {formatNumber(
                                                                    Number(
                                                                        item.price
                                                                    )
                                                                )}
                                                            </p>
                                                        </div>
                                                        {/* <hr className="border-primary" /> */}
                                                    </div>
                                                );
                                            })}

                                        {discountsOptions &&
                                            discountsOptions.map(
                                                (item, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <div className="d-flex px-2 my-2 justify-content-between">
                                                                <p className="lead">
                                                                    {item.label}{" "}
                                                                    :{" "}
                                                                </p>
                                                                <p className="lead">
                                                                    {item.value}{" "}
                                                                    %
                                                                </p>
                                                            </div>
                                                            {/* <hr className="border-primary" /> */}
                                                        </div>
                                                    );
                                                }
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isActive && (
                        <div
                            className={`d-flex justify-content-center mb-5 ${
                                isActive ? "wide-shadow" : "wide-shadow-active"
                            }`}
                        >
                            <button
                                className="btn btn-sm my-auto"
                                onClick={() => {
                                    setIsActive(!isActive);
                                }}
                            >
                                <i className="bi bi-chevron-up"></i>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default MachinePreview;
