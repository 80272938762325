import React from "react";
import formatNumber from "../utils/formatNumber";
import { Link } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import DownloadButton from "./DownloadButton";
function DistributorCard({ record, onEdit }) {
  return (
    <>
      {record && (
        <div className="col-md-12 col-lg-6 col-xl-4">
          <div className="card distributor-card">
            <div className="card-header  d-flex justify-content-between align-items-center">
              <div className="distributor-info">
                <h5>{record?.distributor?.fullName}</h5>
                <span>{record?.distributor?.company?.name}</span>
              </div>

              <div className="dropdown">
                <button
                  class="btn btn-light"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <BsThreeDotsVertical></BsThreeDotsVertical>
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <button
                      class="dropdown-item"
                      onClick={() => {
                        onEdit(record?.distributor);
                      }}
                    >
                      Bearbeiten
                    </button>
                  </li>
                  {record._id && (
                    <>
                      <li>
                        <Link
                          class="dropdown-item"
                          to={`/dashboard/contracts/${record?._id}`}
                        >
                          Neuster Antrag
                        </Link>
                      </li>
                      <li>
                        <Link
                          class="dropdown-item"
                          to={`/dashboard/distributors/${record?.distributor?._id}/contracts`}
                        >
                          Alle ansehen
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
            <div className="card-body">
              <div className="d-flex justify-content-between mt-3">
                <div className="label-text">
                  <label>Prämie</label>
                  <h3 className="income-value">
                    {formatNumber(
                      record?.machines
                        ?.map((m) => Number(m.price))
                        .reduce((a, b) => a + b, 0)
                    )}
                  </h3>
                </div>
                <div className="label-text">
                  <label>Provisionssatz</label>
                  <h3 className="income-value">
                    {record?.distributor?.commissionRate}%
                  </h3>
                </div>
              </div>
              <div className="label-text">
                <label>E-mail</label>
                <p>{record?.distributor?.email}</p>
              </div>
              <div className="label-text">
                <label>Telefon</label>
                <p>{record?.distributor?.phone}</p>
              </div>
            </div>

            {record._id && record.customerInfo && (
              <div className="card-footer">
                <div className="d-flex links">
                  <Link to={`/dashboard/contracts/${record?._id}`}>
                    Neuster Antrag
                  </Link>

                  <Link
                    to={`/dashboard/distributors/${record?.distributor?._id}/contracts`}
                  >
                    Alle ansehen
                  </Link>
                </div>

                <div className="d-flex align-items-start mt-3">
                  <div className="btn-group  w-100" role="group">
                    <DownloadButton recordId={record?._id} target={"offer"} />

                    {record.state === "ContractClosed" && (
                      <DownloadButton
                        recordId={record?._id}
                        target={"contract"}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default DistributorCard;
