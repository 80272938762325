import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    alertType: "",
    alertContent: "",
    isVisible: false
}

const alertSlice = createSlice({
    name: "alert",
    initialState,
    reducers: {
        showAlert: (state, action) => {
            const { alertType, alertContent } = action.payload;
            state.isVisible = true;
            state.alertContent = alertContent;
            state.alertType = alertType;
        },
        hideAlert: (state, action) => {
            state.isVisible = false;
            state.alertContent = "";
            state.alertType = "";
        }
    },
});


export const {
    showAlert,
    hideAlert,
    isVisible
} = alertSlice.actions;


export default alertSlice.reducer;