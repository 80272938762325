import {
  calculateOneItem,
  initMachine,
} from "../features/contract/calculatorSlice";
import {
  fillMachineErrors,
  resetValues,
  setFormValid,
} from "../features/contract/machineSlice";
import { fillContractInfo } from "../features/contract/calculatorSlice";
import {
  addCustomerError,
  clearCustomerErrors,
} from "../features/contract/customerSlice";
import { updateOneContract } from "../features/contract/updateContractSlice";
import { useDispatch, useSelector } from "react-redux";
import { hideAlert, showAlert } from "../features/alertSlice";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../utils/axios";
import axiosInstance from "../utils/axios";
import { getUserFromLocalStorage } from "../utils/localStorage";

function NavigationFooter({ currentIndex, nextStep, openModal, loadStep }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const { isVisible, alertType } = useSelector((store) => store.alert);

  const {
    category,
    machine,
    brand,
    machineType,
    productionYear,
    insuranceStartDate,
    purchasePrice,
    mainDue,
    duration,
    includedPolicyholder,
    extraPrices,
  } = useSelector((store) => store.machine);

  const { total, machines } = useSelector((store) => store.calculator);
  const user = getUserFromLocalStorage();

  const {
    companyName,
    street,
    houseNumber,
    postcode,
    location,
    email,
    paymentPeriod,
    fullName,
    swiftCode,
    iban,
    bankName,
    companyLegalForm,
    country,
    debtFinancing,
    insureName,
    insureType,
    phone,
    notificationBy,
  } = useSelector((store) => store.customer);
  const { record } = useSelector((store) => store.updateOneContractSlice);

  const formOneValid = () => {
    const machineCost =
      extraPrices.map((i) => Number(i.value)).reduce((a, b) => a + b, 0) +
      Number(purchasePrice);

    if (
      category === "63cf81e0c5fb8ebcdb752013" ||
      category === "63cf81e0c5fb8ebcdb752016" ||
      category === "63cf81e0c5fb8ebcdb752004"
    ) {
      if (machineCost > 850000) {
        dispatch(
          showAlert({
            alertContent:
              "Der Höchstpreis für ausgewählte Kategorien beträgt 850,000 €",
            alertType: "danger",
          })
        );

        setTimeout(() => {
          dispatch(hideAlert());
        }, 3000);
        return;
      }
    } else {
      if (machineCost > 750000) {
        dispatch(
          showAlert({
            alertContent:
              "Der Höchstpreis für ausgewählte Kategorien beträgt 750,000 €",
            alertType: "danger",
          })
        );
        setTimeout(() => {
          dispatch(hideAlert());
        }, 3000);
        return;
      }
    }

    if (
      !machine ||
      machine === "" ||
      !brand ||
      brand === "" ||
      !duration ||
      duration === "" ||
      !mainDue ||
      mainDue === "" ||
      !machineType ||
      machineType === "" ||
      !productionYear ||
      productionYear === "" ||
      !insuranceStartDate ||
      insuranceStartDate === "" ||
      !purchasePrice ||
      !includedPolicyholder ||
      includedPolicyholder === "" ||
      duration === "*" ||
      mainDue === "*"
    ) {
      if (productionYear === undefined) {
        dispatch(
          showAlert({
            alertType: "light",
            alertContent: `Diese Maschine deines Kunden ist leider zu alt für unseren
          Rechner. Bitte starte einen individuellen Auftrag oder wähle eine
          andere Maschine.`,
          })
        );
        setTimeout(() => {
          dispatch(hideAlert());
        }, 2000);
        dispatch(setFormValid(true));
      }

      if (purchasePrice === 0 || !purchasePrice) {
        dispatch(
          showAlert({
            alertType: "danger",
            alertContent: `Preis ungültig`,
          })
        );
        setTimeout(() => {
          dispatch(hideAlert());
        }, 2000);
        dispatch(setFormValid(true));
      }
      dispatch(setFormValid({ isValid: false }));
      return false;
    } else {
      dispatch(setFormValid({ isValid: true }));
      return true;
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(fillContractInfo(id));
    }
  }, [id]);

  const onNextStep = () => {
    // const lastItem = [...machines].reverse()[0]
    if (currentIndex === 0) {
      if (formOneValid() === true) {
        nextStep();
      }
    }
    if (currentIndex === 1) {
      if (companyName === "") {
        dispatch(
          addCustomerError({
            field: "companyName",
            type: "invalid",
            text: "Der Firmenname ist ein Pflichtfeld",
          })
        );
        return;
      }
      if (country === "") {
        dispatch(
          addCustomerError({
            field: "country",
            type: "invalid",
            text: "country ist ein Pflichtfeld",
          })
        );
        return;
      }
      if (companyLegalForm === "") {
        dispatch(
          addCustomerError({
            field: "companyLegalForm",
            type: "invalid",
            text: "Bitte Unternehmenstyp auswählen",
          })
        );
        return;
      }
      if (street === "") {
        dispatch(
          addCustomerError({
            field: "street",
            type: "invalid",
            text: "Straße ist Pflichtfeld",
          })
        );
        return;
      }
      if (houseNumber === "") {
        dispatch(
          addCustomerError({
            field: "houseNumber",
            type: "invalid",
            text: "Hausnummer ist Pflichtfeld",
          })
        );
        return;
      }
      if (postcode === "") {
        dispatch(
          addCustomerError({
            field: "postcode",
            type: "invalid",
            text: "Postleitzahl ist Pflichtfeld",
          })
        );
        return;
      }
      if (location === "") {
        dispatch(
          addCustomerError({
            field: "location",
            type: "invalid",
            text: "Standort ist Pflichtfeld",
          })
        );
        return;
      }
      if (notificationBy === "email") {
        if (email === "") {
          dispatch(
            addCustomerError({
              field: "email",
              type: "invalid",
              text: "Standort ist Pflichtfeld",
            })
          );
          return;
        } else {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!email.match(pattern)) {
            dispatch(
              addCustomerError({
                field: "email",
                type: "invalid",
                text: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
              })
            );
            return;
          }
        }
      } else if (notificationBy === "sms") {
        if (phone === "") {
          dispatch(
            addCustomerError({
              field: "phone",
              type: "invalid",
              text: "Standort ist Pflichtfeld",
            })
          );
          return;
        } else {
          const pattern =
            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
          if (!phone.match(pattern)) {
            dispatch(
              addCustomerError({
                field: "phone",
                type: "invalid",
                text: "Bitte geben Sie eine gültige Telefon ein",
              })
            );
            return;
          }
        }
      }

      if (paymentPeriod === "") {
        dispatch(
          addCustomerError({
            field: "paymentPeriod",
            type: "invalid",
            text: "Standort ist Pflichtfeld",
          })
        );
        return;
      }
      if (fullName === "") {
        dispatch(
          addCustomerError({
            field: "fullName",
            type: "invalid",
            text: "vollständiger Name ist Pflichtfeld",
          })
        );
        return;
      }

      if (bankName === "") {
        dispatch(
          addCustomerError({
            field: "bankName",
            type: "invalid",
            text: "Bankname ist Pflichtfeld",
          })
        );
        return;
      }
      if (debtFinancing === "") {
        dispatch(
          addCustomerError({
            field: "debtFinancing",
            type: "invalid",
            text: "Fremdfinanzierung ist Pflichtfeld",
          })
        );
        return;
      }
      if (insureType === "") {
        dispatch(
          addCustomerError({
            field: "debtFinancing",
            type: "invalid",
            text: "Versicherungstyp ist Pflichtfeld",
          })
        );
        return;
      }
      if (insureName === "") {
        dispatch(
          addCustomerError({
            field: "debtFinancing",
            type: "invalid",
            text: "VersichererName ist Pflichtfeld",
          })
        );
        return;
      }

      if (iban === "") {
        dispatch(
          addCustomerError({
            field: "iban",
            type: "invalid",
            text: "Bitte geben Sie eine gültige IBAN ein",
          })
        );
        return;
      }
    }
  };

  const onAddMachine = () => {
    if (
      !machine ||
      machine === "" ||
      !brand ||
      brand === "" ||
      !machineType ||
      machineType === "" ||
      !productionYear ||
      productionYear === "" ||
      !insuranceStartDate ||
      insuranceStartDate === "" ||
      !purchasePrice ||
      purchasePrice === 0 ||
      !duration ||
      duration === "" ||
      !mainDue ||
      !productionYear === "*" ||
      duration === "*" ||
      mainDue === "*" ||
      mainDue === "" ||
      !includedPolicyholder ||
      includedPolicyholder === ""
    ) {
      dispatch(setFormValid({ isValid: false }));
      dispatch(
        showAlert({
          alertType: "danger",
          alertContent:
            "Bitte füllen Sie alle erforderlichen Informationen aus",
        })
      );
      setTimeout(() => {
        dispatch(hideAlert());
      }, 3000);
      return;
    } else {
      for (let machine of machines) {
        dispatch(
          calculateOneItem({
            itemId: machine.id,
            country: country ? country : "austria",
          })
        );
      }
      dispatch(setFormValid({ isValid: true }));
      dispatch(fillMachineErrors());
      dispatch(
        initMachine({
          id: machines.length,
          machine: "",
          brand: "",
          protectType: "fullProduction",
          productionYear: "",
          machineType: "",
          serialNumber: "",
          insuranceStartDate: "",
          description: "",
          purchasePrice: "",
          machineObj: "",
          deductible: "",
          fullProduction: "",
          hull: "",
          mtv: "",
          discountsOptions: [],
          extraPrices: [],
          protectFactor: "",
          price: "",
          paymentPeriod: "per-month",
          discountsList: [],
          discountFactor: null,
          category: "",
          categoryObj: "",
          duration: "",
          mainDue: "",
          includedPolicyholder: "",
        })
      );
      dispatch(resetValues());
    }
  };
  const [loading, setLoading] = useState(false);
  const [savingContract, setSavingContract] = useState(false);
  const [savingContractView, setSavingContractView] = useState(false);
  const createContractEnvelope = async () => {
    if (
      !record.availableParts ||
      !record.byManufacturer ||
      !record.debtFinancing ||
      !record.isPrototypes ||
      !record.insureType ||
      !record.debtFinancing
    ) {
      dispatch(
        showAlert({
          alertContent: "Bitte alle notwendigen Felder ausfüllen",
          alertType: "danger",
        })
      );

      setTimeout(() => {
        dispatch(hideAlert());
      }, 2000);

      setLoading(false);
    } else {
      setLoading(true);
      dispatch(
        showAlert({
          alertContent: "Ihr Antrag wurde zur Signierung des Kunden versendet",
          alertType: "dark",
        })
      );

      try {
        await axiosInstance
          .post(
            "/api/docusign/createEnvelope",
            {
              contractId: record._id,
              insureType: record.insureType,
              isPrototypes: record.isPrototypes,
              byManufacturer: record.byManufacturer,
              availableParts: record.availableParts,
              debtFinancing: record.debtFinancing,
              duration: machines[0]?.duration,
              mainDue: machines[0]?.mainDue,
              insuranceStartDate: machines[0]?.insuranceStartDate,
              includedPolicyholder: machines[0]?.includedPolicyholder,
              machines: machines.map((m) => {
                return { ...m, extraExpenses: m.extraPrices };
              }),
              customer: record.customerInfo,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then(() => {
            dispatch(hideAlert());
          })
          .catch((e) => {
            dispatch(
              showAlert({
                alertType: "danger",
                alertContent: e.response.data.result,
              })
            );
            setTimeout(() => {
              dispatch(hideAlert());
            }, 3000);
            setLoading(true);
            setSavingContract(false);
            throw new Error(e.response.data);
          });
      } catch (error) {
        console.log(error);
        throw new Error(error.message);
      }
      setLoading(false);
    }
  };

  const onOpenModal = async () => {
    let errorMessage = 0;
    if (companyName === "") {
      dispatch(
        addCustomerError({
          field: "companyName",
          type: "invalid",
          text: "Der Firmenname ist ein Pflichtfeld",
        })
      );
      errorMessage = 1;
    }
    if (
      !companyLegalForm ||
      companyLegalForm === {} ||
      companyLegalForm === "*"
    ) {
      dispatch(
        addCustomerError({
          field: "companyLegalForm",
          type: "invalid",
          text: "Bitte Unternehmenstyp auswählen",
        })
      );
      errorMessage = 1;
    }
    if (street === "") {
      dispatch(
        addCustomerError({
          field: "street",
          type: "invalid",
          text: "Straße ist Pflichtfeld",
        })
      );
      errorMessage = 1;
    }
    if (houseNumber === "") {
      dispatch(
        addCustomerError({
          field: "houseNumber",
          type: "invalid",
          text: "Hausnummer ist Pflichtfeld",
        })
      );
      errorMessage = 1;
    }
    if (postcode === "") {
      dispatch(
        addCustomerError({
          field: "postcode",
          type: "invalid",
          text: "Postleitzahl ist Pflichtfeld",
        })
      );
      errorMessage = 1;
    }
    if (location === "") {
      dispatch(
        addCustomerError({
          field: "location",
          type: "invalid",
          text: "Standort ist Pflichtfeld",
        })
      );
      errorMessage = 1;
    }
    if (notificationBy === "email") {
      if (email === "") {
        dispatch(
          addCustomerError({
            field: "email",
            type: "invalid",
            text: "Standort ist Pflichtfeld",
          })
        );
        errorMessage = 1;
      } else {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!email.match(pattern)) {
          dispatch(
            addCustomerError({
              field: "email",
              type: "invalid",
              text: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
            })
          );
          errorMessage = 1;
        }

        const response = await axiosInstance.post(
          "/api/distributor/is-email-available",
          {
            email: email,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        const data = await response.data;

        if (data.result === false) {
          dispatch(
            addCustomerError({
              field: "email",
              type: "invalid",
              text: "Diese E-Mail ist als Partner-E-Mail registriert",
            })
          );
          errorMessage = 1;
        }
      }
    } else if (notificationBy === "sms") {
      if (phone === "") {
        dispatch(
          addCustomerError({
            field: "phone",
            type: "invalid",
            text: "Standort ist Pflichtfeld",
          })
        );
        errorMessage = 1;
      } else {
        const pattern =
          /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
        if (!phone.match(pattern)) {
          dispatch(
            addCustomerError({
              field: "phone",
              type: "invalid",
              text: "Bitte geben Sie eine gültige Telefon ein",
            })
          );
          errorMessage = 1;
        }
      }
    }

    if (paymentPeriod === "") {
      dispatch(
        addCustomerError({
          field: "paymentPeriod",
          type: "invalid",
          text: "Standort ist Pflichtfeld",
        })
      );
      errorMessage = 1;
    }
    if (fullName === "") {
      dispatch(
        addCustomerError({
          field: "fullName",
          type: "invalid",
          text: "vollständiger Name ist Pflichtfeld",
        })
      );
      errorMessage = 1;
    }
    if (bankName === "") {
      dispatch(
        addCustomerError({
          field: "bankName",
          type: "invalid",
          text: "Bankname ist Pflichtfeld",
        })
      );
      errorMessage = 1;
    }
    if (country === "germany") {
      if (swiftCode === "") {
        dispatch(
          addCustomerError({
            field: "swiftCode",
            type: "invalid",
            text: "SWIFT-Code ist ein Pflichtfeld",
          })
        );
        errorMessage = 1;
      }
    }
    if (iban === "" || iban.length < 18 || iban.length > 24) {
      dispatch(
        addCustomerError({
          field: "iban",
          type: "invalid",
          text: "IBAN ist ein Pflichtfeld",
        })
      );
      errorMessage = 1;
    }

    if (errorMessage === 1) {
      dispatch(
        showAlert({
          alertContent: "Bitte alle notwendigen Felder ausfüllen",
          alertType: "danger",
        })
      );

      setTimeout(() => {
        dispatch(hideAlert());
      }, 2000);

      return;
    }

    if (total < 25 && record.paymentPeriod === "per-month") {
      dispatch(
        showAlert({
          alertType: "warning",
          alertContent: `Bitte überprüfen Sie die Versicherungssumme. (Mindestprämie: monatlich 25€)`,
        })
      );
      setTimeout(() => {
        dispatch(hideAlert());
      }, 3000);
      return;
    } else if (total < 75 && record.paymentPeriod === "quarterly") {
      alert(total);
      dispatch(
        showAlert({
          alertType: "warning",
          alertContent: `Bitte überprüfen Sie die Versicherungssumme. (Mindestprämie: Vierteljährlich 75€)`,
        })
      );
      setTimeout(() => {
        dispatch(hideAlert());
      }, 3000);
      return;
    } else if (total < 150 && paymentPeriod === "half-yearly") {
      dispatch(
        showAlert({
          alertType: "warning",
          alertContent: `Bitte überprüfen Sie die Versicherungssumme. (Mindestprämie: Halbjährlich 150€)`,
        })
      );
      setTimeout(() => {
        dispatch(hideAlert());
      }, 3000);
      return;
    } else if (total < 300 && paymentPeriod === "yearly") {
      dispatch(
        showAlert({
          alertType: "warning",
          alertContent: `Bitte überprüfen Sie die Versicherungssumme. (Mindestprämie: Jährlich 300€)`,
        })
      );
      setTimeout(() => {
        dispatch(hideAlert());
      }, 3000);
      return;
    } else {
      dispatch(clearCustomerErrors());
      openModal();
    }
  };

  const onContractSavePDF = async () => {
    if (
      record?.ibanNumber === "" ||
      record?.ibanNumber?.length < 18 ||
      record?.ibanNumber?.length > 24
    ) {
      dispatch(
        showAlert({
          alertContent: "Bitte geben Sie eine gültige IBAN ein",
          alertType: "danger",
        })
      );
      setTimeout(() => {
        dispatch(hideAlert());
      }, 3000);
      return;
    }

    if (
      !record.availableParts ||
      !record.byManufacturer ||
      !record.debtFinancing ||
      !record.isPrototypes ||
      !record.insureType ||
      record.insureType === ""
    ) {
      setLoading(true);
      dispatch(
        showAlert({
          alertContent: "Bitte alle notwendigen Felder ausfüllen",
          alertType: "danger",
        })
      );
      setTimeout(() => {
        dispatch(hideAlert());
      }, 2000);
      setLoading(false);
      return;
    } else {
      for (let machine of machines) {
        if (!machine.serialNumber || machine.serialNumber === "") {
          dispatch(
            showAlert({
              alertContent: "Bitte alle notwendigen Felder ausfüllen",
              alertType: "danger",
            })
          );
          setTimeout(() => {
            dispatch(hideAlert());
          }, 2000);
          return;
        }
      }
      //setLoading(true);
      console.log("record", record);
      const response = await axiosInstance.post(
        "/api/docusign/savePdf",
        {
          contractId: record._id,
          target: "contract",
          insureType: record.insureType,
          isPrototypes: record.isPrototypes,
          byManufacturer: record.byManufacturer,
          availableParts: record.availableParts,
          debtFinancing: record.debtFinancing,
          duration: machines[0]?.duration,
          mainDue: machines[0]?.mainDue,
          insuranceStartDate: machines[0]?.insuranceStartDate,
          includedPolicyholder: machines[0]?.includedPolicyholder,
          machines: machines.map((m) => {
            return { ...m, extraExpenses: m.extraPrices };
          }),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const result = await response.data;
      const fileName = result.result.fileName;
      if (fileName) {
        const el = document.createElement("a");
        el.href = `${BASE_URL}/api/docusign/downloadPdf/${
          fileName.split(".")[0]
        }`;
        el.download = fileName;
        el.click();
        el.remove();
      }
      setLoading(false);
    }
  };

  const onSendDocusign = async () => {
    if (
      !record.availableParts ||
      !record.byManufacturer ||
      !record.debtFinancing ||
      !record.isPrototypes ||
      !record.insureType ||
      record.insureType === ""
    ) {
      setLoading(true);
      dispatch(
        showAlert({
          alertContent: "Bitte alle notwendigen Felder ausfüllen",
          alertType: "danger",
        })
      );

      setTimeout(() => {
        dispatch(hideAlert());
      }, 2000);
      setLoading(false);
      return;
    } else {
      setLoading(true);
      await dispatch(
        updateOneContract({
          values: {
            // ...record,
            total:record.total,
            paymentPeriod:record.paymentPeriod,
            insureName:record.insureName,
            insureType:record.insureType,
            isPrototypes:record.isPrototypes,
            byManufacturer:record.byManufacturer,
            availableParts:record.availableParts,
            debtFinancing:record.debtFinancing,
            protectType:record.protectType,
            duration: machines[0]?.duration,
            mainDue: machines[0]?.mainDue,
            insuranceStartDate: machines[0]?.insuranceStartDate,
            includedPolicyholder: machines[0]?.includedPolicyholder,
            customerInfo:record.customer,
            country:record.country,
            state: "ContractAccepted",
            machines: machines.map((m) => {
              return { ...m, extraExpenses: m.extraPrices };
            }),
          },
          id,
        })
      );
      const response = await axiosInstance.post(
        "/api/docusign/savePdf",
        {
          contractId: record._id,
          target: "contract",
          insureType: record.insureType,
          isPrototypes: record.isPrototypes,
          byManufacturer: record.byManufacturer,
          availableParts: record.availableParts,
          debtFinancing: record.debtFinancing,
          duration: machines[0]?.duration,
          mainDue: machines[0]?.mainDue,
          insuranceStartDate: machines[0]?.insuranceStartDate,
          includedPolicyholder: machines[0]?.includedPolicyholder,
          machines: machines.map((m) => {
            return { ...m, extraExpenses: m.extraPrices };
          }),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const result = await response.data;
      const fileName = result.result.fileName;
      if (fileName) {
        const el = document.createElement("a");
        el.href = `${BASE_URL}/api/docusign/downloadPdf/${
          fileName.split(".")[0]
        }`;
        el.download = fileName;
        el.click();
        el.remove();
      }
      if (user?.role === "admin") {
        navigate("/dashboard/contracts");
      } else {
        navigate("/dashboard/my-contracts");
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if(id){
      loadStep(1);
    }
  },[id]);

  return (
    <>
      {currentIndex === 0 && (
        <div className="inner-footer-container">
          <button
            className="btn btn-sm btn-outline-dark w-50 mx-2"
            onClick={onAddMachine}
          >
            <i className="bi bi-plus-lg me-2"></i>
            <span>Maschine hinzufügen</span>
          </button>
          <button
            onClick={() => {
              onNextStep();
            }}
            className={
              isVisible && alertType === "danger"
                ? "btn btn-sm btn-dark w-50 mx-2 err-shake"
                : "btn btn-sm btn-dark w-50 mx-2 "
            }
            //className="btn btn-sm btn-dark w-50 mx-2"
          >
            <span className="me-2">Nächster Schritt</span>
            <i className="bi bi-arrow-right"></i>
          </button>
        </div>
      )}

      {(currentIndex === 1 || currentIndex === 2) && (
        <div className={"inner-footer-container"}>
          <button
            onClick={() => {
              loadStep(0);
            }}
            className="btn btn-sm btn-dark w-50 mx-2"
          >
            <i className="bi bi-arrow-left"></i>
            <span className="ms-2">Vorheriger Schritt</span>
          </button>
          <button
            onClick={() => {
              onOpenModal();
            }}
            className={
              isVisible && alertType === "danger"
                ? "btn btn-sm btn-dark w-50 mx-2 err-shake"
                : "btn btn-sm btn-dark w-50 mx-2"
            }
            //className="btn btn-sm btn-dark w-50 mx-2"
          >
            <span className="me-2">Nächster Schritt</span>
            <i className="bi bi-arrow-right"></i>
          </button>
        </div>
      )}

      {currentIndex === 3 && (
        <div className="inner-footer-container">
          <div className="container">
            <div className="row row-cols-md-1 row-cols-lg-1 row-cols-xl-3">
              <div className="col-12 col-md-12 col-lg-12 py-2 col-xl-4  ">
                <button
                  className="btn btn-sm btn-outline-dark w-100"
                  onClick={() => {
                    navigate(`/calculator/update/${id}`);
                    
                    // if (user?.role === "admin") {
                    //   navigate("/dashboard/contracts");
                    // } else {
                    //   navigate("/dashboard/my-contracts");
                    // }
                  }}
                >
                  <i className="bi bi-chevron-left me-2"></i>
                  zurück zum Adminbereich
                </button>
              </div>
              <div className="col-12 col-md-12 col-lg-12 py-2 col-xl-4 ">
                <button
                  className={
                    isVisible && alertType === "danger"
                      ? "btn btn-sm btn-dark d-flex justify-content-center align-items-center w-100 err-shake"
                      : "btn btn-sm btn-dark w-100 mx-2 d-flex justify-content-center align-items-center"
                  }
                  onClick={async () => {
                    console.log("HERE");
                    setSavingContractView(true);
                    await onContractSavePDF();
                    setSavingContractView(false);
                  }}
                  disabled={loading ? "disabled" : ""}
                >
                  {savingContractView ? (
                    <>
                      <div class="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </>
                  ) : (
                    <>
                      <i className="bi bi-arrow-down me-2"></i>
                      <span className="ms-2">Antrag herunterladen</span>
                    </>
                  )}
                </button>
              </div>
              <div className="col-12 col-md-12 col-lg-12 py-2 col-xl-4">
                <button
                  className="btn btn-sm btn-dark w-100   d-flex justify-content-center align-items-center"
                  onClick={async (e) => {
                    for (let machine of machines) {
                      if (
                        !machine.serialNumber ||
                        machine.serialNumber === ""
                      ) {
                        dispatch(
                          showAlert({
                            alertContent:
                              "Bitte alle notwendigen Felder ausfüllen",
                            alertType: "danger",
                          })
                        );
                        setTimeout(() => {
                          dispatch(hideAlert());
                        }, 2000);
                        return;
                      }
                    }

                    setSavingContract(true);
                    createContractEnvelope()
                      .then((r) => {
                        ///here
                        onSendDocusign();
                        setSavingContract(false);
                      })
                      .catch((e) => console.log(e));
                  }}
                >
                  {savingContract ? (
                    <>
                      <div class="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </>
                  ) : (
                    <>
                      <i className="bi bi-plus-lg me-2 "></i>
                      <span className="me-2">Signierungprozess einleiten</span>
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default NavigationFooter;
