import { useSelector } from "react-redux";
import formatNumber from "../utils/formatNumber";

function MachineCartItem({
  id,
  index,
  title,
  brand,
  productionYear,
  serialNumber,
  description,
  price,
  paymentPeriod,
}) {
  return (
    <>
      <div className="list-group-item bg-light border-0 m-0 cart-item ">
        <p className="fw-bold small-text text-dark item-category my-auto w-50">
          {index + 1}. {title}
        </p>
        
        <p className="lead item-details my-auto fs-6 fw-normal  small-text text-dark">
          Hersteller: {brand} <br />
          Baujahr: {productionYear} <br />
          Seriennummer: {serialNumber}
        </p>

        
        <div className="item-cost">
          <p className="small-text fw-normal fw-bold text-dark">
            {formatNumber(price)}
            <br />
            <span
              className="payment-period float-end"
              style={{ fontSize: "12px" }}
            >
              {" "}
              {paymentPeriod === "per-month"
                ? "/ Monat"
                : paymentPeriod === "quarterly"
                ? "/ 3 Monate"
                : paymentPeriod === "half-yearly"
                ? "/ 6 Monate"
                : paymentPeriod === "yearly"
                ? "/ Jahr"
                : ""}
            </span>
          </p>
        </div>
      </div>
    </>
  );
}

export default MachineCartItem;
