import React, { useState } from 'react'
import axiosInstance from '../utils/axios'

function RemoveContractModal({ removeId }) {
  const [done, setDone] = useState(false)
  const removeContract = async () => {
    const response = await axiosInstance.delete(
      `/api/distributor/contracts/${removeId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
    )

    const result = await response?.data
    console.log(result)
    setDone(true)
    window.location.reload()
  }

  return (
    <>
      {!done && (
        <div
          className={`modal fade ${done ? 'show' : ''}`}
          id="removeContractModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{
            display: done ? 'block' : '',
          }}
        >
          <div className="modal-dialog bg-light">
            <div className="modal-content bg-light">
              <div className="modal-header border-0">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Vertrag entfernen
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body border-0">
                Möchten Sie die Vertragsposition wirklich entfernen?
              </div>
              <div className="modal-footer border-0">
                <button
                  type="button"
                  className="btn btn-secondary btn-sm"
                  data-bs-dismiss="modal"
                >
                  Abbrechen
                </button>
                <button
                  type="button"
                  className="btn btn-danger btn-sm "
                  onClick={removeContract}
                >
                  Entfernen
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default RemoveContractModal
