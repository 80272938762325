import React, { useState } from "react";
import companiesTypes from "../companiesTypes";
import axiosInstance from "../utils/axios";
import { useDispatch } from "react-redux";
import { showAlert, hideAlert } from "../features/alertSlice";

function EditSide({ onCloseEdit, distributor }) {
  const [values, setValues] = useState({ ...distributor });
  const [ibanNumber, setIbanNumber] = useState(values?.ibanNumber || "");
  const [companyName, setCompanyName] = useState(values?.company?.name || "");
  const [phoneCode, setPhoneCode] = useState(values?.phone?.slice(0, 3) || "");
  const [phone, setPhone] = useState(
    values?.phone?.slice(3, values?.phone?.length) || ""
  );
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  const [companyLegalForm, setCompanyLegalForm] = useState(
    values.company.legalForm
  );
  const [ibanError, setIbanError] = useState(null);
  const [done, setDone] = useState(false);
  const [userActive, setUserActive] = useState(distributor.isActive);

  const onUpdateValue = (e) => {
    const name = e.target.name;
    if (name === "companyName") {
      setCompanyName(e.target.value);
    } else if (name === "companyLegalForm") {
      setCompanyLegalForm(e.target.value);
    } else if (name === "password") {
      setPassword(e.target.value);
    } else if (name === "phoneCode") {
      setPhoneCode(e.target.value);
    } else if (name === "phone") {
      setPhone(e.target.value);
    } else if (name === "ibanNumber") {
      setIbanNumber(e.target.value);
      // if (ibanNumber.length > 5) {
      //   setIbanError("Bitte geben Sie eine gültige IBAN ein");
      // }else{
      //   setIbanError(null);
      // }
      
    }
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (ibanNumber.length < 18 || ibanNumber.length > 24) {
      dispatch(
        showAlert({
          alertType: "danger",
          alertContent: "Bitte geben Sie eine gültige IBAN-Nummer ein",
        })
      );
      setTimeout(() => {
        dispatch(hideAlert());
      }, 2000);
      return;
    } else {
      const updatedValues = {
        ...values,
        ibanNumber,
        isActive: userActive,
        password,
        phone: phoneCode !== "" && phone !== "" ? phoneCode + phone : "",
        companyName,
        companyLegalForm,
      };

      const response = await axiosInstance.put(
        `/api/admin/distributors/${values._id}`,
        { ...updatedValues },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      if (response.status === 200) {
        const result = await response.data.result;
        setDone(true);
        setTimeout(() => {
          setDone(false);
        }, 1500);
        window.location.reload();
      } else {
        console.log(response);
      }
    }
  };
  return (
    <>
      {done && (
        <div class="alert alert-success" role="alert">
          Händlerinformationen wurden erfolgreich aktualisiert
        </div>
      )}
      <div id="editSide" className="editSide shadow">
        <button
          onClick={onCloseEdit}
          className="btn btn-dark btn-sm position-absolute end-0 top-0 m-3"
        >
          x
        </button>
        <div className="row mt-5 px-3  ">
          <div className="col-11 mt-3 py-4 mx-auto rounded bg-white">
            {/* {ibanError && (
              <div className="bg-primary my-4 p-3 text-white rounded">
                {ibanError}
              </div>
            )} */}
            <h4 className="lead my-auto mb-3">
              Partnerinformationen bearbeiten
            </h4>
            <form action="" onSubmit={onSubmit}>
              <div className="mb-3">
                <label htmlFor="fullNameField" className="small-text mb-2">
                  Vollständiger Name
                </label>
                <input
                  type="text"
                  name="fullName"
                  placeholder="Full Name"
                  id="fullNameField"
                  className="form-control form-control-sm shadow-none"
                  value={values.fullName}
                  onChange={onUpdateValue}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="emailField" className="small-text mb-2">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  id="emailField"
                  className="form-control form-control-sm shadow-none"
                  value={values.email}
                  onChange={onUpdateValue}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="passwordField" className="small-text mb-2">
                  Passwort
                </label>
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  id="passwordField"
                  className="form-control form-control-sm shadow-none"
                  value={password}
                  onChange={onUpdateValue}
                />
              </div>

              <div className="mb-3">
                <div className="row">
                  <div className="col-4">
                    <label htmlFor="phoneField" className="small-text mb-2">
                      Landesvorwahl
                    </label>
                    <select
                      name="phoneCode"
                      id="phoneCodeField"
                      className={`form-select form-select-sm bg-light shadow-none w-100 mr-1`}
                      value={phoneCode}
                      defaultValue={phoneCode}
                      onChange={onUpdateValue}
                      required
                    >
                      {["+43", "+49"].map((code, index) => {
                        return (
                          <option
                            key={index}
                            value={code}
                            selected={code === values?.phone?.slice(0, 3)}
                          >
                            {code}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-8">
                    <label htmlFor="phoneField" className="small-text mb-2">
                      Telefon
                    </label>
                    <input
                      type="text"
                      name="phone"
                      placeholder="Phone"
                      id="phoneField"
                      className="form-control form-control-sm shadow-none"
                      value={phone}
                      onChange={onUpdateValue}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="commissionRateField"
                  className="small-text mb-2"
                >
                  Provisionssatz
                </label>
                <input
                  type="text"
                  placeholder="Commission Rate"
                  name="commissionRate"
                  id="commissionRateField"
                  className="form-control form-control-sm shadow-none"
                  value={values.commissionRate}
                  onChange={onUpdateValue}
                />
              </div>

              <p className="mb-3 lead">Zahlungsinformationen</p>
              <div className="mb-3">
                <label htmlFor="bankNameField" className="small-text mb-2">
                  Bank Name
                </label>
                <input
                  type="text"
                  name="bankName"
                  placeholder="Bank Name"
                  id="bankNameField"
                  className="form-control form-control-sm shadow-none"
                  value={values.bankName}
                  onChange={onUpdateValue}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="ibanNumberField" className="small-text mb-2">
                  Iban-Nummer
                </label>
                <input
                  type="number"
                  name="ibanNumber"
                  placeholder="IBAN Number"
                  id="ibanNumberField"
                  className="form-control form-control-sm shadow-none"
                  value={ibanNumber}
                  onChange={(e)=>{
                    
                    if(e.target.value.length >24){
                      setIbanError("Bitte geben Sie eine gültige IBAN ein")
                    }else{
                      setIbanError(null)
                    }
                    setIbanNumber(e.target.value)
                  }}
                />
                <p className="text-danger" style={{fontSize:"12px"}}>
                  {ibanError}
                </p>
              </div>

              <div className="mb-3">
                <label htmlFor="swiftCodeField" className="small-text mb-2">
                  SWIFT-Code
                </label>
                <input
                  type="text"
                  name="swiftCode"
                  placeholder="Swift Code"
                  id="swiftCodeField"
                  className="form-control form-control-sm shadow-none"
                  value={values.swiftCode}
                  onChange={onUpdateValue}
                />
              </div>

              <p className="mb-3 lead">Firmeninformation</p>
              <div className="mb-3">
                <label htmlFor="companyNameField" className="small-text mb-2">
                  Name der Firma
                </label>
                <input
                  type="text"
                  name="companyName"
                  placeholder="Company Name"
                  id="companyNameField"
                  className="form-control form-control-sm shadow-none"
                  value={companyName}
                  onChange={onUpdateValue}
                />
              </div>

              <div className="mb-3">
                <label
                  htmlFor="companyLegalFormField"
                  className="small-text mb-2"
                >
                  Gesellschaft Rechtsform
                </label>
                <select
                  name="companyLegalForm"
                  id="companyLegalFormField"
                  className="form-select form-select-sm border-1 border-info shadow-none"
                  value={companyLegalForm}
                  onChange={onUpdateValue}
                  required
                >
                  {companiesTypes &&
                    companiesTypes.map((com, index) => {
                      const { title, label } = com;
                      return (
                        <option defaultValue key={index} value={title}>
                          {label}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="mb-3">
                <div className="form-check">
                  <input
                    className="form-check-input shadow-none"
                    type="checkbox"
                    name="isActive"
                    value={userActive}
                    onChange={(e) => {
                      setUserActive(!userActive);
                    }}
                    checked={userActive === true}
                    id="isActiveField"
                  />
                  <label className="form-check-label" htmlFor="isActiveField">
                    Ist aktiv
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <input
                  type="submit"
                  value="Änderungen speichern"
                  className="btn btn-dark d-block btn-sm w-100"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditSide;
