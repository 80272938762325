import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { useSelector } from 'react-redux'
import axiosInstance from '../utils/axios'
import { getTokenFromLocalStorage } from '../utils/localStorage'

const initialState = {
  isLoading: false,
  categories: null,
}

export const getCategories = createAsyncThunk(
  'categories/get-all',
  async (token, thunkAPI) => {
    try {
      // call api url
      const response = await axios.get('http://localhost:5000/api/categories', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      const data = await response.data
      return { data }
    } catch (error) {
      const { message } = error.response.data
      console.log(`💥 ${message}`)
    }
  },
)

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    // get all categories status
    builder.addCase(getCategories.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.isLoading = false
      state.categories = action.payload
    })

    builder.addCase(getCategories.rejected, (state, action) => {
      state.isLoading = false
    })
  },
})

export default categorySlice.reducer
