import { useSelector, useDispatch } from "react-redux";
import formatNumber from "../utils/formatNumber";
import axiosInstance from "../utils/axios";
import React, { useState, useEffect } from "react";
import { BASE_URL } from "../utils/axios";
import { calculateAllStates } from "../utils/calculate";
import { getTokenFromLocalStorage } from "../utils/localStorage";
import { createOneContract } from "../features/contract/createOneContractSlice";
import { showAlert, hideAlert } from "../features/alertSlice";
import { useNavigate, useParams } from "react-router-dom";
import defaultDiscountsList, { sortDiscounts } from "../discounts";

function SummaryModal({ refId, isOpen, setIsOpen, nextStep, loadStep }) {
  const { total, machines, protectType } = useSelector(
    (store) => store.calculator
  );
  const {
    fullName,
    bankName,
    location,
    postcode,
    swiftCode,
    email,
    companyName,
    street,
    houseNumber,
    iban,
    phone,
    companyLegalForm,
    //country,
    insureName,
    insureType,
    phoneCode,
    notificationBy,
  } = useSelector((store) => store.customer);

  const { description, country, paymentPeriod } = useSelector(
    (store) => store.calculator
  );
  const { isVisible } = useSelector((store) => store.alert);
  const [contractCreated, setContractCreated] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { id } = useParams();

  const contractData = {
    machines: machines.map((m) => {
      return {
        machine: m?.machine,
        category: m?.category,
        productionYear: m?.productionYear,
        brand: m?.brand,
        serialNumber: m?.serialNumber,
        insuranceStartDate: m?.insuranceStartDate,
        duration: m?.duration,
        machineType: m?.machineType,
        mainDue: m?.mainDue,
        description: m?.description,
        purchasePrice: Number(m.purchasePrice),
        extraExpenses: m.extraPrices,
        deductible: Number(m.deductible),
        protectType: m.protectType,
        protectFactor: Number(m.protectFactor),
        discountsOptions: m.discountsOptions,
        paymentPeriod: m.paymentPeriod,
        price: Number(m.price),
        extraPrices: m?.extraPrices,
        includedPolicyholder: m.includedPolicyholder,
      };
    }),
    customerInformation: {
      fullName,
      email,

      phone: { phoneCode, phone },
      company: {
        name: companyName,
        legalForm: companyLegalForm,
      },
      address: {
        street,
        houseNumber,
        postcode,
        location,
      },
    },
    paymentDetails: {
      bankName,
      swiftCode,
      ibanNumber: iban,
    },
    total,
    taxRate: country === "germany" ? 19 : 11.4,
    insureName: insureName,
    insureType: insureType,
    debtFinancing: "",
    country: country,
    description: description,
    notificationBy: notificationBy,
    refId: refId,
  };

  const [savingPdf, setSavingPdf] = useState(false);
  const [savingContract, setSavingContract] = useState(false);

  let allStats = [];
  const [total500, setTotal500] = useState(0);
  const [total1000, setTotal1000] = useState(0);
  const [total2500, setTotal2500] = useState(0);
  const [total5000, setTotal5000] = useState(0);
  const [totalText, setTotalText] = useState("");

  if (machines) {
    for (let machine of machines) {
      if (machine.machineObj) {
        const id = machine.machine;
        for (let obj of machine?.machineObj?.discounts) {
          const { fullProduction, hull, mtv, deductible } = obj;

          const fullProductionValue = calculateAllStates(
            paymentPeriod,
            machine,
            country,
            machines,
            fullProduction,
            deductible
          );
          const hullValue = calculateAllStates(
            paymentPeriod,
            machine,
            country,
            machines,
            hull,
            deductible
          );
          const mtvValue = calculateAllStates(
            paymentPeriod,
            machine,
            country,
            machines,
            mtv,
            deductible
          );

          allStats.push({
            ...fullProductionValue,
            protectType: "fullProduction",
            _id: id,
            brand: machine.brand,
            randId: machine.id,
          });
          allStats.push({
            ...hullValue,
            protectType: "hull",
            _id: id,
            brand: machine.brand,
            randId: machine.id,
          });
          allStats.push({
            ...mtvValue,
            protectType: "mtv",
            _id: id,
            brand: machine.brand,
            randId: machine.id,
          });
        }
      }
    }
  }

  const onCreateContract = async () => {
    try {
      setSavingContract(true);

      if (total > 25) {


        const newContract = await dispatch(
          createOneContract({
            ...contractData,
            offerId: pdfFileName.split(".")[0],
          })
        );
        const newContractId = await newContract.payload?.result?._id;
        if (newContractId) {
          dispatch(
            showAlert({
              alertType: "success",
              alertContent: "neuer Antrag wurde erfolgreich erstellt",
            })
          );
          setTimeout(() => {
            dispatch(hideAlert());
          }, 1500);
          setSavingContract(false);
          setContractCreated(true);
          console.log(newContractId);
          navigate(`/calculator/contracts/edit/${newContractId}`);
        }
      } else {
        console.log({ paymentPeriod });

        dispatch(
          showAlert({
            alertType: "warning",
            alertContent: "Der Mindestbetrag beträgt 25 €",
          })
        );
        setTimeout(() => {
          dispatch(hideAlert());
        }, 1500);
      }
    } catch (error) {
      setSavingContract(false);
      dispatch(
        showAlert({
          alertType: "danger",
          alertContent: error.message,
        })
      );

      setTimeout(() => {
        dispatch(hideAlert());
      }, 2000);
    }
  };

  const [pdfFileName, setpdfFileName] = useState("");

  const onOfferSavePDF = async () => {
    if (total < 25 && paymentPeriod === "per-month") {
      dispatch(
        showAlert({
          alertType: "warning",
          alertContent: `Bitte überprüfen Sie die Versicherungssumme. (Mindestprämie: monatlich 25€)`,
        })
      );
      setTimeout(() => {
        dispatch(hideAlert());
      }, 3000);
      return;
    } else if (total < 75 && paymentPeriod === "quarterly") {
      dispatch(
        showAlert({
          alertType: "warning",
          alertContent: `Bitte überprüfen Sie die Versicherungssumme. (Mindestprämie: Vierteljährlich 75€)`,
        })
      );
      setTimeout(() => {
        dispatch(hideAlert());
      }, 3000);
      return;
    } else if (total < 150 && paymentPeriod === "half-yearly") {
      dispatch(
        showAlert({
          alertType: "warning",
          alertContent: `Bitte überprüfen Sie die Versicherungssumme. (Mindestprämie: Halbjährlich 150€)`,
        })
      );
      setTimeout(() => {
        dispatch(hideAlert());
      }, 3000);
      return;
    } else if (total < 300 && paymentPeriod === "yearly") {
      dispatch(
        showAlert({
          alertType: "warning",
          alertContent: `Bitte überprüfen Sie die Versicherungssumme. (Mindestprämie: Jährlich 300€)`,
        })
      );
      setTimeout(() => {
        dispatch(hideAlert());
      }, 3000);
      return;
    } else {
      setSavingPdf(true);
      const token = getTokenFromLocalStorage();

      const contractCountRes = await axiosInstance.get("/api/admin/contracts", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const contractCountData = await contractCountRes.data.result;
      const contractCount = await contractCountData.length;

      const response = await axiosInstance.post(
        "/api/docusign/savePdf",
        {
          machines: contractData?.machines,
          target: "offer",
          offerNo: contractCount + 3200,
          customer: {
            fullName,
            email,
            phone,
            company: {
              companyName,
            },
            address: {
              street,
              houseNumber,
              postcode,
              location,
            },
          },
          country,
          taxRate: country === "germany" ? 19 : 11.4,
          companyLegalForm,
          paymentPeriod,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = await response.data;
      const fileName = await result.result.fileName;
      if (fileName) {
        setpdfFileName(fileName);
        const el = await document.createElement("a");
        el.href = `${BASE_URL}/api/docusign/downloadPdf/${fileName.split(".")[0]
          }`;
        el.download = fileName;
        el.click();
        el.remove();
      }
      setSavingPdf(false);
    }
  };

  const removeContract = async (id) => {
    const response = await axiosInstance.delete(
      `/api/distributor/contracts/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    const result = await response?.data;
  };
  const { id } = useParams();
  const onSaveOfferContract = async () => {
    if (pdfFileName) {
      if (id) {
        await removeContract(id);
        await onCreateContract();
      } else {
        await onCreateContract();
      }
    } else {
      setSavingContract(false);
      dispatch(
        showAlert({
          alertType: "danger",
          alertContent: "Bitte erstellen Sie zuerst eine Angebotsunterlage",
        })
      );

      setTimeout(() => {
        dispatch(hideAlert());
      }, 1500);
    }
    setSavingContract(false);
  };

  useEffect(() => {
    let pricesArray = machines.map((machine) => {
      return allStats
        .filter((s) => s._id === machine.machine)
        .filter((s) => s.protectType === protectType)
        .map((s) => {
          return { price: s.price, deductible: s.deductible };
        });
    });

    let total500 = 0;
    let total1000 = 0;
    let total2500 = 0;
    let total5000 = 0;
    for (let arr of pricesArray) {
      let deductible500 = arr
        .filter((i) => i.deductible === 500)
        .map((i) => i.price)[0];

      let deductible1000 = arr
        .filter((i) => i.deductible === 1000)
        .map((i) => i.price)[0];
      let deductible2500 = arr
        .filter((i) => i.deductible === 2500)
        .map((i) => i.price)[0];
      let deductible5000 = arr
        .filter((i) => i.deductible === 5000)
        .map((i) => i.price)[0];

      total500 += deductible500;
      total1000 += deductible1000;
      total2500 += deductible2500;
      total5000 += deductible5000;
    }
    setTotal500(total500);
    setTotal1000(total1000);
    setTotal2500(total2500);
    setTotal5000(total5000);

    let totalTextValue = [total500, total1000, total2500, total5000]
      .map((i) => {
        if (i) {
          return formatNumber(i);
        }
      })
      .join(" | ");

    setTotalText(totalTextValue.slice(0, totalTextValue.length - 2));
  });

  return (
    <>
      {machines && machines.length > 0 && (
        <div
          className={`modal  fade ${isOpen === true ? "show" : ""}`}
          tabIndex="-1"
          aria-labelledby="summaryModalLabel"
          aria-hidden="true"
          style={{
            display: isOpen === true ? "block" : "",
            width: "100vw",
          }}
        >
          {/* <div className="bg-overlay-blur"></div> */}
          {isOpen === true && (
            <div className="modal-dialog modal-lg ">
              <div className="modal-content bg-secondary">
                <div className="modal-header border-0">
                  <h1 className="modal-title fs-5" id="summaryModalLabel">
                    Dein Angebot
                  </h1>
                  <button
                    type="button"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  {machines &&
                    machines?.map((machine, index) => {
                      let photo = require(`../assets/categoryImages/${machine?.categoryObj?.photo}`);

                      return (
                        <div key={index} className="machine-section my-4">
                          <p className="my-auto ms-0 fs-4 w-25  pb-2 text-dark text-with-border">
                            Maschine {index + 1}
                          </p>
                          <p className="lead fs-5 text-dark mt-3">
                            {machine?.categoryObj?.title} -{" "}
                            {machine?.machineObj?.title} -{" "}
                            {machine?.machineType}
                          </p>
                          <div className="contact-info d-flex w-100">
                            <div className="img-holder w-25">
                              <img
                                src={photo}
                                alt=""
                                className="img-fluid rounded-1 border border-1 border-dark"
                              />
                            </div>
                            <div className="details-holder ms-4 w-50">
                              <p className="my-auto text-dark lead fs-6">
                                <span id="brand" className="me-2 fw-bold">
                                  Hersteller :{" "}
                                </span>
                                <span id="brand" className="text-dark">
                                  {machine?.brand}
                                </span>
                              </p>
                              <p className="my-auto text-dark lead fs-6">
                                <span
                                  id="productionYear"
                                  className="me-2 fw-bold"
                                >
                                  Baujahr :{" "}
                                </span>
                                <span id="productionYear" className="text-dark">
                                  {machine?.productionYear}
                                </span>
                              </p>

                              <p className="my-auto text-dark lead fs-6">
                                <span
                                  id="serialNumber"
                                  className="me-2 fw-bold"
                                >
                                  Seriennummer :{" "}
                                </span>
                                <span id="serialNumber" className="text-dark">
                                  {machine?.serialNumber}
                                </span>
                              </p>
                            </div>
                          </div>

                          <hr />

                          <div className="d-flex justify-content-between">
                            <p className="my-auto text-dark lead fs-6">
                              Kaufpreis
                            </p>
                            <p className="my-auto text-dark lead fs-6 value-text">
                              {formatNumber(machine?.purchasePrice)}
                            </p>
                          </div>

                          {machine?.extraPrices &&
                            machine?.extraPrices.map((item, index) => {
                              return (
                                <div key={index}>
                                  <div className="d-flex justify-content-between">
                                    <p className="my-auto text-dark lead fs-6">
                                      {item?.title}:
                                    </p>
                                    <p className="my-auto text-dark lead fs-6 value-text">
                                      {formatNumber(item?.price)}
                                    </p>
                                  </div>
                                </div>
                              );
                            })}

                          <hr />
                          <div className="d-flex justify-content-between">
                            <p className="my-auto text-dark lead fs-6">
                              Versicherungssumme:
                            </p>
                            <p className="my-auto text-dark lead fs-6 value-text">
                              {formatNumber(
                                Number(machine?.purchasePrice) +
                                machine?.extraPrices
                                  .map((p) => Number(p.price))
                                  .reduce(
                                    (acc, current) =>
                                      Number(acc) + Number(current),
                                    0
                                  )
                              )}
                            </p>
                          </div>
                          <hr />
                          <div className="d-flex justify-content-between">
                            <p className="my-auto text-dark lead fs-6">
                              Selbstbehalt:
                            </p>
                            <p className="my-auto text-dark lead fs-6 value-text">
                              {allStats
                                .filter((s) => s.randId === machine.id)
                                .filter(
                                  (s) => s.protectType === machine.protectType
                                )
                                .map((s) => {
                                  if (s.deductible !== 500) {
                                    return (
                                      s.deductible.toString().slice(0, 1) +
                                      "." +
                                      s.deductible
                                        .toString()
                                        .slice(
                                          1,
                                          s.deductible.toString().length
                                        ) +
                                      " €"
                                    );
                                  } else {
                                    return s.deductible.toString() + " €";
                                  }
                                })
                                .join(" | ")}
                            </p>
                          </div>
                          <hr />

                          <div className="d-flex justify-content-between">
                            <p className="my-auto text-dark lead fs-6">
                              Prämie:
                            </p>
                            <p className="my-auto text-dark lead fs-6 value-text">
                              {allStats
                                .filter((s) => s.randId === machine.id)
                                .filter(
                                  (s) => s.protectType === machine.protectType
                                )
                                .map((s) => formatNumber(s.price))
                                .join(" | ")}
                            </p>
                          </div>
                          <hr />
                          <div className="d-flex justify-content-between">
                            <p className="my-auto text-dark lead fs-6">
                              Produkt:
                            </p>
                            <p className="my-auto text-dark lead fs-6 value-text">
                              {machine?.protectType === "fullProduction"
                                ? "Vollschutz"
                                : machine?.protectType === "hull"
                                  ? "Kaskoschutz"
                                  : machine?.protectType === "mtv"
                                    ? "MTV/Teilschutz"
                                    : "Vollschutz"}
                            </p>
                          </div>
                          <hr />
                          <p className="my-auto text-dark lead fs-6">
                            Rabatte & Zuschläge:
                          </p>
                          {sortDiscounts(defaultDiscountsList, machine?.discountsOptions).map((option, index) => {
                            return (
                              <div
                                key={index}
                                className="d-flex justify-content-between align-items-center my-2"
                              >
                                <p>{option.label}</p>
                                <p>{option.value} %</p>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}

                  <hr className="border border-primary border-1" />

                  <div className="row row-cols-md-2 d-flex justify-content-between mt-4">
                    <div className="col-12 col-md-6 col-lg-6 customer-information">
                      <p className="lead text-dark fs-6 text-with-border w-75 text-uppercase">
                        Persönliche Informationen
                      </p>
                      <p className="my-auto lead fs-6 fw-bold mt-4 text-dark">
                        {fullName}
                      </p>
                      <p className="my-auto lead fs-6 fw-bold text-dark fst-italic">
                        {companyName} {companyLegalForm}
                      </p>
                      <p className="my-auto lead fs-6 text-dark fst-italic">
                        {street} {houseNumber}
                      </p>
                      <p className="my-auto lead fs-6 text-dark fst-italic">
                        {postcode} {location}
                      </p>
                      <p className="my-auto lead fs-6 text-dark fst-italic">
                        {phone && <>Tel: {phoneCode + phone}</>}
                      </p>
                      <p className="my-auto lead fs-6 text-dark fst-italic">
                        {email && <>Mail: {email}</>}
                      </p>
                    </div>
                    <div className="col-12 col-md-6">
                      <p className="lead text-dark fs-6 text-with-border payment-information  w-75 text-uppercase">
                        ZahlungsWEISE
                      </p>
                      <p className="my-auto lead fs-6 fw-bold text-dark mt-4 fst-italic">
                        {companyName} {companyLegalForm}
                      </p>
                      <p className="my-auto lead fs-6  text-dark fst-italic">
                        {bankName}
                      </p>

                      <p className="my-auto lead fs-6 text-dark fst-italic">
                        {iban.slice(0, 18)}*** ****
                      </p>
                      <p className="my-auto lead fs-6 text-dark fst-italic">
                        {swiftCode}
                      </p>
                      <p className="my-auto lead fs-6 text-dark fst-italic">
                        {location}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="modal-footer mt-4 border-0">
                  <div className="d-flex  w-100">
                    <button
                      className="btn btn-dark btn-sm w-50 mx-1 text-info d-flex align-items-center justify-content-center"
                      onClick={onOfferSavePDF}
                      disabled={savingPdf}
                    >
                      {savingPdf ? (
                        <>
                          <div class="lds-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </>
                      ) : (
                        <>
                          <i className="bi bi-download m-2"></i>
                          Angebot herunterladen
                        </>
                      )}
                    </button>

                    <button
                      type="button"
                      className="btn btn-dark btn-sm w-50 mx-1 text-info"
                      onClick={(e) => {
                        setpdfFileName(null);
                        loadStep(1);

                        setIsOpen(false);
                      }}
                    >
                      <i className="bi bi-arrow-left me-2"></i>
                      <span className="me-2">Vorheriger Schritt</span>
                    </button>

                    <button
                      type="button"
                      className={
                        isVisible
                          ? "btn btn-dark btn-sm w-50 mx-1 text-info d-flex align-items-center justify-content-center err-shake"
                          : "btn btn-dark btn-sm w-50 mx-1 text-info d-flex align-items-center justify-content-center"
                      }
                      // className="btn btn-dark btn-sm w-50 mx-1 text-info d-flex align-items-center justify-content-center"
                      disabled={savingContract}
                      onClick={onSaveOfferContract}
                    >
                      {savingContract ? (
                        <>
                          <div class="lds-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </>
                      ) : (
                        <>
                          <span className="me-2">Nächster Schritt</span>
                          <i className="bi bi-arrow-right"></i>
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default SummaryModal;
