import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getUserFromLocalStorage } from "../utils/localStorage";
import { useDispatch } from "react-redux";
import { hideAlert, showAlert } from "../features/alertSlice";
import axiosInstance from "../utils/axios";

const initialValues = {
  fullName: "",
  email: "",
  phone: "",
  messageContent: "",
  validEmail: null,
  validPhone: null,
  validFullName: null,
  privacyPolicy: false,
};

function SupportFormAccordion({ emailFormShow, setEmailFormShow }) {
  const navigate = useNavigate();
  const [values, setValues] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);
  const user = getUserFromLocalStorage();
  const onValueChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "email") {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!value || value === "" || !value.match(pattern)) {
        setValues({
          ...values,
          email: value,
          isValid: false,
          validEmail: {
            field: "email",
            type: "invalid",
            text: `Bitte geben Sie eine gültige E-Mail-Adresse ein`,
          },
        });

        return;
      }
    } else {
      setValues({
        ...values,
        email: value,
        isValid: true,
        validEmail: null,
      });
    }

    if (name === "phone") {
      if (!value || value === "") {
        setValues({
          ...values,
          phone: value,
          isValid: false,
          validPhone: {
            field: "phone",
            type: "invalid",
            text: `Bitte geben Sie eine gültige Telefonnummer ein`,
          },
        });
        return;
      }
    } else {
      setValues({
        ...values,
        email: value,
        isValid: true,
        validPhone: null,
      });
    }

    if (name === "fullName") {
      if (!value || value === "") {
        setValues({
          ...values,
          fullName: value,
          isValid: false,
          validFullName: {
            field: "fullName",
            type: "invalid",
            text: `Bitte tragen Sie Ihren vollen Namen ein`,
          },
        });
        return;
      }
    } else {
      setValues({
        ...values,
        email: value,
        isValid: true,
        validFullName: null,
      });
    }

    setValues({
      ...values,
      [name]: value,
      isValid: true,
      validEmail: null,
      validPhone: null,
      validFullName: null,
    });
  };
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!values.email || !values.fullName || !values.phone) {
      dispatch(
        showAlert({
          alertType: "danger",
          alertContent: "Bitte fülle die notwendigen Felder aus",
        })
      );
      setTimeout(() => {
        dispatch(hideAlert());
      }, 2000);
      return;
    }

    if (!values.privacyPolicy) {
      dispatch(
        showAlert({
          alertType: "warning",
          alertContent: "Bitte akzeptieren Sie die Bedingungen",
        })
      );
      setTimeout(() => {
        dispatch(hideAlert());
      }, 2000);
      return;
    }
    setIsLoading(true);
    const response = await axiosInstance.post(
      `/api/mail/support`,
      { ...values },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    setEmailFormShow(false);
    dispatch(
      showAlert({
        alertType: "success",
        alertContent: "Mail erfolgreich gesendet",
      })
    );
    setValues({
      fullName: "",
      email: "",
      phone: "",
      messageContent: "",
      validEmail: null,
      validPhone: null,
      validFullName: null,
      privacyPolicy: false,
    });
    setTimeout(() => {
      dispatch(hideAlert());
    }, 2000);
    setIsLoading(false);
  };
  return (
    <>
      <div className={`emailform container ${emailFormShow && "open"}`}>
        <div className="col-12">
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setEmailFormShow(false);
            }}
          ></button>
          <div className="row page-content">
            <div className="col-12 p-3">
              <div className="row">
                <div className="col-12 col-md-8 col-lg-8 mx-auto">
                  <div className="alert-content">
                    Diese Maschine deines Kunden ist leider zu alt für unseren
                    Rechner. Bitte starte einen individuellen Auftrag oder wähle
                    eine andere Maschine.
                  </div>
                  <p className="text-left">
                    Erhalten Sie unsere Kontakt- und Support-Services, indem Sie
                    eine E-Mail an unser Team senden
                  </p>
                  <form action="" noValidate onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <input
                        type="text"
                        name="fullName"
                        id="fullNameField"
                        className="form-control form-control-sm bg-light shadow-none"
                        placeholder="vollständiger Name *"
                        minLength={3}
                        value={values?.fullName}
                        onChange={onValueChange}
                      />

                      <p
                        className="text-sm text-danger"
                        style={{ fontSize: 12 }}
                      >
                        {values?.isValid === false &&
                          values?.validFullName?.text}
                      </p>
                    </div>

                    <div className="mb-3">
                      <input
                        type="email"
                        name="email"
                        pattern='^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
                        id="emailField"
                        className="form-control form-control-sm bg-light shadow-none"
                        placeholder="E-Mailadresse *"
                        minLength={3}
                        value={values?.email}
                        onChange={onValueChange}
                      />
                      <p
                        className="text-sm text-danger"
                        style={{ fontSize: 12 }}
                      >
                        {values?.isValid === false && values?.validEmail?.text}
                      </p>
                    </div>

                    <div className="mb-3">
                      <input
                        type="text"
                        name="phone"
                        pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im"
                        id="phoneField"
                        className="form-control form-control-sm bg-light shadow-none"
                        placeholder="Telefon *"
                        minLength={3}
                        value={values.phone}
                        onChange={onValueChange}
                      />
                      <p
                        className="text-sm text-danger"
                        style={{ fontSize: 12 }}
                      >
                        {values?.isValid === false && values?.validPhone?.text}
                      </p>
                    </div>

                    <div className="mb-3">
                      <textarea
                        onChange={onValueChange}
                        name="messageContent"
                        id="messageContentField"
                        cols="30"
                        rows="6"
                        className="form-control shadow-none bg-light"
                        placeholder="Ihre Nachricht"
                        value={values.messageContent}
                      ></textarea>
                      <p
                        className="text-sm text-danger"
                        style={{ fontSize: 12 }}
                      >
                        {values?.isValid === false && values?.validPhone?.text}
                      </p>
                    </div>

                    <div className="mb-3">
                      <div className="form-check">
                        <input
                          className="form-check-input shadow-none"
                          type="checkbox"
                          checked={values.privacyPolicy}
                          id="privacyPolicyField"
                          name="privacyPolicy"
                          onChange={(e) => {
                            setValues({
                              ...values,
                              privacyPolicy: !values.privacyPolicy,
                            });
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="privacyPolicyField"
                        >
                          Hiermit akzeptiere ich die auf dieser Webseite
                          geltende
                          <a
                            href="http://versitech.at/datenschutz"
                            className="ms-2"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Datenschutzerklärung
                          </a>
                        </label>
                      </div>
                    </div>

                    {/* <input
                      type="submit"
                      value="senden"
                      className="btn btn-dark btn-sm"
                    /> */}
                    <button
                      type="submit"
                      className="btn btn-dark btn-sm w-25 d-flex justify-content-center align-items-center"
                    >
                      {isLoading ? (
                        <>
                          <div className="lds-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </>
                      ) : (
                        "senden"
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SupportFormAccordion;
