import { useNavigate } from "react-router-dom";
import { logoutUser } from "../features/authSlice";
import { useDispatch } from "react-redux";

function PermissionError() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const backToHome = async () => {
    await dispatch(logoutUser());
    navigate("/");
  };
  return (
    <>
      <div className="container">
        <div className="row">
          <div
            className="col-12  col-md-8 mx-auto d-flex flex-col align-items-center justify-content-center"
            style={{
              height: "100vh",
            }}
          >
            <div className="card bg-light p-5 text-center">
              <h3 className="mb-5">
                Berechtigungsfehler, Sie haben keine Berechtigungen zum
                Fortfahren
              </h3>
              <h1 className="display-1">403</h1>
              <button
                onClick={backToHome}
                className="btn btn-lg btn-primary w-100 mt-5"
              >
                zurück zum Startbildschirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PermissionError;
