import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axiosInstance from "../../../utils/axios";
import Loading from "../../../components/Loading";
import ContractItem from "../../../components/ContractItem";
import ContractList from "../../../components/ContractList";


function AdminOneDistributorContractPage() {
  let [loading, setLoading] = useState(true);
  let [records, setRecords] = useState([]);
  const { id } = useParams();
  let get = async () => {
    const response = await axiosInstance.get(
      `/api/admin/distributors/${id}/contracts`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    const data = await response.data;
    setRecords(data.result);
    setLoading(false);
  };
  let searchFunc = (e) => {
    if (e.target.value.length > 2) {
      let newData = records.filter(
        (x) =>
          x.customer.company.name
            .toUpperCase()
            .includes(e.target.value.toUpperCase()) ||
          x.customer.fullName
            .toUpperCase()
            .includes(e.target.value.toUpperCase()),
      )
      setRecords(newData)
    } else {
      get()
    }
  }
  useEffect(() => {
    get();
  }, []);
  
  if (records) {
    return (
      <>
        <div className="container">
          <div className="col-12">
            <div className="d-flex">
              <div className="me-auto page-title-holder">
                <h2 className="section-title text-dark ">Deine Anträge</h2>
                {/* <p>
                  <span>Alle ({data.length})</span> | Offene Anträge (0) |
                  Abgeschlossen ({data.length})
                </p> */}
              </div>
              <div className="search">
                <div className="input-group">
                  <span className="input-group-text">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25.378"
                      height="25.506"
                      viewBox="0 0 25.378 25.506"
                    >
                      <g
                        id="Ellipse_14"
                        data-name="Ellipse 14"
                        transform="translate(0 11.314) rotate(-45)"
                        fill="none"
                        stroke="#344167"
                        strokeWidth="1"
                      >
                        <circle cx="8" cy="8" r="8" stroke="none" />
                        <circle cx="8" cy="8" r="7.5" fill="none" />
                      </g>
                      <g
                        id="Ellipse_15"
                        data-name="Ellipse 15"
                        transform="translate(2.552 11.441) rotate(-45)"
                        fill="none"
                        stroke="#344167"
                        strokeWidth="1"
                      >
                        <circle cx="6.195" cy="6.195" r="6.195" stroke="none" />
                        <circle cx="6.195" cy="6.195" r="5.695" fill="none" />
                      </g>
                      <g
                        id="Rectangle_82"
                        data-name="Rectangle 82"
                        transform="translate(14.771 17.728) rotate(-45)"
                        fill="none"
                        stroke="#344167"
                        strokeWidth="1"
                      >
                        <rect width="4" height="11" stroke="none" />
                        <rect
                          x="0.5"
                          y="0.5"
                          width="3"
                          height="10"
                          fill="none"
                        />
                      </g>
                    </svg>
                  </span>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Suche"
                    aria-label="Search"
                    aria-describedby="basic-search"
                    onChange={searchFunc}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* to do  */}
          {/* filter section */}
          <div className="col-12">
          {loading ? (
                <Loading />
              ) : (
                <ContractList records={records}></ContractList>
              )}
          </div>
        </div>
      </>
    )
  }
}

export default AdminOneDistributorContractPage;
