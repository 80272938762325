import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import axiosInstance from "../utils/axios";
import { BASE_URL } from "../utils/axios";
function ContractItem({
  contract,
  setRemoveContractId,
  setModalValue,
  setModalContractId,
  setOpenModal,
  updateContractState,
  modalContractId,
  isCompleted,
  setIsCompleted,
}) {
  const [loading, setLoading] = useState(false);
  const downloadClosedContract = async () => {
    setLoading(true);
    const response = await axiosInstance.post(
      "/api/docusign/savePdf",
      {
        target: "contractClosed",
        contractId: contract._id,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    const result = await response.data;
    const fileName = result.result.fileName;
    if (fileName) {
      const el = document.createElement("a");
      el.href = `${BASE_URL}/api/docusign/downloadPdf/${
        fileName.split(".")[0]
      }`;
      el.download = fileName;
      el.click();
      el.remove();
    }
    setLoading(false);
    
  };

  const dot = (color = "transparent") => ({
    alignItems: "center",
    display: "flex",

    ":before": {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: "block",
      marginRight: 8,
      height: 10,
      width: 10,
      minWidth: 10,
    },
  });

  
  const colourStyles = {
    dropdownIndicator: (styles) => ({
      ...styles,
      color: "#344167",
      width: "30px",
    }),
    indicatorSeparator: (styles) => ({ ...styles, display: "none !important" }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "15px",
      color: "#30426A",
    }),
    control: () => ({
      // ...styles,
      fontSize: "15px",
      display: "flex",
      border: "2px solid #9ABDD2",
      borderRadius: "10px",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        ...dot(data.color),
        paddingLeft: "25px",
        ":last-of-type": {
          borderBottom: "none",
        },
        ":hover": {
          fontWeight: "bold",
          background: "rgba(30, 183, 243, 0.3)",
        },
      };
    },
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
  };

  const selectOptions =
    contract.state !== "OfferCreated"
      ? [
          {
            id: "2",
            label: "Antrag erstellt",
            color: "#F1DE00",
            value: "ContractAccepted",
          },
          {
            id: "3",
            label: "Antrag unterschrieben",
            color: "#07B162",
            value: "ContractUnderReview",
          },
          {
            id: "4",
            label: "Antrag abgesch...",
            color: "#919191",
            value: "ContractClosed",
          },
        ]
      : [
          {
            id: "1",
            label: "Angebot erstellt",
            color: "#AE1228",
            value: "OfferCreated",
          },
          {
            id: "2",
            label: "Antrag erstellt",
            color: "#F1DE00",
            value: "ContractAccepted",
          },
          {
            id: "3",
            label: "Antrag unterschrieben",
            color: "#07B162",
            value: "ContractUnderReview",
          },
        ];

  return (
    <>
      <li className="list-group-item list-item py-4 w-100 container">
        <div className="row mv-100 mx-0">
          <div className="col-2 text-left">
            <span>
              {contract?.customer?.fullName} | {contract?.customer?.company?.name}
            </span>
          </div>
          <div className="col-2 text-left">
            <span>
              {contract?.category},{" "}
              {contract?.machine?.length > 60
                ? `${contract?.machine.substring(60)}...`
                : contract?.machine}
            </span>
          </div>

          <div className="col-3">
            <span className="my-auto text-left d-flex justify-content-start align-items-start">
              Auftrag #{contract?._id.slice(0, 8)}
            </span>
          </div>

          <div className="col-5 pr-0">
            <div className="button-area">
              <div className="d-flex align-items-center  justify-content-center">
                {contract.state === "OfferCreated" && (
                  <Link
                    to={`/calculator/update/${contract?._id}`}
                    //to={`/calculator/contracts/update-full/${contract?._id}`}
                    className="btn edit-btn"
                  >
                    <span className="bear-icon">
                      <svg
                        stroke="currentColor"
                        fill="none"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                      </svg>
                    </span>
                    <span className="bear-text">Bearbeiten</span>
                  </Link>
                )}
                {contract.state === "ContractClosed" && (
                  <button
                    onClick={downloadClosedContract}
                    className="btn btn-light btn-sm"
                  >
                    {loading ? (
                      <>
                        <div class="lds-ring">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </>
                    ) : (
                      <>
                        <svg
                          id="Group_253"
                          data-name="Group 253"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="21.494"
                          height="22.455"
                          viewBox="0 0 21.494 22.455"
                        >
                          <g
                            id="Group_252"
                            data-name="Group 252"
                            clipPath="url(#clipPath)"
                          >
                            <path
                              id="Path_350"
                              data-name="Path 350"
                              d="M1.492,267.578c-.124-.04-.251-.075-.373-.121a1.7,1.7,0,0,1-1.113-1.563C0,264.286,0,262.679,0,261.071a.7.7,0,1,1,1.4,0c0,1.535,0,3.069,0,4.6,0,.4.093.5.484.5H19.6c.391,0,.484-.1.484-.5q0-2.291,0-4.582a.684.684,0,0,1,.423-.68.606.606,0,0,1,.729.12.865.865,0,0,1,.24.513q.026,2.433,0,4.867a1.715,1.715,0,0,1-1.318,1.612c-.056.014-.11.033-.166.05Z"
                              transform="translate(0 -245.122)"
                              fill="#344167"
                              stroke="#344167"
                              strokeWidth="0.5"
                            />
                            <path
                              id="Path_351"
                              data-name="Path 351"
                              d="M101.832,0a.862.862,0,0,1,.532.905q-.014,6.994-.006,13.989v.3c.089-.082.147-.132.2-.186q1.473-1.472,2.945-2.946a.714.714,0,0,1,.752-.229.7.7,0,0,1,.354,1.1,1.89,1.89,0,0,1-.164.176L102.26,17.29a.728.728,0,0,1-1.206,0Q98.93,15.166,96.8,13.043a.722.722,0,0,1-.221-.737.657.657,0,0,1,.514-.488.74.74,0,0,1,.727.255q1.454,1.461,2.913,2.916c.057.057.1.124.154.186l.063-.021v-.241q0-7.005-.006-14.011a.856.856,0,0,1,.532-.9Z"
                              transform="translate(-90.91)"
                              fill="#344167"
                              stroke="#344167"
                              strokeWidth="0.5"
                            />
                          </g>
                        </svg>
                      </>
                    )}
                  </button>
                )}
              </div>
              <div className="d-flex align-items-center  justify-content-center">
                {contract.state === "OfferCreated" && (
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#removeContractModal"
                    className="btn delete-btn btn-sm m-0 small-text"
                    onClick={(e) => {
                      setRemoveContractId(contract?._id);
                    }}
                  >
                    <svg
                      id="Group_96"
                      data-name="Group 96"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="20"
                      height="20"
                      viewBox="0 0 23.04 27.705"
                    >
                      <g
                        id="Group_92"
                        data-name="Group 92"
                        clipPath="url(#clipPath)"
                      >
                        <path
                          id="Path_94"
                          data-name="Path 94"
                          d="M9.786,0h3.456a.817.817,0,0,0,.148.048,5.751,5.751,0,0,1,4.859,4.32c.052.2.134.25.325.249,1.071-.008,2.142,0,3.213,0a1.164,1.164,0,0,1,1.059.532A1.1,1.1,0,0,1,22.9,6.3a1.1,1.1,0,0,1-1,.622c-.383.01-.767,0-1.162,0v.391q0,7.249,0,14.5a5.776,5.776,0,0,1-4.3,5.7c-.3.081-.612.129-.919.192H7.518a13.894,13.894,0,0,1-1.481-.379,5.748,5.748,0,0,1-3.743-5.51q-.005-7.263,0-14.526V6.926c-.41,0-.786.007-1.162,0a1.156,1.156,0,1,1,.083-2.309q1.62-.01,3.24,0c.191,0,.271-.046.326-.248A5.618,5.618,0,0,1,8.237.424,13.135,13.135,0,0,1,9.786,0M4.6,6.94v.276q0,7.319,0,14.637A3.439,3.439,0,0,0,8.108,25.39q3.4.013,6.8,0a3.444,3.444,0,0,0,3.514-3.556q0-7.305,0-14.61V6.94ZM15.891,4.6a.776.776,0,0,0-.018-.114A3.414,3.414,0,0,0,12.9,2.327c-.916-.026-1.836-.052-2.75.005A3.323,3.323,0,0,0,7.184,4.423a.953.953,0,0,0-.042.178Z"
                          transform="translate(0)"
                          fill="#30426a"
                        />
                        <path
                          id="Path_95"
                          data-name="Path 95"
                          d="M111.669,164.623c0-1.153,0-2.306,0-3.459a1.136,1.136,0,0,1,.988-1.138,1.123,1.123,0,0,1,1.235.741,1.564,1.564,0,0,1,.08.5q.008,3.364,0,6.729a1.157,1.157,0,1,1-2.307,0q0-1.689,0-3.378"
                          transform="translate(-103.611 -148.467)"
                          fill="#30426a"
                        />
                        <path
                          id="Path_96"
                          data-name="Path 96"
                          d="M177.8,164.595c0,1.162.005,2.324,0,3.486a1.146,1.146,0,0,1-2.226.379,1.517,1.517,0,0,1-.076-.476q-.007-3.378,0-6.756a1.173,1.173,0,0,1,.831-1.192,1.1,1.1,0,0,1,1.27.471,1.539,1.539,0,0,1,.2.711c.02,1.126.009,2.252.009,3.378"
                          transform="translate(-162.829 -148.445)"
                          fill="#30426a"
                        />
                      </g>
                    </svg>
                  </button>
                )}
              </div>
              <div>
                <Select
                  className="select"
                  options={selectOptions}
                  styles={colourStyles}
                  defaultValue={selectOptions.find(
                    (option) => option.value === contract.state
                  )}
                  onChange={(selectedValue) => {
                    if (selectedValue.value === "ContractClosed") {
                      if (contract.state !== "OfferCreated") {
                        setModalValue(selectedValue);
                        setModalContractId(contract._id);
                        setOpenModal(true);
                      } else {
                        return;
                      }
                    } else {
                      updateContractState(selectedValue, contract._id);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </li>
    </>
  );
}

export default ContractItem;
