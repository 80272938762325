import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateItem,
  updateExtraPriceItem,
  calculateOneItem,
  calculateTotal,
} from "../features/contract/calculatorSlice";
import { useRef } from "react";
const initialValues = {
  id: "",
  title: "",
  price: 0,
};
function ExtraPriceModal({
  focusOnCalculator,
  isOpen,
  setIsOpen,
  totalCost,
  category,
}) {
  const { initialCartItem, targetExtraPrice, country } = useSelector(
    (store) => store.calculator
  );
  const [cost, setCost] = useState(totalCost);

  const [values, setValues] = useState(initialValues);
  const [isAdded, setIsAdded] = useState(false);
  const modal = useRef();
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const onValueChange = (e) => {
    if (e.target.name === "price") {
      const total = totalCost + Number(e.target.value);
      setCost(total);
      console.log(total);
    }
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onExtraPriceAdded = (e) => {
    
    setIsAdded(true);
    setIsOpen(false);
    if (!values.title || !values.price) {
      setError("Bitte alle notwendigen Felder ausfüllen");
      setIsAdded(false);
      setTimeout(() => {
        setError("");
      }, 1000);
      return;
    }
    
    const speseficCats = [
      "64648b531fe141b073630e20",
      "64648b531fe141b073630e23",
      "64648b531fe141b073630e11",
    ];
    if (!speseficCats.includes(category) && cost > 750000) {
      setError("Der Höchstpreis für ausgewählte Kategorien beträgt 750.000€");
      setIsAdded(false);
      setTimeout(() => {
        setError("");
      }, 2000);
      return;
    }
    if(speseficCats.includes(category) && cost > 850000){
      setError("Der Höchstpreis für ausgewählte Kategorien beträgt 850.000€");
      setIsAdded(false);
      setTimeout(() => {
        setError("");
      }, 2000);
      return;
    }
    dispatch(
      updateItem({
        itemId: initialCartItem.id,
        name: "extraPrices",
        value: { ...values, id: Math.ceil(Math.random() * 999) },
      })
    );

    dispatch(
      calculateOneItem({
        itemId: initialCartItem.id,
        country: country ? country : "germany",
      })
    );
    dispatch(calculateTotal());
    setValues({
      id: "",
      title: "",
      price: 0,
    });
    modal.current.setAttribute("class", "modal fade");
    document
      .querySelector("#pricesModal")
      .setAttribute("style", "display: none;");
    document.querySelector(".modal-backdrop")?.remove();
    // dispatch(addExtraPrices({ ...values }))
  };

  const onUpdateExtraPrice = () => {
    
    const speseficCats = [
      "64648b531fe141b073630e20",
      "64648b531fe141b073630e23",
      "64648b531fe141b073630e11",
    ];

    if(speseficCats.includes(category) && cost > 850000){
      setError("Der Höchstpreis für ausgewählte Kategorien beträgt 850.000 €");
      setIsAdded(false);
      setTimeout(() => {
        setError("");
      }, 2000);
      return;
    }
    if (!speseficCats.includes(category) && cost > 750000) {
      setError("Der Höchstpreis für ausgewählte Kategorien beträgt 750.000 €");
      setIsAdded(false);
      setTimeout(() => {
        setError("");
      }, 2000);
      return;
    }
    dispatch(
      updateExtraPriceItem({
        itemId: initialCartItem.id,
        name: "extraPrices",
        value: { ...values },
      })
    );
    setIsOpen(false);
    dispatch(
      calculateOneItem({
        itemId: initialCartItem.id,
        country: country ? country : "germany",
      })
    );
    dispatch(calculateTotal());

    modal.current.setAttribute("class", "modal fade");
    document.querySelector(".modal-backdrop").remove();
  };

  useEffect(() => {
    if (isOpen) {
      setValues({ ...initialValues });
    }
    if (targetExtraPrice) {
      const newValues = { ...targetExtraPrice };
      setValues(newValues);
    }
    if (isAdded) {
      setTimeout(() => {
        setIsAdded(false);
      }, 1000);
    }
  }, [isAdded, targetExtraPrice]);

  return (
    <>
      <div
        className={"modal fade"}
        id="pricesModal"
        tabIndex="-1"
        aria-labelledby="pricesModalLabel"
        aria-hidden="true"
        ref={modal}
      >
        <div className="modal-dialog border-none">
          <div className="modal-content border-none">
            <div className="modal-header border-0">
              <h1 className="modal-title fs-5" id="pricesModalLabel">
                Zubehör hinzufügen
              </h1>
            </div>

            <form action="">
              <div className="modal-body border-0">
                {isAdded && !error && (
                  <p className="text-light text-center bg-success p-3" style={{fontSize:"14px"}}>
                    Neue Extrapreise wurden hinzugefügt
                  </p>
                )}
                {error && (
                  <p className="text-light text-center bg-danger p-3" style={{fontSize:"14px"}}>
                    {error}
                  </p>
                )}
                <input
                  type="text"
                  name="title"
                  id="title"
                  className="form-control form-control-sm shadow-none mb-3"
                  placeholder="Zubehör / Anbaugeräte"
                  required
                  minLength={1}
                  value={values?.title}
                  onChange={onValueChange}
                />
                <input
                  type="number"
                  name="price"
                  id="price"
                  className="form-control form-control-sm shadow-none mb-3"
                  placeholder="Preis"
                  required
                  min={0}
                  value={values?.price === 0 ? "" : values.price}
                  onChange={onValueChange}
                />
              </div>
              <div className="modal-footer border-0">
                {/* <button
                  type="button"
                  className="btn btn-outline-dark shadow-none btn-sm"
                  data-bs-dismiss="modal"
                >
                  Abbrechen
                </button> */}
                <button
                  type="button"
                  // onClick={onExtraPriceAdded}
                  onClick={(e) => {
                    targetExtraPrice
                      ? onUpdateExtraPrice(e)
                      : onExtraPriceAdded(e);

                    focusOnCalculator();
                  }}
                  className="btn btn-dark btn-sm shadow-none"
                >
                  {targetExtraPrice
                    ? "Zubehör/Sonderausstattung aktualisieren"
                    : "Zubehör / Anbaugeräte"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExtraPriceModal;
