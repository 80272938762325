import { useDispatch, useSelector } from "react-redux";
import Alert from "../../components/Alert";
import Form1 from "../../components/calculatorForms/form1";
import Form2 from "../../components/calculatorForms/form2";
import Form3 from "../../components/calculatorForms/form3";
import BonusCard from "../../components/BonusCard";
import YearModal from "../../components/YearModal";
import SummaryModal from "../../components/SummaryModal";
import useMultiStepForm from "../../hooks/useMultiStepForm";
import NavigationFooter from "../../components/NavigationFooter";
import NavigationHeader from "../../components/NavigationHeader";
import MachinePreviewList from "../../components/MachinePreviewList";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/Loading";

const forms = [
  {
    number: "1",
    title: "Maschine auswählen",
    content: <Form1></Form1>,
  },
  {
    number: "2",
    title: "Kundeninformationen",
    content: <Form2></Form2>,
  },
  {
    number: "3",
    title: "Angebotserstellung",
    content: <></>,
  },
  {
    number: "4",
    title: "Antragserstellung",
    content: <Form3></Form3>,
  },
];

function CalculatorPage() {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const { role } = user;

  const {
    currentForm,
    isOpen,
    setIsOpen,
    currentIndex,
    nextStep,
    previousStep,
    openModal,
    loadStep,
  } = useMultiStepForm(forms);

  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const { machines } = useSelector((store) => store.calculator);
  const formRef = useRef(null);
  useEffect(() => {    
    
    window.scrollTo(0, 0);
    formRef.current.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, [currentForm, id]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {machines && machines.length > 0 && (
        <SummaryModal
          refId={id ? id : null}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          nextStep={nextStep}
          loadStep={loadStep}
        ></SummaryModal>
      )}

      {/*<YearModal></YearModal>*/}

      <div className="container-fluid calculator-page">
        {/* HEADER */}
        <div className="header fixed-top  d-flex align-items-center bg-light py-4">
          <div className="container-fluid">
            <NavigationHeader
              forms={forms}
              currentForm={currentForm}
              currentStepIndex={currentIndex}
            ></NavigationHeader>
          </div>
        </div>

        <div className="calculator-content">
          <Alert></Alert>
          <div className="d-flex align-items-center">
            <p className="lead my-4 fw-bold">
              versitech Maschinen-Versicherung
            </p>
          </div>

          <div className="row gap-0">
            {/* FORMS */}
            <div className="col-12 col-md-9 col-lg-9 mx-0 position-relative">
              <div className="form-container" ref={formRef}>
                <div className="col-12 ">
                  <div
                    className="card border-0"
                    style={{ background: "#dce8f6" }}
                  >
                    {currentIndex === 0 ? (
                      <MachinePreviewList></MachinePreviewList>
                    ) : (
                      <div className="form">{currentForm.content}</div>
                    )}

                    <div className="card-footer calculator-footer d-flex bg-white  border-0 mt-3  py-4 ">
                      {/* NAVIGATION SECTION */}
                      <NavigationFooter
                        currentIndex={currentIndex}
                        currentForm={currentForm}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        setIsOpen={setIsOpen}
                        openModal={openModal}
                        isOpen={isOpen}
                        loadStep={loadStep}
                      ></NavigationFooter>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* BONUS CARD */}
            <div className="col-12 col-md-3 col-lg-3 mx-0 px-0">
              <BonusCard></BonusCard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CalculatorPage;
