import React, { useState } from "react";
import Select from "react-select";
import moment from "moment";

const getWeeks = () => {
  const year = moment(new Date()).year();
  const startDate = moment(`${year}-01-01`).add(1, "day");
  //const endDate = moment(`${year}-12-31`);
  const endDate = moment(new Date()).add(1, "day").format("YYYY-MM-DD");
  let range = [];
  for (let m = startDate; m.isBefore(endDate); m.add(1, "weeks")) {
    const monday = m.startOf("week").add(1, "days");
    range.push(monday.toISOString());
  }

  const thisWeek = moment(new Date()).week();

  let weeks = range.map((date, index) => {
    let start = moment(date).format("DD.MM.YY");
    let end = moment(new Date(date)).add(1, "weeks").format("DD.MM.YY");
    return {
      label: `KW${index + 1}: ${start} - ${end}`,
      value: date,
      thisWeek: index + 1 === thisWeek,
    };
  });
  return weeks;
};

function DateSelector({ selectedWeek, onDateChange }) {
  const [weeks, setWeeks] = useState(getWeeks().reverse());
  const calenderStyles = {
    dropdownIndicator: (styles) => ({
      ...styles,
      color: "#344167",
      width: "50px",
    }),

    indicatorSeparator: (styles) => ({ ...styles, display: "none !important" }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "16px",
      color: "#30426A",
    }),
    control: () => ({
      // ...styles,
      fontSize: "16px",
      display: "flex",
      width: "280px",
      // border: "2px solid #9ABDD2",
      // borderRadius: "10px",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        paddingLeft: "5px",
        ":last-of-type": {
          borderBottom: "none",
        },
        ":hover": {
          fontWeight: "bold",
          background: "rgba(30, 183, 243, 0.3)",
        },
      };
    },
    singleValue: (styles, { data }) => ({ ...styles }),
  };
  
  return (
    <>
      <div className="today-calender d-flex align-self-center ms-auto bg-light p-3 rounded-3">
        <svg
          className="mr-3"
          id="Group_78"
          data-name="Group 78"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="34.498"
          height="34.485"
          viewBox="0 0 34.498 34.485"
        >
          <g id="Group_77" data-name="Group 77" clipPath="url(#clipPath)">
            <path
              id="Path_58"
              data-name="Path 58"
              d="M34.5,16.028a3.407,3.407,0,0,1-.411.681,1.241,1.241,0,0,1-1.395.31,1.3,1.3,0,0,1-.881-1.167c-.017-.167-.008-.337-.008-.5q0-3.519,0-7.039a2.724,2.724,0,0,0-2.922-2.923H27.356c0,.474.017.931,0,1.385a1.338,1.338,0,0,1-2.056,1.1,1.3,1.3,0,0,1-.629-1.126c-.011-.436,0-.873,0-1.333H18.535c0,.347,0,.691,0,1.036,0,1.01-.506,1.627-1.338,1.633S15.84,7.461,15.84,6.429c0-.335,0-.669,0-1.023H9.777c0,.432.008.876,0,1.319a1.343,1.343,0,0,1-2.686.031c-.017-.445,0-.892,0-1.4a17.238,17.238,0,0,0-2.307.132A2.617,2.617,0,0,0,2.71,8.021c0,.09,0,.18,0,.269q0,10.306,0,20.611a2.628,2.628,0,0,0,1.527,2.592,3.348,3.348,0,0,0,1.363.284c3.311.023,6.623.015,9.935.01a1.493,1.493,0,0,1,1.136.41,1.342,1.342,0,0,1-.387,2.153c-.1.048-.2.089-.3.134H4.793a.416.416,0,0,0-.117-.056A5.461,5.461,0,0,1,0,28.89c.034-6.868.009-13.736.016-20.6a6.4,6.4,0,0,1,.113-1.27A5.368,5.368,0,0,1,5.345,2.694c.569-.011,1.138,0,1.741,0,0-.481-.014-.927,0-1.372a1.343,1.343,0,0,1,2.686.032c.01.443,0,.886,0,1.32H15.84c0-.42,0-.821,0-1.223a1.351,1.351,0,1,1,2.693.009c0,.4,0,.8,0,1.212h6.13c0-.411,0-.8,0-1.193a1.352,1.352,0,1,1,2.693-.013c0,.4,0,.8,0,1.232.664,0,1.292-.029,1.916.005A5.236,5.236,0,0,1,33.937,5.73,11.31,11.31,0,0,1,34.5,7.474Z"
              transform="translate(0 0)"
              fill="#30426a"
            />
            <path
              id="Path_59"
              data-name="Path 59"
              d="M223.166,231.615a22.581,22.581,0,0,1-2.333-.6,8.094,8.094,0,0,1-5.032-8.8,8.142,8.142,0,0,1,16.129.316,2.373,2.373,0,0,0,.06.258v1.347c-.023.108-.05.216-.067.325a8,8,0,0,1-5.3,6.636,15.9,15.9,0,0,1-2.112.516Zm6.129-8.147a5.455,5.455,0,1,0-5.467,5.451,5.472,5.472,0,0,0,5.467-5.451"
              transform="translate(-197.491 -197.13)"
              fill="#30426a"
            />
            <path
              id="Path_60"
              data-name="Path 60"
              d="M85.339,151.7a1.345,1.345,0,1,1-1.345,1.352,1.352,1.352,0,0,1,1.345-1.352"
              transform="translate(-76.908 -138.906)"
              fill="#30426a"
            />
            <path
              id="Path_61"
              data-name="Path 61"
              d="M156.148,153.03a1.345,1.345,0,1,1-1.369-1.329,1.352,1.352,0,0,1,1.369,1.329"
              transform="translate(-140.513 -138.903)"
              fill="#30426a"
            />
            <path
              id="Path_62"
              data-name="Path 62"
              d="M224.259,151.7a1.345,1.345,0,1,1-1.337,1.361,1.352,1.352,0,0,1,1.337-1.361"
              transform="translate(-204.116 -138.906)"
              fill="#30426a"
            />
            <path
              id="Path_63"
              data-name="Path 63"
              d="M293.724,151.7a1.345,1.345,0,1,1-1.337,1.361,1.352,1.352,0,0,1,1.337-1.361"
              transform="translate(-267.72 -138.906)"
              fill="#30426a"
            />
            <path
              id="Path_64"
              data-name="Path 64"
              d="M86.684,222.495a1.345,1.345,0,1,1-1.369-1.329,1.352,1.352,0,0,1,1.369,1.329"
              transform="translate(-76.909 -202.507)"
              fill="#30426a"
            />
            <path
              id="Path_65"
              data-name="Path 65"
              d="M154.8,223.856a1.345,1.345,0,1,1,1.348-1.35,1.352,1.352,0,0,1-1.348,1.35"
              transform="translate(-140.512 -202.508)"
              fill="#30426a"
            />
            <path
              id="Path_66"
              data-name="Path 66"
              d="M85.32,293.32a1.345,1.345,0,1,1,1.361-1.337,1.352,1.352,0,0,1-1.361,1.337"
              transform="translate(-76.906 -266.112)"
              fill="#30426a"
            />
            <path
              id="Path_67"
              data-name="Path 67"
              d="M154.789,293.32a1.345,1.345,0,1,1,1.358-1.34,1.352,1.352,0,0,1-1.358,1.34"
              transform="translate(-140.511 -266.112)"
              fill="#30426a"
            />
            <path
              id="Path_68"
              data-name="Path 68"
              d="M298.961,266.247c.249,0,.45-.009.65,0a1.345,1.345,0,0,1,1.3,1.357,1.331,1.331,0,0,1-1.3,1.326q-1.025.032-2.051,0a1.307,1.307,0,0,1-1.288-1.3c-.02-.952-.022-1.906,0-2.858a1.325,1.325,0,0,1,1.33-1.292,1.339,1.339,0,0,1,1.349,1.31c.017.468,0,.938,0,1.452"
              transform="translate(-271.268 -241.257)"
              fill="#30426a"
            />
          </g>
        </svg>
        <Select
          className="select"
          options={weeks}
          styles={calenderStyles}
          defaultValue={selectedWeek}
          value={selectedWeek}
          onChange={(selectedValue) => onDateChange(selectedValue)}
        />
      </div>
    </>
  );
}

export default DateSelector;
