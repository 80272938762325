import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../utils/axios";
import {
  addUserToLocalStorage,
  getTokenFromLocalStorage,
} from "../utils/localStorage";
import { hideAlert, showAlert } from "./alertSlice";

const initialState = {
  isLoading: false,
  user: null,
  role: null,
  distributors: null,
  customers: null,
};

export const getProfile = createAsyncThunk(
  "user/profile",
  async (token, thunkAPI) => {
    try {
      if (token) {
        let response = await axiosInstance.get("/api/utils/me", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const { result } = await response.data;
        addUserToLocalStorage(result);
        return { result };
      }
    } catch (error) {
      const { message } = error.response.data;
      console.log(`💥 ${message}`);
    }
  }
);

export const createDistributor = createAsyncThunk(
  "user/create-distributor",
  async (values, thunkAPI) => {
    try {
      const token = getTokenFromLocalStorage();
      const user = {
        ...values,
        company: {
          legalForm: values.companyLegalForm,
          name: values.companyName,
        },
      };
      let response = await axiosInstance.post(
        "/api/admin/distributors",
        { ...user },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { result } = await response.data;

      thunkAPI.dispatch(
        showAlert({
          alertType: "success",
          alertContent: "Ihre Anfrage wurde erfolgreich hinzugefügt",
        })
      );
      setTimeout(() => {
        thunkAPI.dispatch(hideAlert());
      }, 2000);
      return { result };
    } catch (error) {
      const { message } = error.response.data;
      console.log(message);
      thunkAPI.dispatch(
        showAlert({
          alertType: "danger",
          alertContent:
            "Diese E-Mail wird erstellt, bevor Sie sie nicht erneut hinzufügen können",
        })
      );

      setTimeout(() => {
        thunkAPI.dispatch(hideAlert());
      }, 2000);

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllDistributors = createAsyncThunk(
  "user/all-distributors",
  async (thunkAPI) => {
    try {
      const token = getTokenFromLocalStorage();
      const response = await axiosInstance.get("/api/users/distributors", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { result } = await response.data;
      return { result };
    } catch (error) {
      const { message } = error.response.data;
      console.log(`💥 ${message}`);
    }
  }
);

export const getAllCustomers = createAsyncThunk(
  "user/all-customers",
  async (userId, thunkAPI) => {
    try {
      const token = getTokenFromLocalStorage();
      const response = await axiosInstance.get(`/api/distributor/customers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { result } = await response.data;
      console.log({ customers: result });
      return { result };
    } catch (error) {
      const { message } = error.response.data;
      console.log(`💥 ${message}`);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updatePhoto: (state, action) => {
      console.log(action.payload);
      state.user = { ...state.user, photo: action.payload.url };
      console.log({ ...state.user });
    },
  },
  extraReducers: (builder) => {
    // get profile
    builder.addCase(getProfile.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload.result;
      state.role = action.payload.result.role;
    });

    builder.addCase(getProfile.rejected, (state) => {
      state.isLoading = false;
    });

    // create distributor
    builder.addCase(createDistributor.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(createDistributor.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(createDistributor.rejected, (state, action) => {
      state.isLoading = false;
    });

    // get all distributors
    builder.addCase(getAllDistributors.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getAllDistributors.fulfilled, (state, action) => {
      state.isLoading = false;
      state.distributors = action.payload.result;
    });

    builder.addCase(getAllDistributors.rejected, (state) => {
      state.isLoading = false;
      state.distributors = null;
    });
    // get all distributors
    builder.addCase(getAllCustomers.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getAllCustomers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.customers = action.payload.result;
    });

    builder.addCase(getAllCustomers.rejected, (state) => {
      state.isLoading = false;
      state.customers = null;
    });
  },
});
export const { updatePhoto } = userSlice.actions;
export default userSlice.reducer;
