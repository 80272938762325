import { useSelector, useDispatch } from 'react-redux'
import React, { useState } from 'react'
import { onValueChange, setYearModal } from '../features/contract/machineSlice'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
function YearModal() {
  const { productionYear } = useSelector((store) => store.machine)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let minYear = new Date() - 1000 * 60 * 60 * 24 * 365 * 10
  return (
    <>
      <div
        className={`modal fade ${
          productionYear === 'Baujahr vor 2013' ? 'show' : ''
        }`}
        id="yearModal"
        tabIndex="-1"
        aria-labelledby="yearModalLabel"
        aria-hidden="true"
        style={{
          display: productionYear === 'Baujahr vor 2013' ? 'block' : '',
        }}
      >
        <div className="modal-dialog ">
          <div className="modal-content bg-light">
            <div className="modal-header bg-light">
              <h1 className="modal-title  fs-5" id="yearModalLabel">
                {`Baujahr vor ${moment(minYear).format('YYYY')}`}
              </h1>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  dispatch(setYearModal(""))
                }}
              ></button>
            </div>
            <div className="modal-body bg-light">
              Diese Maschine deines Kunden ist leider zu alt für unseren
              Rechner. Bitte starte einen individuellen Auftrag oder wähle eine
              andere Maschine.
            </div>
            <div className="modal-footer border-0 d-flex justify-content-center">
              {/* <button type="button" className="btn btn-dark btn-sm" data-bs-dismiss="modal">Individueller Auftrag</button> */}
              <button
                to={'/dashboard/support'}
                className="btn btn-dark btn-sm"
                onClick={(e) => {
                  dispatch(setYearModal())
                  navigate('/dashboard/support')
                }}
              >
                individuelle Anfrage stellen
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default YearModal
