import React, { useState } from "react";
import { useEffect } from "react";
import axiosInstance from "../../../utils/axios";
import Loading from "../../../components/Loading";
import ContractList from "../../../components/ContractList";

function DistributorContractsPage() {
  let [loading, setLoading] = useState(true);
  let [data, setData] = useState([]);
  const [completedContractsCount, setCompletedContractsCount] = useState(0);
  const [offersCount, setOffersCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  let get = async () => {
    const response = await axiosInstance.get("/api/distributor/contracts", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    setData(response.data.result);
    const offerCountsValue = response.data.result.filter(
      (c) => c.state === "OfferCreated"
    ).length;
    const completedCountsValue = response.data.result.filter(
      (c) => c.state === "ContractClosed"
    ).length;
    setCompletedContractsCount(completedCountsValue);
    setOffersCount(offerCountsValue);
    setLoading(false);
    setTotalCount(response.data.result.length);
    return response.data.result;
  };
  let [targetState, setTargetState] = useState("*");
  const filterData = async (target) => {
    setTargetState(target)
    setLoading(true);
    let baseRecords = await get();
    let newRecords;
    if (target === "OfferCreated") {
      newRecords = baseRecords.filter(
        (record) => record.state === "OfferCreated"
      );
      setData(newRecords);
    } else if (target === "ContractClosed") {
      newRecords = baseRecords.filter(
        (record) => record.state === "ContractClosed"
      );
      setData(newRecords);
    } else {
      setData(baseRecords);
    }
    console.log({ newRecords, target });
    setLoading(false);
  };

  useEffect(() => {
    get();
  }, []);

  let searchFunc = (e) => {
    if (e.target.value.length > 2) {
      let newData = data.filter(
        (x) =>
          x?.customer?.company?.name?.toUpperCase()
            .includes(e.target.value.toUpperCase()) ||
          x?.customer?.fullName?.toUpperCase()
            .includes(e.target.value.toUpperCase()) ||
          x?.category?.toUpperCase().includes(e.target.value.toUpperCase()) ||
          x?.machine?.toUpperCase().includes(e.target.value.toUpperCase())
      );
      setData(newData);
      setLoading(false);
    } else {
      get();
    }
  };

  if (loading) {
    return <Loading></Loading>;
  }

  return (
    <>
      <div className="container">
        <div className="col-12">
          <div className="d-flex">
            <div className="me-auto page-title-holder">
              <h2 className="section-title text-dark ">Deine Anträge</h2>
              <div className="d-flex align-items-center head-btns">
                <button
                       className={`btn btn-sm btn-light shadow-none rounded-0 ${targetState=="*" && 'active'}`}
                  onClick={() => filterData("*")}
                >
                  Alle <span>({totalCount})</span>{" "}
                </button>
                <button
                  className={`btn btn-sm btn-light shadow-none rounded-0 ${targetState=="OfferCreated" && 'active'}`}
                  onClick={() => filterData("OfferCreated")}
                >
                  Offene Anträge <span>({offersCount})</span>{" "}
                </button>
                <button
                     className={`btn btn-sm btn-light shadow-none rounded-0 ${targetState=="ContractClosed" && 'active'}`}
                  onClick={() => filterData("ContractClosed")}
                >
                  Abgeschlossen <span>({completedContractsCount})</span>{" "}
                </button>
              </div>
            </div>
            <div className="search">
              <div className="input-group">
                <span className="input-group-text">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.378"
                    height="25.506"
                    viewBox="0 0 25.378 25.506"
                  >
                    <g
                      id="Ellipse_14"
                      data-name="Ellipse 14"
                      transform="translate(0 11.314) rotate(-45)"
                      fill="none"
                      stroke="#344167"
                      strokeWidth="1"
                    >
                      <circle cx="8" cy="8" r="8" stroke="none" />
                      <circle cx="8" cy="8" r="7.5" fill="none" />
                    </g>
                    <g
                      id="Ellipse_15"
                      data-name="Ellipse 15"
                      transform="translate(2.552 11.441) rotate(-45)"
                      fill="none"
                      stroke="#344167"
                      strokeWidth="1"
                    >
                      <circle cx="6.195" cy="6.195" r="6.195" stroke="none" />
                      <circle cx="6.195" cy="6.195" r="5.695" fill="none" />
                    </g>
                    <g
                      id="Rectangle_82"
                      data-name="Rectangle 82"
                      transform="translate(14.771 17.728) rotate(-45)"
                      fill="none"
                      stroke="#344167"
                      strokeWidth="1"
                    >
                      <rect width="4" height="11" stroke="none" />
                      <rect x="0.5" y="0.5" width="3" height="10" fill="none" />
                    </g>
                  </svg>
                </span>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Suche"
                  aria-label="Search"
                  aria-describedby="basic-search"
                  onChange={(e) => {
                    setTimeout(() => {
                      searchFunc(e);
                    }, 500);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* to do  */}
        {/* filter section */}
        <div className="col-12">
          <ContractList records={data} />
          {/* {openModal && (
            <StateModal
              openModal={openModal}
              setOpenModal={setOpenModal}
              updateState={() => {
                updateContractState(modalValue, modalContractId);
              }}
            ></StateModal>
          )}
          <ul className="list-group admin-contracts-list">
            <li className="list-group-item d-flex list-header">
              <div className="col">Kunde</div>
              <div className="col">Versicherungsobjekt</div>
              <div className="col">Auftragsnummer</div>
              <div className="col text-end">Status des Antrags</div>
            </li>
            <RemoveContractModal
              removeId={removeContractId}
            ></RemoveContractModal>
            {data.map((contract, index) => {
              return (
                <li key={index} className="list-group-item list-item py-4 d-flex align-items-start">
                  <div className="row w-100">
                    <div className="col">
                      <span>
                        {contract?.customer?.fullName} |{" "}
                        {contract?.customer?.company?.name}
                      </span>
                    </div>
                    <div className="col">
                      <span>
                        {contract?.category}, {contract?.machine}
                      </span>
                    </div>
                    <div className="col-2">
                      <span>Auftrag #{contract?._id.slice(0, 8)}</span>
                    </div>
                    <div className="col-auto">
                      <div className="d-flex justify-content-between align-items-center">
                        {contract.state === "OfferCreated" && (
                          <Link
                            to={`/calculator/contracts/edit/${contract?._id}`}
                            className="btn edit-btn"
                          >
                            Bearbeiten
                          </Link>
                        )}
                        <button
                          data-bs-toggle="modal"
                          data-bs-target="#removeContractModal"
                          className="btn delete-btn"
                          onClick={(e) => {
                            setRemoveContractId(contract?._id);
                          }}
                        >
                          <svg
                            id="Group_96"
                            data-name="Group 96"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="23.04"
                            height="27.705"
                            viewBox="0 0 23.04 27.705"
                          >
                            <g
                              id="Group_92"
                              data-name="Group 92"
                              clipPath="url(#clipPath)"
                            >
                              <path
                                id="Path_94"
                                data-name="Path 94"
                                d="M9.786,0h3.456a.817.817,0,0,0,.148.048,5.751,5.751,0,0,1,4.859,4.32c.052.2.134.25.325.249,1.071-.008,2.142,0,3.213,0a1.164,1.164,0,0,1,1.059.532A1.1,1.1,0,0,1,22.9,6.3a1.1,1.1,0,0,1-1,.622c-.383.01-.767,0-1.162,0v.391q0,7.249,0,14.5a5.776,5.776,0,0,1-4.3,5.7c-.3.081-.612.129-.919.192H7.518a13.894,13.894,0,0,1-1.481-.379,5.748,5.748,0,0,1-3.743-5.51q-.005-7.263,0-14.526V6.926c-.41,0-.786.007-1.162,0a1.156,1.156,0,1,1,.083-2.309q1.62-.01,3.24,0c.191,0,.271-.046.326-.248A5.618,5.618,0,0,1,8.237.424,13.135,13.135,0,0,1,9.786,0M4.6,6.94v.276q0,7.319,0,14.637A3.439,3.439,0,0,0,8.108,25.39q3.4.013,6.8,0a3.444,3.444,0,0,0,3.514-3.556q0-7.305,0-14.61V6.94ZM15.891,4.6a.776.776,0,0,0-.018-.114A3.414,3.414,0,0,0,12.9,2.327c-.916-.026-1.836-.052-2.75.005A3.323,3.323,0,0,0,7.184,4.423a.953.953,0,0,0-.042.178Z"
                                transform="translate(0)"
                                fill="#30426a"
                              />
                              <path
                                id="Path_95"
                                data-name="Path 95"
                                d="M111.669,164.623c0-1.153,0-2.306,0-3.459a1.136,1.136,0,0,1,.988-1.138,1.123,1.123,0,0,1,1.235.741,1.564,1.564,0,0,1,.08.5q.008,3.364,0,6.729a1.157,1.157,0,1,1-2.307,0q0-1.689,0-3.378"
                                transform="translate(-103.611 -148.467)"
                                fill="#30426a"
                              />
                              <path
                                id="Path_96"
                                data-name="Path 96"
                                d="M177.8,164.595c0,1.162.005,2.324,0,3.486a1.146,1.146,0,0,1-2.226.379,1.517,1.517,0,0,1-.076-.476q-.007-3.378,0-6.756a1.173,1.173,0,0,1,.831-1.192,1.1,1.1,0,0,1,1.27.471,1.539,1.539,0,0,1,.2.711c.02,1.126.009,2.252.009,3.378"
                                transform="translate(-162.829 -148.445)"
                                fill="#30426a"
                              />
                            </g>
                          </svg>
                        </button>

                        <Select
                          className="select"
                          options={selectOptions}
                          styles={colourStyles}
                          defaultValue={selectOptions.find(
                            (option) => option.value === contract.state
                          )}
                          onChange={(selectedValue) => {
                            if (selectedValue.value === "ContractClosed") {
                              setModalValue(selectedValue);
                              setModalContractId(contract._id);
                              setOpenModal(true);
                            } else {
                              updateContractState(selectedValue, contract._id);
                            }

                            // (selectedValue) => {
                            //   updateContractState(selectedValue, contract._id);
                            // };
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul> */}
        </div>
      </div>
    </>
  );
}

export default DistributorContractsPage;
