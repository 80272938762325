import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTokenFromLocalStorage } from "../../utils/localStorage";
import axiosInstance from "../../utils/axios";
import { hideAlert, showAlert } from "../alertSlice";

const initialState = {
  isLoading: false,
  pdfId: null,
  record: null,
  error: null,
};

export const createOneContract = createAsyncThunk(
  "contract/create",
  async (values, thunkAPI) => {
    try {
      const token = getTokenFromLocalStorage();
      if (token) {
        const contractCountRes = await axiosInstance.get("/api/admin/contracts", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const contractCountData = await contractCountRes.data.result;
        const contractCount = await contractCountData.length;
        const response = await axiosInstance.post(
          "/api/distributor/contracts",
          { ...values, state: "OfferCreated", offerNo: contractCount + 3200 + 1 },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const { result } = response.data;
        return { result };
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
      thunkAPI.dispatch(
        showAlert({ alertType: "danger", alertContent: "Es ist Ihnen nicht gestattet, einen Kunden anzulegen. Diese E-Mail ist bereits als Händler registriert." })
      );

      setTimeout(() => {
        thunkAPI.dispatch(hideAlert());
      }, 2000);

      return thunkAPI.rejectWithValue(errorMessage);

    }
  }
);

const createOneContractSlice = createSlice({
  name: "createOneContractSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createOneContract.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createOneContract.fulfilled, (state, action) => {

      state.isLoading = false;
      state.record = action.payload?.result;
      state.pdfId = action.payload?.result?._id;
      localStorage.setItem("pdfId", action?.payload?.result?._id);
    });
    builder.addCase(createOneContract.rejected, (state, action) => {
      state.isLoading = false;
      state.record = null;
      state.pdfId = null;
    });
  },
});

export default createOneContractSlice.reducer;
