import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTokenFromLocalStorage } from "../../utils/localStorage";

import axiosInstance from "../../utils/axios";
import moment from "moment";
//

const initialState = {
  id: null,
  machineErrors: [
    {
      field: "machine",
      type: "invalid",
      text: "Bitte wählen Sie den Maschinentyp aus",
    },
    {
      field: "brand",
      type: "invalid",
      text: "Marke ist ein Pflichtfeld",
    },
    {
      field: "productionYear",
      type: "invalid",
      text: "Produktionsjahr ist ein Pflichtfeld",
    },
    {
      field: "purchasePrice-required",
      type: "invalid",
      text: "Kaufpreis ist ein Pflichtfeld",
    },
    {
      field: "protectType",
      type: "invalid",
      text: "Bitte wählen Sie die Schutzmethode",
    },
    {
      field: "insuranceStartDate",
      type: "invalid",
      text: "Versicherungsbeginn ist ein Pflichtfeld",
    },
    {
      field: "deductible",
      type: "invalid",
      text: "Bitte wählen Sie die Schutzmethode",
    },

    {
      field: "machineType",
      type: "invalid",
      text: "Maschinentyp ist ein Pflichtfeld",
    },
    {
      field: "duration",
      type: "invalid",
      text: "Dauer ist ein Pflichtfeld",
    },
    {
      field: "mainDue",
      type: "invalid",
      text: "Hauptschuld ist ein Pflichtfeld",
    },
    {
      field: "includedPolicyholder",
      type: "invalid",
      text: "enthalten Versicherungsnehmer Pflichtfeld",
    },
    {
      field: "purchasePrice-price",
      type: "invalid",
      text: `Der Höchstpreis beträgt 75000 €`,
    },
  ],
  machineValid: true,
  isLoading: false,
  categoriesList: [],
  machinesList: [],
  discountsList: [],
  selectedMachine: null,
  selectedCategory: null,
  machine: "",
  category: "",
  brand: "",
  productionYear: "",
  serialNumber: "",
  insuranceStartDate: "",
  insuranceEndDate: "",
  description: "",
  purchasePrice: 0,
  deductible: null,
  fullProduction: 0,
  hull: 0,
  mtv: 0,
  protectType: "fullProduction",
  discountsOptions: [],
  protectFactor: null,
  extraPrices: [],
  machineObj: null,
  categoryObj: null,
  // result values
  price: 0,
  machineType: "",
  duration: "",
  mainDue: "",
  includedPolicyholder: "",
};

export const getCategories = createAsyncThunk(
  "contract/get-categories",
  async (thunkAPI) => {
    try {
      const token = getTokenFromLocalStorage();
      if (token) {
        const response = await axiosInstance.get("/api/utils/categories", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const { result } = await response.data;
        console.log("get-categories",result);
        return { result };
      }
    } catch (error) {
      const { message } = error.response.data;
      console.log(`💥 ${message}`);
    }
  }
);

export const getMachines = createAsyncThunk(
  "contract/get-machines",
  async (categoryId, thunkAPI) => {
    try {
      const token = getTokenFromLocalStorage();
      if (token) {
        const response = await axiosInstance.get(
          `/api/utils/categories/${categoryId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const { result } = await response.data;

        return { result };
      }
    } catch (error) {
      const { message } = error.response.data;
      console.log(`💥 ${message}`);
    }
  }
);

export const getMachine = createAsyncThunk(
  "contract/get-machine",
  async (machineId, thunkAPI) => {
    try {
      const token = getTokenFromLocalStorage();
      if (token) {
        const response = await axiosInstance.get(
          `/api/utils/machines/${machineId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const { result } = await response.data;
        return { result };
      }
    } catch (error) {
      const { message } = error.response.data;
      console.log(`💥 ${message}`);
    }
  }
);

const machineSlice = createSlice({
  name: "machineSlice",
  initialState,
  reducers: {
    setMachineValues: (state, action) => {
      const { machine } = action.payload;

      // state.id = machine.id;
      state.machineErrors = [];
      state.machineValid = true;
      state.isLoading = false;
      state.discountsList = machine?.discountsList;
      state.selectedMachine = machine?.selectedMachine;
      state.selectedCategory = machine?.selectedCategory;
      state.machine = machine?.machine;
      state.category = machine?.category;
      state.brand = machine?.brand;
      state.productionYear = machine?.productionYear;
      state.serialNumber = machine?.serialNumber;
      state.insuranceStartDate = moment(machine?.insuranceStartDate).format("yyyy-MM-DD");
      state.insuranceEndDate = machine?.insuranceEndDate;
      state.description = machine?.description;
      state.purchasePrice = machine?.purchasePrice;
      state.deductible = machine?.deductible;
      state.fullProduction = machine?.fullProduction || 0;
      state.hull = machine?.hull || 0;
      state.mtv = machine?.mtv || 0;
      state.protectType = machine?.protectType;
      state.discountsOptions = machine?.discountsOptions;
      state.protectFactor = machine?.protectFactor;
      state.extraPrices = machine?.extraPrices;
      state.machineObj = machine?.machineObj;
      state.categoryObj = machine?.categoryObj;
      state.price = machine?.price;
      state.machineType = machine?.machineType;
      state.duration = machine?.duration;
      state.mainDue = moment(machine?.mainDue).format("MM.DD");
      state.includedPolicyholder = machine?.includedPolicyholder;
      state.paymentPeriod=machine?.paymentPeriod || "per-month";
    },
    onCategoryChange: (state, action) => {
      state.category = action.payload.categoryId;
      const selectedCategory = [...state.categoriesList]?.find(
        (category) => category._id === action.payload.categoryId
      );
      state.categoryObj = selectedCategory;
      state.selectedCategory = selectedCategory;
    },
    updateDiscountOptionsList: (state, action) => {
      const inList = state.discountsOptions.find(
        (item) => item.value === action.payload.value
      );
      if (inList) {
        state.discountsOptions.pop(action.payload);
      } else {
        state.discountsOptions.push(action.payload);
      }
    },
    resetValues: (state, action) => {
      state.price = 0;
      state.machine = "";
      state.category = "";
      state.brand = "";
      state.deductible = "";
      state.fullProduction = "";
      state.hull = "";
      state.extraPrices = [];
      state.protectType = "";
      state.protectFactor = "";
      state.serialNumber = "";
      state.purchasePrice = 0;
      state.extraPrices = [];
      state.description = "";
      state.insuranceStartDate = "";
      state.insuranceEndDate = "";
      state.machinesList = [];
      state.productionYear = "";
      state.machineType = "";
      state.includedPolicyholder = "";
      state.mainDue = "";
      state.duration = "";
    },
    addExtraPrices: (state, action) => {
      state.extraPrices = [
        ...state.extraPrices,
        { ...action.payload, id: Math.ceil(Math.random() * 9999) },
      ];
    },
    removeExtraPrice: (state, action) => {
      state.extraPrices = state.extraPrices.filter(
        (item) => item.id !== action.payload.itemId
      );
    },
    setYearModal: (state, action) => {
      state.productionYear = action.payload;
    },

    addMachineError: (state, action) => {
      state.machineValid = false;
      state.machineErrors = [...state.machineErrors, { ...action.payload }];
    },
    removeMachineError: (state, action) => {
      if ([...state.machineErrors].length > 0) {
        state.machineErrors = state.machineErrors.filter(
          (error) => error.field !== action.payload.field
        );
      } else {
        state.machineValid = true;
        state.machineErrors = [];
      }
    },
    fillMachineErrors: (state) => {
      state.machineValid = true;
      state.machineErrors = [
        {
          field: "machine",
          type: "invalid",
          text: "Bitte wählen Sie den Maschinentyp aus",
        },
        {
          field: "brand",
          type: "invalid",
          text: "Marke ist ein Pflichtfeld",
        },
        {
          field: "productionYear",
          type: "invalid",
          text: "Produktionsjahr ist ein Pflichtfeld",
        },
        {
          field: "purchasePrice",
          type: "invalid",
          text: "Kaufpreis ist ein Pflichtfeld",
        },
        {
          field: "protectType",
          type: "invalid",
          text: "Bitte wählen Sie die Schutzmethode",
        },
        {
          field: "insuranceStartDate",
          type: "invalid",
          text: "Versicherungsbeginn ist ein Pflichtfeld",
        },
        {
          field: "insuranceEndDate",
          type: "invalid",
          text: "Enddatum der Versicherung ist ein Pflichtfeld",
        },
        {
          field: "deductible",
          type: "invalid",
          text: "Bitte wählen Sie die Schutzmethode",
        },
      ];
    },
    clearMachineErrors: (state, action) => {
      state.machineErrors = [];
      state.machineValid = true;
    },
    onValueChange: (state, action) => {
      const { name, value } = action.payload;

      if (name === "machine") {
        const selectedMachine = [...state.machinesList]?.find(
          (machine) => machine._id === value
        );
        state.discountsList = [...selectedMachine?.discounts];
        state.selectedMachine = selectedMachine;
      }

      if (name === "category") {
        const selectedCategory = [...state.categoriesList]?.find(
          (category) => category._id === value
        );
        state.selectedCategory = selectedCategory;
      }

      if (name === "deductible") {
        const discounts = [...state.discountsList];
        state.protectType = "";
        for (let discount of discounts) {
          const { deductible, fullProduction, hull, mtv } = { ...discount };
          if (deductible == value) {
            state.fullProduction = fullProduction;
            state.hull = hull;
            state.mtv = mtv;
          }
        }

        state.machineErrors.push({
          field: "protectType",
          type: "invalid",
          text: "Bitte wählen Sie die Schutzmethode",
        });
      }

      if (name === "protectType") {
        state.protectType = value;
        state.protectFactor = state[value];
      }

      // if (name === "duration") {
      //   console.log(value);
      //   if(value != 1){
      //     [...state.discountsOptions].push({
      //       id: 6,
      //       label: "Mehrjährigkeitsrabatt bei einer Laufzeit von 3 Jahren (-10%)",
      //       value: -10,
      //     });
      //   }else{
      //     [...state.discountsOptions].pop({
      //       id: 6,
      //       label: "Mehrjährigkeitsrabatt bei einer Laufzeit von 3 Jahren (-10%)",
      //       value: -10,
      //     });
      //   }
      //   console.log({...state.discountsOptions});
      // }
      

      if (value === "") {
        state.machineErrors = [
          ...state.machineErrors,
          {
            field: name,
            type: "invalid",
            text: `Bitte füllen Sie das Pflichtfeld aus`,
          },
        ];
      } else {
        state.machineErrors = [...state.machineErrors].filter(
          (error) => error.field !== name 
        );
      }
      state[name] = value;
    },
    updateDescription: (state, action) => {
      state.description = action.payload.value;
    },
    clearDiscountOptionsList: (state, action) => {
      state.discountsOptions = [];
    },
    calculate: (state, action) => {
      try {
        const { total, period } = action.payload;

        const totalExtra = [...state.extraPrices]
          .map((item) => item.price)
          .reduce((acc, current) => Number(acc) + Number(current), 0);

        const totalDiscounts = [...state.discountsOptions]
          .map((item) => item.value)
          .reduce((acc, current) => Number(acc) + Number(current), 0);

        let machineCost;
        if (!total) {
          machineCost = Number(0) + Number(state.purchasePrice) + totalExtra;
        } else {
          machineCost =
            Number(total) + Number(state.purchasePrice) + totalExtra;
        }

        if (state.protectFactor) {
          let newPrice = (machineCost * Number(state.protectFactor)) / 1000;

          state.price = newPrice.toFixed(2);
        }
        if (totalDiscounts) {
          let newPrice = (machineCost * Number(state.protectFactor)) / 1000;
          let discountValue = (totalDiscounts / 100) * Number(newPrice);
          state.price = (newPrice + discountValue).toFixed(2);
        }
      } catch (error) {
        console.log(error);
      }
    },

    setFormValid: (state, action) => {
      const { isValid } = action.payload;
      console.log(isValid);
      state.machineValid = isValid;
    },
  },
  extraReducers: (builder) => {
    // get categories from db
    builder.addCase(getCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.categoriesList = action.payload.result;
    });
    builder.addCase(getCategories.rejected, (state) => {
      state.isLoading = false;
    });

    // get machine of selected category
    builder.addCase(getMachines.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMachines.fulfilled, (state, action) => {
      state.isLoading = false;
      state.machinesList = action.payload.result.machines;
    });
    builder.addCase(getMachines.rejected, (state) => {
      state.isLoading = false;
    });

    // get one machine
    builder.addCase(getMachine.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getMachine.fulfilled, (state, action) => {
      state.isLoading = false;
      state.machineObj = action.payload.result;
    });

    builder.addCase(getMachine.rejected, (state) => {
      state.isLoading = false;
      state.machineObj = null;
    });
  },
});

export const {
  onCategoryChange,
  onMachineChange,
  onValueChange,
  updateDiscountOptionsList,
  updateErrorList,
  resetValues,
  addExtraPrices,
  removeExtraPrice,
  setYearModal,
  calculate,
  addMachineError,
  clearMachineErrors,
  removeMachineError,
  clearDiscountOptionsList,
  setFormValid,
  fillMachineErrors,
  updateDescription,
  setMachineValues,
} = machineSlice.actions;

export default machineSlice.reducer;
