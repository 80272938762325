import { useSelector,useDispatch } from 'react-redux'
import MachinePreview from './MachinePreview'
import Form1 from './MachineForm'
import { useEffect,useState } from 'react';


import {
  getCategories
} from "../features/contract/machineSlice";

function MachinePreviewList() {

  const dispatch = useDispatch();

  const { protectType, initialCartItem, machines } = useSelector(
    (store) => store.calculator
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-12 col-lg-12 mx-auto">
          {machines.length ? (
            <ul className="list-group machines-previews bg-light">
              {machines.map((machine, index) => {
                if(initialCartItem.id === machine.id) {
                  return (
                    <li
                      key={index}
                      className="list-group-item machine-preview w-100  p-0  bg-light "
                    >
                      <Form1 {...machine} index={index}></Form1>
                    </li>
                  )
                } else {
                  return (
                    <li
                      key={index}
                      className="list-group-item machine-preview w-100  p-0  bg-light "
                    >
                      <MachinePreview {...machine} index={index}></MachinePreview>
                    </li>
                  )
                }

                
                
                
              })}
            </ul>
          ):(
            <ul className="list-group machines-previews bg-light">
                    <li
                      className="list-group-item machine-preview w-100  p-0  bg-light "
                    >
                      <Form1></Form1>
                    </li>
                  
            </ul>
          )}
        </div>
      </div>
    </>
  )
}

export default MachinePreviewList
