import { Outlet } from "react-router-dom";
import Footer from "../../components/Footer";

import SidebarList from "../../components/SidebarList";
import Alert from "../../components/Alert";
function LayoutPage() {

    return (<>
        <div className="container-fluid dashboard-container">
            <div className="row row-cols-md-2 row-cols-lg-2">
                
                {/* <!-- USER CARD --> */}
                <div className="col-auto col-md-4 col-lg-3 rounded-3">
                    <SidebarList></SidebarList>
                </div>
                {/* <!--END OF USER CARD --> */}


                {/* <!-- CONTENT --> */}
                <div className="col-12 col-md-8 col-lg-9  main-content">
           
                    <Outlet></Outlet>
                    {/* <!-- END OF CONTENT --> */}
            
        
              
                </div>
           
                <Footer />
          
            </div>
        </div>
    </>);
}

export default LayoutPage;