import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTokenFromLocalStorage } from "../utils/localStorage";
import axiosInstance from "../utils/axios";

const initialState = {
    isLoading: false,
    contracts: [],
    myContracts: [],
    currentContract: null,
    // contract form
    categories: [],
    machines: [],
    selectedMachine: null,
    appendedMachines: null
}

export const getAllContracts = createAsyncThunk(
    "contracts/get-all",
    async (thunkAPI) => {
        try {
            const token = getTokenFromLocalStorage();
            if (token) {
                const response = await axiosInstance.get("/api/contracts", {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });
                const { result } = response.data;

                return { result };
            }

        } catch (error) {
            const { message } = error.response.data;
            console.log(`💥 ${message}`);
        }
    }
);

export const getMyContracts = createAsyncThunk(
    "contracts/get-my-contracts",
    async (thunkAPI) => {
        try {
            const token = getTokenFromLocalStorage();
            if (token) {
                const response = await axiosInstance.get("/api/contracts/my-contracts", {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });
                const { result } = response.data;

                return { result };
            }

        } catch (error) {
            const { message } = error.response.data;
            console.log(`💥 ${message}`);
        }
    }
);

export const getOneContract = createAsyncThunk(
    "contracts/get-one",
    async (contractId, thunkAPI) => {
        try {
            const token = getTokenFromLocalStorage();
            if (token) {
                const response = await axiosInstance.get(`/api/contracts/${contractId}`, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });
                const { result } = response.data;
                return { result };
            }

        } catch (error) {
            const { message } = error.response.data;
            console.log(`💥 ${message}`);
        }
    }
);

export const getCategories = createAsyncThunk(
    "categories/get-all",
    async (thunkAPI) => {
        try {
            const token = getTokenFromLocalStorage();
            if (token) {
                const response = await axiosInstance.get("/api/categories", {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });
                const { result } = await response.data;
                return { result };
            }
        } catch (error) {
            const { message } = error.response.data;
            console.log(`💥 ${message}`);
        }
    }
)

export const getMachines = createAsyncThunk(
    "categories/get-machines",
    async (categoryId, thunkAPI) => {
        try {
            const token = getTokenFromLocalStorage();
            if (token) {
                const response = await axiosInstance.get(`/api/categories/${categoryId}`, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });
                const { result } = await response.data;
                return { result };
            }
        } catch (error) {
            const { message } = error.response.data;
            console.log(`💥 ${message}`);
        }
    }
)



const contractSlice = createSlice({
    name: "contract",
    initialState,
    reducers: {
        appendedMachine: (state, action) => {
            console.log(action.payload);
        },

    },
    extraReducers: (builder) => {
        // get all contracts
        builder.addCase(getAllContracts.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(getAllContracts.fulfilled, (state, action) => {
            state.isLoading = false;
            state.contracts = action.payload.result;
        });
        builder.addCase(getAllContracts.rejected, (state, action) => {
            state.isLoading = false;
        });

        // get one contract
        builder.addCase(getOneContract.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(getOneContract.fulfilled, (state, action) => {
            state.isLoading = false;
            state.currentContract = action.payload.result;
        });
        builder.addCase(getOneContract.rejected, (state, action) => {
            state.isLoading = false;
            state.currentContract = null;
        });

        // get my contracts
        builder.addCase(getMyContracts.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(getMyContracts.fulfilled, (state, action) => {
            state.isLoading = false;
            state.myContracts = action.payload.result;
        });
        builder.addCase(getMyContracts.rejected, (state, action) => {
            state.isLoading = false;
            state.currentContract = null;
        });

        // get categories
        builder.addCase(getCategories.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(getCategories.fulfilled, (state, action) => {
            state.isLoading = false;
            state.categories = action.payload.result;
        });
        builder.addCase(getCategories.rejected, (state, action) => {
            state.isLoading = false;
        });

        // get machines
        builder.addCase(getMachines.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(getMachines.fulfilled, (state, action) => {
            state.isLoading = false;
            state.machines = action.payload.result;
        });
        builder.addCase(getMachines.rejected, (state, action) => {
            state.isLoading = false;
            state.machines = [];
        });
    }
})
export const { appendedMachine } = contractSlice.actions;
export default contractSlice.reducer;