import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTokenFromLocalStorage } from "../../utils/localStorage";
import axiosInstance from "../../utils/axios";
let initialState = {
  loading: false,
  record: {
    _id: "",
    total: 0,
    insureName: "",
    insureType: "",
    debtFinancing: "",
    machines: [],
    protectType: "",
    paymentPeriod: "",
    taxRate: 0,
    country: "",
    isPrototypes: "yes",
    byManufacturer: "yes",
    availableParts: "yes",
    customer: {
      _id: "",
      fullName: "",
      bankName: "",
      swiftCode: "",
      ibanNumber: "",
    },
    paymentDetails: {
      bankName: "",
      swiftCode: "",
      ibanNumber: "",
    },
  },
};

export const getOneContract = createAsyncThunk(
  "contract/get-one",
  async (id, thunkAPI) => {
    try {
      const token = getTokenFromLocalStorage();
      if (token) {
        const response = await axiosInstance.get(`/api/contracts/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        const { result } = response.data;
        return { result };
      }
    } catch (error) {
      const { message } = error.response.data;
      console.log(`💥 ${message}`);
    }
  }
);

export const updateOneContract = createAsyncThunk(
  "contract/update-one",
  async (_, thunkAPI) => {
    try {
      const token = getTokenFromLocalStorage();
      const { id, values } = _;
      
      if (token) {
        const response = await axiosInstance.put(
          `/api/distributor/contracts/${id}`,
          { ...values },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const { result } = response.data;

        return { result };
      }
    } catch (error) {
      const { message } = error.response.data;
      console.log(`💥 ${message}`);
    }
  }
);

const updateOneContractSlice = createSlice({
  name: "createOneContractSlice",
  initialState,
  reducers: {
    setValues: (state, action) => {
      const { name, value } = { ...action.payload };
      if (name === "fullName") {
        state.record.customer.fullName = value;
      }
      if (name === "ibanNumber") {
        console.log(value);
        state.record.paymentDetails.ibanNumber = value;
      }
      if (name === "bankName") {
        state.record.paymentDetails.bankName = value;
      }
      if (name === "swiftCode") {
        state.record.paymentDetails.swiftCode = value;
      }
      if (name === "serialNumber") {
        console.log(value);
      }

      state.record = { ...state.record, [name]: value };
    },

    setMachines: (state, action) => {
      const { machines } = action.payload;
      state.record.machines = machines;
    },

    updateMachine: (state, action) => {
      const { id, price, value, name } = { ...action.payload };
      let machines = { ...state.record }.machines;
      const targetMachine = [...machines][id]
      // state.record = {...targetMachine,serialNumber:value}
    },

    calculate: (state, action) => {
      const record = { ...state.record };
      const { value } = { ...action.payload };
      // find total of selected discount
      const recordMachines = [...record.machines];

      recordMachines.map((machine) => {
        let paymentPeriod =
          record.paymentPeriod === "per-month"
            ? 1 / 12
            : record.paymentPeriod === "quarterly"
            ? 1 / 4
            : record.paymentPeriod === "half-yearly"
            ? 1 / 2
            : record.paymentPeriod === "yearly"
            ? 1
            : null;

        const targetDiscount = [...machine.discounts].find(
          (d) => d.deductible == machine.deductible
        );

        for (let discount of [...machine.discounts]) {
          const { deductible, fullProduction, hull, mtv } = { ...discount };
          if (deductible === targetDiscount.deductible) {
            if (value === "mtv") {
              machine.protectFactor = mtv;
            } else if (value === "fullProduction") {
              machine.protectFactor = fullProduction;
            } else if (value === "hull") {
              machine.protectFactor = hull;
            }
          }
        }
        let taxRate = { ...state.record }.taxRate;

        machine.price =
          (Number(machine.purchasePrice) +
            Number(machine.purchasePrice) * (taxRate / 100)) *
          paymentPeriod;
        const totalExtra = [...machine.extraExpenses]
          .map((i) => i.price)
          .reduce((acc, current) => Number(acc) + Number(current), 0);
        if (totalExtra) {
          machine.price =
            (Number(machine.purchasePrice) +
              totalExtra +
              (Number(machine.purchasePrice) + totalExtra) * (taxRate / 100)) *
            paymentPeriod;
        }
        if (machine.protectFactor) {
          machine.price =
            ((Number(machine.purchasePrice) + totalExtra) *
              (machine.protectFactor / 1000) +
              (Number(machine.purchasePrice) + totalExtra) *
                (machine.protectFactor / 1000) *
                (taxRate / 100)) *
            paymentPeriod;
        }
        const machinesCount = recordMachines.length;

        if (machinesCount >= 3) {
          [...machine.discountsOptions].push({
            id: 6,
            label:
              "Mehrjährigkeitsrabatt bei einer Laufzeit von 3 Jahren (-10%)",
            value: -10,
          });
        }

        if (machinesCount >= 3 && machinesCount <= 5) {
          recordMachines.push({
            id: 7,
            label: "new addition discount item",
            value: 7,
          });
        } else if (machinesCount >= 6 && machinesCount <= 10) {
          [...machine.discountsOptions].filter((i) => i.id !== 7);
          [...machine.discountsOptions].push({
            id: 7,
            label: "new addition discount item",
            value: 10,
          });
        } else {
          [...machine.discountsOptions].filter((i) => i.id !== 7);
        }

        // console.log([...machine.discountsOptions]);
        const totalDiscounts = [...machine.discountsOptions]
          .map((i) => i.value)
          .reduce((acc, current) => acc + current, 0);
        if (totalDiscounts) {
          machine.price =
            ((Number(machine.purchasePrice) + totalExtra) *
              (Number(machine.protectFactor) / 1000) +
              (totalDiscounts / 100) *
                ((Number(machine.purchasePrice) + totalExtra) *
                  (Number(machine.protectFactor) / 1000)) +
              ((Number(machine.purchasePrice) + totalExtra) *
                (Number(machine.protectFactor) / 1000) +
                (totalDiscounts / 100) *
                  ((Number(machine.purchasePrice) + totalExtra) *
                    (Number(machine.protectFactor) / 1000))) *
                (taxRate / 100)) *
            paymentPeriod;
        }
      });

      state.record.total = recordMachines
        .map((m) => {
          return m.price;
        })
        .reduce((current, next) => current + next, 0);
    },

    calculatorOneMachine: (state, action) => {
      const { _id, brand, price, value } = action.payload;
      let machines = { ...state.record }.machines;
      const targetMachine = machines.find(
        (m) => m.brand === brand && m.purchasePrice === Number(price)
      );
      let { protectType } = targetMachine;
      const record = { ...state.record };

      let paymentPeriod =
        record.paymentPeriod === "per-month"
          ? 1 / 12
          : record.paymentPeriod === "quarterly"
          ? 1 / 4
          : record.paymentPeriod === "half-yearly"
          ? 1 / 2
          : record.paymentPeriod === "yearly"
          ? 1
          : null;

      const targetDiscount = [...targetMachine.discounts].find(
        (d) => d.deductible == value
      );

      let protectFactor;

      const { deductible, fullProduction, hull, mtv } = { ...targetDiscount };

      if (protectType === "fullProduction") {
        protectFactor = fullProduction;
      } else if (protectType === "hull") {
        protectFactor = hull;
      } else if (protectType === "mtv") {
        protectFactor = mtv;
      }

      let taxRate = { ...state.record }.taxRate;

      targetMachine.price =
        (Number(targetMachine.purchasePrice) +
          Number(targetMachine.purchasePrice) * (taxRate / 100)) *
        paymentPeriod;

      const totalExtra = [...targetMachine.extraExpenses]
        .map((i) => i.price)
        .reduce((acc, current) => Number(acc) + Number(current), 0);
      if (totalExtra) {
        targetMachine.price =
          (Number(targetMachine.purchasePrice) +
            totalExtra +
            (Number(targetMachine.purchasePrice) + totalExtra) *
              (taxRate / 100)) *
          paymentPeriod;
      }

      if (protectFactor) {
        targetMachine.price =
          ((Number(targetMachine.purchasePrice) + totalExtra) *
            (protectFactor / 1000) +
            (Number(targetMachine.purchasePrice) + totalExtra) *
              (protectFactor / 1000) *
              (taxRate / 100)) *
          paymentPeriod;
      }

      const machinesCount = [...state.record.machines].length;

      if (machinesCount >= 3) {
        [...targetMachine.discountsOptions].push({
          id: 6,
          label: "Mehrjährigkeitsrabatt bei einer Laufzeit von 3 Jahren (-10%)",
          value: -10,
        });
      }

      if (machinesCount >= 3 && machinesCount <= 5) {
        [...targetMachine.discountsOptions].push({
          id: 7,
          label: "new addition discount item",
          value: 7,
        });
      } else if (machinesCount >= 6 && machinesCount <= 10) {
        [...targetMachine.discountsOptions].filter((i) => i.id !== 7);
        [...targetMachine.discountsOptions].push({
          id: 7,
          label: "new addition discount item",
          value: 10,
        });
      } else {
        [...targetMachine.discountsOptions].filter((i) => i.id !== 7);
      }

      const totalDiscounts = [...targetMachine.discountsOptions]
        .map((i) => i.value)
        .reduce((acc, current) => acc + current, 0);

      
      if (totalDiscounts) {
        targetMachine.price =
          ((Number(targetMachine.purchasePrice) + totalExtra) *
            (Number(protectFactor) / 1000) +
            (totalDiscounts / 100) *
              ((Number(targetMachine.purchasePrice) + totalExtra) *
                (Number(protectFactor) / 1000)) +
            ((Number(targetMachine.purchasePrice) + totalExtra) *
              (Number(protectFactor) / 1000) +
              (totalDiscounts / 100) *
                ((Number(targetMachine.purchasePrice) + totalExtra) *
                  (Number(protectFactor) / 1000))) *
              (taxRate / 100)) *
          paymentPeriod;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOneContract.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOneContract.fulfilled, (state, action) => {
      state.isLoading = false;
      console.log(action.payload);
      let record = action.payload.result;
      state.record = { ...record };
    });
    builder.addCase(getOneContract.rejected, (state) => {
      state.isLoading = false;
      state.record = {
        _id: "",
        total: 0,
        insureName: "",
        insureType: "",
        debtFinancing: "",
        machines: [],
        protectType: "",
        paymentPeriod: "",
        taxRate: 0,
        customer: {
          _id: "",
          fullName: "",
          bankName: "",
          swiftCode: "",
          ibanNumber: "",
        },
      };
    });
  },
});
export const {
  setValues,
  calculatorOneMachine,
  updateMachine,
  calculate,
  updateCustomer,
  setMachines
} = updateOneContractSlice.actions;
export default updateOneContractSlice.reducer;
