import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from "react-redux";
import { store } from './store';
import "./sass/main.scss";
//theme
import "primereact/resources/themes/fluent-light/theme.css";     
    
//core
import "primereact/resources/primereact.min.css";    
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

