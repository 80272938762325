import React, { useState } from "react";

import { useEffect } from "react";

import hilfe01 from "../../assets/images/h1.png";
import hilfe02 from "../../assets/images/h2.png";
import ModalVideo from "react-modal-video";

function HelpPage() {
  let [data, setData] = useState([]);
  let [currentCat, setCurrentCat] = useState("");
  let [currentTag, setCurrentTag] = useState("");
  let [isOpen, setIsOpen] = useState(false);
  const [targetVideoId, setTargetVideoId] = useState("");
  let distTags = [
    "Kundenverwaltung",
    "Anträge bearbeiten",
    "Dein Profil",
    "Maschinen Versicherungen",
    "Zahlungen",
  ];
  let adminTags = [
    "Antrag",
    "Kunden",
    "Prämien / Provisionen",
    "Versicherungspartner",
  ];

  let dumyData = [
    {
      _id: 1,
      name: "01 | Dein Kundenbereich",
      video: "812391992",
      video_thumb: require("../../assets/videos/293856731.jpeg"),
      time: 2,
      category: "dist",
      tags: ["Kundenverwaltung", "Maschinen Versicherungen"],
      description:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore",
    },
    {
      _id: 2,
      name: "02 | Kunde hinzufügen",
      video: "812391992",
      video_thumb: require("../../assets/videos/293856731.jpeg"),
      time: 5,
      category: "dist",
      tags: ["Kundenverwaltung", "Anträge bearbeiten"],
      description:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore",
    },
    {
      _id: 3,
      name: "03 | Kundenstatus ändern",
      video: "812391992",
      video_thumb: require("../../assets/videos/293856731.jpeg"),
      time: 3,
      category: "dist",
      tags: ["Kundenverwaltung", "Anträge bearbeiten"],
      description:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore",
    },
    {
      _id: 4,
      name: "04 | Antrag ausfüllen",
      video: "812391992",
      video_thumb: require("../../assets/videos/293856731.jpeg"),
      time: 3,
      category: "dist",
      tags: ["Zahlungen", "Dein Profil"],
      description:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore",
    },
    {
      _id: 5,
      name: "05 | Kundenprofil löschen",
      video: "812391992",
      video_thumb: require("../../assets/videos/293856731.jpeg"),
      time: 3,
      category: "dist",
      tags: ["Zahlungen", "Dein Profil"],
      description:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore",
    },
    {
      _id: 6,
      name: "06 | Kunden gruppieren",
      video: "812391992",
      video_thumb: require("../../assets/videos/293856731.jpeg"),
      time: 3,
      category: "dist",
      tags: ["Kundenverwaltung"],
      description:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore",
    },
    {
      _id: 7,
      channel: "vimeo",
      time: 1,
      name: "01 | Warum versitech?",
      video: "812391992",
      video_thumb: require("../../assets/videos/812391992.jpeg"),
      category: "admin",
      tags: ["Antrag", "Versicherungspartner", "Kunden"],
    },
    {
      _id: 8,
      channel: "vimeo",
      time: 1,
      name: "02 | Listenpreis oder Kaufpreis?",
      video: "814510878",
      video_thumb: require("../../assets/videos/814510878.jpeg"),
      category: "admin",
      tags: ["Antrag", "Versicherungspartner"],
    },
    {
      _id: 9,
      channel: "vimeo",
      time: 3,
      name: "03 | Verglasung mitversichert?",
      video: "814512180",
      video_thumb: require("../../assets/videos/814512180.jpeg"),
      category: "admin",
      tags: ["Kunden", "Prämien / Provisionen"],
    },
    {
      _id: 10,
      channel: "vimeo",
      time: 1,
      name: "04 | Verschleißteile",
      video: "814514340",
      video_thumb: require("../../assets/videos/814514340.jpeg"),
      category: "admin",
      tags: ["Antrag", "Kunden"],
    },
    {
      _id: 11,
      channel: "vimeo",
      time: 1,
      name: "05 | Verleihen an Dritte",
      video: "814514579",
      video_thumb: require("../../assets/videos/814514579.jpeg"),
      category: "admin",
      tags: ["Antrag", "Versicherungspartner"],
    },
    {
      _id: 12,
      channel: "vimeo",
      time: 1,
      name: "06 | Teilschaden",
      video: "814514812",
      video_thumb: require("../../assets/videos/814514812.jpeg"),
      category: "admin",
      tags: ["Antrag", "Versicherungspartner"],
    },
    {
      _id: 13,
      channel: "vimeo",
      time: 1,
      name: "07 | Zuwegungskosten",
      video: "814514972",
      video_thumb: require("../../assets/videos/814514972.jpg"),
      category: "admin",
      tags: ["Antrag", "Versicherungspartner"],
    },
    {
      _id: 14,
      channel: "vimeo",
      time: 1,
      name: "08 | Vorsteuerabzugsberechtigung",
      video: "814515022",
      video_thumb: require("../../assets/videos/814515022.jpeg"),
      category: "admin",
      tags: ["Antrag", "Versicherungspartner"],
    },
  ];

  let categoryTriger = (categoryName) => {
    if (currentCat == categoryName) {
      setCurrentCat("");
    } else {
      setCurrentCat(categoryName);
    }
    setData(dumyData);
    console.log("currentCat", currentCat);
  };

  let tagTriger = (tagName) => {
    console.log(tagName);
    if (currentTag == tagName) {
      setCurrentTag("");
      setData(dumyData);
    } else {
      setCurrentTag(tagName);
    }

    let newData = dumyData.filter((item) => item.tags.includes(tagName));
    if (newData.length > 0) {
      setCurrentCat(newData[0].category);
    }

    setData([...newData]);
    console.log("tagName", tagName);
    console.log("currentTag", currentTag);
  };

  let get = async () => {
    // db den gelecek daha sonra
    setData(dumyData);
  };

  useEffect(() => {
    get();
  }, []);

  let searchFunc = (e) => {
    if (e.target.value.length > 2) {
      let newData = dumyData.filter(
        (x) =>
          x?.name?.toUpperCase().includes(e.target.value.toUpperCase()) ||
          x?.description?.toUpperCase().includes(e.target.value.toUpperCase())
      );
      setData(newData);
    } else {
      get();
    }
  };

  return (
    <>
      <div className="container">
        <div className="col-12">
          <div className="d-flex">
            <div className="me-auto page-title-holder">
              <h2 className="section-title text-dark ">Hilfe & Service</h2>
            </div>
            <div className="search">
              <div className="input-group">
                <span className="input-group-text">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.378"
                    height="25.506"
                    viewBox="0 0 25.378 25.506"
                  >
                    <g
                      id="Ellipse_14"
                      data-name="Ellipse 14"
                      transform="translate(0 11.314) rotate(-45)"
                      fill="none"
                      stroke="#344167"
                      strokeWidth="1"
                    >
                      <circle cx="8" cy="8" r="8" stroke="none" />
                      <circle cx="8" cy="8" r="7.5" fill="none" />
                    </g>
                    <g
                      id="Ellipse_15"
                      data-name="Ellipse 15"
                      transform="translate(2.552 11.441) rotate(-45)"
                      fill="none"
                      stroke="#344167"
                      strokeWidth="1"
                    >
                      <circle cx="6.195" cy="6.195" r="6.195" stroke="none" />
                      <circle cx="6.195" cy="6.195" r="5.695" fill="none" />
                    </g>
                    <g
                      id="Rectangle_82"
                      data-name="Rectangle 82"
                      transform="translate(14.771 17.728) rotate(-45)"
                      fill="none"
                      stroke="#344167"
                      strokeWidth="1"
                    >
                      <rect width="4" height="11" stroke="none" />
                      <rect x="0.5" y="0.5" width="3" height="10" fill="none" />
                    </g>
                  </svg>
                </span>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Suche"
                  aria-label="Search"
                  aria-describedby="basic-search"
                  onChange={searchFunc}
                />
              </div>
            </div>
          </div>
        </div>

        {/* <ModalVideo channel='vimeo' isOpen={isOpen} videoId={videoUrl} onClose={() => setIsOpen(false)} /> */}
        <ModalVideo
          channel="custom"
          isOpen={isOpen}
          url={
            targetVideoId
              ? require(`../../assets/videos/${targetVideoId}.mp4`)
              : ""
          }
          
          onClose={() => setIsOpen(false)}
        />

        <div className="hilfe mt-5">
          <div className="row">
            <div className="col-6">
              <div
                className={`box ${currentCat == "dist" && "active"}`}
                onClick={() => categoryTriger("dist")}
                style={{ cursor: "pointer" }}
              >
                <img className="overlay" src={hilfe01} />
                <div className="box-content">
                  <h5>Software erklärt</h5>
                  <h3>Ich habe Fragen zur Software</h3>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div
                className={`box ${currentCat == "admin" && "active"}`}
                onClick={() => categoryTriger("admin")}
                style={{ cursor: "pointer" }}
              >
                <img className="overlay" src={hilfe02} />
                <div className="box-content">
                  <h5>Das VersiTech Glossar</h5>
                  <h3>Ich habe Fragen zu Begrifflichkeiten</h3>
                </div>
              </div>
            </div>
          </div>

          {(currentCat == "dist" || currentCat == "") && (
            <div className="helps">
              <h4>Hilfsvideos: Die versitech Software erklärt</h4>
              <div className="buttons mt-3">
                {distTags.map((item, index) => (
                  <button
                    key={index}
                    className={`btn col ${currentTag == item ? "active" : ""}`}
                    onClick={() => tagTriger(item)}
                  >
                    {item}
                  </button>
                ))}
              </div>

              <div className="row">
                {data.slice(0, 2).map((item, index) => (
                  <>
                    {item.category == "dist" && (
                      <div className="col-6 col-lg-4">
                        <div className="video-wrap">
                          <div className="img-wrap">
                            <img src={item.video_thumb} className="img-fluid" />

                            <button
                              className="play"
                              onClick={() => {
                                setIsOpen(true);
                                setTargetVideoId(item.video);
                                // setChannel(item.channel);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="84"
                                height="84"
                                viewBox="0 0 84 84"
                              >
                                <g
                                  id="Group_154"
                                  data-name="Group 154"
                                  transform="translate(-960 -984)"
                                >
                                  <circle
                                    id="Ellipse_23"
                                    data-name="Ellipse 23"
                                    cx="42"
                                    cy="42"
                                    r="42"
                                    transform="translate(960 984)"
                                    fill="#dbe9f6"
                                    opacity="0.551"
                                  />
                                  <path
                                    id="Polygon_8"
                                    data-name="Polygon 8"
                                    d="M16.415,4.442a3,3,0,0,1,5.183,0L35.38,28.07a3,3,0,0,1-2.591,4.512H5.223A3,3,0,0,1,2.632,28.07Z"
                                    transform="translate(1022.291 1006.994) rotate(90)"
                                    fill="#91bed4"
                                  />
                                </g>
                              </svg>
                            </button>
                          </div>
                          <div className="content">
                            <div className="d-flex justify-content-between align-items-baseline mt-2">
                              <h5 className="title">{item.name}</h5>
                              <div className="time-wrap">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  width="14.613"
                                  height="14.584"
                                  viewBox="0 0 14.613 14.584"
                                >
                                  <g
                                    id="Group_161"
                                    data-name="Group 161"
                                    opacity="0.55"
                                  >
                                    <g
                                      id="Group_160"
                                      data-name="Group 160"
                                      clipPath="url(#clipPath)"
                                    >
                                      <path
                                        id="Path_289"
                                        data-name="Path 289"
                                        d="M7.734,14.584H6.878a.786.786,0,0,0-.11-.023,6.962,6.962,0,0,1-3.188-1A7.2,7.2,0,0,1,.114,8.546C.063,8.273.037,7.995,0,7.719V6.864q.017-.154.035-.308a7.164,7.164,0,0,1,1.79-4.074A7.159,7.159,0,0,1,7,.009a6.957,6.957,0,0,1,4.03,1.016A7.185,7.185,0,0,1,14.5,6.037c.051.273.076.552.114.828v.855c-.012.1-.024.2-.035.307a7.2,7.2,0,0,1-1.835,4.127A7.17,7.17,0,0,1,8.565,14.47c-.275.049-.554.076-.83.113m-.425-1.133A6.159,6.159,0,1,0,1.135,7.3a6.168,6.168,0,0,0,6.174,6.153"
                                        transform="translate(0 0)"
                                      />
                                      <path
                                        id="Path_290"
                                        data-name="Path 290"
                                        d="M188.836,97.052c0-.551,0-1.1,0-1.652a.567.567,0,0,1,.77-.559.582.582,0,0,1,.365.588q0,1.082,0,2.164c0,.247,0,.494,0,.74a.238.238,0,0,0,.109.224c.63.466,1.255.939,1.884,1.407a.566.566,0,1,1-.643.929c-.392-.284-.777-.58-1.165-.87-.342-.256-.681-.517-1.028-.767a.664.664,0,0,1-.293-.582c0-.541,0-1.082,0-1.623"
                                        transform="translate(-182.097 -91.42)"
                                      />
                                    </g>
                                  </g>
                                </svg>
                                <span>{item.time} min</span>
                              </div>
                            </div>

                            <div className="contact-wrap mt-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                              >
                                <g
                                  id="Group_212"
                                  data-name="Group 212"
                                  opacity="0.624"
                                >
                                  <g
                                    id="Group_211"
                                    data-name="Group 211"
                                    clipPath="url(#clipPath)"
                                  >
                                    <path
                                      id="Path_309"
                                      data-name="Path 309"
                                      d="M18.066,19.2h-.413c-.124-.04-.249-.078-.372-.12-1.169-.4-2.34-.8-3.506-1.208a.494.494,0,0,0-.406.025A9.4,9.4,0,0,1,.151,11.048C.089,10.693.05,10.334,0,9.977V8.852A1.443,1.443,0,0,0,.03,8.69,9.277,9.277,0,0,1,1.258,4.7,9.4,9.4,0,0,1,18.8,9.079a9.323,9.323,0,0,1-.89,4.323.389.389,0,0,0-.031.259c.446,1.323.9,2.643,1.352,3.963v.412a1.4,1.4,0,0,1-.563.885,4.922,4.922,0,0,1-.6.277M1.14,9.388a8.206,8.206,0,0,0,3.331,6.592,8.015,8.015,0,0,0,4.847,1.653,8.072,8.072,0,0,0,3.814-.887.867.867,0,0,1,.736-.045c.475.173.956.331,1.434.5.8.276,1.605.555,2.412.822A.334.334,0,0,0,18,17.985c.058-.064.043-.2.049-.3a.373.373,0,0,0-.041-.123c-.418-1.214-.829-2.432-1.26-3.642a1.023,1.023,0,0,1,.054-.874,7.683,7.683,0,0,0,.853-3.458,7.987,7.987,0,0,0-3.685-7.036,7.9,7.9,0,0,0-7.588-.832A8.2,8.2,0,0,0,1.14,9.388"
                                      transform="translate(0 0)"
                                      fill="rgba(0,0,0)"
                                    />
                                    <path
                                      id="Path_310"
                                      data-name="Path 310"
                                      d="M141.343,107.752c0,.6,0,1.192,0,1.784a.561.561,0,0,1-.671.571.533.533,0,0,1-.455-.5c-.009-.812-.011-1.623,0-2.435,0-.3.246-.465.607-.523a2.714,2.714,0,0,0,.782-.222,1.648,1.648,0,0,0,.828-1.792,1.693,1.693,0,0,0-3.348.253,1.048,1.048,0,0,1-.061.31.56.56,0,0,1-.648.325.58.58,0,0,1-.437-.582,2.833,2.833,0,0,1,5.624-.458,2.815,2.815,0,0,1-2.09,3.228c-.041.011-.081.026-.13.042"
                                      transform="translate(-131.463 -97.348)"
                                      fill="rgba(0,0,0)"
                                    />
                                    <path
                                      id="Path_311"
                                      data-name="Path 311"
                                      d="M186.855,289.85a.565.565,0,1,1-.563.555.562.562,0,0,1,.563-.555"
                                      transform="translate(-177.545 -276.241)"
                                      fill="rgba(0,0,0)"
                                    />
                                  </g>
                                </g>
                              </svg>
                              <span>
                                Du hast immer noch eine Frage? Sende uns eine
                                Mail an office@versitech.at
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
          )}

          {(currentCat == "admin" || currentCat == "") && (
            <div className="helps">
              <h4>Hilfsvideos: Die versitech Software erklärt</h4>
              <div className="buttons mt-3">
                {adminTags.map((item, index) => (
                  <button
                    key={index}
                    className={`btn col ${currentTag == item ? "active" : ""}`}
                    onClick={() => tagTriger(item)}
                  >
                    {item}
                  </button>
                ))}
              </div>

              <div className="row">
                {data.map((item, index) => (
                  <>
                    {item.category == "admin" && (
                      <div className="col-6 col-lg-4">
                        <div className="video-wrap">
                          <div className="img-wrap">
                            <img
                              src={item.video_thumb}
                              alt="img"
                              className="img-fluid"
                            />
                            <button
                              className="play"
                              onClick={() => {
                                setIsOpen(true);
                                setTargetVideoId(item?.video);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="84"
                                height="84"
                                viewBox="0 0 84 84"
                              >
                                <g
                                  id="Group_154"
                                  data-name="Group 154"
                                  transform="translate(-960 -984)"
                                >
                                  <circle
                                    id="Ellipse_23"
                                    data-name="Ellipse 23"
                                    cx="42"
                                    cy="42"
                                    r="42"
                                    transform="translate(960 984)"
                                    fill="#dbe9f6"
                                    opacity="0.551"
                                  />
                                  <path
                                    id="Polygon_8"
                                    data-name="Polygon 8"
                                    d="M16.415,4.442a3,3,0,0,1,5.183,0L35.38,28.07a3,3,0,0,1-2.591,4.512H5.223A3,3,0,0,1,2.632,28.07Z"
                                    transform="translate(1022.291 1006.994) rotate(90)"
                                    fill="#91bed4"
                                  />
                                </g>
                              </svg>
                            </button>
                          </div>
                          <div className="content">
                            <div className="d-flex justify-content-between align-items-center mt-2">
                              <h5 className="title">{item.name}</h5>
                              <div className="time-wrap">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  width="14.613"
                                  height="14.584"
                                  viewBox="0 0 14.613 14.584"
                                >
                                  <g
                                    id="Group_161"
                                    data-name="Group 161"
                                    opacity="0.55"
                                  >
                                    <g
                                      id="Group_160"
                                      data-name="Group 160"
                                      clipPath="url(#clipPath)"
                                    >
                                      <path
                                        id="Path_289"
                                        data-name="Path 289"
                                        d="M7.734,14.584H6.878a.786.786,0,0,0-.11-.023,6.962,6.962,0,0,1-3.188-1A7.2,7.2,0,0,1,.114,8.546C.063,8.273.037,7.995,0,7.719V6.864q.017-.154.035-.308a7.164,7.164,0,0,1,1.79-4.074A7.159,7.159,0,0,1,7,.009a6.957,6.957,0,0,1,4.03,1.016A7.185,7.185,0,0,1,14.5,6.037c.051.273.076.552.114.828v.855c-.012.1-.024.2-.035.307a7.2,7.2,0,0,1-1.835,4.127A7.17,7.17,0,0,1,8.565,14.47c-.275.049-.554.076-.83.113m-.425-1.133A6.159,6.159,0,1,0,1.135,7.3a6.168,6.168,0,0,0,6.174,6.153"
                                        transform="translate(0 0)"
                                      />
                                      <path
                                        id="Path_290"
                                        data-name="Path 290"
                                        d="M188.836,97.052c0-.551,0-1.1,0-1.652a.567.567,0,0,1,.77-.559.582.582,0,0,1,.365.588q0,1.082,0,2.164c0,.247,0,.494,0,.74a.238.238,0,0,0,.109.224c.63.466,1.255.939,1.884,1.407a.566.566,0,1,1-.643.929c-.392-.284-.777-.58-1.165-.87-.342-.256-.681-.517-1.028-.767a.664.664,0,0,1-.293-.582c0-.541,0-1.082,0-1.623"
                                        transform="translate(-182.097 -91.42)"
                                      />
                                    </g>
                                  </g>
                                </svg>
                                <span>{item.time} min</span>
                              </div>
                            </div>

                            <div className="contact-wrap mt-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                              >
                                <g
                                  id="Group_212"
                                  data-name="Group 212"
                                  opacity="0.624"
                                >
                                  <g
                                    id="Group_211"
                                    data-name="Group 211"
                                    clipPath="url(#clipPath)"
                                  >
                                    <path
                                      id="Path_309"
                                      data-name="Path 309"
                                      d="M18.066,19.2h-.413c-.124-.04-.249-.078-.372-.12-1.169-.4-2.34-.8-3.506-1.208a.494.494,0,0,0-.406.025A9.4,9.4,0,0,1,.151,11.048C.089,10.693.05,10.334,0,9.977V8.852A1.443,1.443,0,0,0,.03,8.69,9.277,9.277,0,0,1,1.258,4.7,9.4,9.4,0,0,1,18.8,9.079a9.323,9.323,0,0,1-.89,4.323.389.389,0,0,0-.031.259c.446,1.323.9,2.643,1.352,3.963v.412a1.4,1.4,0,0,1-.563.885,4.922,4.922,0,0,1-.6.277M1.14,9.388a8.206,8.206,0,0,0,3.331,6.592,8.015,8.015,0,0,0,4.847,1.653,8.072,8.072,0,0,0,3.814-.887.867.867,0,0,1,.736-.045c.475.173.956.331,1.434.5.8.276,1.605.555,2.412.822A.334.334,0,0,0,18,17.985c.058-.064.043-.2.049-.3a.373.373,0,0,0-.041-.123c-.418-1.214-.829-2.432-1.26-3.642a1.023,1.023,0,0,1,.054-.874,7.683,7.683,0,0,0,.853-3.458,7.987,7.987,0,0,0-3.685-7.036,7.9,7.9,0,0,0-7.588-.832A8.2,8.2,0,0,0,1.14,9.388"
                                      transform="translate(0 0)"
                                      fill="rgba(0,0,0)"
                                    />
                                    <path
                                      id="Path_310"
                                      data-name="Path 310"
                                      d="M141.343,107.752c0,.6,0,1.192,0,1.784a.561.561,0,0,1-.671.571.533.533,0,0,1-.455-.5c-.009-.812-.011-1.623,0-2.435,0-.3.246-.465.607-.523a2.714,2.714,0,0,0,.782-.222,1.648,1.648,0,0,0,.828-1.792,1.693,1.693,0,0,0-3.348.253,1.048,1.048,0,0,1-.061.31.56.56,0,0,1-.648.325.58.58,0,0,1-.437-.582,2.833,2.833,0,0,1,5.624-.458,2.815,2.815,0,0,1-2.09,3.228c-.041.011-.081.026-.13.042"
                                      transform="translate(-131.463 -97.348)"
                                      fill="rgba(0,0,0)"
                                    />
                                    <path
                                      id="Path_311"
                                      data-name="Path 311"
                                      d="M186.855,289.85a.565.565,0,1,1-.563.555.562.562,0,0,1,.563-.555"
                                      transform="translate(-177.545 -276.241)"
                                      fill="rgba(0,0,0)"
                                    />
                                  </g>
                                </g>
                              </svg>
                              <span>
                                Du hast immer noch eine Frage? Sende uns eine
                                Mail an office@versitech.at
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default HelpPage;
