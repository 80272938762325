import { useEffect, useState } from "react";
import CustomerCards from "../../../components/CustomersCards";
import axiosInstance from "../../../utils/axios";
import { getUserFromLocalStorage } from "../../../utils/localStorage";
function DistributorCustomersPage() {
  let [loading, setLoading] = useState(true);
  let [data, setData] = useState([]);
  const { role } = getUserFromLocalStorage();

  let get = async () => {
    let response;
    console.log(role);
    if (role === "admin") {
      response = await axiosInstance.get("/api/admin/customers", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    } else {
      response = await axiosInstance.get("/api/distributor/customers", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    }

    const result = await response.data.result;
    console.log(result);
    setData(result);
    setLoading(false);
    // console.log({ result })
  };

  useEffect(() => {
    get();
    console.log(data);
  }, []);

  let searchFunc = (e) => {
    if (e.target.value.length > 2) {
      let newData = data.filter(
        (x) =>
          x?.customer?.fullName?.toUpperCase()
            .includes(e.target.value.toUpperCase()) ||
          x?.customer?.company?.name?.toUpperCase()
            .includes(e.target.value.toUpperCase()) ||
          x?.customer?.email?.toUpperCase().includes(e.target.value.toUpperCase())
      );
      setData(newData);
    } else {
      get();
    }
  };

  return (
    <>
      <CustomerCards
        data={data}
        searchFunc={searchFunc}
        loading={loading}
      ></CustomerCards>
    </>
  );
}

export default DistributorCustomersPage;
