import { useEffect, useState } from "react";
import axiosInstance from "../../utils/axios";
import CustomersCards from "../../components/CustomersCards";
import { useParams } from "react-router-dom";

function CustomerContractsPage() {
  let [loading, setLoading] = useState(true);
  let [data, setData] = useState([]);
  const { id } = useParams();

  let get = async () => {
    const response = await axiosInstance.get(`/api/customer/${id}/contracts`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    const result = await response.data.result;
    console.log(result);
    setData(result);
    setLoading(false);
  };

  useEffect(() => {
    get();
    console.log(data);
  }, []);

  let searchFunc = (e) => {
    if (e.target.value.length > 2) {
      let newData = data.filter(
        (x) =>
          x.customer.fullName
            .toUpperCase()
            .includes(e.target.value.toUpperCase()) ||
          x.customer.company.name
            .toUpperCase()
            .includes(e.target.value.toUpperCase())
      );
      setData(newData);
    } else {
      get();
    }
  };

  return (
    <>
      <CustomersCards
        data={data}
        searchFunc={searchFunc}
        loading={loading}
      ></CustomersCards>
    </>
  );
}

export default CustomerContractsPage;
