import { useSelector } from "react-redux";
import { onValueChange } from "../../features/contract/customerSlice";
import { Tooltip } from "react-tooltip";
import {
  updateDeductibleValue,
  updateSerialNumber,
  updateProtectType,
  calculateOneItem,
  calculateTotal,
} from "../../features/contract/calculatorSlice";
import { useDispatch } from "react-redux";
import { showAlert } from "../../features/alertSlice";
function Form3() {
  const dispatch = useDispatch();
  const {
    fullName,
    ibanCode,
    iban,
    country,
    customerErrors,
    bankName,
    swiftCode,
    debtFinancing,
    insureName,
    insureType,
  } = useSelector((store) => store.customer);
  const { machines, protectType } = useSelector((store) => store.calculator);

  const onProtectChange = (e) => {
    const value = e.target.value;
    for (let machine of machines) {
      if (!machine.deductible) {
        dispatch(
          showAlert({
            alertType: "warning",
            alertContent: "Please select deductible for all machines",
          })
        );
        return;
      }
    }

    dispatch(updateProtectType({ value: value }));

    for (let machine of machines) {
      dispatch(calculateOneItem({ itemId: machine.id }));
    }
    dispatch(calculateTotal());
  };
  return (
    <>
      <div className="card-header border-0 bg-light fw-bold">
        <p className="my-auto">Zertifikat</p>
      </div>

      <div className="card-body bg-light">
        <p className="lead fs-5 mb-3">Versicherer</p>
        <input
          type="text"
          name="insureName"
          id="insureNameField"
          className="form-control form-control-sm bg-light shadow-none"
          placeholder="Versicherer *"
          required
          minLength={3}
          value={insureName}
          onChange={(e) => {
            dispatch(
              onValueChange({ name: e.target.name, value: e.target.value })
            );
          }}
        />
        <p className="text-sm text-danger" style={{ fontSize: 12 }}>
          {customerErrors.find((error) => error.field === "insureName")?.text}
        </p>

        <div className="d-flex mt-3 align-items-center justify-content-between">
          <p className="lead  my-auto">Versicherer auswählen *</p>
          <div className="d-flex w-25 justify-content-between mx-0">
            {[
              { label: "R+V", value: "RV" },
              { label: "Ergo", value: "ERGO" },
            ].map((option, index) => {
              return (
                <div key={index} className="input-group">
                  <input
                    type="radio"
                    className="btn-check btn-sm"
                    name="insureType"
                    id={`insureType-${index}`}
                    autoComplete="off"
                    value={insureType}
                    onChange={(e) => {
                      dispatch(
                        onValueChange({
                          name: e.target.name,
                          value: e.target.value,
                        })
                      );
                    }}
                  />
                  <label
                    className="btn btn-outline-dark  rounded-1 btn-sm w-100 ms-1"
                    htmlFor={`insureType-${index}`}
                  >
                    {option.label}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <p className="text-sm text-danger" style={{ fontSize: 12 }}>
          {customerErrors.find((error) => error.field === "insureType")?.text}
        </p>
        <hr className="border border-primary my-4" />

        {machines &&
          machines.map((machine, index) => {
            return (
              <div key={index} className="mb-5">
                <p className="lead text-primary ">
                  {machine?.machineObj?.title}
                </p>

                <div className="row mb-2">
                  <div className="col-12 col-md-5 col-lg-5">
                    <p className="">Selbstbehalt *</p>
                  </div>
                  <div className="col-12 col-md-7 col-lg-7 mx-0">
                    <div className="d-flex  justify-content-between ">
                      {machine?.discountsList &&
                        machine?.discountsList.map((discount, index) => {
                          const { deductible } = discount;
                          return (
                            <div key={index} className="input-group mx-1">
                              <input
                                type="radio"
                                className="btn-check"
                                name={`deductible-${machine.machine}`}
                                id={`deductible-${machine.machine}-${index}`}
                                autoComplete="off"
                                value={deductible}
                                // checked={deductible == machine.deductible}
                                onChange={async (e) => {
                                  dispatch(
                                    updateDeductibleValue({
                                      itemId: machine.machine,
                                      name: e.target.name,
                                      value: { deductible: e.target.value },
                                    })
                                  );
                                }}
                              />
                              <label
                                className="btn btn-outline-dark  rounded-1 btn-sm w-100"
                                htmlFor={`deductible-${machine.machine}-${index}`}
                              >
                                {deductible} €
                              </label>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-12 col-md-5 col-lg-5">
                    <p className="my-auto">
                    Seriennummer / Fabriksnummer / Motornummer
                    </p>
                  </div>
                  <div className="col-12 col-md-7 col-lg-7">
                    <input
                      type="text"
                      name="serialNumber"
                      id="serialNumberName"
                      className="form-control form-control-sm bg-light shadow-none"
                      placeholder="Seriennummer / Fabriksnummer / Motornummer"
                      required
                      value={machine.serialNumber}
                      onChange={(e) => {
                        dispatch(
                          updateSerialNumber({
                            itemId: machine.machine,
                            value: e.target.value,
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}

        {/* Customer Address Information's */}
        {/* <p className="lead fs-5 mb-3">Unterschrift deines Kunden</p> */}

        <hr className="border border-primary my-4" />
        <p className="lead">Protect *</p>
        <div className="row">
          <div className="col-12 col-md-4 col-lg-4 px-2">
            <div className="card protectType-card border-dark">
              <div className="card-header">
                <p className="lead my-auto text-center">
                  Maschinen-Teilversicherung (MTV)
                </p>
              </div>
              <div className="card-body">
                <div className="d-flex justify-contact-start align-items-start mb-2">
                  <span className="fs-4 text-primary ">
                    <i className="bi bi-check-lg"></i>
                  </span>
                  <p className="ms-2">
                    Diebstahl, Einbruchdiebstahl oder Raub.
                  </p>
                </div>

                <div className="d-flex justify-contact-start align-items-start mb-2">
                  <span className="fs-4 text-primary ">
                    <i className="bi bi-check-lg"></i>
                  </span>
                  <p className="ms-2">
                    Unbefugter Gebrauch durch Betriebsfremde
                  </p>
                </div>

                <div className="d-flex justify-contact-start align-items-start mb-2">
                  <span className="fs-4 text-primary ">
                    <i className="bi bi-check-lg"></i>
                  </span>
                  <p className="ms-2">Glasbruchschäden</p>
                </div>

                <div className="d-flex justify-contact-start align-items-start mb-2">
                  <span className="fs-4 text-primary ">
                    <i className="bi bi-check-lg"></i>
                  </span>
                  <p className="ms-2">Versaufen, Verschlammen</p>
                </div>
              </div>

              <div className="card-footer">
                <div className="input-group">
                  <input
                    type="radio"
                    className="btn-check"
                    name="protectType"
                    id={`mtv`}
                    autoComplete="off"
                    value={"mtv"}
                    onChange={onProtectChange}
                    checked={protectType === "mtv"}
                  />
                  <label
                    className={`btn btn-outline-dark btn-sm w-75 rounded-2 mx-auto ${
                      protectType === "mtv" && "checked"
                    }`}
                    htmlFor={`mtv`}
                  >
                    Tarif wählen
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 col-lg-4 px-2">
            <div className="card protectType-card border-dark">
              <div className="card-header">
                <p className="lead my-auto text-center">Kasko</p>
              </div>
              <div className="card-body">
                <div className="d-flex justify-contact-start align-items-start mb-2">
                  <span className="fs-4 text-primary ">
                    <i className="bi bi-check-lg"></i>
                  </span>
                  <p className="ms-2">einschließlich Deckung MTV</p>
                </div>

                <div className="d-flex justify-contact-start align-items-start mb-2">
                  <span className="fs-4 text-primary ">
                    <i className="bi bi-check-lg"></i>
                  </span>
                  <p className="ms-2">Transporte (ausgenommen Seetransporte)</p>
                </div>

                <div className="d-flex justify-contact-start align-items-start mb-2">
                  <span className="fs-4 text-primary ">
                    <i className="bi bi-check-lg"></i>
                  </span>
                  <p className="ms-2">
                    Kaskoschäden (Verkehrs-/Unfallereignisse)
                  </p>
                </div>

                <div className="d-flex justify-contact-start align-items-start mb-2">
                  <span className="fs-4 text-primary ">
                    <i className="bi bi-check-lg"></i>
                  </span>
                  <p className="ms-2">Erdrutsch</p>
                </div>

                <div className="d-flex justify-contact-start align-items-start mb-2">
                  <span className="fs-4 text-primary ">
                    <i className="bi bi-check-lg"></i>
                  </span>
                  <p className="ms-2">Glasbruchschäden</p>
                </div>
              </div>

              <div className="card-footer">
                <div className="input-group">
                  <input
                    type="radio"
                    className="btn-check"
                    name="protectType"
                    id={`hull`}
                    autoComplete="off"
                    value={"hull"}
                    onChange={onProtectChange}
                    checked={protectType === "hull"}
                  />
                  <label
                    className={`btn btn-outline-dark btn-sm w-75 rounded-2 mx-auto ${
                      protectType === "hull" && "checked"
                    }`}
                    htmlFor={`hull`}
                  >
                    Tarif wählen
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4 col-lg-4 px-2">
            <div className="card protectType-card border-dark">
              <div className="card-header">
                <p className="lead my-auto text-center">Vollschutz</p>
              </div>
              <div className="card-body">
                <div className="d-flex justify-contact-start align-items-start mb-2">
                  <span className="fs-4 text-primary ">
                    <i className="bi bi-check-lg"></i>
                  </span>
                  <p className="ms-2">einschließlich Deckung Kasko</p>
                </div>

                <div className="d-flex justify-contact-start align-items-start mb-2">
                  <span className="fs-4 text-primary ">
                    <i className="bi bi-check-lg"></i>
                  </span>
                  <p className="ms-2">
                    Innere Betriebsschäden - elektronische Bauteile - Erst-
                    Risiko-Summe 2.500 EUR
                  </p>
                </div>

                <div className="d-flex justify-contact-start align-items-start mb-2">
                  <span className="fs-4 text-primary ">
                    <i className="bi bi-check-lg"></i>
                  </span>
                  <p className="ms-2">
                    Kurzschluss, Überstrom und Überspannung
                  </p>
                </div>

                <div className="d-flex justify-contact-start align-items-start mb-2">
                  <span className="fs-4 text-primary ">
                    <i className="bi bi-check-lg"></i>
                  </span>
                  <p className="ms-2">Bedienungsfehler aller Art</p>
                </div>

                <div className="d-flex justify-contact-start align-items-start mb-2">
                  <span className="fs-4 text-primary ">
                    <i className="bi bi-check-lg"></i>
                  </span>
                  <p className="ms-2">Ungeschicklichkeit und Fahrlässigkeit</p>
                </div>
              </div>

              <div className="card-footer">
                <div className="input-group">
                  <input
                    type="radio"
                    className="btn-check"
                    name="protectType"
                    id={`fullProduction`}
                    autoComplete="off"
                    value={"fullProduction"}
                    checked={protectType === "fullProduction"}
                    onChange={onProtectChange}
                  />
                  <label
                    className={`btn btn-outline-dark btn-sm w-75 rounded-2 mx-auto ${
                      protectType === "fullProduction" && "checked"
                    }`}
                    htmlFor={`fullProduction`}
                  >
                    Tarif wählen
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="border border-primary my-4" />

        <p className="lead fs-5 mb-3">Zahlungsart</p>
        <div className="row">
          <div className="col-12">
            <input
              type="text"
              name="fullName"
              id="fullNameField"
              className="form-control form-control-sm bg-light shadow-none"
              placeholder="Name des Kontoinhabers"
              value={fullName}
              onChange={(e) => {
                dispatch(
                  onValueChange({ name: e.target.name, value: e.target.value })
                );
              }}
              required
              minLength={3}
            />
            <p className="text-sm text-danger" style={{ fontSize: 12 }}>
              {customerErrors.find((error) => error.field === "fullName")?.text}
            </p>
          </div>
        </div>
        <div className="row mt-3  row-cols-md-2 row-cols-lg-2">
          <div className="col-12 col-md-6">
            <input
              type="text"
              name="bankName"
              id="bankNameField"
              className="form-control form-control-sm bg-light shadow-none"
              placeholder="Name des Kreditinstituts"
              value={bankName}
              onChange={(e) => {
                dispatch(
                  onValueChange({ name: e.target.name, value: e.target.value })
                );
              }}
              required
              minLength={3}
            />
            <p className="text-sm text-danger" style={{ fontSize: 12 }}>
              {customerErrors.find((error) => error.field === "bankName")?.text}
            </p>
          </div>

          {country === "germany" && (
            <div className="col-12 col-md-6">
              <input
                type="text"
                name="swiftCode"
                id="swiftCodeField"
                className="form-control form-control-sm bg-light shadow-none"
                placeholder="SWIFT-Code"
                value={swiftCode}
                onChange={(e) => {
                  dispatch(
                    onValueChange({
                      name: e.target.name,
                      value: e.target.value,
                    })
                  );
                }}
                required
                minLength={3}
              />
              <p className="text-sm text-danger" style={{ fontSize: 12 }}>
                {
                  customerErrors.find((error) => error.field === "swiftCode")
                    ?.text
                }
              </p>
            </div>
          )}
        </div>

        <div className="row row-cols-md-2 row-cols-lg-2 mt-3">
          <div className="col-12 col-md-1 col-lg-1">
            <input
              type="text"
              name="ibanCode"
              id="ibanCodeField"
              className="form-control form-control-sm bg-light shadow-none"
              value={ibanCode}
              required
              minLength={3}
              readOnly
            />
          </div>
          <div className="col-12 col-md-11 col-lg-11">
            <input
              type="text"
              name="iban"
              maxLength={18}
              id="ibanField"
              className="form-control form-control-sm bg-light shadow-none"
              placeholder="IBAN"
              pattern="/^[0-9]{18,24}$/"
              value={iban}
              onChange={(e) => {
                dispatch(
                  onValueChange({ name: e.target.name, value: e.target.value })
                );
              }}
              required
              minLength={3}
            />
            <p className="text-sm text-danger" style={{ fontSize: 12 }}>
              {customerErrors.find((error) => error.field === "iban")?.text}
            </p>
          </div>
        </div>

        <hr className="border border-primary my-4" />
        <p className="lead fs-5 mb-3">Fremdfinanzierung</p>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <p className="lead  my-auto">
            Sind die Maschinen fremdfinanziert? *
            <span className="ms-2 fs-6">
              <i
                className="bi bi-info-circle"
                id="debtFinancingToolTip"
                data-tooltip-content="Als fremdfinanzierte Maschinen gelten in diesem Fall Maschinen finanziert durch Banken und Kreditgeber."
              ></i>
            </span>
          </p>
          <Tooltip className="toolTip" anchorId="debtFinancingToolTip" />
          <div className="d-flex w-50">
            {[
              { label: "Ja", value: "yes" },
              { label: "Nein", value: "no" },
            ].map((option, index) => {
              return (
                <div key={index} className="input-group">
                  <input
                    type="radio"
                    className="btn-check btn-sm"
                    name="debtFinancing"
                    id={`debtFinancing-${index}`}
                    autoComplete="off"
                    value={debtFinancing}
                    checked={option.value == debtFinancing}
                    onChange={async (e) => {
                      dispatch(
                        onValueChange({
                          name: e.target.name,
                          value: option.value,
                        })
                      );
                    }}
                  />
                  <label
                    className="btn btn-outline-dark  rounded-1 btn-sm w-75 mx-auto"
                    htmlFor={`debtFinancing-${index}`}
                  >
                    {option.label}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        {/* <div className="row row-cols-md-4 row-cols-lg-4">
          <div className="col-12 col-md-3 col-lg-3">
            <a
              href={`https://greyt.arneca.com/docusign/signRequest?signerName=Ali%20Burak&signerEmail=kulakli@gmail.com&fileName=${localStorage.getItem(
                'pdfId',
              )}&fileType=pdf&documentName=Titel%20des%20zu%20unterzeichnenden%20Dokuments&emailSubject=Bitte%20Unterschreiben&recipientId=123&returnAddress=https://greyt.arneca.com/dashboard/distributor`}
              className="btn btn-dark btn-sm"
            >
              UNTERSCHRIFT ANFORDERN
            </a>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default Form3;
