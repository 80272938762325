import moment from "moment";
const getWeeks = () => {
  const year = moment(new Date()).year();
  const startDate = moment(`${year}-01-01`).add(1, "day");
  //const endDate = moment(`${year}-12-31`);
  const endDate = moment(new Date()).add(1, "day").format("YYYY-MM-DD");
  let range = [];
  for (let m = startDate; m.isBefore(endDate); m.add(1, "weeks")) {
    const monday = m.startOf("week").add(1,"days");
    range.push(monday.toISOString());
  }

  const thisWeek = moment(new Date()).week();
  console.log({thisWeek});
  let weeks = range.map((date, index) => {
    let start = moment(date).format("DD.MM.YY");
    let end = moment(new Date(date)).add(1, "weeks").format("DD.MM.YY");
    return {
      label: `KW${index + 1}: ${start} - ${end}`,
      value: date,
      thisWeek: index+ 2 === thisWeek,
    };
  });
  return weeks;
};

export default getWeeks;
