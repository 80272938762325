import { createSlice } from "@reduxjs/toolkit";

const companiesTypes = [
  {
    id: 1,
    title: "gmbH",
    label: "GmbH",
  },
  {
    id: 2,
    title: "one-manBusiness",
    label: "Einzelunternehmen",
  },
  {
    id: 3,
    title: "publicCompany",
    label: "Aktiengesellschaft",
  },
  {
    id: 4,
    title: "cooperative",
    label: "Genossenschaft",
  },
  {
    id: 5,
    title: "gebR",
    label: "GesbR",
  },

  {
    id: 6,
    title: "GmbH&Co.KG",
    label: "GmbH & Co. KG",
  },

  {
    id: 7,
    title: "KgLimitedPartnership",
    label: "KG – Kommanditgesellschaft",
  },

  {
    id: 8,
    title: "OgOpenSociety",
    label: "OG – Offene Gesellschaft",
  },
  {
    id: 9,
    title: "Ohg",
    label: "OHG",
  },
  {
    id: 10,
    title: "foundation",
    label: "Stiftung",
  },
  {
    id: 11,
    title: "society",
    label: "Verein",
  },
];
const paymentPeriods = [
  { id: 1, label: "Jährlich", value: "yearly" },
  { id: 2, label: "Halbjährlich", value: "half-yearly" },
  { id: 3, label: "Vierteljährlich", value: "quarterly" },
  { id: 4, label: "Monatlich", value: "per-month" },
];

const currencies = ["USD", "EURO"];
const initialState = {
  customerErrors: [],
  isLoading: false,
  customerValid: false,
  companiesTypes: companiesTypes,
  paymentPeriods: paymentPeriods,
  currencies: currencies,
  companyName: "",
  companyLegalForm: "",
  email: "",
  phone: "",
  street: "",
  houseNumber: "",
  postcode: "",
  location: "",
  paymentPeriod: "per-month",
  fullName: "",
  bankName: "",
  iban: "",
  ibanCode: "AT",
  swiftCode: "",
  country: "austria",
  debtFinancing: "",
  insureName: "",
  insureType: "",
  phoneCode: "+43",
  notificationBy:"email"
};

const customerSlice = createSlice({
  name: "customerSlice",
  initialState,
  reducers: {
    setCustomerValues: (state, action) => {
      const { customer, paymentDetails } = action.payload;
      console.log({customer,paymentDetails});
      state.email = customer.email;
      state.fullName = customer.fullName;
      state.street = customer.address.street;
      state.houseNumber = customer.address.houseNumber;
      state.postcode = customer.address.postcode;
      state.location = customer.address.location;
      state.bankName = paymentDetails.bankName;
      state.swiftCode = paymentDetails.swiftCode;
      state.iban = paymentDetails.ibanNumber;
      state.companyName = customer.company.name;
      state.companyLegalForm = customer.company.legalForm;
      state.notificationBy = customer.notificationBy;
      state.phone = customer.phone.slice(3);
      state.phoneCode = customer.phone.slice(0, 3);
    },
    onValueChange: (state, action) => {
      const { name, value } = action.payload;
      console.log(name);
      if (
        name === "houseNumber" ||
        name === "insureName" ||
        name === "insureType" ||
        name === "iban"
      ) {
        if (value === "") {
          state.customerErrors = [
            ...state.customerErrors,
            {
              field: name,
              type: "invalid",
              text: `Bitte füllen Sie das Pflichtfeld aus`,
            },
          ];
        }
        state.customerErrors = [...state.customerErrors].filter(
          (error) => error.field !== name
        );
      } else {
        if (name === "phone") {
          const pattern =
            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
          if (value !== "" && !value.match(pattern)) {
            state.customerErrors = [
              ...state.customerErrors,
              {
                field: name,
                type: "invalid",
                text: `Bitte geben Sie eine gültige Telefonnummer ein`,
              },
            ];
            state.phone = value;
            return;
          }
        }
        if (name === "iban") {
          const pattern = /^[0-9]{18,24}$/;
          if (value.length < 10) {
            state.customerErrors = [
              ...state.customerErrors,
              {
                field: name,
                type: "invalid",
                text: `Bitte geben Sie einen gültigen Iban-Wert ein`,
              },
            ];
            state.iban = value;  
          }
          if (!value.match(pattern)) {
            state.customerErrors = [
              ...state.customerErrors,
              {
                field: name,
                type: "invalid",
                text: `Bitte geben Sie einen gültigen Iban-Wert ein`,
              },
            ];
            state.iban = value;
            return;
          }
        }
        if (name === "houseNumber") {
          if (value.length > 4) {
            state.customerErrors = [
              ...state.customerErrors,
              {
                field: name,
                type: "invalid",
                text: `Bitte geben Sie einen gültigen Postleitzahl ein`,
              },
            ];
            state.houseNumber = value;
            return;
          }
        }
        if (name === "country") {
          state.country = value;
          state.ibanCode = value === "germany" ? "DE" : "AT";
          state.houseNumber = "";
          state.location = "";
          state.street = "";
          state.postcode = "";
        }

        if (name === "postcode") {
          let validLength = state.country === "germany" ? 5 : 4;
          if (!value.match(/^[0-9]+$/)) {
            state.customerErrors = [
              ...state.customerErrors,
              {
                field: name,
                type: "invalid",
                text: `Bitte geben Sie einen gültigen Postleitzahl ein`,
              },
            ];
            state.postcode = value;
            return;
          }
          if (value.length > validLength) {
            state.customerErrors = [
              ...state.customerErrors,
              {
                field: name,
                type: "invalid",
                text: `Bitte geben Sie einen gültigen Postleitzahl ein`,
              },
            ];
            state.postcode = value;
            return;
          }
        }

        if (name === "email") {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!value.match(pattern)) {
            state.customerErrors = [
              ...state.customerErrors,
              {
                field: name,
                type: "invalid",
                text: `Bitte geben Sie eine gültige E-Mail-Adresse ein`,
              },
            ];
            
            state.email = value;
            return;
          }
        }

        state.customerErrors = [...state.customerErrors].filter(
          (error) => error.field !== name
        );
      }

      state[name] = value;
    },
    addCustomerError: (state, action) => {
      state.customerValid = false;
      state.customerErrors = [...state.customerErrors, { ...action.payload }];
    },
    clearCustomerErrors: (state, action) => {
      state.customerValid = true;
      state.customerErrors = [];
    },
  },
});

export const {
  onValueChange,
  addCustomerError,
  clearCustomerErrors,
  setCustomerValues,
} = customerSlice.actions;

export default customerSlice.reducer;
