import React, { useState } from "react";
import axiosInstance from "../utils/axios";
import { BASE_URL } from "../utils/axios";
function DownloadButton({ recordId, target, width }) {
  const [loading, setLoading] = useState(false);
  const onContractSavePDF = async () => {
    const contractResponse = await axiosInstance.get(
      `/api/contracts/${recordId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    const contract = await contractResponse.data?.result;
      console.log('contract ----',contract);
    const response = await axiosInstance.post(
      "/api/docusign/savePdf",
      {
        target: "contractClosed",
        contractId: recordId,
        offerNo: contract.offerNo,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    const result = await response.data;
    const fileName = result.result.fileName;
    if (fileName) {
      const el = document.createElement("a");
      el.href = `${BASE_URL}/api/docusign/downloadPdf/${
        fileName.split(".")[0]
      }`;
      el.download = fileName;
      el.click();
      el.remove();
    }
  };

  const onOfferSavePDF = async () => {
    if (recordId) {
      setLoading(true);
      const contractResponse = await axiosInstance.get(
        `/api/contracts/${recordId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const contract = await contractResponse.data?.result;
      if (contract) {
        const response = await axiosInstance.post(
          "/api/docusign/savePdf",
          {
            contractId: contract._id,
            machines: contract?.machines,
            target: "offer",
            offerNo: contract?.offerNo,
            // customer: contract?.customer,
            country: contract?.country,
            taxRate: contract?.taxRate,
            companyLegalForm: contract?.customer?.company?.companyLegalForm,
            paymentPeriod: contract?.paymentPeriod,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const result = await response.data;
        const fileName = result.result.fileName;
        if (fileName) {
          const el = document.createElement("a");
          el.href = `${BASE_URL}/api/docusign/downloadPdf/${
            fileName.split(".")[0]
          }`;
          el.download = fileName;
          el.target = "_blank";
          el.click();
          el.remove();
        }
      }
      setLoading(false);
    }
  };

  return (
    <>
      <button
        onClick={target === "contract" ? onContractSavePDF : onOfferSavePDF}
        className={`btn btn-light btn-sm rounded-0 d-flex align-items-center justify-content-center ${
          width ? width : "w-50"
        }`}
        disabled={loading}
      >
        {loading ? (
          <>
            <div class="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </>
        ) : (
          <>
            <svg
              id="Group_253"
              data-name="Group 253"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="21.494"
              height="22.455"
              viewBox="0 0 21.494 22.455"
            >
              <g id="Group_252" data-name="Group 252" clipPath="url(#clipPath)">
                <path
                  id="Path_350"
                  data-name="Path 350"
                  d="M1.492,267.578c-.124-.04-.251-.075-.373-.121a1.7,1.7,0,0,1-1.113-1.563C0,264.286,0,262.679,0,261.071a.7.7,0,1,1,1.4,0c0,1.535,0,3.069,0,4.6,0,.4.093.5.484.5H19.6c.391,0,.484-.1.484-.5q0-2.291,0-4.582a.684.684,0,0,1,.423-.68.606.606,0,0,1,.729.12.865.865,0,0,1,.24.513q.026,2.433,0,4.867a1.715,1.715,0,0,1-1.318,1.612c-.056.014-.11.033-.166.05Z"
                  transform="translate(0 -245.122)"
                  fill="#344167"
                  stroke="#344167"
                  strokeWidth="0.5"
                />
                <path
                  id="Path_351"
                  data-name="Path 351"
                  d="M101.832,0a.862.862,0,0,1,.532.905q-.014,6.994-.006,13.989v.3c.089-.082.147-.132.2-.186q1.473-1.472,2.945-2.946a.714.714,0,0,1,.752-.229.7.7,0,0,1,.354,1.1,1.89,1.89,0,0,1-.164.176L102.26,17.29a.728.728,0,0,1-1.206,0Q98.93,15.166,96.8,13.043a.722.722,0,0,1-.221-.737.657.657,0,0,1,.514-.488.74.74,0,0,1,.727.255q1.454,1.461,2.913,2.916c.057.057.1.124.154.186l.063-.021v-.241q0-7.005-.006-14.011a.856.856,0,0,1,.532-.9Z"
                  transform="translate(-90.91)"
                  fill="#344167"
                  stroke="#344167"
                  strokeWidth="0.5"
                />
              </g>
            </svg>
            <span className="text-dark ms-1">
              {target === "contract" ? "Antrag" : "Angebot"}
            </span>
          </>
        )}
      </button>
    </>
  );
}

export default DownloadButton;
