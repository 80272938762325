import Alert from "../../components/Alert";
import Form1 from "../../components/calculatorForms/form1";
import Form2 from "../../components/calculatorForms/form2";
import Form3 from "../../components/calculatorForms/form3";
import useMultiStepForm from "../../hooks/useMultiStepForm";
import NavigationFooter from "../../components/NavigationFooter";
import NavigationHeader from "../../components/NavigationHeader";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import Loading from "../../components/Loading";
import formatNumber from "../../utils/formatNumber";
import { useDispatch, useSelector } from "react-redux";
import {
  calculateOneItem,
  editDeductibleValue,
  updateItem,
} from "../../features/contract/calculatorSlice";
import {
  getOneContract,
  setValues,
  calculate,
  updateMachine,
  setMachines,
} from "../../features/contract/updateContractSlice";

const forms = [
  {
    number: "1",
    title: "Maschine auswählen",
    content: <Form1></Form1>,
  },
  {
    number: "2",
    title: "Kundeninformationen",
    content: <Form2></Form2>,
  },
  {
    number: "3",
    title: "Angebotserstellung",
    content: <></>,
  },
  {
    number: "4",
    title: "Antragserstellung",
    content: <Form3></Form3>,
  },
];

const MachineSection = ({ machine, setTargetMachine }) => {
  const dispatch = useDispatch();
  let machineInfo = `(${machine?.selectedCategory?.title}- ${machine?.selectedMachine?.title} - ${machine?.machineType} - ${machine?.brand})`;
  return (
    <>
      <div className="mb-5">
        {/* <p className="lead text-primary ">{machine?.categoryObj?.title}</p> */}

        <div className="row row-cols-md-1 row-cols-lg-1 row-cols-xl-2 mb-2">
          <div className="col-12 col-md-12 col-lg-12 col-xl-6">
            <p className="d-flex flex-column align-items-start">
              Selbstbehalt *<span className="small-text">{machineInfo}</span>{" "}
            </p>
          </div>

          <div className="col-12 col-md-12 col-lg-12 col-xl-6 mx-0">
            <div className="d-flex  justify-content-between align-items-center">
              {machine?.selectedMachine?.discounts &&
                machine?.selectedMachine?.discounts?.map((discount, index) => {
                  const { deductible } = discount;
                  const targetId = Math.ceil(Math.random() * 1000);
                  return (
                    <div key={index} className="input-group mx-1">
                      <input
                        type="radio"
                        className="btn-check"
                        name={`deductible-${machine.brand}-${machine.purchasePrice}-${machine.id}`}
                        id={`deductible-${machine.brand}-${machine.purchasePrice}-${targetId}`}
                        autoComplete="off"
                        value={deductible}
                        onChange={setTargetMachine}
                        checked={deductible === Number(machine.deductible)}
                      />
                      <label
                        className="btn btn-outline-dark  rounded-1 btn-sm w-100"
                        htmlFor={`deductible-${machine.brand}-${machine.purchasePrice}-${targetId}`}
                      >
                        {deductible === 500
                          ? deductible
                          : deductible.toString().slice(0, 1) +
                            "." +
                            deductible
                              .toString()
                              .slice(1, deductible.toString().length)}
                      </label>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-12 col-md-6 col-lg-6">
            <p className="my-auto">
              Seriennummer / Fabriksnummer / Motornummer
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-6">
            <input
              type="text"
              name="serialNumber"
              id="serialNumberName"
              className="form-control form-control-sm bg-light shadow-none"
              placeholder="Seriennummer/Fabriksnummer"
              required
              value={machine?.serialNumber}
              onChange={(e) => {
                console.log(e);
                dispatch(
                  updateItem({
                    _id: machine._id,
                    id: machine.id,
                    itemId: machine.id,
                    name: e.target.name,
                    value: e.target.value,
                  })
                );
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const BonusCard = ({ machines }) => {
  return (
    <>
      <div className="card bonus-card bg-light border-0 px-2 py-1">
        <div className="card-header bg-light">
          <p className="my-auto lead">Prämie</p>
        </div>
        <div className="card-body m-0 p-0">
          <ul className="list-group m-0 p-0 bg-light">
            {machines &&
              machines.map((machine, index) => {
                return (
                  <div
                    key={index}
                    className="list-group-item bg-light border-0 m-0 cart-item "
                  >
                    <p className="fw-bold small-text text-dark item-category my-auto w-50">
                      {index + 1}. {machine.selectedMachine?.title}
                    </p>
                    <p className="lead item-details my-auto fs-6 fw-normal  small-text text-dark">
                      Hersteller: {machine?.brand} <br />
                      Baujahr: {machine?.productionYear} <br />
                      Seriennummer: {machine?.serialNumber} <br />
                    </p>

                    <div className="item-cost">
                      <p className="small-text fw-normal fw-bold text-dark">
                        {formatNumber(machine?.price)}
                        <br />
                        <span
                          className="payment-period float-end"
                          style={{ fontSize: "12px" }}
                        >
                          {" "}
                          {machine?.paymentPeriod === "per-month"
                            ? "/ Monat"
                            : machine?.paymentPeriod === "quarterly"
                            ? "/ 3 Monate"
                            : machine?.paymentPeriod === "half-yearly"
                            ? "/ 6 Monate"
                            : machine?.paymentPeriod === "yearly"
                            ? "/ Jahr"
                            : ""}
                        </span>
                      </p>
                    </div>
                  </div>
                );
              })}
          </ul>
        </div>
        <div className="card-footer  bg-light py-0">
          <div className="d-flex justify-content-between w-100">
            <p className="lead price-label d-flex flex-column">
              <span>Vorläufige Endsumme</span>
              <span>inkl. Steuern</span>
            </p>
            <p className="lead total-price">
              {formatNumber(
                Number(
                  machines
                    .map((m) => {
                      return Number(m.price);
                    })
                    .reduce((a, c) => a + c, 0)
                )
              )}
            </p>
          </div>
          {/* <p className="lead price-label">Vorläufige Endsumme</p>
          <p className="lead total-price">
            {formatNumber(
              Number(
                machines
                  .map((m) => Number(m.price))
                  .reduce((current, next) => current + next, 0)
              )
            )}
          </p> */}
        </div>
      </div>
    </>
  );
};

const EditForm = ({
  values,
  machines,
  onValueChange,
  setTargetMachine,
  errors,
}) => {
  return (
    <>
      <div className="card-header border-0 bg-light fw-bold">
        <p className="my-auto">Zertifikat</p>
      </div>

      <div className="card-body bg-light">
        <p className="lead fs-5 mb-3">Versicherer</p>
        <div className="d-flex mt-3 align-items-center justify-content-between">
          <p className="text-sm w-75  my-auto">Versicherer auswählen *</p>
          <div className="d-flex w-25 justify-content-between mx-0">
            {[
              { label: "R+V", value: "RV" },
              { label: "Ergo", value: "ERGO" },
            ].map((option, index) => {
              return (
                <div key={index} className="input-group">
                  <input
                    type="radio"
                    className="btn-check  btn-sm"
                    name="insureType"
                    id={`insureType-${index}`}
                    checked={values.insureType === option.value}
                    autoComplete="off"
                    value={option.value}
                    onChange={onValueChange}
                  />
                  <label
                    className="btn btn-outline-dark ms-3  rounded-1 btn-sm w-75 "
                    htmlFor={`insureType-${index}`}
                  >
                    {option.label}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <p className="text-sm text-danger" style={{ fontSize: 12 }}>
          {errors && errors.field === "insureType" && errors.text}
        </p>
        <hr className="border border-primary my-4" />
        <div className="d-flex align-items-start justify-content-between mb-3">
          <p className="my-auto text-sm w-75">
            Handelt es sich bei den zu versichernden Anlagen und Maschinen um
            Prototypen? *
          </p>
          {/* <Tooltip className="toolTip" anchorId="debtFinancingToolTip" /> */}
          <div className="d-flex w-25">
            {[
              { label: "Ja", value: "yes" },
              { label: "Nein", value: "no" },
            ].map((option, index) => {
              return (
                <div key={index} className="input-group">
                  <input
                    type="radio"
                    className="btn-check btn-sm"
                    name="byManufacturer"
                    id={`byManufacturer-${index}`}
                    autoComplete="off"
                    value={option.value}
                    checked={option.value == values?.byManufacturer}
                    onChange={onValueChange}
                  />
                  <label
                    className="btn btn-outline-dark  rounded-1 btn-sm w-75 mx-auto"
                    htmlFor={`byManufacturer-${index}`}
                  >
                    {option.label}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <div className="d-flex align-items-start justify-content-between mb-3">
          <p className="my-auto text-sm w-75">
            Werden die zu versichernden Anlagen und Maschinen nach den
            Vorschriften des Herstellers bzw. der Hersteller regelmäßig
            gewartet? *
          </p>
          {/* <Tooltip className="toolTip" anchorId="debtFinancingToolTip" /> */}
          <div className="d-flex w-25">
            {[
              { label: "Ja", value: "yes" },
              { label: "Nein", value: "no" },
            ].map((option, index) => {
              return (
                <div key={index} className="input-group">
                  <input
                    type="radio"
                    className="btn-check btn-sm"
                    name="isPrototypes"
                    id={`isPrototypes-${index}`}
                    autoComplete="off"
                    value={option.value}
                    checked={option.value == values?.isPrototypes}
                    onChange={onValueChange}
                  />
                  <label
                    className="btn btn-outline-dark  rounded-1 btn-sm w-75 mx-auto"
                    htmlFor={`isPrototypes-${index}`}
                  >
                    {option.label}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <div className="d-flex align-items-start justify-content-between mb-3">
          <p className="my-auto text-sm w-75">
            Gab es an der Maschine oder Anlage Vorschäden? *
          </p>
          {/* <Tooltip className="toolTip" anchorId="debtFinancingToolTip" /> */}
          <div className="d-flex w-25">
            {[
              { label: "Ja", value: "yes" },
              { label: "Nein", value: "no" },
            ].map((option, index) => {
              return (
                <div key={index} className="input-group">
                  <input
                    type="radio"
                    className="btn-check btn-sm"
                    name="availableParts"
                    id={`availableParts-${index}`}
                    autoComplete="off"
                    value={option.value}
                    checked={option.value == values?.availableParts}
                    onChange={onValueChange}
                  />
                  <label
                    className="btn btn-outline-dark  rounded-1 btn-sm w-75 mx-auto"
                    htmlFor={`availableParts-${index}`}
                  >
                    {option.label}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <hr className="border border-primary my-4" />
        {machines &&
          machines.map((machine, index) => {
            return (
              <MachineSection
                key={index}
                machine={machine}
                setValues={onValueChange}
                setTargetMachine={setTargetMachine}
              ></MachineSection>
            );
          })}
        {/* Customer Address Information's */}
        <hr className="border border-primary my-4" />
        <p className="lead fs-5 mb-3">Zahlungsart</p>
        <div className="row">
          <div className="col-12">
            <input
              type="text"
              name="fullName"
              id="fullNameField"
              className="form-control form-control-sm bg-light shadow-none"
              placeholder="Name des Kontoinhabers"
              value={values?.customer?.fullName}
              onChange={onValueChange}
              required
              minLength={3}
            />
            {/* <p className="text-sm text-danger" style={{ fontSize: 12 }}>
              {customerErrors.find((error) => error.field === "fullName")?.text}
            </p> */}
          </div>
        </div>{" "}
        <div className="row mt-3  row-cols-md-2 row-cols-lg-2">
          <div className="col-12 col-md-6">
            <input
              type="text"
              name="bankName"
              id="bankNameField"
              className="form-control form-control-sm bg-light shadow-none"
              placeholder="Name des Kreditinstituts"
              value={values?.paymentDetails?.bankName}
              onChange={onValueChange}
              required
              minLength={3}
            />
            {/* <p className="text-sm text-danger" style={{ fontSize: 12 }}>
              {customerErrors.find((error) => error.field === "bankName")?.text}
            </p> */}
          </div>

          {values?.record?.country === "germany" && (
            <div className="col-12 col-md-6">
              <input
                type="text"
                name="swiftCode"
                id="swiftCodeField"
                className="form-control form-control-sm bg-light shadow-none"
                placeholder="SWIFT-Code"
                value={values?.paymentDetails?.swiftCode}
                onChange={onValueChange}
                required
                minLength={3}
              />
              <p className="text-sm text-danger" style={{ fontSize: 12 }}>
                {/* {
                  customerErrors.find((error) => error.field === "swiftCode")
                    ?.text
                } */}
              </p>
            </div>
          )}
        </div>{" "}
        <div className="row row-cols-md-2 row-cols-lg-2 mt-3">
          <div className="col-12 col-md-2 col-lg-2">
            <input
              type="text"
              name="ibanCode"
              id="ibanCodeField"
              className="form-control form-control-sm bg-light shadow-none"
              value={values?.record?.country === "germany" ? "DE" : "AT"}
              required
              minLength={3}
              readOnly
            />
          </div>
          <div className="col-12 col-md-10 col-lg-10">
            <input
              type="text"
              name="ibanNumber"
              maxLength={18}
              id="ibanField"
              className="form-control form-control-sm bg-light shadow-none"
              placeholder="IBAN"
              pattern="/^[0-9]{18,24}$/"
              value={values.paymentDetails.ibanNumber}
              onChange={onValueChange}
              required
              minLength={3}
            />
          </div>
        </div>{" "}
        <hr className="border border-primary my-4" />
        <p className="lead fs-5 mb-3">Fremdfinanzierung</p>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <p className="text-sm w-75  my-auto">
            Sind die Maschinen fremdfinanziert? *
            <span className="ms-2 fs-6">
              <i
                className="bi bi-info-circle"
                id="debtFinancingToolTip"
                data-tooltip-content="Als fremdfinanzierte Maschinen gelten in diesem Fall Maschinen finanziert durch Banken und Kreditgeber."
              ></i>
            </span>
          </p>
          <Tooltip className="toolTip" anchorId="debtFinancingToolTip" />
          <div className="d-flex w-25">
            {[
              { label: "Ja", value: "yes" },
              { label: "Nein", value: "no" },
            ].map((option, index) => {
              return (
                <div key={index} className="input-group">
                  <input
                    type="radio"
                    className="btn-check btn-sm"
                    name="debtFinancing"
                    id={`debtFinancing-${index}`}
                    autoComplete="off"
                    value={option.value}
                    checked={option.value == values?.debtFinancing}
                    onChange={onValueChange}
                  />
                  <label
                    className="btn btn-outline-dark  rounded-1 btn-sm w-75 mx-auto"
                    htmlFor={`debtFinancing-${index}`}
                  >
                    {option.label}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

function EditContractPage() {
  const { isOpen, setIsOpen, nextStep, previousStep, openModal, loadStep } =
    useMultiStepForm(forms);
  const { id } = useParams();
  const dispatch = useDispatch();
  const { record, loading } = useSelector(
    (store) => store.updateOneContractSlice
  );
  const { country, customerErrors } = useSelector((store) => store.customer);
  const { machines } = useSelector((store) => store.calculator);

  const setTargetMachine = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    const targetMachineBrand = name.split("-")[1];
    const targetMachinePrice = name.split("-")[2];
    const targetMachineId = name.split("-")[3];
    if (targetMachineBrand && targetMachinePrice) {
      dispatch(editDeductibleValue({ itemId: targetMachineId, value: value }));
      dispatch(calculateOneItem({ itemId: targetMachineId, country: country }));
    }
  };

  const onValueChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    dispatch(setValues({ name, value }));
    if (name === "protectType") {
      dispatch(calculate({ value }));
    }
  };

  useEffect(() => {
    dispatch(getOneContract(id));
    dispatch(setMachines({ machines: machines }));
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (record) {
    return (
      <>
        <div className="container-fluid calculator-page">
          {/* HEADER */}
          <div className="header fixed-top  d-flex align-items-center bg-light py-4">
            <div className="container-fluid">
              <NavigationHeader
                forms={forms}
                currentForm={Form3}
                currentStepIndex={3}
              ></NavigationHeader>
            </div>
          </div>

          <div className="calculator-content">
            <p className="lead my-4 fw-bold">
              versitech Maschinen-Versicherung
            </p>

            <div className="row gap-0">
              {/* FORMS */}
              <div className="col-12 col-md-9 col-lg-9 mx-0 position-relative">
                <div className="form-container">
                  <Alert></Alert>
                  <div className="col-12 ">
                    <div className="card bg-light  border-0">
                      <div className="card-body inner-form-container">
                        <div className="form">
                          <EditForm
                            //machines={machines.map(m=>{return {...m,_id:m.id,id:Math.ceil(Math.random()*1000)}})}
                            machines={machines}
                            values={record}
                            customerErrors={customerErrors}
                            onValueChange={onValueChange}
                            setTargetMachine={setTargetMachine}
                          ></EditForm>
                        </div>
                      </div>
                      <div className="card-footer calculator-footer d-flex bg-white  border-0 mt-3  py-4 ">
                        {/* NAVIGATION SECTION */}
                        <NavigationFooter
                          currentIndex={3}
                          currentForm={Form3}
                          nextStep={nextStep}
                          previousStep={previousStep}
                          setIsOpen={setIsOpen}
                          openModal={openModal}
                          isOpen={isOpen}
                          loadStep={loadStep}
                        ></NavigationFooter>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* BONUS CARD */}
              <div className="col-12 col-md-3 col-lg-3 mx-0 px-0">
                <BonusCard machines={machines}></BonusCard>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default EditContractPage;
