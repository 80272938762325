import React from 'react';
import { Navigate } from "react-router-dom";
import { getUserFromLocalStorage } from './localStorage';



function PermissionRoute({ children, redirectPath = "/permission-error", allowed }) {

    const user = getUserFromLocalStorage();

    if (![...allowed].includes(user?.role)) {
        return <Navigate to={redirectPath} replace></Navigate>
    }

    return children;
}

export default PermissionRoute;